import { TEN_SEC_STRIP_DETAIL } from 'constant/ChartEditConst';

import {
  TimeEventConstTypes,
  TimeEventType,
} from '@type/ecgEventType/baseEventType';
import { BeatsNEctopic } from '@dtoType/patchEcgs/beats/filter-waveformIndexRange';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { TimeEvent } from '@type/ecgEventType/eventType';

export enum timeEventListDataSource {
  'server' = 'server',
  'server_init' = 'server_init',
  'optimisticUpdate' = 'optimisticUpdate',
  'optimisticUpdate_update_EventId' = 'optimisticUpdate_update_EventId',
}

export enum optimisticEventDataUpdateCase {
  'postBeats' = 'postBeats',
  'patchBeats' = 'patchBeats',
  'patchBeatsByWaveformIndexList' = 'patchBeatsByWaveformIndexList',
  'patchBeatsByRangeList' = 'patchBeatsByRangeList',
  'deleteBeats' = 'deleteBeats',

  'postTimeEvent' = 'postTimeEvent',
  'postAfib' = 'postAfib',
  'postPause' = 'postPause',
  'postOthers' = 'postOthers',
  'deleteAfib' = 'deleteAfib',
  'deletePause' = 'deletePause',
  'deleteOthers' = 'deleteOthers',
}

// 10s strip header avg hr 값에 optimistic update가 적용된 경우
export enum hrValue {
  'optimisticEventDataUpdated' = 'optimisticEventDataUpdated',
}

//
export const optimisticTabList = [
  TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW,
  TEN_SEC_STRIP_DETAIL.TAB.BEAT_REVIEW,
];

// todo: jyoon(240529) - refactoring type
export type optimisticEventDataUpdateForTimeEventType = `${TimeEventType}`;

export type optimisticEventDataUpdateForTimeEventConstTypes =
  TimeEventConstTypes;
// `${TimeEventConstTypes}`;

export interface EventUpdateParam {
  tid: string;
  isRemove: boolean;
  timeEventType: optimisticEventDataUpdateForTimeEventType; // 이름 설정 잘못되어 있음 timeEventType이어야함
  onsetWaveformIndex: WaveformIndex;
  terminationWaveformIndex: WaveformIndex;
}
export interface optimisticEventDataUpdateForTimeEventParam {
  updateReqOption: EventUpdateParam;
}
export interface OptimisticEventDataUpdateForBeats extends BeatsNEctopic {
  hr: Array<number | null | hrValue>;
}

export interface TimeEventForOptimisticUpdate extends TimeEvent {
  isOptimisticEventDataUpdate: boolean;
}
