import styled from 'styled-components';

const Wrapper = styled.div`
  height: 0px;
  width: 100%;
  ${(props) => `border-bottom: 1px solid ${props.theme.color.COOL_GRAY_40}`};
`;

function Divider(props) {
  const { style } = props;

  return <Wrapper style={style}></Wrapper>;
}

export default Divider;
