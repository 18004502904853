import { TIME_EVENT_TYPE } from 'constant/EventConst';

import { getEventInfo } from 'util/EventConstUtil';

import { TimeEvent } from '@type/ecgEventType/eventType';
import { SelectedEventList } from '@type/optimisticUpdate/process';
import {
  TimeEventForOptimisticUpdate,
  optimisticEventDataUpdateForTimeEventConstTypes,
  optimisticEventDataUpdateForTimeEventParam,
} from '@type/optimisticUpdate/type';

import {
  EventHandlingParams,
  EventReviewOptimisticEventDataUpdateForTimeEvent,
} from './optimisticEventDataUpdateForTimeEvent';

export class OptimisticEventDataUpdateForAVBlock extends EventReviewOptimisticEventDataUpdateForTimeEvent {
  validation() {}
  //
  *optimisticEventData({
    updateReqOption,
  }: optimisticEventDataUpdateForTimeEventParam): Generator<any, void, any> {
    const isAddEvent = !updateReqOption.isRemove;
    const {
      leadOff: staleLeadOffList,
      data: staleTimeEventList,
    }: { leadOff: any; data: TimeEvent[] } = yield this.getTimeEventList();
    const {
      onset: onsetSelectionStrip,
      termination: terminationSelectionStrip,
    } = yield this.getSelectionStrip();

    if (isAddEvent) {
      yield* this.handleAddEvent({
        updateReqOption,
        //
        staleLeadOffList,
        staleTimeEventList,
        //
        onsetSelectionStrip,
        terminationSelectionStrip,
      });
    } else {
      yield* this.handleDeleteEvent({
        updateReqOption,
        //
        staleLeadOffList,
        staleTimeEventList,
        //
        onsetSelectionStrip,
        terminationSelectionStrip,
      });
    }
  }
  private *handleAddEvent({
    updateReqOption,
    //
    staleLeadOffList,
    staleTimeEventList,
    //
    onsetSelectionStrip,
    terminationSelectionStrip,
  }: EventHandlingParams) {
    /***************************************************************************************************************/
    /* STEP LIST                                                                                                   */
    /*                                                                                                             */
    /* * STEP1. remove Fully Inclusive Range from selectionStrip Range but, except other target timeEvent type     */
    /*                                                                                                             */
    /* * STEP2. make new event marker state                                                                        */
    /*    - selection marker range와 selection marker range에 포함되는                                             */
    /*    event의 onset, termination Ms 중에서 가장 작은 onset과 가장큰 termination이                              */
    /*                                                                                                             */
    /* 새로 만드는 new event marker state의 기준이 된다.                                                           */
    /***************************************************************************************************************/

    const freshLeadOffList = [...staleLeadOffList];
    const eventType = getEventInfo({
      timeEventType: updateReqOption.timeEventType,
    }).findOne()?.type as optimisticEventDataUpdateForTimeEventConstTypes;
    const avBlockList = getEventInfo({
      timeEventGroup: TIME_EVENT_TYPE.AV_BLOCK,
    });
    const exclusiveRange = staleTimeEventList.filter((staleTimeEvent) => {
      const isSameType = staleTimeEvent.type === eventType;
      const isAvBlock = avBlockList.some(
        (avBlockInfo) => avBlockInfo.type === staleTimeEvent.type
      );

      const conditionOfExclusiveRangeOfSelectionStrip =
        this.isExclusiveRangeOfSelectionStrip({
          onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
          terminationSelectionStrip: terminationSelectionStrip.clickedTimestamp,
          onsetRange: staleTimeEvent.onsetMs,
          terminationRange: staleTimeEvent.terminationMs,
        });

      const conditionOfIsFullyExclusiveRangeOfSelectionStrip =
        this.isFullyExclusiveRangeOfSelectionStrip({
          onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
          terminationSelectionStrip: terminationSelectionStrip.clickedTimestamp,
          onsetRange: staleTimeEvent.onsetMs,
          terminationRange: staleTimeEvent.terminationMs,
        });

      // todo: jyoon(240521) - 조건 정리, 설명 추가 필요
      return (
        // 같은 타입은 제외 또는 selectionStrip 구간이 event 구간을 포함하지 않는 경우(경계선 포함)
        (!isSameType || conditionOfExclusiveRangeOfSelectionStrip) &&
        // avBlock 아니고, 같은 타입 이벤트, selectionStrip 구간이 event 구간을 포함하지 않는 경우(경계선 미포함)
        !(
          isAvBlock &&
          !isSameType &&
          !conditionOfIsFullyExclusiveRangeOfSelectionStrip
        )
      );
    });

    // * STEP2. make new event marker state
    const partiallyInclusiveRangeOptionFalse = staleTimeEventList.filter(
      (staleTimeEvent) => {
        const isSameType = staleTimeEvent.type === eventType;
        const conditionOfIsPartiallyInclusiveRangeOfSelectionStrip =
          this.isPartiallyInclusiveRangeOfSelectionStrip(
            {
              onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
              terminationSelectionStrip:
                terminationSelectionStrip.clickedTimestamp,
              onsetRange: staleTimeEvent.onsetMs,
              terminationRange: staleTimeEvent.terminationMs,
            },
            { isIncludeBorder: false }
          );

        return (
          isSameType && conditionOfIsPartiallyInclusiveRangeOfSelectionStrip
        );
      }
    );

    let minOnsetMs = onsetSelectionStrip.clickedTimestamp;
    let maxTerminationMs = terminationSelectionStrip.clickedTimestamp;
    if (partiallyInclusiveRangeOptionFalse.length > 0) {
      minOnsetMs = Math.min(
        ...partiallyInclusiveRangeOptionFalse.map((event) => event.onsetMs),
        onsetSelectionStrip.clickedTimestamp
      );
      maxTerminationMs = Math.max(
        ...partiallyInclusiveRangeOptionFalse.map(
          (event) => event.terminationMs
        ),
        terminationSelectionStrip.clickedTimestamp
      );
    }

    let responseInfo: TimeEventForOptimisticUpdate[] = [];
    // # responseInfo.push case1. selection strip으로 추가되는 이벤트
    responseInfo.push({
      createAt: new Date().getTime(),
      durationMs: maxTerminationMs - minOnsetMs,
      onsetMs: minOnsetMs,
      terminationMs: maxTerminationMs,
      type: eventType,
      timeEventId: null,
      onsetRPeakIndex: null,
      position: null,
      isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
    });

    // leadoff 구간을 제외한 새로운 이벤트 만들어 냄
    responseInfo = this.getAvailableEventRange({
      excludedEventList: freshLeadOffList,
      eventType,
      responseInfo,
    });

    // avBlock event rule에 의해서 추가되는 케이스
    /****************************************************/
    /*                                                  */
    /*      [ *C ]               [ *C ]                 */
    /*           |               |      <--- border     */
    /*   [ A ]   (    [ *B ]     )   [ A ]              */
    /*           |               |      <--- border     */
    /*           [ *D ]     [ *D ]                      */
    /*           |               |      <--- border     */
    /*        [ *E ]          [ *E ]                    */
    /*        [         *F         ]                    */
    /*                                                  */
    /*           (              )       <--- 구간       */
    /****************************************************/
    const partiallyInclusiveRangeOtherAvBlockOptionFalse =
      staleTimeEventList.filter((staleTimeEvent) => {
        const isAvBlock = avBlockList.some(
          (avBlockInfo) => avBlockInfo.type === staleTimeEvent.type
        );
        const isSameType = staleTimeEvent.type === eventType;
        // [ A ] case
        const conditionOfIsFullyExclusiveRangeOfSelectionStrip =
          this.isFullyExclusiveRangeOfSelectionStrip({
            onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
            terminationSelectionStrip:
              terminationSelectionStrip.clickedTimestamp,
            onsetRange: staleTimeEvent.onsetMs,
            terminationRange: staleTimeEvent.terminationMs,
          });

        return (
          isAvBlock &&
          !isSameType &&
          !conditionOfIsFullyExclusiveRangeOfSelectionStrip
        );
      });

    //
    if (partiallyInclusiveRangeOtherAvBlockOptionFalse.length !== 0) {
      for (let item of partiallyInclusiveRangeOtherAvBlockOptionFalse) {
        // check onsetSelectionStrip, terminationSelectionStrip is in the range of item
        const isOnsetSelectionStripInItemRange =
          item.onsetMs <= onsetSelectionStrip.clickedTimestamp &&
          onsetSelectionStrip.clickedTimestamp <= item.terminationMs;
        const isTerminationSelectionStripInItemRange =
          item.onsetMs <= terminationSelectionStrip.clickedTimestamp &&
          terminationSelectionStrip.clickedTimestamp <= item.terminationMs;

        // todo: jyoon(240521) - [refactor] if(isOnsetSelectionStripInItemRange), if (isTerminationSelectionStripInItemRange) -> 두 condition 합치기
        // make new event marker state by overlapping Event
        if (isOnsetSelectionStripInItemRange) {
          // # responseInfo.push case2. avBlock event rule에 의해서 추가되는 케이스
          responseInfo.push({
            createAt: new Date().getTime(),
            durationMs: onsetSelectionStrip.clickedTimestamp - item.onsetMs,
            onsetMs: item.onsetMs,
            terminationMs: onsetSelectionStrip.clickedTimestamp,
            type: item.type as string,
            timeEventId: null,
            onsetRPeakIndex: null,
            position: null,
            isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
          });
        }

        // # responseInfo.push case2. avBlock event rule에 의해서 추가되는 케이스
        if (isTerminationSelectionStripInItemRange) {
          responseInfo.push({
            createAt: new Date().getTime(),
            durationMs:
              item.terminationMs - terminationSelectionStrip.clickedTimestamp,
            onsetMs: terminationSelectionStrip.clickedTimestamp,
            terminationMs: item.terminationMs,
            type: item.type,
            timeEventId: null,
            onsetRPeakIndex: null,
            position: null,
            isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
          });
        }
      }
    }

    // STEP3. STEP1 + STEP2
    let freshTimeEventList = [
      ...exclusiveRange,
      ...responseInfo,
    ] as TimeEvent[];
    freshTimeEventList = this.sortTimeEventList(freshTimeEventList);

    yield this.setTimeEventsList({
      freshLeadOffList,
      freshTimeEventList,
    });
  }
  private *handleDeleteEvent({
    updateReqOption,
    //
    staleLeadOffList,
    staleTimeEventList,
    //
    onsetSelectionStrip,
    terminationSelectionStrip,
  }: EventHandlingParams) {
    /*****************************************************/
    /* # 제거 되는 케이스 2가지                          */
    /*   CASE1. 이벤트 선택 제거 (isWholeUnMark = true)  */
    /*   CASE2. 구간 선택 제거 (isWholeUnMark = false)   */
    /*****************************************************/
    const freshLeadOffList = [...staleLeadOffList];
    const isWholeUnMark: unknown = yield this.getIsWholeUnMark();
    if (isWholeUnMark) {
      // CASE1. 이벤트 선택 제거 (isWholeUnMark = true)
      const selectedEventList: SelectedEventList[] =
        yield this.getSelectedEventList();
      const freshTimeEventList = staleTimeEventList.filter(
        (staleTimeEvent) =>
          staleTimeEvent.timeEventId !== selectedEventList[0].timeEventId
      );

      yield this.setTimeEventsList({
        freshLeadOffList,
        freshTimeEventList,
      });
    } else {
      // CASE2. 구간 선택 제거 (isWholeUnMark = false)
      const eventType = getEventInfo({
        timeEventType: updateReqOption?.timeEventType,
      }).findOne()?.type as optimisticEventDataUpdateForTimeEventConstTypes;
      /*****************************************************************************************************************/
      /*  STEP LIST                                                                                                    */
      /*  * STEP1. remove Fully Inclusive Range from selectionStrip Range but, except other target timeEvent type      */
      /*  * STEP2. create new event marker state by overlapping Event                                                  */
      /*****************************************************************************************************************/

      // todo: jyoon(240521) - [refactor] 대상(아래 설명 업데이트 추가 필요)
      //        timeEventList중 그대로 사용할 리스트

      // STEP1. remove Fully Inclusive Range from selectionStrip Range
      const fullyExclusiveRange = staleTimeEventList.filter(
        (staleTimeEvent) => {
          const conditionOfFullyExclusiveRangeOfSelectionStrip =
            this.isFullyExclusiveRangeOfSelectionStrip({
              onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
              terminationSelectionStrip:
                terminationSelectionStrip.clickedTimestamp,
              onsetRange: staleTimeEvent.onsetMs,
              terminationRange: staleTimeEvent.terminationMs,
            });
          return (
            staleTimeEvent.type !== eventType ||
            conditionOfFullyExclusiveRangeOfSelectionStrip // 다른 타입은 제외
          );
        }
      );

      // STEP2. create new event marker state by overlapping Event
      const partiallyInclusiveRange = staleTimeEventList.filter(
        (staleTimeEvent) => {
          const conditionOfIsPartiallyInclusiveRangeOfSelectionStrip =
            this.isPartiallyInclusiveRangeOfSelectionStrip(
              {
                onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
                terminationSelectionStrip:
                  terminationSelectionStrip.clickedTimestamp,
                onsetRange: staleTimeEvent.onsetMs,
                terminationRange: staleTimeEvent.terminationMs,
              },
              { isIncludeBorder: true }
            );

          return (
            staleTimeEvent.type === eventType &&
            conditionOfIsPartiallyInclusiveRangeOfSelectionStrip
          );
        }
      );

      // todo: jyoon(240521) - [refactor] 대상
      const onsetOverlappingEvent = this.findOverLappingEvent({
        baseSelectionStripTimeStamp: onsetSelectionStrip.clickedTimestamp,
        partiallyInclusiveRange,
      });
      const terminationOverlappingEvent = this.findOverLappingEvent({
        baseSelectionStripTimeStamp: terminationSelectionStrip.clickedTimestamp,
        partiallyInclusiveRange,
      });

      const responseInfo: TimeEventForOptimisticUpdate[] = [];
      if (onsetOverlappingEvent) {
        responseInfo.push({
          createAt: new Date().getTime(),
          durationMs:
            onsetSelectionStrip.clickedTimestamp -
            onsetOverlappingEvent.onsetMs,
          onsetMs: onsetOverlappingEvent.onsetMs,
          terminationMs: onsetSelectionStrip.clickedTimestamp,
          type: eventType || onsetOverlappingEvent.type,
          timeEventId: null,
          onsetRPeakIndex: null,
          position: null,
          isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
        });
      }
      if (terminationOverlappingEvent) {
        responseInfo.push({
          createAt: new Date().getTime(),
          durationMs:
            terminationOverlappingEvent.terminationMs -
            terminationSelectionStrip.clickedTimestamp,
          onsetMs: terminationSelectionStrip.clickedTimestamp,
          terminationMs: terminationOverlappingEvent.terminationMs,
          type: eventType || terminationOverlappingEvent.type,
          timeEventId: null,
          onsetRPeakIndex: null,
          position: null,
          isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
        });
      }

      let freshTimeEventList = [
        ...fullyExclusiveRange,
        ...responseInfo,
      ] as TimeEvent[];
      freshTimeEventList = this.sortTimeEventList(freshTimeEventList);

      yield this.setTimeEventsList({
        freshLeadOffList,
        freshTimeEventList,
      });
    }
  }
}
