import { SUFFIX_LIST } from 'constant/EventConst';

import { EventType } from '@type/ecgEventType/baseEventType';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';

export interface TaskQueueState {
  requestQueue: any[]; // requestQueue의 실제 타입을 알 수 없으므로 any[]를 사용
  processingTask: NewRequestInfo | null; // processingTask의 실제 타입을 알 수 없으므로 any를 사용
  queueValidation: boolean;
}
export interface NewRequestInfo {
  requestStatement: RequestStatement;
  // requestStatement: RequestStatementOfBeatEvent | RequestStatementOfOthers;
  calls?: any; // POST_TIME_EVENT에서만 사용
  requestPosition?: number;
  beatEventWaveformIndex?: WaveformIndex[] | undefined;
  getAction?: (arg: any) => any; //GET_TIME_EVENT, GET_EVENT_DETAIL에서만 사용
  succeedCallback?: (arg: any) => void;
  failedCallback?: (arg: any) => void;
}

export interface ProcessingTask extends NewRequestInfo {}

export interface TaskQueueAction {
  type: string;
  payload: {
    newRequestInfo: NewRequestInfo;
  };
}

interface RequestStatementOfBeatEvent {
  ecgTestId: any;
  requestType: string;
  reqBody: any;
}
interface RequestStatementOfOthers {
  tid?: any;
  reqTime?: any;
  type?: any;
  isRemove?: any;
  eventType?: any;
  timeEventType?: any;
  groupType?: any;
  //
  onsetWaveformIndex?: any;
  terminationWaveformIndex?: any;
  onsetWaveformIndexes?: any;
  terminationWaveformIndexes?: any;
}
interface RequestStatement {
  type: typeof taskQueueType;
  requestType: string;
  eventType: string;
  reqBody: any;
  ecgTestId: string;
  postProcessEditedTimeEvent: (arg: any) => void; // POST_PROCESS_EDITED_TIME_EVENT 케이스에서만 사용
}

export enum ActionType {
  Edit = 'Edit',
  Get = 'Get',
  PostProcess = 'PostProcess',
}
export enum EditType {
  Add = 'Add',
  Remove = 'Remove',
  Edit = 'Edit',
}
export const taskQueueType = {
  POST_BEAT_EVENT: {
    action: SUFFIX_LIST.EDIT_BEAT_SUFFIX.ADD_BEATS,
    actionType: ActionType.Edit,
    editType: EditType.Add,
    eventType: EventType.BeatType,
  },
  REMOVE_BEATS: {
    action: SUFFIX_LIST.EDIT_BEAT_SUFFIX.REMOVE_BEATS,
    actionType: ActionType.Edit,
    editType: EditType.Remove,
    eventType: EventType.BeatType,
  },
  PATCH_BEAT_EVENT_BY_WAVEFORM_INDEXES: {
    action: SUFFIX_LIST.EDIT_BEAT_SUFFIX.UPDATE_BEATS_BY_INDEXES,
    actionType: ActionType.Edit,
    editType: EditType.Edit,
    eventType: EventType.BeatType,
  },
  PATCH_BEAT_EVENT_BY_BETWEEN_INDEX: {
    action: SUFFIX_LIST.EDIT_BEAT_SUFFIX.UPDATE_BEATS_BY_BETWEEN_INDEX,
    actionType: ActionType.Edit,
    editType: EditType.Edit,
    eventType: EventType.BeatType,
  },
  FETCH_BEAT_EVENT: {
    action: 'updates/between-waveform-index',
    actionType: ActionType.Get,
    editType: '',
    eventType: EventType.BeatType,
  },
  POST_TIME_EVENT: {
    action: 'POST_TIME_EVENT',
    actionType: ActionType.Edit,
    editType: [EditType.Add, EditType.Edit, EditType.Remove],
    eventType: EventType.TimeEventType,
  },
  GET_TIME_EVENTS_LIST: {
    action: 'GET_TIME_EVENTS_LIST',
    actionType: ActionType.Get,
    editType: '',
    eventType: '',
  },
  GET_EVENT_DETAIL: {
    action: 'GET_EVENT_DETAIL',
    actionType: ActionType.Get,
    editType: '',
    eventType: '',
  },
  POST_PROCESS_EDITED_TIME_EVENT: {
    action: 'POST_PROCESS_EDITED_TIME_EVENT',
    actionType: ActionType.PostProcess,
    editType: '',
    eventType: '',
  },
} as const;

export const getTaskQueueTypeByQuery = ({
  target = '',
  value = '',
}): typeof taskQueueType | {} => {
  if (!target && !value) return {};

  return Object.fromEntries(
    Object.entries(taskQueueType).filter((v) => {
      return v[1][target] === value;
    })
  );
};
