import styled from 'styled-components';

import Const from 'constant/Const';
import { CONTEXT_MENU } from 'constant/EventConst';
import {
  CheckBoxAll,
  ShapeReviewSectionArea,
  shapeReviewLabel,
} from 'constant/ShapeReviewConst';

import DateUtil from 'util/DateUtil';

import useKeyEventListener, {
  keyboardEventInterfaceMap,
} from 'component/hook/useKeyEventListener';

import TypeContent from 'component/fragment/test-result/side-panel/TypeContent';
import OverallTogo from 'component/fragment/test-result/side-panel/OverallTogo';

import Button from 'component/ui/button/Button';
import Tooltip from 'component/ui/tooltip/Tooltip';
import ButtonGroup from 'component/ui/buttonGroup/ButtonGroup';

import { ReactComponent as FolderIcon } from 'static/icon/ic-folder.svg';
import { ReactComponent as InfoIcon } from 'static/icon/ic-info.svg';
import { ReactComponent as BeatIcon } from 'static/icon/ic-beat.svg';
import { ReactComponent as OpenInNewIcon } from 'static/icon/ic-open-in-new.svg';

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TitleWrapper = styled.div`
  height: 38px;
  padding: 9px 18px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-bottom: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => props.theme.color.COOL_GRAY_90};
`;
const EditedBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 3px;
  height: 14px;
  border-radius: 2px;
  font-size: 11px;
  ::after {
    content: '${shapeReviewLabel.Edited}';
  }
  color: ${(props) => props.theme.color.WHITE};
  background-color: ${(props) => props.theme.color.BLUE_70};
`;
const ContentWrapper = styled.div`
  padding: 12px 18px;
`;
const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 11px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;
const PlaceHolderWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 4px;
`;
const PlaceHolderText = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 150%;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;

const BeatIconWrapper = styled(BeatIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: ${(props) => props.theme.color.COOL_GRAY_90};
  }
`;
const FolderIconWrapper = styled(FolderIcon)`
  width: 20px;
  height: 20px;
`;
const InfoIconWrapper = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  path {
    fill: ${(props) => props.theme.color.COOL_GRAY_70};
  }
`;
const PlaceHolderDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PlaceHolderDivText = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  width: 60%;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;

const FIVE_SECONDS_IN_MILLISECONDS = 5000;
const KEYBOARD_EVENT_LISTEN_LIST = [
  Const.KEY_MAP.KEY_Q,
  Const.KEY_MAP.KEY_W,
  Const.KEY_MAP.KEY_E,
  Const.KEY_MAP.KEY_R,
  Const.KEY_MAP.KEY_Z,
];

function SelectedItemPanelFragment({
  selectedBeatType,
  activePanel,
  handlePatchBeatsByFormIdRequested,
  handlePatchBeatsByWaveformIndexRequested,
  handleDeleteBeatsByWaveformIndexRequested,
  handleDeleteBeatsByFormIdRequested,
  formRankNumber,
  distributionRatio,
  numberOfChecked,
  prevHrBpm,
  beatHrBpm,
  nextHrBpm,
  isCoupletEctopicTypeClicked,
  isAllChecked,
  isSelectedForms,
  isSelectedEvents,
  isClickedFormEdited,
  isClickedEventEdited,
  isMultiSelectedForms,
  isMultiSelectedEvents,
  tenSecStripOnsetMs,
  beatCount,
  isTenSecStripEditMode,
  numberOfClickedForms,
  hasOriginWaveformIndex,
  hasTerminationWaveformIndex,
  openNewTabHandler,
  onClickButtonGroupDataListHandler,
  itemPanelTitle,
  removeBeatSetting,
}: any) {
  const { BEAT_TYPE } = CONTEXT_MENU;

  // 이벤트 영역의 이벤트를 한개만 선택했을 경우(ectopic 비트가 모두 존재할 때) - bpm, hr 정보 영역 노출
  const isConditionEventSelectionAboutInfo =
    activePanel === ShapeReviewSectionArea.EVENTS &&
    hasOriginWaveformIndex &&
    hasTerminationWaveformIndex &&
    !isMultiSelectedEvents;

  // 이벤트 영역에서 이벤트를(여러개) 선택하는 경우 편집 버튼 영역 노출 케이스 조건
  // 1. ectopic 비트가 존재하는 케이스 ||
  // 2. 여러개의 이벤트를 선택하는 케이스
  const isConditionOfEventSelectionAboutEdit =
    activePanel === ShapeReviewSectionArea.EVENTS &&
    (hasOriginWaveformIndex ||
      hasTerminationWaveformIndex ||
      isMultiSelectedEvents);

  // 이벤트 영역에서 이벤트를(여러개) 선택하는 경우 편집 리포트 버튼 영역 노출 케이스 조건
  // 1. ectopic 비트가 반드시 존재하는 케이스 &&
  // 2. 여러개의 이벤트를 선택하지 않는 케이스
  const isConditionOfEventSelectionAboutReport =
    activePanel === ShapeReviewSectionArea.EVENTS &&
    hasOriginWaveformIndex &&
    hasTerminationWaveformIndex &&
    !isMultiSelectedEvents;

  // Event 비트가 삭제된 Event 클릭된 케이스
  // isMultiSelectedEvents는 여러개 선택이 되어이 있는 상태에서 ctrl + 이동, 클릭으로 다른 이벤트에 포커스 했을때 노출되지 않기위해 추가
  const isPanelDisabledOfDeletedEvents =
    hasOriginWaveformIndex === false &&
    hasTerminationWaveformIndex === false &&
    isMultiSelectedEvents === false &&
    activePanel === ShapeReviewSectionArea.EVENTS;

  // 최초 진입 시 form영역에 선택 되어있는 경우, 선택된 폼은 없는 케이스
  const isNoFormSelected =
    activePanel === ShapeReviewSectionArea.FORMS && !isSelectedForms;

  // 최초 진입 시 선택된 Event, Form은 없고 Events 영역만 클릭한 케이스
  const isFormsAndEventsUnselected =
    isAllChecked['FORMS'] === CheckBoxAll.None &&
    isAllChecked['EVENTS'] === CheckBoxAll.None;

  // Events 영역에 클릭 되고 Event는 따로 선택되지 않은 케이스
  const isSelectedEventsPanelAndNoSelectedEvents =
    hasOriginWaveformIndex === undefined &&
    hasTerminationWaveformIndex === undefined &&
    isAllChecked['EVENTS'] === CheckBoxAll.None &&
    activePanel === ShapeReviewSectionArea.EVENTS;

  const buttonGroupDataList = [
    {
      id: BEAT_TYPE.NORMAL.label,
      dataValue: BEAT_TYPE.NORMAL.value,
      dataClicked: selectedBeatType === BEAT_TYPE.NORMAL.value,
      color:
        selectedBeatType === BEAT_TYPE.NORMAL.value ? 'pressed' : 'secondary',
      title: (
        <EditTitle
          label={BEAT_TYPE.NORMAL.shortLabel}
          shortcut={BEAT_TYPE.NORMAL.shortcut}
          value={BEAT_TYPE.NORMAL.value}
        />
      ),
      onClick: () => {
        onClickButtonGroupDataListHandler(() => {
          if (activePanel === ShapeReviewSectionArea.EVENTS) {
            handlePatchBeatsByWaveformIndexRequested({
              beatType: BEAT_TYPE.NORMAL.value,
            });
          } else if (activePanel === ShapeReviewSectionArea.FORMS) {
            handlePatchBeatsByFormIdRequested({
              beatType: BEAT_TYPE.NORMAL.value,
            });
          }
        });
      },
    },
    {
      id: BEAT_TYPE.APC.label,
      dataValue: BEAT_TYPE.APC.value,
      dataClicked: selectedBeatType === BEAT_TYPE.APC.value,
      color: selectedBeatType === BEAT_TYPE.APC.value ? 'pressed' : 'secondary',
      title: (
        <EditTitle
          label={BEAT_TYPE.APC.shortLabel}
          shortcut={BEAT_TYPE.APC.shortcut}
          value={BEAT_TYPE.APC.value}
        />
      ),
      onClick: () => {
        onClickButtonGroupDataListHandler(() => {
          if (activePanel === ShapeReviewSectionArea.EVENTS) {
            handlePatchBeatsByWaveformIndexRequested({
              beatType: BEAT_TYPE.APC.value,
            });
          } else if (activePanel === ShapeReviewSectionArea.FORMS) {
            handlePatchBeatsByFormIdRequested({
              beatType: BEAT_TYPE.APC.value,
            });
          }
        });
      },
    },
    {
      id: BEAT_TYPE.VPC.label,
      dataValue: BEAT_TYPE.VPC.value,
      dataClicked: selectedBeatType === BEAT_TYPE.VPC.value,
      color: selectedBeatType === BEAT_TYPE.VPC.value ? 'pressed' : 'secondary',
      title: (
        <EditTitle
          label={BEAT_TYPE.VPC.shortLabel}
          shortcut={BEAT_TYPE.VPC.shortcut}
          value={BEAT_TYPE.VPC.value}
        />
      ),
      onClick: () => {
        onClickButtonGroupDataListHandler(() => {
          if (activePanel === ShapeReviewSectionArea.EVENTS) {
            handlePatchBeatsByWaveformIndexRequested({
              beatType: BEAT_TYPE.VPC.value,
            });
          } else if (activePanel === ShapeReviewSectionArea.FORMS) {
            handlePatchBeatsByFormIdRequested({
              beatType: BEAT_TYPE.VPC.value,
            });
          }
        });
      },
    },
    {
      id: BEAT_TYPE.QUESTIONABLE.label,
      dataValue: BEAT_TYPE.QUESTIONABLE.value,
      dataClicked: selectedBeatType === BEAT_TYPE.QUESTIONABLE.value,
      color:
        selectedBeatType === BEAT_TYPE.QUESTIONABLE.value
          ? 'pressed'
          : 'secondary',
      title: (
        <EditTitle
          label={BEAT_TYPE.QUESTIONABLE.shortLabel}
          shortcut={BEAT_TYPE.QUESTIONABLE.shortcut}
          value={BEAT_TYPE.QUESTIONABLE.value}
        />
      ),
      onClick: () => {
        onClickButtonGroupDataListHandler(() => {
          if (activePanel === ShapeReviewSectionArea.EVENTS) {
            handlePatchBeatsByWaveformIndexRequested({
              beatType: BEAT_TYPE.QUESTIONABLE.value,
            });
          } else if (activePanel === ShapeReviewSectionArea.FORMS) {
            handlePatchBeatsByFormIdRequested({
              beatType: BEAT_TYPE.QUESTIONABLE.value,
            });
          }
        });
      },
    },
  ];
  useKeyEventListener(
    Const.EVENT_TYPE.KEYUP,
    [...KEYBOARD_EVENT_LISTEN_LIST],
    (event: KeyboardEvent) => {
      // 10s의 비트를 선택했을때, add beat 중일때 사이드 패널의 단축키 비활성화
      if (isTenSecStripEditMode) return;
      onKeyPress({
        event,
        numberOfChecked,
        buttonGroupDataList,
        removeBeatSetting,
        onClickButtonGroupDataListHandler,
      });
    },
    keyboardEventInterfaceMap.code
  );

  return (
    <Wrapper>
      <TitleWrapper>
        {
          <>
            {!isSelectedForms && !isSelectedEvents && (
              <Title>Selected Item</Title>
            )}
            {isSelectedForms && <FolderIconWrapper />}
            {isSelectedEvents && <BeatIconWrapper />}
            {(isSelectedForms || isSelectedEvents) && (
              <Title>{itemPanelTitle}</Title>
            )}
            {((isSelectedForms &&
              isClickedFormEdited &&
              !isMultiSelectedForms &&
              formRankNumber !== 0) ||
              (isSelectedEvents &&
                isClickedEventEdited &&
                !isMultiSelectedEvents)) && <EditedBadge />}
          </>
        }
      </TitleWrapper>
      {isSelectedForms && (
        <>
          {!isMultiSelectedForms && (
            <ContentWrapper>
              <ContentTitle style={{ marginBottom: 0 }}>정보</ContentTitle>
              <TypeContent
                type={'Number of events included'}
                content={`${beatCount.toLocaleString()} events`}
              />
              <TypeContent
                type={'Proportion of events included'}
                content={
                  isNaN(distributionRatio) ? 'N/A' : distributionRatio + '%'
                }
              />
            </ContentWrapper>
          )}
          <ContentWrapper>
            <ContentTitle>편집 / 제거</ContentTitle>
            <ButtonGroup style={{ height: 28 }}>
              {buttonGroupDataList.map((buttonGroupData) => {
                const { id, dataValue, dataClicked, color, title, onClick } =
                  buttonGroupData;

                const getDataTestId = (value: number) => {
                  switch (value) {
                    case BEAT_TYPE.NORMAL.value:
                      return 'shape-review-selected-item-panel-normal-beat-button';
                    case BEAT_TYPE.APC.value:
                      return 'shape-review-selected-item-panel-apc-beat-button';
                    case BEAT_TYPE.VPC.value:
                      return 'shape-review-selected-item-panel-vpc-beat-button';
                    case BEAT_TYPE.QUESTIONABLE.value:
                      return 'shape-review-selected-item-panel-questionable-beat-button';
                    default:
                      return '';
                  }
                };

                return (
                  <Button
                    dataTestId={getDataTestId(dataValue)}
                    disabled={!numberOfChecked && !isTenSecStripEditMode}
                    key={id}
                    id={id}
                    data-value={dataValue}
                    data-clicked={dataClicked}
                    color={color}
                    style={{ height: 28 }}
                    title={title}
                    onClick={onClick}
                    outline
                  />
                );
              })}
            </ButtonGroup>
            <div style={{ height: 28, marginTop: 8 }}>
              <Button
                disabled={!numberOfChecked && !isTenSecStripEditMode}
                color={'secondary'}
                outline
                style={{
                  width: '192px',
                  height: '28px',
                }}
                iconColor={'COOL_GRAY_90'}
                text={'Remove'}
                onClick={() => {
                  onClickButtonGroupDataListHandler(() => {
                    if (activePanel === ShapeReviewSectionArea.EVENTS) {
                      handleDeleteBeatsByWaveformIndexRequested({});
                    } else if (activePanel === ShapeReviewSectionArea.FORMS) {
                      handleDeleteBeatsByFormIdRequested({});
                    }
                  });
                }}
              />
            </div>
            <PlaceHolderWrapper>
              <InfoIconWrapper />
              <PlaceHolderText>
                {`선택한 ${numberOfClickedForms}개의 Form에 포함된 모든 Beat가 편집/제거됩니다.`}
              </PlaceHolderText>
            </PlaceHolderWrapper>
          </ContentWrapper>
        </>
      )}
      <>
        {isConditionEventSelectionAboutInfo && (
          <ContentWrapper>
            <ContentTitle style={{ marginBottom: 0 }}>정보</ContentTitle>

            <TypeContent
              type={'Previous beat HR'}
              content={
                typeof prevHrBpm === 'number'
                  ? prevHrBpm + ' bpm' ?? '-'
                  : prevHrBpm
              }
            />
            {!isCoupletEctopicTypeClicked && (
              <TypeContent
                type={'Beat HR'}
                content={
                  typeof beatHrBpm === 'number'
                    ? beatHrBpm + ' bpm' ?? '-'
                    : beatHrBpm
                }
              />
            )}
            {isCoupletEctopicTypeClicked && (
              <TypeContent
                type={'1st beat HR'}
                content={
                  typeof beatHrBpm === 'number'
                    ? beatHrBpm + ' bpm' ?? '-'
                    : beatHrBpm
                }
              />
            )}
            {isCoupletEctopicTypeClicked && (
              <TypeContent
                type={'2nd beat HR'}
                content={
                  typeof nextHrBpm === 'number'
                    ? nextHrBpm + ' bpm' ?? '-'
                    : nextHrBpm
                }
              />
            )}
            <TypeContent
              type={'Time'}
              content={DateUtil.format(
                new Date(tenSecStripOnsetMs + FIVE_SECONDS_IN_MILLISECONDS),
                'yyyy-MM-dd, HH:mm:ss'
              )}>
              <OverallTogo
                selectedTimeMs={
                  new Date(tenSecStripOnsetMs + FIVE_SECONDS_IN_MILLISECONDS)
                }
                onClick={openNewTabHandler}
              />
            </TypeContent>
          </ContentWrapper>
        )}
        {isConditionOfEventSelectionAboutEdit && (
          <ContentWrapper>
            <ContentTitle>편집 / 제거 </ContentTitle>
            <ButtonGroup style={{ height: 28 }}>
              {buttonGroupDataList.map((buttonGroupData) => {
                const { id, dataValue, dataClicked, color, title, onClick } =
                  buttonGroupData;
                return (
                  <Button
                    disabled={!numberOfChecked || isTenSecStripEditMode}
                    key={id}
                    id={id}
                    data-value={dataValue}
                    data-clicked={dataClicked}
                    color={color}
                    style={{ height: 28 }}
                    title={title}
                    onClick={onClick}
                    outline
                  />
                );
              })}
            </ButtonGroup>
            <div style={{ height: 28, marginTop: 8 }}>
              <Button
                disabled={!numberOfChecked || isTenSecStripEditMode}
                color={'secondary'}
                outline
                style={{
                  width: '192px',
                  height: '28px',
                }}
                iconColor={'COOL_GRAY_90'}
                text={'Remove'}
                onClick={() => {
                  onClickButtonGroupDataListHandler(() => {
                    if (activePanel === ShapeReviewSectionArea.EVENTS) {
                      handleDeleteBeatsByWaveformIndexRequested({});
                    } else if (activePanel === ShapeReviewSectionArea.FORMS) {
                      handleDeleteBeatsByFormIdRequested({});
                    }
                  });
                }}
              />
            </div>
            <PlaceHolderWrapper>
              <InfoIconWrapper />
              <PlaceHolderText>
                {`${
                  numberOfChecked ? numberOfChecked.toLocaleString() : 0
                }개의 Beat가 편집 / 제거됩니다.`}
              </PlaceHolderText>
            </PlaceHolderWrapper>
          </ContentWrapper>
        )}

        {isConditionOfEventSelectionAboutReport && (
          <ContentWrapper>
            <ContentTitle>리포트</ContentTitle>
            <Tooltip
              title={
                '편집된 이벤트는 정렬(Arrange) 후에 리포트에 담을 수 있습니다.'
              }
              disableHoverListener={!isClickedEventEdited}>
              <Button
                disabled={
                  isClickedEventEdited ||
                  !hasOriginWaveformIndex ||
                  !hasTerminationWaveformIndex
                }
                color={'secondary'}
                outline
                style={{
                  width: '192px',
                  height: '28px',
                }}
                startIcon={<OpenInNewIcon />}
                iconColor={'COOL_GRAY_90'}
                text={'리포트 담기'}
                onClick={openNewTabHandler}
              />
            </Tooltip>
          </ContentWrapper>
        )}

        {isPanelDisabledOfDeletedEvents ? (
          <PlaceHolderDiv>
            <PlaceHolderDivText>
              선택한 Form 또는 Event의 정보가 이곳에 표시됩니다.
            </PlaceHolderDivText>
          </PlaceHolderDiv>
        ) : isNoFormSelected ? (
          <PlaceHolderDiv>
            <PlaceHolderDivText>
              선택한 Form 또는 Event의 정보가 이곳에 표시됩니다.
            </PlaceHolderDivText>
          </PlaceHolderDiv>
        ) : isFormsAndEventsUnselected ? (
          // 최초 진입 시 선택된 Event, Form은 없고 Events 영역만 클릭한 케이스
          <PlaceHolderDiv>
            <PlaceHolderDivText>
              선택한 Form 또는 Event의 정보가 이곳에 표시됩니다.
            </PlaceHolderDivText>
          </PlaceHolderDiv>
        ) : isSelectedEventsPanelAndNoSelectedEvents ? (
          <PlaceHolderDiv>
            <PlaceHolderDivText>
              선택한 Form 또는 Event의 정보가 이곳에 표시됩니다.
            </PlaceHolderDivText>
          </PlaceHolderDiv>
        ) : null}
      </>
    </Wrapper>
  );
}

export default SelectedItemPanelFragment;

interface IEditTitleProps {
  label: string;
  shortcut: string;
  value: number;
}
const EditTitle = ({ label, shortcut, value }: IEditTitleProps) => (
  <div id={label} data-index={value}>
    {label}(
    <u id={label} data-index={value}>
      {shortcut}
    </u>
    )
  </div>
);

interface RemoveBeatSetting {
  activePanel: string;
  handleDeleteBeatsByWaveformIndexRequested: ({}) => void;
  handleDeleteBeatsByFormIdRequested: ({}) => void;
}
interface OnKeyPressParams {
  event: KeyboardEvent;
  numberOfChecked: number;
  buttonGroupDataList: any[];
  removeBeatSetting: RemoveBeatSetting;
  onClickButtonGroupDataListHandler: ({ params }: any) => void;
}

function onKeyPress({
  event,
  numberOfChecked,
  buttonGroupDataList,
  removeBeatSetting,
  onClickButtonGroupDataListHandler,
}: OnKeyPressParams) {
  const {
    activePanel,
    handleDeleteBeatsByWaveformIndexRequested,
    handleDeleteBeatsByFormIdRequested,
  } = removeBeatSetting;

  const { code } = event;
  const [
    normalBeatButtonData,
    apcBeatButtonData,
    vpcBeatButtonData,
    questionableBeatButtonData,
  ] = buttonGroupDataList;

  if (!numberOfChecked) return;
  switch (code) {
    case Const.KEY_MAP.KEY_Q: // q, 0
      normalBeatButtonData.onClick();
      break;
    case Const.KEY_MAP.KEY_W: // w, 1
      apcBeatButtonData.onClick();
      break;
    case Const.KEY_MAP.KEY_E: // e, 2
      vpcBeatButtonData.onClick();
      break;
    case Const.KEY_MAP.KEY_R: // r, 3
      questionableBeatButtonData.onClick();
      break;
    case Const.KEY_MAP.KEY_Z: // z, remove
      onClickButtonGroupDataListHandler(() => {
        if (activePanel === ShapeReviewSectionArea.EVENTS) {
          handleDeleteBeatsByWaveformIndexRequested({});
        } else if (activePanel === ShapeReviewSectionArea.FORMS) {
          handleDeleteBeatsByFormIdRequested({});
        }
      });
      break;
    default:
      return;
  }
}
