import { select } from 'redux-saga/effects';

import { EVENT_CONST_TYPES } from 'constant/EventConst';

import { calculateHeartRate } from 'util/reduxDuck/TestResultDuckUtil';

import { BeatType } from '@type/ecgEventType/baseEventType';
import { OptimisticEventDataUpdateForBeats } from '@type/optimisticUpdate/type';
import {
  selectRecordingTime,
  selectTimeEventList,
} from 'redux/duck/testResultDuck';
import { TimeEvent } from '@type/ecgEventType/eventType';

import { PatchBeat, Validation } from './eventReview_PatchBeat';

import {
  ReqBody,
  UpdateReqOptionProps,
} from '../optimisticEventDataUpdateForBeatEvent';
import { Command } from '../../eventUpdateCmdPattern';

export class PatchBeatByWaveformIndexList extends PatchBeat {
  result: any;
  reqParam: any;

  constructor(reqParam: any) {
    super();
    this.reqParam = reqParam;
  }

  *optimisticEventDataUpdate({
    updateReqOption,
    filterBeatsNEctopicList,
  }: UpdateReqOptionProps) {
    let result: OptimisticEventDataUpdateForBeats = {
      hr: [],
      beatType: [],
      waveformIndex: [],
    };
    const { reqBody } = updateReqOption;

    const mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats =
      this.mergeBeatsNEctopicList(filterBeatsNEctopicList);
    const copyMergedBeatsNEctopicList = this.rfdcClone(mergedBeatsNEctopicList);

    // STEP1: beatUpdate
    result = this.updateLogic({ reqBody, mergedBeatsNEctopicList });
    // STEP2: validation
    result = yield this._validation({
      reqBody,
      updateReqOption,
      mergedBeatsNEctopicList: result,
      copyMergedBeatsNEctopicList,
    });

    this.result = this.setOptimisticEventDataUpdateResult({
      isValidationSuccessful: true,
      result,
    });
  }

  updateLogic({
    reqBody,
    mergedBeatsNEctopicList,
  }: {
    reqBody: ReqBody;
    mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
  }): OptimisticEventDataUpdateForBeats {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    for (let idx = 0; idx < reqBody.waveformIndexes.length; idx++) {
      const updateTargetWaveform = reqBody.waveformIndexes[idx];
      const updateTargetWaveformIndexIndex: number =
        waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex === updateTargetWaveform
        );

      const isTargetBeatNoise =
        beatTypeList[updateTargetWaveformIndexIndex] === BeatType.NOISE;
      const isPrevBeatNoise =
        beatTypeList[updateTargetWaveformIndexIndex - 1] === BeatType.NOISE;
      const isNextBeatNoise =
        beatTypeList[updateTargetWaveformIndexIndex + 1] === BeatType.NOISE;

      if (isTargetBeatNoise) {
        hrList[updateTargetWaveformIndexIndex] = isPrevBeatNoise
          ? null
          : calculateHeartRate(
              waveformIndexList[updateTargetWaveformIndexIndex],
              waveformIndexList[updateTargetWaveformIndexIndex - 1]
            );

        hrList[updateTargetWaveformIndexIndex + 1] = isNextBeatNoise
          ? null
          : calculateHeartRate(
              waveformIndexList[updateTargetWaveformIndexIndex + 1],
              waveformIndexList[updateTargetWaveformIndexIndex]
            );
      }

      // 요청한 비트 타입으로 변경
      beatTypeList[updateTargetWaveformIndexIndex] = reqBody.beatType;

      // 비트를 Noise(Q비트)로 변경 시
      if (reqBody.beatType === BeatType.NOISE) {
        hrList[updateTargetWaveformIndexIndex] = null;
        hrList[updateTargetWaveformIndexIndex + 1] = null;
      }
    }

    return mergedBeatsNEctopicList;
  }

  *_validation({
    reqBody,
    updateReqOption,
    mergedBeatsNEctopicList,
    copyMergedBeatsNEctopicList,
  }: Validation): any {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    if (reqBody.beatType === BeatType.APC) {
      const EVENT_CONST_TYPE_AF = EVENT_CONST_TYPES.AF;
      const { recordingStartMs } = yield select(selectRecordingTime);
      const afList: TimeEvent[] = yield select((state) =>
        selectTimeEventList(state, EVENT_CONST_TYPE_AF)
      );

      reqBody.waveformIndexes.forEach((updateTargetWaveform) => {
        const updateTargetWaveformIndexIndex = waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex === updateTargetWaveform
        );
        const selectedEventTimeStamp =
          recordingStartMs + updateTargetWaveform * 4;
        const isWithinAfEvent = afList.some(
          (afInfo) =>
            selectedEventTimeStamp >= afInfo.onsetMs &&
            selectedEventTimeStamp <= afInfo.terminationMs
        );

        if (isWithinAfEvent) {
          hrList[updateTargetWaveformIndexIndex] = null;
          beatTypeList[updateTargetWaveformIndexIndex] =
            copyMergedBeatsNEctopicList.beatType[
              updateTargetWaveformIndexIndex
            ];
          waveformIndexList[updateTargetWaveformIndexIndex] =
            updateTargetWaveform;
        }
      });
    }

    return yield this.validation({
      reqBody,
      updateReqOption,
      mergedBeatsNEctopicList,
      copyMergedBeatsNEctopicList,
    });
  }

  getResult() {
    return this.result;
  }
}

// ### COMMAND 역할
export class PatchBeatByWaveformIndexListCommand {
  command: any;
  executeInst: any;

  constructor(value: any) {
    this.command = new Command(PatchBeatByWaveformIndexList, null, value);
  }

  *execute() {
    this.executeInst = new this.command.executeClass(this.command.value);
    yield this.executeInst.optimisticEventDataUpdate(this.command.value);
  }

  getResult() {
    return this.executeInst.getResult();
  }
}
