import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { EVENT_CONST_TYPES } from 'constant/EventConst';
import { CLASS_NAME_CHART_BASE } from 'constant/ChartConst';
import { CHART_EDIT_CONST } from 'constant/ChartEditConst';

import { getEventInfo } from 'util/EventConstUtil';
import { isNumberWaveformIndexPair } from 'util/validation/ValidationUtil';
import ChartUtil from 'util/ChartUtil';
import BeatTypeSeriesUtil from 'util/BeatTypeSeriesUtil';

import useGetArrhythmiaEvents from './useGetArrhythmiaEvents/useGetArrhythmiaEvents';
import usePrevious from './usePrevious';

const LINE_WIDTH = 1;

/**
 * ### 부정맥 이벤트 정보 시각화/상태 관리 모듈 ###
 *
 *  * 이벤트 정보 시각화
 *    - Event Marker를 render한다.(onset, termination, series)
 *    - Event Marker는 onset, termination, series로 구성된다.
 *      - Event Marker(onset, termination), render Event Marker(series)
 *
 *  * 이벤트 상태 관리
 *    - 클릭 상태에 따라 Event Marker의 색상을 변경한다.
 *
 * @param {*} onsetWaveformIndex
 * @param {*} terminationWaveformIndex
 * @param {*} ecgData
 * @param {*} chartInst useEffect 로직이 처리될때는 항상 존재
 * @param {*} chartWidth
 * @param {*} beatsOrigin
 * @param {*} beatsOrigin render할 beat data를 해당 함수 변수로 받는 상황에서 사용(event review longterm chart를 제외한 10's strip, four's strip에서는 beatsOrigin 데이터를 넘겨주고 있음, longterm chart는 useGetArrhythmiaEvents.jsx에서 redux state에서 접근해서 사용하고 있음.)
 * @param {Boolean} withoutTimeEvents Beat Review 탭 에서 Ectopic 정보만 보여주기 위해 사용
 * @param {Boolean} withoutUnderLine 10초 디테일 텝에서 Time Event 의 Marker 중 Under Line 생략하는 기능
 * @param {Boolean} withoutOnsetNTermLine Shape Review 탭 에서 이벤트 마커의 Onset, Termination Line 을 생략하는 기능
 */
function useManagedEventMarker(
  onsetWaveformIndex,
  terminationWaveformIndex,
  ecgData,
  chartInst,
  chartWidth,
  beatsOrigin,
  //
  isNotChangeState = false,
  withoutTimeEvents = false,
  withoutUnderLine = false,
  withoutOnsetNTermLine = false
) {
  const theme = useTheme();

  const {
    beats,
    noiseList,
    ectopicList,
    arrhythmiaEventList,
    selectedEventLocalIndex, // 선택한 event Marker highLight 작업에 사용
  } = useGetArrhythmiaEvents(
    onsetWaveformIndex,
    terminationWaveformIndex,
    beatsOrigin,
    //
    isNotChangeState,
    withoutTimeEvents
  );
  // todo: jyoon(240707) - [refactor] markers -> eventMarkerInstList로 변경하기
  const [markers, setMarkers] = useState([]);

  // useEffect 1: init
  const prevChartWidth = usePrevious(chartWidth);
  const prevWaveformIndexRange = usePrevious({
    onsetWaveformIndex,
    terminationWaveformIndex,
  });
  useEffect(() => {
    if (
      !chartInst ||
      isSameWaveformIndexRange({
        prevWaveformIndexRange,
        currWaveformIndexRange: {
          onsetWaveformIndex,
          terminationWaveformIndex,
        },
      })
    ) {
      return;
    }

    removeSeries(chartInst);
    chartInst.redraw(false);
    initEventMarkerGroup(chartInst, setMarkers);
  }, [ecgData, onsetWaveformIndex, terminationWaveformIndex]);

  // useEffect 2: Event Marker(onset, termination) 렌더링
  const prevEventMarkerInfoList = usePrevious([
    ...arrhythmiaEventList,
    ...noiseList,
  ]);
  useEffect(() => {
    if (
      !chartInst ||
      (isSameItems(prevEventMarkerInfoList, [
        ...arrhythmiaEventList,
        ...noiseList,
      ]) &&
        markers.length === prevEventMarkerInfoList.length &&
        prevChartWidth === chartWidth)
    ) {
      return;
    }

    if (
      !isNumberWaveformIndexPair(onsetWaveformIndex, terminationWaveformIndex)
    ) {
      // 모든 요소 삭제
      removeSeries(chartInst);
      chartInst.redraw(false);
      initEventMarkerGroup(chartInst, setMarkers);
      return;
    }

    // render Event Marker
    renderEventMarkerGroup(
      chartInst,
      arrhythmiaEventList,
      noiseList,
      theme,
      withoutUnderLine,
      setMarkers,
      onsetWaveformIndex,
      withoutOnsetNTermLine
    );
  }, [chartInst?.series[0]?.data.length, arrhythmiaEventList, chartWidth]);

  // useEffect 3: Event Marker 상태(하이라이트, highLight) 업데이트
  useEffect(() => {
    if (
      !chartInst ||
      (!isNumberWaveformIndexPair(
        onsetWaveformIndex,
        terminationWaveformIndex
      ) &&
        prevChartWidth === chartWidth)
    ) {
      return;
    }

    setTimeout(() => {
      for (const index in markers) {
        markers[index].setSelectedState(
          selectedEventLocalIndex?.includes(parseInt(index))
        );
      }
    });
  }, [markers, selectedEventLocalIndex, chartWidth]);

  // useEffect 4: Series 정보 업데이트
  const prevBeatEventMarkerInfoList = usePrevious([
    ...ectopicList,
    ...noiseList,
  ]);
  useEffect(() => {
    if (
      !chartInst ||
      !isNumberWaveformIndexPair(
        onsetWaveformIndex,
        terminationWaveformIndex
      ) ||
      isSameItems(prevBeatEventMarkerInfoList, [...ectopicList, ...noiseList])
    ) {
      return;
    }

    updateBeatType(chartInst, ecgData, ectopicList, noiseList);
  }, [chartInst, noiseList]);

  return { markers, beats };
}

/**
 * ECG 차트 Inst. 에 Event Marker 그룹 초기화
 *
 * 기존에 render 된 요소는 삭제 처리됨
 */
function initEventMarkerGroup(chartInst, setMarkers) {
  if (!chartInst) return;

  // 전체 Event Marker 초기화
  if (chartInst.eventMarkerGroup) {
    chartInst.eventMarkerGroup.destroy();
    delete chartInst.eventMarkerGroup;
    setMarkers([]);
  }
  const eventMarkerGroup = chartInst.renderer
    .g()
    .attr({
      class: CHART_EDIT_CONST.EVENT_MARKER_GROUP,
      zIndex: 5,
    })
    .add();
  chartInst.eventMarkerGroup = eventMarkerGroup;
}
/**
 * ECG 차트 Inst. 에 Event Marker 들을 render 합니다.
 *
 * TODO: 준호 - 차트 옵션 중 chart.events.render 에서 실행 해야 할 수 있음,
 * chartWidth 변경 없는데 차트의 너비가 변경되는 사례 종종 발생
 */
function renderEventMarkerGroup(
  chartInst,
  arrhythmiaEvents,
  noiseList,
  theme,
  withoutUnderLine,
  setMarkers,
  onsetWaveformIndex,
  withoutOnsetNTermLine
) {
  initEventMarkerGroup(chartInst, setMarkers);
  if (!chartInst) return;

  // render Event Marker(onset, termination)
  const newMarkers = arrhythmiaEvents.map((value) => {
    const eventMetaInfo = getEventInfo({ type: value.type })?.[0];
    const marker = ChartUtil.renderEventMarker(chartInst, {
      onsetLocalWaveformIndex: value.onsetLocalWaveformIndex,
      hasOnsetMarker: withoutOnsetNTermLine ? null : value.hasOnsetMarker,
      terminationLocalWaveformIndex: value.terminationLocalWaveformIndex,
      hasTerminationMarker: value.hasTerminationMarker,
      ...eventMetaInfo.renderAttrs,
      offset: withoutUnderLine
        ? eventMetaInfo.renderAttrs.offsetAlter
        : eventMetaInfo.renderAttrs.offset,
    });

    // Lead-Off 구간의 Marker는 클릭 이벤트를 막습니다
    if (value.type === EVENT_CONST_TYPES.LEAD_OFF) {
      marker.priorityElement.element.addEventListener(
        'click',
        function (event) {
          event.stopPropagation();
        }
      );
    }

    // 반환하는 Marker 목록 중 특정 항목의 식별값
    marker.timeEventId = value.timeEventId;
    marker.onsetRPeakIndex = value.onsetRPeakIndex;
    marker.type = value.type;
    return marker;
  });

  setMarkers(newMarkers);
  renderNoiseEventMarkerGroup(
    chartInst,
    withoutOnsetNTermLine,
    noiseList,
    theme
  );
}
function renderNoiseEventMarkerGroup(
  chartInst,
  withoutOnsetNTermLine,
  noiseList,
  theme
) {
  // render noise Event Marker(onset, termination)
  noiseList.forEach((value) => {
    const eventMarkerInfo = {
      onsetLocalWaveformIndex: value.onsetLocalWaveformIndex,
      hasOnsetMarker: withoutOnsetNTermLine ? null : value.hasOnsetMarker,
      terminationLocalWaveformIndex: value.terminationLocalWaveformIndex,
      hasTerminationMarker: value.hasTerminationMarker,
      ...getEventInfo({ type: EVENT_CONST_TYPES.NOISE }).findOne().renderAttrs,
    };

    ChartUtil.renderEventMarker(chartInst, eventMarkerInfo);
  });
}
function updateBeatType(chartInst, ecgData, ectopicList, noiseList) {
  try {
    //전체 Beat Type 데이터 시각화 갱신
    if (!chartInst || chartInst.chartInst?.series[0]?.data.length === 0) return;

    //기존 Beat Type 데이터 시각화 삭제
    removeSeries(chartInst);
    // Beat Type 데이터 가공
    const { ectopicSeries, noiseSeries } =
      BeatTypeSeriesUtil.generateExtraSeries(ecgData, ectopicList, noiseList);

    // render Event Marker(series)
    for (const series of ectopicSeries) {
      chartInst.addSeries(
        {
          ...series,
          lineWidth: LINE_WIDTH + 0.1,
        },
        false,
        false
      );
    }
    for (const series of noiseSeries) {
      chartInst.addSeries(
        {
          ...series,
          lineWidth: LINE_WIDTH + 0.1,
        },
        false,
        false
      );
    }
    // Beat Type을 위한 차트 Inst. 갱신
    chartInst.redraw(false);
  } catch (error) {
    console.error(error);
  }
}
function removeSeries(chartInst) {
  chartInst.series
    .filter((value) => !value.group.hasClass(CLASS_NAME_CHART_BASE))
    .forEach((element) => {
      element.remove(false, false, false);
    });

  /*
  ❗️❗️ 주의 ❗️❗️
  chartInst.series 의 각 series 삭제하면 chartInst.series 의 배열이 변경됨, 
  queue 방식으로 제거 해야함
  https://www.highcharts.com/forum/viewtopic.php?t=7048
  */
  // while (!!chartInst.series[4]) {
  //   chartInst.series[targetIndex].remove(false, false, false);
  // }
}

function isSameWaveformIndexRange({
  prevWaveformIndexRange,
  currWaveformIndexRange: {
    onsetWaveformIndex: currOnsetWaveformIndex,
    terminationWaveformIndex: currTerminationWaveformIndex,
  },
}) {
  return (
    prevWaveformIndexRange.onsetWaveformIndex === currOnsetWaveformIndex &&
    prevWaveformIndexRange.terminationWaveformIndex ===
      currTerminationWaveformIndex
  );
}
/**
 * @typedef LocalEventMarkerInfoType
 * @property {string} type
 * @property {number} onsetLocalWaveformIndex
 * @property {number} terminationLocalWaveformIndex
 */
/**
 *
 * @param {Array<LocalEventMarkerInfoType>} leftList
 * @param {Array<LocalEventMarkerInfoType>} rightList
 */
function isSameItems(leftList, rightList) {
  if (leftList.length !== rightList.length) return false;

  const leftString = JSON.stringify(
    leftList.map(getLocalEventMarkerInfo).sort(compareLocalEventMarkerInfoList)
  );
  const rightString = JSON.stringify(
    rightList.map(getLocalEventMarkerInfo).sort(compareLocalEventMarkerInfoList)
  );
  return leftString === rightString;
}
function getLocalEventMarkerInfo(someItem) {
  return {
    type: someItem.type,
    onsetLocalWaveformIndex: someItem.onsetLocalWaveformIndex,
    terminationLocalWaveformIndex: someItem.terminationLocalWaveformIndex,
  };
}
/**
 *
 * @param {LocalEventMarkerInfoType} a
 * @param {LocalEventMarkerInfoType} b
 */
function compareLocalEventMarkerInfoList(a, b) {
  if (a.onsetLocalWaveformIndex < b.onsetLocalWaveformIndex) return -1;
  else if (a.onsetLocalWaveformIndex > b.onsetLocalWaveformIndex) return 1;
  else return a.type.localeCompare(b.type, 'en');
}

export default useManagedEventMarker;
