// beat review avg hr이 변경 되어야 하는 케이스
// 1. beatEvent가 추가, 삭제, 변경 되었을 때
import { hrValue } from '@type/optimisticUpdate/type';

export const beatReviewUpdatePostProcess = {
  postBeatEventUpdate: ({
    updateTarget,
    editedEventWaveformIndex,
    updateWaveformIndexList,
    updateIndex,
  }) => {
    // 수정하고자 하는 beatEvent의 index
    const editIndex = updateTarget.beats.waveformIndex.findIndex(
      (v) => v > editedEventWaveformIndex
    );

    updateTarget.beats.waveformIndex.splice(
      editIndex,
      0,
      updateWaveformIndexList.waveformIndex[0]
    );
    updateTarget.beats.beatType.splice(
      editIndex,
      0,
      updateWaveformIndexList.beatType[0]
    );
    updateTarget.beats.hr.splice(editIndex, 0, updateWaveformIndexList.hr[0]);

    setOptimisticUpdate(editIndex, updateTarget);
    return updateTarget;
  },

  patchBeatEventUpdate: ({
    updateTarget,
    editedEventWaveformIndex,
    updateWaveformIndexList,
    updateIndex,
  }) => {
    // 수정하고자 하는 beatEvent의 index
    const editIndex = updateTarget.beats.waveformIndex.indexOf(
      editedEventWaveformIndex
    );

    setOptimisticUpdate(editIndex, updateTarget);

    updateTarget.beats.beatType[editIndex] =
      updateWaveformIndexList.beatType[updateIndex];
    updateTarget.beats.hr[editIndex] = updateWaveformIndexList.hr[updateIndex];

    return updateTarget;
  },

  deleteBeatEventUpdate: ({
    updateTarget,
    editedEventWaveformIndex,
    updateWaveformIndexList,
    updateIndex,
  }) => {
    // 수정하고자 하는 beatEvent의 index
    const editIndex = updateTarget.beats.waveformIndex.indexOf(
      editedEventWaveformIndex
    );

    setOptimisticUpdate(editIndex, updateTarget);

    updateTarget.beats.waveformIndex.splice(editIndex, 1);
    updateTarget.beats.beatType.splice(editIndex, 1);
    updateTarget.beats.hr.splice(editIndex, 1);

    return updateTarget;
  },
};

// 추가, 수정, 삭제 시 hr에 optimistic update 처리
// avg hr을 구하기 위함
function setOptimisticUpdate(editIndex, updateTarget) {
  const prevIndex = editIndex - 1;
  const nextIndex = editIndex + 1;

  const optimisticEventUpdateIndexList = [prevIndex, nextIndex].filter(
    (item) => {
      const isInBeatsInfoRange =
        item >= 0 && item < updateTarget.beats.waveformIndex.length;
      return isInBeatsInfoRange;
    }
  );

  for (let index of optimisticEventUpdateIndexList) {
    updateTarget.beats.hr[index] = hrValue.optimisticEventDataUpdated;
  }
}
