import {
  BEAT_REVIEW_FETCHING_OPTION,
  CHART_EDIT_CONST,
  OFFSET_POSITION_ARRAY,
  POSITION_MOVE_TYPE,
} from 'constant/ChartEditConst';
import { isEmpty } from 'util/validation/ValidationUtil';

export function reArrangeOfPosition({
  clickedEventType,
  currentPositionIndex,
  sidePanelState,
  eventOnsetWaveformIndexList,
  rawAndEventList,
}) {
  try {
    let result = currentPositionIndex;
    const waveformIndexOfCurrentPositionIndex =
      eventOnsetWaveformIndexList[clickedEventType][currentPositionIndex];
    const hasRawAndEventCurrentPositionIndex = !isEmpty(
      rawAndEventList[clickedEventType][waveformIndexOfCurrentPositionIndex]
    );

    if (!hasRawAndEventCurrentPositionIndex) {
      result = sidePanelState.currentPositionIndex;
    }
    return result;
  } catch (error) {
    console.error('error during reArrangeOfPosition');
  }
}

export function getDirectionOfMovement(prevPositionIndex, nextPositionIndex) {
  return prevPositionIndex - nextPositionIndex > 0
    ? CHART_EDIT_CONST.TEN_SEC_STRIP_MOVE_DIR.PREV
    : CHART_EDIT_CONST.TEN_SEC_STRIP_MOVE_DIR.NEXT;
}

export const getRawAndEventProcess = {
  getLastOfFetchedIndex,
  getInfoSliceInfo,
};

export function getLastOfFetchedIndex(
  currentPositionIndex,
  poolingStartIndexOfWaveformIndexList
) {
  let result;
  if (currentPositionIndex) {
    //  case3. current position jump
    result = currentPositionIndex;
  } else {
    // case1. 이벤트 클릭시 (비트리뷰 탭에서 사이드 패널)
    // case2. data pooling (포지션 이동시)
    result = poolingStartIndexOfWaveformIndexList;
  }
  return result;
}

export function getInfoSliceInfo(
  positionMoveType,
  lastOfFetchedIndex,
  basisFetchingCount
) {
  let sliceInfo = { begin: undefined, end: undefined };
  const offsetPositions = OFFSET_POSITION_ARRAY;
  const backwardFetchingCount =
    BEAT_REVIEW_FETCHING_OPTION.BACKWARD_FETCHING_COUNT;

  if (positionMoveType === POSITION_MOVE_TYPE.PREV) {
    sliceInfo.begin = lastOfFetchedIndex + offsetPositions - basisFetchingCount;
    sliceInfo.end = lastOfFetchedIndex + offsetPositions;
  } else if (
    positionMoveType === POSITION_MOVE_TYPE.INIT ||
    positionMoveType === POSITION_MOVE_TYPE.NEXT
  ) {
    sliceInfo.begin = lastOfFetchedIndex;
    sliceInfo.end = lastOfFetchedIndex + basisFetchingCount;
  } else if (positionMoveType === POSITION_MOVE_TYPE.JUMP) {
    sliceInfo.begin =
      lastOfFetchedIndex - backwardFetchingCount >= 0
        ? lastOfFetchedIndex - backwardFetchingCount
        : 0;
    sliceInfo.end = lastOfFetchedIndex + offsetPositions + basisFetchingCount;
  }
  return sliceInfo;
}

/**
 * @typedef waveformIndex
 * @type {number}
 */
