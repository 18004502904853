import styled from 'styled-components';

import Tooltip from 'component/ui/tooltip/Tooltip';

import { ReactComponent as InfoIcon } from 'static/icon/ic-info.svg';

import { FormsPanelNavigationFragmentWrapper } from '../share/FormsEventsPanelNavigationFragment';

const FormsPanelFooterFragmentWrapper = styled(
  FormsPanelNavigationFragmentWrapper
)`
  border: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
`;

const FormsPanelFooterLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const TotalCountText = styled.div`
  font-size: 11px;
  line-height: 14.3px;
  color: ${(props) => props.theme.color.COOL_GRAY_80};
`;

export const InfoIconWrapper = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
  cursor: pointer;
  path {
    fill: ${(props) => props.theme.color.COOL_GRAY_60};
  }
  :hover {
    path {
      fill: ${(props) => props.theme.color.COOL_GRAY_70};
    }
  }
`;

const TooltipCustomTitle = styled.div``;

export interface IFormsPanelFooterFragmentProps {
  totalEventsCount: number;
  totalFormsCount: number;
}

function FormsPanelFooterFragment({
  totalEventsCount,
  totalFormsCount,
}: IFormsPanelFooterFragmentProps) {
  return (
    <FormsPanelFooterFragmentWrapper>
      <FormsPanelFooterLeft>
        <TotalCountText>
          {`${totalFormsCount.toLocaleString()} Forms > ${totalEventsCount.toLocaleString()} Events`}
        </TotalCountText>
        <Tooltip
          title={
            <TooltipCustomTitle>
              유사한 형태와 간격의 <br />
              Beat로 이뤄진 Event가 <br />
              Form으로 그룹화됩니다.
            </TooltipCustomTitle>
          }>
          <InfoIconWrapper style={{ width: 16, height: 16, marginBottom: 0 }} />
        </Tooltip>
      </FormsPanelFooterLeft>
    </FormsPanelFooterFragmentWrapper>
  );
}

export default FormsPanelFooterFragment;
