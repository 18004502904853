import {
  OpenableRange,
  InRangeFinder,
  Range,
} from '@type/beatNEctopic/process';

import { hasOverlappedRange } from './common';

/**
 * 특정 구간에 겹쳐있거나 인접한 Lead-Off 구간과 AF 구간을 찾는 함수를 반환
 * @param param0 Beat Event 와 겹쳐질 수 없는 Episode 정보 구조
 * @returns
 */
export function getOverlapRangeFilter({
  leadOffList = [],
  afList = [],
}: {
  leadOffList: Array<Range>;
  afList: Array<Range>;
}): InRangeFinder {
  return function (typeChangedRange, isBeatTypeS): Array<Range> {
    if (
      !(
        typeChangedRange.onsetWaveformIndex ||
        typeChangedRange.terminationWaveformIndex
      )
    ) {
      throw new Error(
        'getOverlapFilter error: targetRange 값은 둘 중에 하나만 undefined 이어야 함'
      );
    }

    if (isBeatTypeS) {
      const result = getInRangeList(typeChangedRange, [
        ...leadOffList,
        ...afList,
      ]);
      return result;
    }
    const result = getInRangeList(typeChangedRange, leadOffList);
    return result;
  };
}

function getInRangeList(
  typeChangedRange: OpenableRange,
  list: Array<Range>
): Array<Range> {
  let result = Array<Range>();

  if (
    typeChangedRange.onsetWaveformIndex !== undefined &&
    typeChangedRange.terminationWaveformIndex !== undefined
  ) {
    result = list.filter((value) =>
      hasOverlappedRange(value, typeChangedRange)
    );
  } else if (typeChangedRange.onsetWaveformIndex !== undefined) {
    result = list.filter(
      (value) => typeChangedRange.onsetWaveformIndex <= value.onsetWaveformIndex
    );
  } else if (typeChangedRange.terminationWaveformIndex !== undefined) {
    result = list.filter(
      (value) =>
        value.terminationWaveformIndex <=
        typeChangedRange.terminationWaveformIndex
    );
  }

  return result;
}
