import {
  ShapeReviewSectionArea,
  navigationPanelCase,
} from 'constant/ShapeReviewConst';

const disabledInputStatusCase: {
  [ShapeReviewSectionArea.FORMS]: INavigationPanelCase;
  [ShapeReviewSectionArea.EVENTS]: INavigationPanelCase;
} = {
  [ShapeReviewSectionArea.FORMS]: {
    isPauseCase: false, // form의 event pooling을 일시정지한 케이스
    isPendingCase: false, // form의 event pooling하고 있는 케이스
    isTotalPageInit: true, // total page가 0인 케이스
    isAbleCase: false, // panel의 navigation의 pagination 기능(prev/next icon, input box) 가능 케이스
  },
  [ShapeReviewSectionArea.EVENTS]: {
    isPauseCase: true,
    isPendingCase: true,
    isTotalPageInit: true,
    isAbleCase: false,
  },
};

export function getDisableInputStatus(
  isForms: boolean,
  isEvents: boolean,
  totalPage: number,
  poolingStateOfPanel: {
    calledType: string;
    pending: boolean;
    pauseState?: boolean | undefined;
  }
) {
  let disableInputStatus = false;
  const isPauseCase = poolingStateOfPanel.pauseState === true;
  const isPendingCase = poolingStateOfPanel.pending === true;

  if (isForms) {
    let caseStatusFlag: undefined | navigationPanelCase = undefined;
    const formsPanelCase =
      disabledInputStatusCase[ShapeReviewSectionArea.FORMS];

    if (isPauseCase) {
      caseStatusFlag = navigationPanelCase.isPauseCase;
    } else if (totalPage === 0) {
      caseStatusFlag = navigationPanelCase.isTotalPageInit;
    } else if (isPendingCase) {
      caseStatusFlag = navigationPanelCase.isPendingCase;
    } else {
      caseStatusFlag = navigationPanelCase.isAbleCase;
    }
    disableInputStatus = formsPanelCase[caseStatusFlag];
  }

  if (isEvents) {
    let caseStatusFlag: undefined | navigationPanelCase = undefined;
    const eventsPanelCase =
      disabledInputStatusCase[ShapeReviewSectionArea.EVENTS];

    if (isPauseCase) {
      caseStatusFlag = navigationPanelCase.isPauseCase;
    } else if (totalPage === 0) {
      caseStatusFlag = navigationPanelCase.isTotalPageInit;
    } else if (isPendingCase) {
      caseStatusFlag = navigationPanelCase.isPendingCase;
    } else {
      caseStatusFlag = navigationPanelCase.isAbleCase;
    }
    disableInputStatus = eventsPanelCase[caseStatusFlag];
  }

  return disableInputStatus;
}

interface INavigationPanelCase {
  isPauseCase: boolean;
  isPendingCase: boolean;
  isTotalPageInit: boolean;
  isAbleCase: boolean;
}
