import styled from 'styled-components';

import {
  SHAPE_REVIEW_CHART_INFO,
  ShapeReviewSectionArea,
} from 'constant/ShapeReviewConst';
import { rawAndEventCalledCase } from 'constant/ChartEditConst';

import {
  getFocusState,
  getSelectedItem,
  getSelectedClassName,
  getCheckConditions,
  getIsEditedOfEventPanel,
} from 'util/shapeReview/shapeReviewUtil';

import {
  ChartItemRectangle,
  PanelListFragmentProps,
} from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';

import ProgressTimer from 'component/ui/progress/ProgressTimer';

import ChartEventsInfoFragment from '../share/chart/ChartEventsInfoFragment';
import { GridItemWrapper } from '../FormsPanel/FormsPanelListFragment';
import GridItem from '../share/chart/GridItem';

export const GridLayoutWrapper = styled.div<{
  activePanel?: string;
}>`
  padding: ${SHAPE_REVIEW_CHART_INFO.PADDING_UP_DOWN}px
    ${SHAPE_REVIEW_CHART_INFO.PADDING_LEFT_RIGHT}px;
  border-top: 1px solid ${(props) => props.theme.color.COOL_GRAY_30};
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  ${({ activePanel, theme }) =>
    activePanel === ShapeReviewSectionArea.EVENTS &&
    `
      border-left: 1px solid ${theme.color.BLUE_70};
      border-right: 1px solid ${theme.color.BLUE_70};
    `}
`;

const GridLayoutItemWrapper = styled.div<{
  chartItemRectangle: ChartItemRectangle;
}>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${SHAPE_REVIEW_CHART_INFO.MIN_WIDTH}px, 1fr)
  );
  grid-template-rows: repeat(
    auto-fill,
    minmax(${SHAPE_REVIEW_CHART_INFO.MIN_HEIGHT}px, 1fr)
  );
  align-content: space-between;
  justify-content: space-around;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ExtendGridLayoutWrapper = styled(GridLayoutWrapper)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0px;
`;

const MsgWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;

const PauseWrapper = styled.label`
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background: ${(props) => props.theme.color.COOL_GRAY_30};
  }
`;

function EventsPanelListFragment({
  // props state
  checkBoxStatus,
  // local state
  formPanelSelectedItemListLength = 0,
  // useChartList Hook state
  gridLayoutRef,
  chartList,
  chartItemRectangle,
  selectedItemList,
  lastSelectedSectionInfo,
  setSelectedItemList,
  setLastSelectedSectionInfo,
  // redux state
  activePanel,
  clickedInfo,
  panelPatchedList,
  eventPoolingProgressPercentage = 0,
  isCoupletEctopicTypeClicked,
  poolingStateOfPanel,
  formPanelDetailOfFirstIndexOfLastSelectedSection,
  //
  onClickPause,
  onClickGridItem,
  onClickFetchingAgain,
  handleSelectedCheckboxStatus,
}: PanelListFragmentProps) {
  const $DuringFetchingAllEventCase = (
    <MsgWrapper>
      Event를 불러오고 있습니다. <br />
      불러오는 중에는 다른 Form을 선택할 수 없습니다.
      <ProgressTimer
        style={{ width: '230px' }}
        progressValue={eventPoolingProgressPercentage}
      />
      <PauseWrapper onClick={onClickPause}>일시정지</PauseWrapper>
    </MsgWrapper>
  );

  const $PauseFetchingAllEventCase = (
    <MsgWrapper>
      일시정지됨
      <ProgressTimer
        style={{ width: '230px' }}
        progressValue={eventPoolingProgressPercentage}
      />
      <PauseWrapper onClick={onClickFetchingAgain}>계속 불러오기</PauseWrapper>
    </MsgWrapper>
  );

  const $NoneSelectCase = (
    <MsgWrapper>
      선택된 Form에 포함된 이벤트가 <br />
      이곳에 표시됩니다.
    </MsgWrapper>
  );

  // Edited Form 선택시 편집된 이벤트가 없을 때
  const $NoneEditedEventCase = (
    <MsgWrapper>
      편집된 이벤트가 없습니다.
      <br />
      편집된 이벤트가 Edited Form에 추가됩니다.
    </MsgWrapper>
  );

  // Etc Form 선택시, 폼에 이벤트가 없을 때
  const $NoneEtcEventCase = (
    <MsgWrapper>
      Form에 포함된 이벤트가 없습니다.
      <br />
      다른 Form과 유사하지 않은 이벤트가 Etc.로 분류됩니다.
    </MsgWrapper>
  );

  // Forms panel Form을 2개 이상 선택했을 때(Edited Form 제외)
  const $multiSelectedCase = (
    <MsgWrapper>{formPanelSelectedItemListLength}개의 Form 선택됨</MsgWrapper>
  );

  /**
   * # form선택 시 event panel 케이스 정리
   *   * formPanelSelectedItemListLength === 0
   *     - case1: form을 선택하지 았을 때
   *   * formPanelSelectedItemListLength === 1
   *     - case2.1 선택된 form에 event를 받는 중일 때
   *     - case2.2 일시 정지를 누른 케이스
   *     - case2.3 edited Form 선택시 편집된 이벤트가 없을 때
   *     - case2.4 Etc Form 선택시 폼에 이벤트가 없을 때
   *     - case2.5 form 선택시 event가 있을 경우
   *   * formPanelSelectedItemListLength > 1
   *     - case3: form을 1개 이상 선택했을 때
   */
  let $Content;
  if (formPanelSelectedItemListLength === 0) {
    // case1: form을 선택하지 았을 때
    $Content = (
      <ExtendGridLayoutWrapper>{$NoneSelectCase}</ExtendGridLayoutWrapper>
    );
  } else if (formPanelSelectedItemListLength === 1) {
    const isPendingCase =
      poolingStateOfPanel.calledType === rawAndEventCalledCase.CLICK_EVENT ||
      poolingStateOfPanel.calledType === rawAndEventCalledCase.POSITION_JUMP;

    if (
      chartList.length === 0 &&
      isPendingCase &&
      poolingStateOfPanel &&
      poolingStateOfPanel.pending === true &&
      poolingStateOfPanel.pauseState === false
    ) {
      // case2.1 선택된 form에 event를 받는 중일 때
      $Content = (
        <ExtendGridLayoutWrapper>
          {$DuringFetchingAllEventCase}
        </ExtendGridLayoutWrapper>
      );
    } else if (poolingStateOfPanel.pauseState === true) {
      // case2.2 일시 정지를 누른 케이스
      $Content = $PauseFetchingAllEventCase;
    } else if (
      formPanelDetailOfFirstIndexOfLastSelectedSection?.formInfo
        .formRankNumber === 0 &&
      chartList.length === 0
    ) {
      // case2.3 edited Form 선택시 편집된 이벤트가 없을 때
      $Content = $NoneEditedEventCase;
    } else if (
      // case2.4 Etc Form 선택시 폼에 이벤트가 없을 때
      formPanelDetailOfFirstIndexOfLastSelectedSection?.formInfo
        .formRankNumber === -1 &&
      chartList.length === 0
    ) {
      $Content = $NoneEtcEventCase;
    } else if (chartList.length !== 0) {
      // case2.5 form 선택시 event가 있을 경우
      const isShiftClick = lastSelectedSectionInfo[1] !== undefined;
      const lastClickInfo = lastSelectedSectionInfo[isShiftClick ? 1 : 0];

      $Content = (
        <GridLayoutItemWrapper
          className="gridLayoutItemWrapper"
          chartItemRectangle={chartItemRectangle}>
          {chartList.map((chartInfo) => {
            const hasOriginWaveformIndex =
              chartInfo.beats.waveformIndex.includes(
                chartInfo.originWaveformIndex
              );

            const hasTerminationWaveformIndex =
              chartInfo.beats.waveformIndex.includes(
                chartInfo.terminationWaveformIndex
              );

            const isEdited = getIsEditedOfEventPanel(
              panelPatchedList,
              chartInfo
            );
            const isFocused = getFocusState(
              checkBoxStatus,
              lastClickInfo,
              chartInfo
            );
            const selectedItem = getSelectedItem(selectedItemList, chartInfo);
            const isSelected = !!selectedItem;
            const { isCheckedFirst, isCheckedSecond } =
              getCheckConditions(selectedItem);
            const selectedClass = getSelectedClassName(
              selectedItemList,
              chartInfo
            );

            return (
              <GridItemWrapper
                key={chartInfo.index + chartInfo.millisecondTime}>
                <GridItem
                  gridType={ShapeReviewSectionArea.EVENTS}
                  selectedClass={selectedClass}
                  activePanel={activePanel}
                  clickedInfo={clickedInfo}
                  chartInfo={chartInfo}
                  //
                  isEdited={isEdited}
                  isFocused={isFocused}
                  isSelected={isSelected}
                  isCheckedFirst={isCheckedFirst}
                  isCheckedSecond={isCheckedSecond}
                  isCoupletEctopicTypeClicked={isCoupletEctopicTypeClicked}
                  //
                  onClickGridItem={onClickGridItem}
                  setLastSelectedSectionInfo={setLastSelectedSectionInfo}
                  setSelectedItemList={setSelectedItemList}
                  //
                  handleSelectedCheckboxStatus={handleSelectedCheckboxStatus}
                  //
                  InfoFragment={
                    <ChartEventsInfoFragment
                      hasOriginWaveformIndex={hasOriginWaveformIndex}
                      hasTerminationWaveformIndex={hasTerminationWaveformIndex}
                      eventId={chartInfo.index}
                      totalIndex={chartInfo.totalIndex}
                    />
                  }
                />
              </GridItemWrapper>
            );
          })}
        </GridLayoutItemWrapper>
      );
    } else {
      $Content = (
        <ExtendGridLayoutWrapper>{$NoneSelectCase}</ExtendGridLayoutWrapper>
      );
    }
  } else if (formPanelSelectedItemListLength > 1) {
    // case3: form을 1개 이상 선택했을 때
    $Content = (
      <ExtendGridLayoutWrapper>{$multiSelectedCase}</ExtendGridLayoutWrapper>
    );
  }

  return (
    <GridLayoutWrapper
      className="EventsPanel gridLayoutWrapper"
      ref={gridLayoutRef}
      activePanel={activePanel}>
      {$Content}
    </GridLayoutWrapper>
  );
}

export default EventsPanelListFragment;

// export default React.memo(EventsPanelListFragment, (prev, next) => {
//   return (
//     prev.poolingStateOfPanel.pauseState ===
//       next.poolingStateOfPanel.pauseState &&
//     prev.poolingStateOfPanel.pending === next.poolingStateOfPanel.pending &&
//     prev.eventPoolingProgressPercentage ===
//       next.eventPoolingProgressPercentage &&
//     prev.chartList[0]?.originWaveformIndex ===
//       next.chartList[0]?.originWaveformIndex &&
//     prev.selectedItemList === next.selectedItemList &&
//     prev.lastSelectedSectionInfo === next.lastSelectedSectionInfo
//   );
// });
