import { useState } from 'react';
import styled from 'styled-components';

import NumberUtil from 'util/NumberUtil';
import { shapeReviewState } from 'util/shapeReview/shapeReviewUtil';

import {
  ISelectedEvent,
  ShapeEctopicStatisticsInfo,
} from 'redux/container/fragment/test-result/shape-review/navigation/NavigationFragmentContainer';
import { IShowDialogParams } from 'redux/container/fragment/test-result/shape-review/selectedItemPanel/SelectedItemPanelFragmentContainer';

import Button from 'component/ui/button/Button';
import Tooltip from 'component/ui/tooltip/Tooltip';
import NotificationBadge from 'component/ui/icon/NotificationBadge';

import {
  BeatType,
  EctopicType,
  ShapeReviewEventSection,
} from '@type/ecgEventType/baseEventType';
import {
  ArrangeRequiredStatus,
  PatchBeatByFormIds,
  PatchBeatByWaveFormIndexes,
  PoolingData,
} from 'redux/duck/shapeReview/shapeReviewDuckType';

import { ReactComponent as SyncIcon } from 'static/icon/ic-sync.svg';
import { ReactComponent as CloseIcon } from 'static/icon/ic-close.svg';

const TopLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0px;
  column-gap: 24px;
`;

const TopRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Divider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${(props) => props.theme.color.COOL_GRAY_30};
`;

const ArrangeButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 28px;
  position: relative;
`;

const SyncIconWrapper = styled(SyncIcon)`
  width: 16px;
  height: 16px;
`;
const CloseIconWrapper = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  path {
    fill: white;
  }
  cursor: pointer;
`;

const TooltipCustomTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
`;
const TooltipCustomTitle = styled.div``;

const LegendItemWrapper = styled.div`
  display: flex;
`;

interface ILegendItem {
  text: string;
  colorName: string;
  icon?: JSX.Element;
}

const LegendItemIcon = styled.div<{ colorName: string }>`
  margin: 2px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${(props) =>
    props.colorName
      ? props.theme.color[props.colorName]
      : props.theme.color.DARK};
`;

const LegendItemText = styled.div`
  font-weight: 400;
  font-size: 9px;
  line-height: 130%;
  color: ${(props) => props.theme.color.BLACK};
  display: flex;
  align-items: center;
`;

function LegendItem(props: ILegendItem) {
  const { text, colorName, icon } = props;
  return (
    <LegendItemWrapper>
      {icon ?? <LegendItemIcon colorName={colorName} />}
      <LegendItemText>{text}</LegendItemText>
    </LegendItemWrapper>
  );
}

const Wrapper = styled.div`
  padding: 8px 24px;
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NavigationChildWrapper = styled.div<{ childrenGap: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > * {
    :not(:last-child) {
      margin-right: ${(props) => props.childrenGap ?? 8}px;
    }
  }
`;

const ShapeEctopicStatisticsInfoWrapper = styled.div<{
  key: string;
  qty: number;
  selected: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 4px;
  cursor: pointer;
  & > * {
    cursor: pointer;
  }

  ${(props) => {
    let resultStyle;

    if (props.selected) {
      resultStyle = `
        & > .ShapeEctopicStatisticsInfoLabel {
          color: ${props.theme.color.COOL_GRAY_90};
        }
        & > .ShapeEctopicStatisticsInfoQty {
          color: ${props.theme.color.COOL_GRAY_80};
          border: 1px solid ${props.theme.color.COOL_GRAY_30};
          background: ${props.theme.color.COOL_GRAY_30};
        }
      `;
    } else {
      resultStyle = `
        & > .ShapeEctopicStatisticsInfoLabel {
          color: ${props.theme.color.COOL_GRAY_60};
        }
        & > .ShapeEctopicStatisticsInfoQty {
          color: ${props.theme.color.COOL_GRAY_60};
          border: 1px solid ${props.theme.color.COOL_GRAY_30};
        }
      `;
    }

    if (props.qty === 0) {
      resultStyle = `
        & > .ShapeEctopicStatisticsInfoLabel {
          color: ${props.theme.color.COOL_GRAY_50};
        }
        & > .ShapeEctopicStatisticsInfoQty {
          color: ${props.theme.color.COOL_GRAY_50};
          border: 1px solid ${props.theme.color.COOL_GRAY_30};
        }
      `;
    }

    return resultStyle;
  }};

  :hover {
    ${(props) => {
      if (props.selected || props.qty === 0) return ``;

      return `
        & > .ShapeEctopicStatisticsInfoLabel {
          color: ${props.theme.color.COOL_GRAY_90};
        }
        & > .ShapeEctopicStatisticsInfoQty {
          color: ${props.theme.color.COOL_GRAY_70};
          border: 1px solid ${props.theme.color.COOL_GRAY_30};
        }
      `;
    }}
  }
`;
const ShapeEctopicStatisticsInfoLabel = styled.label`
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  height: 17px;
`;
const ShapeEctopicStatisticsInfoQty = styled.label`
  padding: 1px 4px;
  height: 20px;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
`;

export interface INavigationFragment {
  selectedEvent: ISelectedEvent;
  shapeEctopicStatisticsInfoList: ShapeEctopicStatisticsInfo[];
  //
  isArrangeRequired: Pick<ArrangeRequiredStatus, 'isArrangeRequired'>;
  patchBeatByFormIdsPending: Pick<PatchBeatByFormIds, 'pending'>;
  patchBeatByWaveFormIndexesPending: Pick<
    PatchBeatByWaveFormIndexes,
    'pending'
  >;
  pendingOfEventPoolingStateOfSelectedEvent: Pick<PoolingData, 'pending'>;
  handlePatchBeatPostprocessRequested: () => void;
  handleShowDialog: (params: IShowDialogParams) => void;
  //
  onClickShapeReviewEventStatisticInfo: (
    qty: number,
    beatType: BeatType,
    ectopicType: Extract<EctopicType, 'ISOLATE' | 'COUPLET'>,
    eventSection: ShapeReviewEventSection
  ) => void;
}

function NavigationFragment({
  selectedEvent,
  shapeEctopicStatisticsInfoList,
  //
  isArrangeRequired,
  patchBeatByFormIdsPending,
  patchBeatByWaveFormIndexesPending,
  pendingOfEventPoolingStateOfSelectedEvent,
  //
  handlePatchBeatPostprocessRequested,
  handleShowDialog,
  //
  onClickShapeReviewEventStatisticInfo,
}: INavigationFragment) {
  const [isOpen, setIsOpen] = useState(true);

  const $ShapeReviewEventStatisticInfo = shapeEctopicStatisticsInfoList.map(
    (shapeEctopicStatisticsInfo) => {
      const { label, qty, eventSection, beatType, ectopicType } =
        shapeEctopicStatisticsInfo;

      const qtyComma = NumberUtil.threeDigitComma(qty);
      return (
        <ShapeEctopicStatisticsInfoWrapper
          key={label}
          qty={qtyComma}
          selected={
            beatType === selectedEvent.beatType &&
            ectopicType === selectedEvent.ectopicType
          }
          onClick={() => {
            const isPendingEventUpdate =
              patchBeatByFormIdsPending || patchBeatByWaveFormIndexesPending;
            // if (isPendingEventUpdate) return;
            if (pendingOfEventPoolingStateOfSelectedEvent) return;

            onClickShapeReviewEventStatisticInfo(
              qty,
              beatType,
              ectopicType,
              eventSection
            );
          }}>
          <ShapeEctopicStatisticsInfoLabel className="ShapeEctopicStatisticsInfoLabel">
            {label}
          </ShapeEctopicStatisticsInfoLabel>
          <ShapeEctopicStatisticsInfoQty className="ShapeEctopicStatisticsInfoQty">
            {qtyComma}
          </ShapeEctopicStatisticsInfoQty>
        </ShapeEctopicStatisticsInfoWrapper>
      );
    }
  );

  return (
    <Wrapper>
      <TopLeftWrapper className="TopLeftWrapper">
        {$ShapeReviewEventStatisticInfo}
      </TopLeftWrapper>
      <TopRightWrapper className="TopRightWrapper">
        <NavigationChildWrapper childrenGap={8}>
          <LegendItem text={'S'} colorName={'SVE_70'} />
          <LegendItem text={'V'} colorName={'VE_70'} />
          <LegendItem text={'Q'} colorName={'MEDIUM'} />
        </NavigationChildWrapper>
        <Divider />
        <Tooltip
          title={
            <TooltipCustomTitleWrapper>
              <TooltipCustomTitle style={{ textAlign: 'initial' }}>
                편집된 이벤트가 있습니다. <br />
                편집 결과를 반영하려면 정렬해주세요.
              </TooltipCustomTitle>
              <CloseIconWrapper
                onClick={() => {
                  shapeReviewState.visibleArrangeRequiredTooltip = true;
                  setIsOpen(false);
                }}
              />
            </TooltipCustomTitleWrapper>
          }
          placement="bottom-end"
          open={
            isOpen &&
            isArrangeRequired &&
            !shapeReviewState.visibleArrangeRequiredTooltip
          }
          option={{ maxWidth: 227 }}>
          <ArrangeButtonWrapper className="ArrangeButtonWrapper">
            <Button
              style={{
                width: 'fit-content',
                padding: '4px 8px',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '15.6px',
              }}
              color={'secondary'}
              startIcon={<SyncIconWrapper />}
              iconColor={'COOL_GRAY_90'}
              title={'Arrange'}
              outline
              onClick={() => {
                handleShowDialog({
                  dialogKey: 'ConfirmDialog',
                  params: {
                    title: '편집 결과를 반영하여 정렬하시겠습니까?',
                    message:
                      '편집된 이벤트는 Edited Form으로 이동합니다. \n 정렬에는 최대 1분이 소요됩니다.',
                    confirmButtonText: '정렬',
                    onSubmit: () => {
                      handlePatchBeatPostprocessRequested();
                    },
                  },
                });
              }}
            />
            {isArrangeRequired && <NotificationBadge />}
          </ArrangeButtonWrapper>
        </Tooltip>
      </TopRightWrapper>
    </Wrapper>
  );
}

export default NavigationFragment;
