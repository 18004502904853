import { optimisticEventDataUpdate } from 'util/optimisticEventDataUpdate/optimisticEventDataUpdate';

import AlertDialog from './AlertDialog';

function AlertExceedBpmLimitDialog(props) {
  const { open, callback, onClose } = props;

  return (
    <AlertDialog
      open={open}
      params={{
        message: `Beat의 HR이 ${optimisticEventDataUpdate.basisBpm}bpm을 초과하는 경우 Beat를 추가할 수 없습니다.`,
        confirmButtonText: '확인',
      }}
      callback={callback}
      onClose={onClose}
      zIndex={13}
    />
  );
}

export default AlertExceedBpmLimitDialog;
