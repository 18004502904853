import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Const from 'constant/Const';
import {
  HR_REVIEW_HISTOGRAM_TYPE,
  LABEL_TEXT_LOOKUP,
  INTL_MAP,
  MIN_AVG_MAX_BADGE_TYPE,
  BADGE_TYPE_TEXT_LOOKUP,
} from 'constant/HrReviewConst';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import useSnackbarStack, {
  WithoutIconContent,
} from 'component/hook/useSnackbarStack';
import usePrevious from 'component/hook/usePrevious';
import useKeyEventListener from 'component/hook/useKeyEventListener';
import useAuthority from 'component/hook/useAuthority';

import { TooltipTitleWrapper } from 'component/fragment/test-result/hr-review/side-panel/index';
import ReportsInfoWrapper from 'component/fragment/test-result/side-panel/ReportsInfoWrapper/index';

import Tooltip from 'component/ui/tooltip/Tooltip';
import Button from 'component/ui/button/Button';
import ShortcutText from 'component/ui/highlight/ShortcutText';
import { AddIcon } from 'component/ui/icons';

import {
  postReportEventRequested,
  selectHrTenSecStripDetailState,
} from 'redux/duck/hrReviewDuck';
import { hideDialog, showDialog } from 'redux/duck/dialogDuck';

function ReportInfo({
  minAvgMaxBadgeType,
  reportStates: { data, error, pending, isManuallyDeleted },
  histType,
}) {
  const dispatch = useDispatch();
  const { isReadOnly } = useAuthority();
  const { formatMessage } = useIntl();
  const { enqueueMessage } = useSnackbarStack(WithoutIconContent);
  useKeyEventListener(
    Const.EVENT_TYPE.KEYDOWN,
    [Const.KEY_MAP.ENTER],
    addReportShortCutHandler,
    'code'
  );
  useConfirmDialogEffect();

  // selector
  const { registeredStrip } = useShallowEqualSelector(
    selectHrTenSecStripDetailState
  );

  // dispatch
  const handleShowDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };
  const postReportEvent = () => {
    dispatch(postReportEventRequested());
  };

  // useEffect
  const previousHistType = usePrevious(histType);
  const previousData = usePrevious(data[histType]);
  const previousPending = usePrevious(pending);
  useEffect(() => {
    if (!previousData) return;
    if (previousHistType !== histType) return;
    if (previousData.length <= data[histType].length) return;
    if (!previousPending || pending) return;
    if (error) return;

    const message = isManuallyDeleted
      ? INTL_MAP.REPORT_REMOVED_MANUALLY
      : INTL_MAP.REPORT_REMOVED;

    enqueueMessage(formatMessage(message, { histType }));
  }, [pending]);

  const isBadgeTypeNone = minAvgMaxBadgeType === MIN_AVG_MAX_BADGE_TYPE.NONE;
  const reportTypeList =
    histType === HR_REVIEW_HISTOGRAM_TYPE.HR ? 'Max, Min, Avg' : 'Max, Min';
  const reportListOfCurrentStripType = data[histType].filter(
    (report) => report.stripType === minAvgMaxBadgeType
  );
  const reportListOfCurrentPosition = registeredStrip?.filter(
    (reportInfo) =>
      reportInfo.reportSection === histType &&
      reportInfo.stripType === minAvgMaxBadgeType
  );

  function addReportShortCutHandler(event) {
    if (!event.shiftKey) return;
    if (event.code !== Const.KEY_MAP.ENTER) return;
    onClickButton();
  }
  function onClickButton() {
    if (isBadgeTypeNone) return;

    const title = formatMessage(INTL_MAP.CHANGE_REPORT_CONFIRM_TITLE, {
      unitType: BADGE_TYPE_TEXT_LOOKUP[minAvgMaxBadgeType],
      labelText: LABEL_TEXT_LOOKUP[histType],
    });
    const message = formatMessage(INTL_MAP.CHANGE_REPORT_CONFIRM_MESSAGE);
    const confirmButtonText = formatMessage(
      INTL_MAP.CHANGE_REPORT_CONFIRM_BUTTON_TEXT
    );
    const hasReport = reportListOfCurrentStripType.length >= 1;
    const onSubmit = () => postReportEvent();

    if (hasReport) {
      setTimeout(() => {
        // shift + enter 시, dialog가 먼저 뜨는 것을 방지하기 위해 setTimeout 사용
        handleShowDialog('ConfirmDialog', {
          title,
          message,
          confirmButtonText,
          onSubmit,
        });
      });
    } else {
      postReportEvent();
    }
  }

  return (
    <ReportsInfoWrapper
      wrapperStyle={{ paddingTop: 12 }}
      eventType={histType}
      reportInfoList={reportListOfCurrentPosition}
      isReadOnly={isReadOnly}>
      {(reportListOfCurrentPosition?.length === 0 ||
        !reportListOfCurrentPosition) && (
        <Tooltip
          option={{ maxWidth: 150 }}
          title={
            <TooltipTitleWrapper style={{ flexDirection: 'column' }}>
              <TooltipTitle>
                {isBadgeTypeNone
                  ? formatMessage(INTL_MAP.ADD_REPORT_DISABLED_TOOLTIP, {
                      reportTypeList,
                      histType,
                    })
                  : formatMessage(INTL_MAP.ADD_TO_REPORT_TOOLTIP, {
                      unitType: BADGE_TYPE_TEXT_LOOKUP[minAvgMaxBadgeType],
                      labelText: LABEL_TEXT_LOOKUP[histType],
                    })}
              </TooltipTitle>
              {!isBadgeTypeNone && (
                <ShortcutText style={{ width: 75 }} text="Shift + ENTER" />
              )}
            </TooltipTitleWrapper>
          }
          placement="top">
          <Button
            disabled={isBadgeTypeNone || isReadOnly}
            color={'secondary'}
            outline
            style={{
              width: '192px',
              height: '28px',
              margin: '4px 0px',
            }}
            startIcon={<AddIcon />}
            iconColor={'COOL_GRAY_90'}
            text={formatMessage({
              id: '11-ReportsInfoWrapper-Button-addReport',
              description: '리포트 담기',
              defaultMessage: '리포트 담기',
            })}
            onClick={onClickButton}
          />
        </Tooltip>
      )}
    </ReportsInfoWrapper>
  );
}

function useConfirmDialogEffect() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { pending: tenSecStripDetailPending } = useShallowEqualSelector(
    selectHrTenSecStripDetailState
  );
  const { message: ConfirmDialogMessage } = useShallowEqualSelector(
    (state) => state.dialogReducer.ConfirmDialog
  );

  const handleHideDialog = (dialogKey) => {
    dispatch(hideDialog(dialogKey));
  };

  useEffect(() => {
    // 다른 BADGE_TYPE(min,max,avg)를 누르고 요청 중 shift+enter로 담은 스트립 변경 버튼을 누를경우
    // 이전에 조회하고 있던 BADGE_TYPE을 담으려고 시도한 것으로 간주할 수 있어서 dialog를 닫아줌
    const CHANGE_REPORT_CONFIRM_MESSAGE = formatMessage(
      INTL_MAP.CHANGE_REPORT_CONFIRM_MESSAGE
    );

    if (ConfirmDialogMessage !== CHANGE_REPORT_CONFIRM_MESSAGE) return;
    handleHideDialog('ConfirmDialog');
  }, [tenSecStripDetailPending]);
}

const TooltipTitle = styled.div``;

export default ReportInfo;
