/**
 * 23" 08. 23.
 * https://www.figma.com/file/z17YYGRtWzt0PQ4f5tbICm/05-08-%5BEvent-Review%5D-리포트-담기?node-id=1864%3A569483&mode=dev
 * 노준호
 */
import styled from 'styled-components';

const SIDE_PANEL_INNER_WIDTH = 225;

export const TitleWrapper = styled.div`
  width: ${SIDE_PANEL_INNER_WIDTH}px;
  height: 40px;
  max-height: 48px;
  padding: 6px 18px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`;

export const ColumnItemListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FragmentWrapper = styled(ColumnItemListWrapper)`
  width: ${SIDE_PANEL_INNER_WIDTH}px;
  flex: 1 1 auto;
  padding-top: 8px;
`;

export const InfoViewerWrapper = styled(ColumnItemListWrapper)`
  padding: 0px 18px 0px 17px;
`;

export const InfoTitle = styled.div`
  height: 20px;
  padding: 4px 0;

  display: flex;
  align-items: center;

  font-size: 11px;
  font-weight: 700;
  line-height: 130%;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

export const ContentWrapper = styled(ColumnItemListWrapper)`
  width: 100%;
`;

export const Divider = styled.div`
  height: 11px;
  margin-top: 12px;
  // border-top: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
`;
