import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Const from 'constant/Const';
import {
  HR_REVIEW_HISTOGRAM_TYPE,
  SELECTABLE_VALUE_TYPE,
  UNIT_TEXT_LOOKUP,
  INTL_MAP,
  MIN_AVG_MAX_BADGE_TYPE,
  BADGE_TYPE_TEXT_LOOKUP,
} from 'constant/HrReviewConst';
import { HR_SORT_OPTION_LIST, RR_SORT_OPTION_LIST } from 'constant/SortConst';

import { convertPresentText, getRefinedBinKey } from 'util/HrReviewUtil';

import Overall from 'component/fragment/test-result/hr-review/side-panel/Overall';
import ReportInfo from 'component/fragment/test-result/hr-review/side-panel/ReportInfo';
import {
  InfoTitle,
  TitleWrapper,
} from 'component/fragment/test-result/side-panel/CommonUi';
import ProgressFragment from 'component/fragment/ProgressFragment';

import Button from 'component/ui/button/Button';
import SortOptionDropdown from 'component/ui/dropdown/SortOptionDropdown';
import HrReviewBadge from 'component/ui/highlight/HrReviewBadge';
import PositionInput from 'component/ui/input/PositionInput';
import Tooltip from 'component/ui/tooltip/Tooltip';
import ShortcutText from 'component/ui/highlight/ShortcutText';
import {
  HeartRateSelectedIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  KeyboardArrowUpIcon,
  RevertIcon,
} from 'component/ui/icons';

const SORT_OPTION_LIST_MAP = {
  [HR_REVIEW_HISTOGRAM_TYPE.HR]: HR_SORT_OPTION_LIST,
  [HR_REVIEW_HISTOGRAM_TYPE.RR]: RR_SORT_OPTION_LIST,
};

function HrReviewSidePanel(props) {
  const {
    //
    histType,
    maxData,
    minData,
    avgData,
    reportStates,
    //
    binKey,
    position,
    sortOrder,
    selectedEpisodeValue,
    minAvgMaxBadgeType,
    handleOnChange,
    handleKeyPress,
    //
    isRevertable,
    handleRevert,
    handleSetLimit,
    limitState,
    //
    maxPosition,
    pending,
    disabledSetMinMax,
  } = props;
  const { formatMessage } = useIntl();

  const sortOptionList = SORT_OPTION_LIST_MAP[histType];
  const unit = UNIT_TEXT_LOOKUP[histType];
  const selectedEpisodeText = convertPresentText(
    selectedEpisodeValue,
    histType
  );

  return (
    <Wrapper>
      {pending && (
        <ProgressFragment
          displayProgress={false}
          style={{
            cursor: 'not-allowed',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
          }}
        />
      )}
      <Overall
        histType={histType}
        maxData={maxData}
        minData={minData}
        avgData={avgData}
        reportData={reportStates.data}
        handleOnChange={handleOnChange}
        unit={unit}
        sortOptionList={sortOptionList}
      />
      <SelectedEpisodeWrapper>
        <HeartRateSelectedIcon />
        <SelectedEpisodeText>{`${selectedEpisodeText} ${unit}`}</SelectedEpisodeText>
        <HrReviewBadge
          minAvgMaxBadgeType={minAvgMaxBadgeType}
          histType={histType}
        />
      </SelectedEpisodeWrapper>
      <TitleWrapper>
        <PositionInput
          style={{ padding: '0px' }}
          value={position}
          maxValue={maxPosition || '-'}
          helpText="(Sort by Time)"
          onChange={(newPosition) =>
            handleOnChange(SELECTABLE_VALUE_TYPE.POSITION, newPosition)
          }>
          <SortOptionDropdown
            selectedOption={sortOrder}
            optionList={sortOptionList}
            onChange={(newSortOrder) =>
              handleOnChange(SELECTABLE_VALUE_TYPE.SORT_ORDER, newSortOrder)
            }
          />
        </PositionInput>
      </TitleWrapper>
      <HrDataListWrapper>
        <TotalButtonWrapper>
          <MovePositionOrBinButtonWrapper>
            <ButtonGroupWrapper>
              <SidePanelMovePositionButton
                disabled={position <= 1 || pending}
                startIcon={<KeyboardArrowLeftIcon />}
                text={'Prev'}
                onClick={() => {
                  handleKeyPress({ code: Const.KEY_MAP.ARROW_LEFT });
                }}
              />
              <SidePanelMovePositionButton
                disabled={position >= maxPosition || pending}
                endIcon={<KeyboardArrowRightIcon />}
                text={'Next'}
                onClick={() => {
                  handleKeyPress({ code: Const.KEY_MAP.ARROW_RIGHT });
                }}
              />
            </ButtonGroupWrapper>

            <ButtonGroupWrapper>
              <SidePanelMovePositionButton
                disabled={
                  // 선택된 Bin 이 Min Data 의 Bin 이면 버튼을 비활성화
                  binKey <= getRefinedBinKey(minData, histType) || pending
                }
                startIcon={<KeyboardArrowDownIcon />}
                text={'Lower'}
                onClick={() => {
                  handleKeyPress({ code: Const.KEY_MAP.ARROW_DOWN });
                }}
              />
              <SidePanelMovePositionButton
                disabled={
                  // 선택된 Bin 이 Max Data 의 Bin 이면 버튼을 비활성화
                  binKey >= getRefinedBinKey(maxData, histType) || pending
                }
                text={'Higher'}
                endIcon={<KeyboardArrowUpIcon />}
                onClick={() => {
                  handleKeyPress({ code: Const.KEY_MAP.ARROW_UP });
                }}
              />
            </ButtonGroupWrapper>
          </MovePositionOrBinButtonWrapper>
          {histType === HR_REVIEW_HISTOGRAM_TYPE.RR && (
            <>
              <InfoTitle style={{ padding: '20px 18px 4px' }}>
                {formatMessage(INTL_MAP.EDIT)}
              </InfoTitle>
              <ButtonGroupWrapper>
                <Tooltip
                  title={
                    <TooltipTitleWrapper>
                      {formatMessage(INTL_MAP.LIMIT_TOOLTIP, {
                        setType:
                          BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MIN],
                      })}
                      <ShortcutText text="Shift + N" />
                    </TooltipTitleWrapper>
                  }
                  placement={'top'}
                  option={{ maxWidth: 'initial' }}>
                  <SidePanelMovePositionButton
                    pending={
                      limitState.data.setLimitType ===
                        BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MIN] &&
                      limitState.pending
                    }
                    disabled={disabledSetMinMax}
                    text={`Set ${
                      BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MIN]
                    }`}
                    onClick={() =>
                      handleSetLimit(
                        BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MIN],
                        selectedEpisodeValue
                      )
                    }
                  />
                </Tooltip>
                <Tooltip
                  title={
                    <TooltipTitleWrapper>
                      {formatMessage(INTL_MAP.LIMIT_TOOLTIP, {
                        setType:
                          BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MAX],
                      })}
                      <ShortcutText text="Shift + M" />
                    </TooltipTitleWrapper>
                  }
                  placement={'top'}
                  option={{ maxWidth: 'initial' }}>
                  <SidePanelMovePositionButton
                    pending={
                      limitState.data.setLimitType ===
                        BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MAX] &&
                      limitState.pending
                    }
                    disabled={disabledSetMinMax}
                    text={`Set ${
                      BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MAX]
                    }`}
                    onClick={() =>
                      handleSetLimit(
                        BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MAX],
                        selectedEpisodeValue
                      )
                    }
                  />
                </Tooltip>
              </ButtonGroupWrapper>
              <ButtonGroupWrapper>
                <Tooltip
                  title={formatMessage(
                    isRevertable
                      ? INTL_MAP.REVERT_DEFAULT_TOOLTIP
                      : INTL_MAP.REVERT_DISABLED_TOOLTIP
                  )}
                  placement={'top'}>
                  <SidePanelMovePositionButton
                    disabled={!isRevertable}
                    text={'Unset'}
                    startIcon={<RevertIcon />}
                    onClick={() => handleRevert()}
                  />
                </Tooltip>
              </ButtonGroupWrapper>
            </>
          )}
        </TotalButtonWrapper>
        <ReportInfo
          minAvgMaxBadgeType={minAvgMaxBadgeType}
          reportStates={reportStates}
          histType={histType}
        />
      </HrDataListWrapper>
    </Wrapper>
  );
}

// HrReviewSidePanel's Styled Components
const Wrapper = styled.div`
  width: 100%;
  // Layout Header: 50px, Test Detail Header: 44px, HR Review Header: 44px
  min-height: calc(
    100vh - ${(props) => props.theme.size.headerHeight}px - 44px - 44px
  );
  max-height: calc(
    100vh - ${(props) => props.theme.size.headerHeight}px - 44px - 44px
  );
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SelectedEpisodeWrapper = styled.div`
  padding: 12px 0px 4px 18px;
  border-top: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};

  display: flex;
  gap: 4px;
`;

const SelectedEpisodeText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.color.PRIMARY_BLUE};
`;

const TotalButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MovePositionOrBinButtonWrapper = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};
`;

const ButtonGroupWrapper = styled.div`
  padding: 4px 18px;
  // height: 28px;
  gap: 6px;
  display: flex;

  & svg {
    width: 16px;
    height: 16px;
  }
`;

const HrDataListWrapper = styled.div`
  height: 198px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: auto;
`;

export const TooltipTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SidePanelMovePositionButton = forwardRef((props, ref) => {
  const { style, ...restProps } = props;
  return (
    <Button
      ref={ref}
      color={'secondary'}
      outline
      style={{
        flex: 1,
        width: 'auto',
        height: 'auto',
        padding: '3px 15px',
        ...style,
      }}
      {...restProps}
    />
  );
});

export default HrReviewSidePanel;
