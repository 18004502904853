import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 12px;
  color: ${(props) =>
    props.textColor ? props.textColor : props.theme.color.BLACK};
`;

function ListItemText(props, ref) {
  const { style, textColor, onClick, disabled, children } = props;

  return (
    <Wrapper
      style={style}
      textColor={textColor}
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick && onClick();
      }}>
      {children}
    </Wrapper>
  );
}

export default forwardRef(ListItemText);
