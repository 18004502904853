import styled from 'styled-components';

import { EVENT_GROUP_TYPE, SIDE_PANEL_EVENT_GROUP } from 'constant/EventConst';
import {
  CheckBoxAll,
  NAVIGATION_HEIGHT,
  POSITION_MOVE_TYPE,
  ShapeReviewSectionArea,
  shapeReviewLabel,
} from 'constant/ShapeReviewConst';
import { ComponentId } from 'constant/ComponentId';
import {
  LABEL_SIMILARITY_LOWEST,
  LABEL_TIME_OLDEST,
  SIMILARITY_SORT_DEFAULT,
  SortOption,
  TIME_SORT_DEFAULT_SHAPE_REVIEW,
} from 'constant/SortConst';

import { isWindowsUser } from 'util/Utility';
import { getDisableInputStatus } from 'util/shapeReview/validatePanelNavigation';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { ActivePanelType } from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';
import { IFormsEventsPanelNavigationFragmentContainer } from 'redux/container/fragment/test-result/shape-review/share/FormsEventsPanelNavigationFragmentContainer';
import OrderingContextMenuContainer from 'redux/container/fragment/test-result/share/orderingContextMenu/OrderingContextMenuContainer';

import CheckBox from 'component/ui/checkbox/CheckBox';
import Tooltip from 'component/ui/tooltip/Tooltip';

import { selectEventPoolingStateOfSelectedEvent } from 'redux/duck/shapeReviewDuck';
import {
  PoolingData,
  SelectAll,
  SetPage,
} from 'redux/duck/shapeReview/shapeReviewDuckType';
import { inputRef, onClickLabelMouseEvent } from '@type/reactTypes';
import { EventConstTypes } from '@type/ecgEventType/baseEventType';

import { ReactComponent as SidePanelPrevIcon } from 'static/icon/icon-side-panel-prev.svg';
import { ReactComponent as SidePanelNextIcon } from 'static/icon/icon-side-panel-next.svg';
import { ReactComponent as SortAscendingIcon } from 'static/icon/ic-sort-ascending.svg';
import { ReactComponent as SortDescendingIcon } from 'static/icon/ic-sort-descending.svg';

const navigationHeight = NAVIGATION_HEIGHT;

export const FormsPanelNavigationFragmentWrapper = styled.div<{
  navigationType?: string;
  activePanel?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: ${navigationHeight}px;
  padding: 12px 30px;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  ${({ navigationType, activePanel, theme }) =>
    activePanel === navigationType &&
    `
    border-top: 1px solid ${theme.color.BLUE_70};
    border-right: 1px solid ${theme.color.BLUE_70};
    border-left: 1px solid ${theme.color.BLUE_70};
  `}
`;

const FormsPanelNavigationLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxWrapper = styled.label`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  line-height: 15px;
  margin-right: 32px;
`;

const CheckboxMainLabel = styled.label`
  font-weight: 400;
  line-height: 15.6px;
  color: ${(props) => props.theme.color.COOL_GRAY_80};
`;

const CheckboxAdditionalLabel = styled.label`
  font-weight: 500;
  color: ${(props) => props.theme.color.COOL_GRAY_90};
`;
const SortLabelWrapper = styled.div`
  display: flex;
  height: 24px;
  padding: 4px;
  gap: 8px;
  border-radius: 6px;
  opacity: 0px;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
  }
`;

const SortLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  color: ${(props) => props.theme.color.COOL_GRAY_80};
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const FormsPanelNavigationRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 8px;
`;

const PaginationArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginationNavigationWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2px;
  font-size: 12px;
  & > * {
    ${({ theme, disabled }) =>
      disabled ? `color: ${theme.color.MEDIUM}` : ''};
  }
`;

const IconButton = styled.svg<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled, theme }) =>
    disabled
      ? `path { stroke: ${theme.color.MEDIUM}; }`
      : `path { stroke: ${theme.color.BLACK}; }
  `}
`;

const PrevIconButton = styled(IconButton).attrs({ as: SidePanelPrevIcon })``;
const NextIconButton = styled(IconButton).attrs({ as: SidePanelNextIcon })``;

const StyledAscendingIcon = styled(SortAscendingIcon)`
  width: 16px;
  height: 16px;
`;
const StyledDescendingIcon = styled(SortDescendingIcon)`
  width: 16px;
  height: 16px;
`;

const InputPage = styled.input<{ ref: inputRef }>`
  padding: 6px 7px;
  width: 3ch;
  height: 24px;
  text-align: center;

  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  border: 1px solid ${(props) => props.theme.color.MEDIUM_LIGHT};
  border-radius: 6px;
  :focus {
    outline: none;
  }
`;

export const TooltipCustomTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  line-height: 16px;
  text-align: center;
`;

export const ShortCutKeyGuide = styled.div`
  width: 45px;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.color.COOL_GRAY_80};
`;

const TotalPage = styled.div``;

export interface IFormsEventsPanelNavigationFragment
  extends IFormsEventsPanelNavigationFragmentContainer {
  activePanel: ActivePanelType;
  numberOfClickedForms: number;
  poolingStateOfPanel: {
    calledType: string;
    pending: boolean;
    pauseState?: boolean;
  };
  eventType: typeof EventConstTypes;
  formRankNumber: Number;
  isOpenOrderingContextmenu: boolean;
  orderingTypeSetting: SortOption;

  //
  handleSetSelectAll: ({ panelType, selectAllState }: SelectAll) => void;
  handleSetPage: ({ panelType, setPageType, value }: SetPage) => void;
  handleOrderingContextmenu: (isOpenOrderingContextmenu: boolean) => void;

  //
  inputRef: inputRef;
  paginationIconStatus: {
    disablePrevIcon: boolean;
    disableNextIcon: boolean;
  };
  //
  onKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  onSelectAll: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onChangeInputPosition: React.ChangeEventHandler<HTMLInputElement>;
}

function FormsEventsPanelNavigationFragment({
  // props state
  navigationOption: { type },
  panelPaginationInfoOfSelectedEvent,
  pagination,
  setPagination,
  checkBoxStatus,
  setCheckBoxStatus,
  // redux state
  activePanel,
  numberOfClickedForms,
  poolingStateOfPanel,
  eventType,
  formRankNumber,
  isOpenOrderingContextmenu,
  orderingTypeSetting,
  // redux dispatches
  handleSetSelectAll,
  handleSetPage,
  handleOrderingContextmenu,
  // local state
  inputRef,
  paginationIconStatus: { disablePrevIcon, disableNextIcon },
  // local state - event listener callback function
  onKeyUp,
  onSelectAll,
  onChangeInputPosition,
}: IFormsEventsPanelNavigationFragment) {
  const navigationType = type;
  const isForms = navigationType === ShapeReviewSectionArea.FORMS;
  const isEvents = navigationType === ShapeReviewSectionArea.EVENTS;

  let $checkboxLabel, totalPage, disabledPaginationOfNavigation;
  let $CheckboxAdditionalLabel, $SortLabel;

  totalPage = panelPaginationInfoOfSelectedEvent?.totalPageSize || 0;

  disabledPaginationOfNavigation = getDisableInputStatus(
    isForms,
    isEvents,
    totalPage,
    poolingStateOfPanel
  );
  const disablePrevIconButton =
    disablePrevIcon || disabledPaginationOfNavigation;
  const disableNextIconButton =
    disableNextIcon || disabledPaginationOfNavigation;

  if (isForms) {
    $checkboxLabel = (
      <CheckboxMainLabel style={{ fontWeight: 500 }}>
        {shapeReviewLabel.Forms}
      </CheckboxMainLabel>
    );
  } else if (isEvents) {
    $CheckboxAdditionalLabel = (
      <CheckboxAdditionalLabel>Events</CheckboxAdditionalLabel>
    );

    const noneClickCase = numberOfClickedForms === 0;
    const isSingleSelectedOfFormPanel = numberOfClickedForms === 1;
    const isMultiSelectedOfFormPanel = numberOfClickedForms > 1;
    const clickEditedFolderCase = formRankNumber === 0;
    const clickEtcFolderCase = formRankNumber === -1;
    const clickClusteringFolderCase = formRankNumber !== 0;

    const $tooltip = getIntlMessage(formRankNumber, orderingTypeSetting);

    if (noneClickCase) {
      $checkboxLabel = '';
      $SortLabel = '';
    } else if (isMultiSelectedOfFormPanel) {
      $checkboxLabel = '';
    } else if (isSingleSelectedOfFormPanel) {
      if (clickEditedFolderCase) {
        const shapeEctopicList = SIDE_PANEL_EVENT_GROUP[
          EVENT_GROUP_TYPE.SHAPE
        ] as [any];
        const clickedEventTypeLabel = shapeEctopicList.find(
          (v) => v.type === eventType
        ).label;

        $checkboxLabel = (
          <CheckboxMainLabel>
            {`${shapeReviewLabel.Edited} ${clickedEventTypeLabel} / `}
          </CheckboxMainLabel>
        );

        $SortLabel = (
          <Tooltip
            title={$tooltip}
            option={{ maxWidth: 'initial' }}
            placement="top">
            <SortLabel data-cid={ComponentId.ORDERING_CONTEXTMENU}>
              {orderingTypeSetting.optionText === ''
                ? LABEL_TIME_OLDEST
                : orderingTypeSetting.optionText}
              {orderingTypeSetting.ascending ? (
                <StyledAscendingIcon />
              ) : (
                <StyledDescendingIcon />
              )}
            </SortLabel>
          </Tooltip>
        );
      } else if (clickEtcFolderCase) {
        const shapeEctopicList = SIDE_PANEL_EVENT_GROUP[
          EVENT_GROUP_TYPE.SHAPE
        ] as [any];
        const clickedEventTypeLabel = shapeEctopicList.find(
          (v) => v.type === eventType
        ).label;

        $checkboxLabel = (
          <CheckboxMainLabel>
            {`${shapeReviewLabel.Etc}. ${clickedEventTypeLabel} / `}
          </CheckboxMainLabel>
        );
        $SortLabel = (
          <Tooltip
            title={$tooltip}
            option={{ maxWidth: 'initial' }}
            placement="top">
            <SortLabel data-cid={ComponentId.ORDERING_CONTEXTMENU}>
              {orderingTypeSetting.optionText === ''
                ? LABEL_TIME_OLDEST
                : orderingTypeSetting.optionText}
              {orderingTypeSetting.ascending ? (
                <StyledAscendingIcon />
              ) : (
                <StyledDescendingIcon />
              )}
            </SortLabel>
          </Tooltip>
        );
      } else if (clickClusteringFolderCase) {
        $checkboxLabel = (
          <CheckboxMainLabel>{`Form ${formRankNumber} / `}</CheckboxMainLabel>
        );
        $SortLabel = (
          <Tooltip
            title={$tooltip}
            option={{ maxWidth: 'initial' }}
            placement="top">
            <SortLabel data-cid={ComponentId.ORDERING_CONTEXTMENU}>
              {orderingTypeSetting.optionText === ''
                ? LABEL_SIMILARITY_LOWEST
                : orderingTypeSetting.optionText}
              {orderingTypeSetting.ascending ? (
                <StyledAscendingIcon />
              ) : (
                <StyledDescendingIcon />
              )}
            </SortLabel>
          </Tooltip>
        );
      }
    }
  }

  const { isChecked, isIndeterminate } = checkBoxStatus;

  const { pending: pendingOfEventPoolingStateOfSelectedEvent } =
    useShallowEqualSelector(selectEventPoolingStateOfSelectedEvent) as {
      pending: Pick<PoolingData, 'pending'>;
    };

  return (
    <FormsPanelNavigationFragmentWrapper
      className="FormsPanelNavigationFragmentWrapper"
      activePanel={activePanel}
      navigationType={navigationType}>
      <FormsPanelNavigationLeft className="FormsPanelNavigationLeft">
        {activePanel === navigationType && (
          <Tooltip
            title={
              <TooltipCustomTitleWrapper>
                <div>
                  {isChecked || isIndeterminate
                    ? '전체 선택 해제'
                    : '전체 선택'}
                </div>
                <ShortCutKeyGuide>
                  {`${isWindowsUser() ? 'ctrl' : 'cmd'} + A`}
                </ShortCutKeyGuide>
              </TooltipCustomTitleWrapper>
            }
            placement="top"
            offset={[0, 0]}>
            <CheckboxWrapper
              onClick={(e: onClickLabelMouseEvent) => {
                e.preventDefault();
              }}>
              <CheckBox
                indeterminate={isIndeterminate}
                checked={isChecked}
                onChange={() => {
                  // 전체 선택 off -> 전체 선택 on
                  const isOffToOn =
                    isIndeterminate === false && isChecked === false;
                  if (
                    isOffToOn ||
                    (pendingOfEventPoolingStateOfSelectedEvent && isOffToOn)
                  ) {
                    return handleSetSelectAll({
                      panelType: navigationType,
                      selectAllState: CheckBoxAll.CheckedAll,
                    });
                  }

                  // 전체 선택 on -> 전체 선택 off
                  const isOnToOff =
                    isIndeterminate === false && isChecked === true;
                  if (
                    isOnToOff ||
                    (pendingOfEventPoolingStateOfSelectedEvent && isOnToOff)
                  ) {
                    return handleSetSelectAll({
                      panelType: navigationType,
                      selectAllState: CheckBoxAll.None,
                    });
                  }
                  // some 선택 -> 전체 선택 off
                  const isSomeToOff =
                    isIndeterminate === true && isChecked === true;
                  if (
                    isSomeToOff &&
                    !pendingOfEventPoolingStateOfSelectedEvent
                  ) {
                    return handleSetSelectAll({
                      panelType: navigationType,
                      selectAllState: CheckBoxAll.None,
                    });
                  }
                }}
                size={'16px'}
                hover
                hoverStyle={{ padding: '7px 4px' }}
                labelStyle={{ fontSize: 12 }}
              />
            </CheckboxWrapper>
          </Tooltip>
        )}
        <CheckboxLabel>
          {$checkboxLabel}
          {$CheckboxAdditionalLabel}
        </CheckboxLabel>
        {isEvents && (
          <SortLabelWrapper
            onClick={() => {
              handleOrderingContextmenu(true);
            }}>
            {isOpenOrderingContextmenu && (
              <OrderingContextMenuContainer
                orderingTypeSetting={orderingTypeSetting}
                formRankNumber={formRankNumber}
              />
            )}
            {$SortLabel}
          </SortLabelWrapper>
        )}
      </FormsPanelNavigationLeft>
      <FormsPanelNavigationRight className="FormsPanelNavigationRight">
        <PaginationArrowWrapper className="PaginationArrowWrapper">
          <Tooltip
            title={
              !disablePrevIcon && (
                <TooltipCustomTitleWrapper>
                  <div>이전</div>
                  <ShortCutKeyGuide style={{ width: 12 }}>
                    {'['}
                  </ShortCutKeyGuide>
                </TooltipCustomTitleWrapper>
              )
            }
            placement="top">
            <PrevIconButton
              disabled={disablePrevIconButton}
              onClick={(e) => {
                !disablePrevIconButton &&
                  handleSetPage({
                    panelType: navigationType,
                    setPageType: POSITION_MOVE_TYPE.PREV,
                  });
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              !disableNextIcon && (
                <TooltipCustomTitleWrapper>
                  <div>다음</div>
                  <ShortCutKeyGuide style={{ width: 12 }}>
                    {']'}
                  </ShortCutKeyGuide>
                </TooltipCustomTitleWrapper>
              )
            }
            placement="top">
            <NextIconButton
              disabled={disableNextIconButton}
              onClick={(e) => {
                !disableNextIconButton &&
                  handleSetPage({
                    panelType: navigationType,
                    setPageType: POSITION_MOVE_TYPE.NEXT,
                  });
              }}
            />
          </Tooltip>
        </PaginationArrowWrapper>
        <PaginationNavigationWrapper
          disabled={disabledPaginationOfNavigation}
          className="PaginationNavigationWrapper">
          <InputPage
            ref={inputRef}
            disabled={disabledPaginationOfNavigation}
            pattern="[0-9]*"
            value={String(pagination.currentPage)}
            maxLength={String(totalPage).length}
            onKeyUp={onKeyUp}
            onFocus={onSelectAll}
            onChange={onChangeInputPosition}
          />
          <TotalPage>/ {totalPage.toLocaleString()} page</TotalPage>
        </PaginationNavigationWrapper>
      </FormsPanelNavigationRight>
    </FormsPanelNavigationFragmentWrapper>
  );
}

function getIntlMessage(formRankNumber, orderingTypeSetting) {
  if (!orderingTypeSetting.valueText) {
    if (formRankNumber < 1) {
      return TIME_SORT_DEFAULT_SHAPE_REVIEW.tooltipTitle.defaultMessage;
    } else {
      return SIMILARITY_SORT_DEFAULT.tooltipTitle.defaultMessage;
    }
  } else {
    return orderingTypeSetting.tooltipTitle.defaultMessage;
  }
}

export default FormsEventsPanelNavigationFragment;
