import { useDispatch, batch } from 'react-redux';

import { ECG_CHART_UNIT, SELECTION_MARKER_TYPE } from 'constant/ChartEditConst';
import { BASIS_AMPLITUDE_RATE, STRIP_TYPE_MAP } from 'constant/ReportConst';
import {
  getInitRepresentativeStripInfo,
  getOnsetTerminationByCenter,
} from 'util/reduxDuck/TestResultDuckUtil';
import {
  selectRecordingTime,
  setRepresentativeStripInfo as setRepresentativeStripInfoRequested,
  setSelectionStripRequest,
  setReportEventEditorNewState,
} from 'redux/duck/testResultDuck';
import useGetResetMarkersFunc from './useGetResetMarkersFunc';
import useShallowEqualSelector from './useShallowEqualSelector';

const TEN_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX;
const THIRTY_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX;
const THIRTY_SEC_MS = 30 * 1000;

function useGetRepresentativeMarkersFunc() {
  const dispatch = useDispatch();

  const _reportEventDetailData = useShallowEqualSelector(
    ({ testResultReducer: state }) => state.reportDetail.data
  );

  const {
    recordingStartMs: _recordingStartMs,
    recordingEndMs: _recordingEndMs,
  } = useShallowEqualSelector(selectRecordingTime);
  const recordingEndWaveformIndex = (_recordingEndMs - _recordingStartMs) / 4;

  const initRepresentativeStripInfo = getInitRepresentativeStripInfo(
    _reportEventDetailData
  );

  const {
    removeSelectionMarker,
    removeRepresentativeStripMarker,
    resetSelectionStrip,
    resetSidePanelRepresentative,
  } = useGetResetMarkersFunc();

  const removeRepresentativeMarkers = () => {
    removeSelectionMarker();
    removeRepresentativeStripMarker();
  };

  /** 대표 Strip Marker 와 대표 Selection Marker 를 지우고, Global State 도 초기화 */
  const resetRepresentativeStates = () => {
    batch(() => {
      resetSelectionStrip();
      resetSidePanelRepresentative();
    });
  };

  const setRepresentativeStripInfo = (newInfo) => {
    dispatch(setRepresentativeStripInfoRequested(newInfo));
  };

  /**
   * Representative Strip 의 Selected Marker 와 Strip Marker 초기값 설정
   *
   * @param {ReportEventRepresentativeStrip} markerInfo
   * @param {'main' | 'sub'} [modifiableStripType]
   */
  const setSelectionStrip = (markerInfo, modifiableStripType) => {
    const {
      centerWaveformIndex,
      representativeStripLength = TEN_SEC_WAVEFORM_LENGTH,
    } = markerInfo;
    const {
      modifiedCenterWaveformIndex,
      onsetWaveformIndex,
      terminationWaveformIndex,
    } = getOnsetTerminationByCenter(
      centerWaveformIndex,
      representativeStripLength / 2,
      recordingEndWaveformIndex
    );
    const selectedMs = _recordingStartMs + modifiedCenterWaveformIndex * 4;

    const clickedWaveformIndex =
      centerWaveformIndex % THIRTY_SEC_WAVEFORM_LENGTH;
    const representativeTimestamp = selectedMs - (selectedMs % THIRTY_SEC_MS);
    const representativeWaveformIndex =
      centerWaveformIndex - clickedWaveformIndex;

    batch(() => {
      setRepresentativeStripInfo({
        selectedMs: selectedMs,
        representativeOnsetIndex: onsetWaveformIndex,
        representativeTerminationIndex: terminationWaveformIndex,
      });

      if (modifiableStripType) {
        // Representative Strip 정보 업데이트
        let newSubState;
        if (modifiableStripType === STRIP_TYPE_MAP.MAIN) {
          newSubState = {
            mainRepresentativeInfo: {
              selectedMs: selectedMs,
              representativeOnsetIndex: onsetWaveformIndex,
              representativeTerminationIndex: terminationWaveformIndex,
              amplitudeRate: BASIS_AMPLITUDE_RATE,
            },
          };
        } else {
          newSubState = {
            subRepresentativeInfo: {
              selectedMs: selectedMs,
              representativeOnsetIndex: onsetWaveformIndex,
              representativeTerminationIndex: terminationWaveformIndex,
              amplitudeRate: BASIS_AMPLITUDE_RATE,
              isRemoved: false,
              isMainChanged: false,
            },
          };
        }
        dispatch(setReportEventEditorNewState(newSubState));
      }

      dispatch(
        setSelectionStripRequest({
          selectionMarkerType: SELECTION_MARKER_TYPE.ONSET,
          representativeTimestamp,
          representativeWaveformIndex,
          clickedWaveformIndex,
          extraParam: {
            isNoise: false,
          },
        })
      );
    });
  };

  return {
    initRepresentativeStripInfo,
    removeRepresentativeMarkers,
    resetRepresentativeStates,
    setRepresentativeStripInfo,
    setSelectionStrip,
  };
}

/**
 * @typedef RepresentativeMarkerInfo
 * @prop {number} centerWaveformIndex Selected Marker 위치
 * @prop {number} [representativeStripLength] Representative Strip 길이
 */

export default useGetRepresentativeMarkersFunc;
