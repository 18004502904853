import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from 'styled-components';

import { AMPLITUDE_OPTION, ECG_CHART_UNIT } from 'constant/ChartEditConst';
import { CLASS_NAME_CHART_BASE } from 'constant/ChartConst';
import { ShapeReviewSectionArea } from 'constant/ShapeReviewConst';
import { ComponentId } from 'constant/ComponentId';

import useManagedEventMarker from 'component/hook/useManagedEventMarker';
import useManagedBeatMarker from 'component/hook/useManagedBeatMarker';

const chartPlotBorderWidth = 182;
const chartPlotBorderHeight = 56;

function FourSecChart(props) {
  const { chartInfo, gridType, isFocused, isEdited } = props;
  const chartRef = useRef();
  const theme = useTheme();

  const isEventsPanelGrid = gridType === ShapeReviewSectionArea.EVENTS;

  const slicedEcgRaw = sliceEcg({
    ecgRaw: chartInfo.mainECG.rawECG,
    startSecond: 3,
    endSecond: 7,
  });

  const { beats } = useManagedEventMarker(
    isEventsPanelGrid
      ? chartInfo.mainECG.onsetWaveformIndex + 3 * 250
      : chartInfo.mainECG.onsetWaveformIndex,
    chartInfo.mainECG.terminationWaveformIndex,
    isEventsPanelGrid ? slicedEcgRaw : chartInfo.mainECG.rawECG,
    chartRef.current?.chart,
    chartPlotBorderWidth,
    chartInfo.beats,
    //
    true, // isNotChangeState
    true, //  withoutTimeEvents
    true, // withoutUnderLine
    true //  withoutOnsetNTermLine
  );

  useManagedBeatMarker(
    isEventsPanelGrid
      ? chartInfo.mainECG.onsetWaveformIndex + 3 * 250
      : chartInfo.mainECG.onsetWaveformIndex,
    chartRef.current?.chart,
    beats,
    ECG_CHART_UNIT.FOUR_SEC_WAVEFORM_IDX
  );

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      allowChartUpdate={true}
      immutable={false}
      updateArgs={[false, false, false]}
      options={generateChartOptions({
        theme,
        isFocused,
        isEdited,
        ecgRaw: isEventsPanelGrid ? slicedEcgRaw : chartInfo.mainECG.rawECG,
        onsetWaveformIdx: chartInfo.mainECG.onsetWaveformIndex,
        beatTypeZones: undefined,
      })}
      containerProps={{
        className: ComponentId.FOUR_SEC_STRIP_CHART_CONTAINER,
        'data-cid': ComponentId.FOUR_SEC_STRIP_CHART_CONTAINER,
        style: {
          // 화면에서 제공할 너비 높이
          width: chartPlotBorderWidth,
          height: chartPlotBorderHeight,
        },
      }}
      callback={(chart) => {}}
    />
  );
}

const LINE_WIDTH = 1;
const X_AXIS_TICK_LENGTH = 0;
const CHART_MARGIN_TOP = 4;
const CHART_MARGIN_BOTTOM = 1;

const generateChartOptions = ({
  theme,
  ecgRaw,
  isFocused,
  isEdited,
  beatTypeZones,
  onsetWaveformIdx,
}) => {
  return {
    chart: {
      spacing: [CHART_MARGIN_TOP, LINE_WIDTH, CHART_MARGIN_BOTTOM, LINE_WIDTH],
      plotBorderColor: theme.color.COOL_GRAY_40,
      plotBorderWidth: 1,
      plotBackgroundColor: isEdited
        ? theme.color.COOL_GRAY_40
        : theme.color.WHITE,
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Spoqa Han Sans Neo',
      },
      events: {
        click: function (event) {},
        load: function (chart) {},
        render: function () {},
        redraw: function () {},
      },
      animation: false,
    },
    xAxis: {
      plotLines: [
        {
          className: 'huinno-four-sec-strip-xAxis-center-plot-line',
          color: theme.color.COOL_GRAY_70,
          width: 0.5,
          value: 500,
          zIndex: 2,
        },
      ],
      minPadding: 0,
      min: 0, // ecg data raw: # down sampling
      max: ECG_CHART_UNIT.FOUR_SEC_WAVEFORM_IDX,
      lineWidth: 0,
      tickLength: 0,
      // Major Tick & Grid
      tickInterval: 100 * 2.5,
      tickWidth: 1,
      gridLineColor: theme.color.COOL_GRAY_50,
      gridLineWidth: 0.5,
      // Minor Grid
      minorTickInterval: 50,
      minorGridLineColor: theme.color.COOL_GRAY_40,
      minorGridLineWidth: 0.5,
      gridZIndex: 0,
      // Label
      labels: {
        enabled: true,
        padding: 0,
        x: 9,
        y: 14,
        style: {
          fontSize: '9px',
          'line-height': '130%',
          fill: theme.color.MEDIUM_DARK,
          color: theme.color.MEDIUM_DARK,
        },
        // 마지막 10초의 라벨은 생략
        formatter: function () {},
        plotBands: [],
      },
    },
    yAxis: [
      {
        min: -1 / AMPLITUDE_OPTION.TEN_MV.RATE,
        max: 2 / AMPLITUDE_OPTION.TEN_MV.RATE,
        lineWidth: 0,
        gridLineWidth: 0,
        gridLineWidth: 0.5,
        gridLineColor: theme.color.COOL_GRAY_40,
        // tickInterval: 0.5 / AMPLITUDE_OPTION.TEN_MV.RATE, // warning: 차트가 작은 경우에 정상적으로 그려지지 않음(7칸이 아니라 3간으로 보임) 그래서 임시로 tickAmount 사용(칸의 크기는 일정함))
        tickAmount: 7,
        title: {
          enabled: false,
        },
        labels: {
          enabled: false,
        },
      },
    ],
    series: [
      {
        id: '4sec-beats-strip-series',
        data: (ecgRaw ?? []).length
          ? ecgRaw
          : Array.from(
              { length: ECG_CHART_UNIT.FOUR_SEC_WAVEFORM_IDX },
              () => -100
            ),
        onsetWaveformIdx: onsetWaveformIdx,
        lineWidth: LINE_WIDTH,
        color: theme.color.ECG_BLACK,
        animation: false,
        pointPlacement: 'on',
        zoneAxis: 'x',
        zones: beatTypeZones,
        className: `huinno-4sec-data ${CLASS_NAME_CHART_BASE}`,
      },
    ],
    plotOptions: {
      series: {
        events: {
          click: function (event) {},
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false, // hover 시 마우스 포인터와 가까운 포인트 강조 효과 제거
            },
          },
        },
        states: {
          inactive: {
            enabled: false,
          },
          hover: {
            enabled: true,
            halo: null, // hover 시 마우스 포인터와 가까운 포인트 주변 후광(?) 효과 제거
            lineWidthPlus: 0,
          },
        },
        animation: false, // animation 제거(렌더 시간 단축!!!)
      },
    },
    tooltip: {
      enabled: false,
    },
    title: null,
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

export default React.memo(FourSecChart, (prev, next) => {
  return (
    prev.chartInfo.originWaveformIndex === next.chartInfo.originWaveformIndex &&
    prev.isFocused === next.isFocused &&
    prev.isEdited === next.isEdited
  );
});

export function sliceEcg({ ecgRaw, startSecond, endSecond }) {
  const WAVEFORM_INDEX_PER_SECOND = 250;
  const startIdx = startSecond * WAVEFORM_INDEX_PER_SECOND;
  const endIdx = endSecond * WAVEFORM_INDEX_PER_SECOND;

  const slicedEcgRaw = ecgRaw.slice(startIdx, endIdx);

  return slicedEcgRaw;
}
