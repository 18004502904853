import styled, { CSSProperties } from 'styled-components';

// ShortcutText Component
function ShortcutText({ style, text }: ShortcutTextProps) {
  return <Wrapper style={style}>{text}</Wrapper>;
}

// ShortcutText's Styled Components
const Wrapper = styled.div`
  padding: 1px 4px;

  border-radius: 2px;
  background-color: ${(props) => props.theme.color.COOL_GRAY_80};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.color.WHITE};
  font-size: 11px;
  font-weight: 500;
  line-height: 130%;
`;

// ShortcutText's Types
type ShortcutTextProps = {
  style: CSSProperties;
  /** 단축키 메시지 */
  text: 'string';
};

export default ShortcutText;
