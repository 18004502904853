import styled from 'styled-components';

import { ReactComponent as FolderIcon } from 'static/icon/ic-folder.svg';
import { ReactComponent as BeatIcon } from 'static/icon/ic-beat.svg';
import { ReactComponent as InfoIcon } from 'static/icon/ic-info.svg';

export const ChartInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 16px;
  width: 176px;

  font-size: 11px;
  line-height: 14.3px;
`;

export const ChartLeftInfoWrapper = styled.div<{
  // isRead: boolean;
  isSelected: boolean;
  isEdited: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 16px;

  gap: 2px;

  ${(props) => {
    const { isSelected, /* isRead, */ isEdited } = props;
    let color, textDecoration;
    let showEditedLabelState = {
      visibility: 'hidden',
      backgroundColor: '',
    };

    if (isSelected) {
      color = props.theme.color.BLUE_70;
    } else {
      color = props.theme.color.COOL_GRAY_80;
    }

    // if (isRead) {
    //   textDecoration = 'line-through';
    // }

    if (isEdited) {
      showEditedLabelState.visibility = 'visible';
      if (isSelected) {
        showEditedLabelState.backgroundColor = props.theme.color.BLUE_70;
      } else {
        showEditedLabelState.backgroundColor = props.theme.color.COOL_GRAY_70;
      }
    } else {
      showEditedLabelState.visibility = 'hidden';
    }

    return `
      color: ${color};
      & .beatIcon {
        & path {
          fill: ${color};
        }
      }
      & .chartIdLabel {
        height: 14px;
        font-weight: 400;
        text-decoration: ${textDecoration};
      } 
      & .editedLabel{
        color: white;
        visibility: ${showEditedLabelState.visibility};
        background-color: ${showEditedLabelState.backgroundColor};
      }
    `;
  }}
`;

export const ChartRightInfoWrapper = styled.div<{ isSelected: boolean }>`
  color: ${(props) => props.theme.color.COOL_GRAY_80};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  :hover {
    > * {
      display: block;
    }
  }
`;

export const StyledFolderIcon = styled(FolderIcon)<{ disabled: boolean }>`
  height: 16px;
  width: 16px;
`;

export const StyledBeatIcon = styled(BeatIcon)<{ disabled: boolean }>`
  height: 16px;
  width: 16px;
`;

export const IconWrapper = styled.label`
  display: flex;
  justify-content: center;
`;

export const ChartIdLabel = styled.label``;

export const RrIntervalRatio = styled.label``;

export const StyledInfoIcon = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
  fill: ${(props) => props.theme.color.COOL_GRAY_60};
  :hover {
    path {
      fill: ${(props) => props.theme.color.COOL_GRAY_80};
    }
  }
`;

export const EventsNumberLabel = styled.label``;

export const EditedLabel = styled.label`
  height: 14px;
  border-radius: 2px;
  padding: 0 3px;
  font-weight: 400;
`;
