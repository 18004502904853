import { BEAT_TYPE } from 'constant/EventConst';
import AppColors from 'theme/AppColors';
/**
 *           color:
            ectopicInfo.beatType === BEAT_TYPE.APC
              ? AppColors.SVE_600
              : AppColors.VE_600,

          color: AppColors.MEDIUM_DARK,
          color: theme.color.ECG_BLACK,

 */
const BeatTypeSeriesUtil = {
  /**
   *
   * @param {Number[]} ecgData
   * @param {(import('./reduxDuck/TestResultDuckUtil').TransformedEventType & import('./reduxDuck/TestResultDuckUtil').EventMarkerRenderDataType & import('./reduxDuck/TestResultDuckUtil').EctopicResponseType)[]} ectopicList
   * @param {(import('./reduxDuck/TestResultDuckUtil').TransformedEventType & import('./reduxDuck/TestResultDuckUtil').EventMarkerRenderDataType)[]} noiseList
   * @returns
   */
  convertSeries: (ecgData, ectopicList, noiseList) => {
    const decideColor = (index) => {
      // Ectopic 구간 확인
      let eventInfo = ectopicList.find(
        (value) =>
          value.onsetLocalWaveformIndex <= index &&
          index < value.terminationLocalWaveformIndex
      );
      if (eventInfo)
        return eventInfo.beatType === BEAT_TYPE.APC
          ? AppColors.SVE_600
          : AppColors.VE_600;
      // Noise 구간 확인
      eventInfo = noiseList.find(
        (value) =>
          value.onsetLocalWaveformIndex <= index &&
          index < value.terminationLocalWaveformIndex
      );
      if (eventInfo) return AppColors.MEDIUM_DARK;
      // Normal 구간
      return AppColors.ECG_BLACK;
    };
    return ecgData.map((value, index) => ({
      y: value,
      segmentColor: decideColor(index),
    }));
  },

  /**
   *
   * @param {Number[]} originalSeries
   * @param {(import('./reduxDuck/TestResultDuckUtil').TransformedEventType & import('./reduxDuck/TestResultDuckUtil').EventMarkerRenderDataType & import('./reduxDuck/TestResultDuckUtil').EctopicResponseType)[]} ectopicList
   * @param {(import('./reduxDuck/TestResultDuckUtil').TransformedEventType & import('./reduxDuck/TestResultDuckUtil').EventMarkerRenderDataType)[]} noiseList
   * @returns
   */
  generateExtraSeries: (originalSeries, ectopicList, noiseList) => {
    // const extraList = [...ectopicList, ...noiseList].sort(
    //   (a, b) => a.onsetLocalWaveformIndex - b.onsetLocalWaveformIndex
    // );
    const ectopicSeries = ectopicList.map((ectopicInfo) => ({
      data: originalSeries
        .slice(
          ectopicInfo.onsetLocalWaveformIndex,
          ectopicInfo.terminationLocalWaveformIndex
        )
        .map((value, index) => [
          ectopicInfo.onsetLocalWaveformIndex + index,
          value,
        ]),
      color:
        ectopicInfo.beatType === BEAT_TYPE.APC
          ? AppColors.SVE_80
          : AppColors.VE_80,
      zIndex: 5,
    }));
    const noiseSeries = noiseList.map((noiseInfo) => ({
      data: originalSeries
        .slice(
          noiseInfo.onsetLocalWaveformIndex,
          noiseInfo.terminationLocalWaveformIndex
        )
        .map((value, index) => [
          noiseInfo.onsetLocalWaveformIndex + index,
          value,
        ]),
      color: AppColors.COOL_GRAY_50,
      zIndex: 5,
    }));
    return { ectopicSeries, noiseSeries };
  },
};

export default BeatTypeSeriesUtil;
