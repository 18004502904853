import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import usePrevious from 'component/hook/usePrevious';
import useSnackbarStack from 'component/hook/useSnackbarStack';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import EventsPanelListFragment from 'component/fragment/test-result/shape-review/EventsPanel/EventsPanelListFragment';

import {
  setPausePoolingEventList,
  selectProgressPercentage,
  editedEventListByClickedForm,
  selectSelectedInfoOfFormsPanel,
  selectPatchBeatByWaveFormIndexes,
  selectIsCoupletEctopicTypeClicked,
  selectEventPoolingStateOfSelectedEvent,
  selectFormPanelDetailOfFirstIndexOfLastSelectedSection,
  selectActivePanel,
  selectClickedInfo,
  setSelectedCheckboxStatus,
} from 'redux/duck/shapeReviewDuck';
import {
  Checkbox,
  ClickedInfo,
} from 'redux/duck/shapeReview/shapeReviewDuckType';

import {
  PanelListFragmentContainerProps,
  ActivePanelType,
} from '../ShapeReviewFragmentContainer';

function EventsPanelListFragmentContainer({
  // local state
  checkBoxStatus,
  // useChartList Hook state
  gridLayoutRef,
  chartList,
  chartItemRectangle,
  selectedItemList,
  lastSelectedSectionInfo,
  setSelectedItemList,
  setLastSelectedSectionInfo,
  //
  onClickGridItem,
}: PanelListFragmentContainerProps) {
  const dispatch = useDispatch();
  const { enqueueMessage } = useSnackbarStack(undefined, 10 * 1000);

  // redux selectors
  const clickedInfo = useShallowEqualSelector(selectClickedInfo) as ClickedInfo;
  const activePanel = useShallowEqualSelector(
    selectActivePanel
  ) as ActivePanelType;
  const { calledType, pending, pauseState } = useShallowEqualSelector(
    selectEventPoolingStateOfSelectedEvent
  ) as any;
  const selectedFormsInfo: any = useShallowEqualSelector(
    selectSelectedInfoOfFormsPanel
  );
  const formPanelDetailOfFirstIndexOfLastSelectedSection: any =
    useShallowEqualSelector(
      selectFormPanelDetailOfFirstIndexOfLastSelectedSection
    );
  const eventPanelPatchedList = useShallowEqualSelector((state: any) =>
    editedEventListByClickedForm(state)
  ) as number[];
  const isCoupletEctopicTypeClicked = useShallowEqualSelector(
    selectIsCoupletEctopicTypeClicked
  ) as boolean;
  const eventPoolingProgressPercentage: number = useShallowEqualSelector(
    selectProgressPercentage
  ) as number;
  const {
    pending: patchBeatByWaveFormIndexesPending,
    responseValidationResult: { succeedWaveformIndexes, failedWaveformIndexes },
  } = useShallowEqualSelector(selectPatchBeatByWaveFormIndexes) as any;

  // redux dispatches
  const handleSetPausePoolingEventList = (state: boolean) =>
    dispatch(setPausePoolingEventList(state));

  // checkbox 상태 변경 redux function(이벤트 클릭, 이벤트의 체크박스 변경시에만 호출)
  const handleSelectedCheckboxStatus = (checkboxStatus: Checkbox) => {
    dispatch(setSelectedCheckboxStatus(checkboxStatus));
  };

  const formPanelSelectedItemListLength =
    [...selectedFormsInfo.selectedItemList].length ?? 0;

  // useEffects
  const prevPatchBeatByWaveFormIndexesPending = usePrevious(
    patchBeatByWaveFormIndexesPending
  );
  useEffect(() => {
    if (
      prevPatchBeatByWaveFormIndexesPending &&
      !patchBeatByWaveFormIndexesPending &&
      failedWaveformIndexes.length > 0
    ) {
      enqueueMessage(
        `${succeedWaveformIndexes.length}개 Beat 편집 완료, ${failedWaveformIndexes.length}개 편집 불가.\n편집이 불가한 이벤트가 있습니다.`
      );
    }
  }, [patchBeatByWaveFormIndexesPending]);

  // etc
  const onClickPause = (e: React.MouseEvent<HTMLLabelElement>) => {
    handleSetPausePoolingEventList(true);
  };
  const onClickFetchingAgain = (e: React.MouseEvent<HTMLLabelElement>) => {
    handleSetPausePoolingEventList(false);
  };

  return (
    <EventsPanelListFragment
      // props state
      checkBoxStatus={checkBoxStatus}
      // local state
      formPanelSelectedItemListLength={formPanelSelectedItemListLength}
      // useChartList Hook state
      gridLayoutRef={gridLayoutRef}
      chartList={chartList}
      chartItemRectangle={chartItemRectangle}
      selectedItemList={selectedItemList}
      lastSelectedSectionInfo={lastSelectedSectionInfo}
      setSelectedItemList={setSelectedItemList}
      setLastSelectedSectionInfo={setLastSelectedSectionInfo}
      // redux state
      activePanel={activePanel}
      clickedInfo={clickedInfo}
      panelPatchedList={eventPanelPatchedList}
      eventPoolingProgressPercentage={eventPoolingProgressPercentage}
      isCoupletEctopicTypeClicked={isCoupletEctopicTypeClicked}
      poolingStateOfPanel={{ calledType, pending, pauseState }}
      formPanelDetailOfFirstIndexOfLastSelectedSection={
        formPanelDetailOfFirstIndexOfLastSelectedSection
      }
      //
      onClickPause={onClickPause}
      onClickGridItem={onClickGridItem}
      onClickFetchingAgain={onClickFetchingAgain}
      // redux function
      handleSelectedCheckboxStatus={handleSelectedCheckboxStatus}
    />
  );
}

export default EventsPanelListFragmentContainer;
