import { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { BEAT_TYPE } from 'constant/EventConst';
import ChartUtil from 'util/ChartUtil';
import { isPositiveWaveformIndexPair } from 'util/validation/ValidationUtil';
import { CHART_EDIT_CONST } from 'constant/ChartEditConst';

const ENABLED_LOGGING_PERFORMANCE = false;

const initBeatMarkerGroup = (chartInst, onsetWaveformIndex) => {
  if (chartInst) {
    ENABLED_LOGGING_PERFORMANCE &&
      console.time(`${onsetWaveformIndex} - 전체 Beat Marker 초기화`);
    if (chartInst.beatMarkerGroup) {
      chartInst.beatMarkerGroup.destroy();
      delete chartInst.beatMarkerGroup;
    }
    const beatMarkerGroup = chartInst.renderer
      .g()
      .attr({
        class: CHART_EDIT_CONST.BEAT_MARKER_GROUP,
        zIndex: 4,
      })
      .add();
    chartInst.beatMarkerGroup = beatMarkerGroup;
    ENABLED_LOGGING_PERFORMANCE &&
      console.timeEnd(`${onsetWaveformIndex} - 전체 Beat Marker 초기화`);
  }
};

function useManagedBeatMarker(
  onsetWaveformIndex,
  chartInst,
  beats,
  totalWaveformIndex = 7500
) {
  const theme = useTheme();

  useEffect(() => {
    if (
      !beats ||
      !isPositiveWaveformIndexPair(onsetWaveformIndex, onsetWaveformIndex) ||
      !chartInst?.series[0]?.data.length
    ) {
      return;
    }
    const { waveformIndex, beatType, hr } = beats;
    initBeatMarkerGroup(chartInst, onsetWaveformIndex);

    ENABLED_LOGGING_PERFORMANCE &&
      console.time(`${onsetWaveformIndex} - 전체 Beat Marker 렌더링`);
    for (let i = 0; i < beatType.length; i++) {
      ChartUtil.renderBeatMarker(chartInst, {
        waveformIndex: waveformIndex[i] - onsetWaveformIndex,
        beatType: beatType[i],
        hr: hr[i],
        color: theme.color.COOL_GRAY_80,
        totalWaveformIndex,
      });
    }
    ENABLED_LOGGING_PERFORMANCE &&
      console.timeEnd(`${onsetWaveformIndex} - 전체 Beat Marker 렌더링`);
  }, [onsetWaveformIndex, chartInst, beats]);
}

export default useManagedBeatMarker;
