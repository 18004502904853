import {
  BEAT_TYPE,
  REPORT_TYPE,
  TIME_EVENT_TYPE,
  //
  EVENT_INFO,
  EVENT_CONST_TYPES,
  EVENT_GROUP_TYPE,
  //
  CONTEXT_MENU,
  REPORT_SECTION,
  SIDE_PANEL_EVENT_GROUP,
  VALIDATED_AF_MIN_DURATION,
  REPORT_EVENT_ANNOTATION_DEFAULT,
  AV_BLOCK_LIST,
} from 'constant/EventConst';

import { EventSection } from '@type/ecgEventType/baseEventType';

const CONST_REPORT_SECTION = REPORT_SECTION;
const CONST_EVENT_SECTION = EventSection;
const CONST_EVENT_INFO = EVENT_INFO;
const CONST_VALIDATED_AF_MIN_DURATION = VALIDATED_AF_MIN_DURATION;
const CONST_REPORT_EVENT_ANNOTATION_DEFAULT = REPORT_EVENT_ANNOTATION_DEFAULT;
const CONST_CONTEXT_MENU = CONTEXT_MENU;

/**
 * av block 판단
 *
 * @param {*} eventType
 * @returns
 */
export const checkIfIsAvBlockEventType = (eventType) => {
  return AV_BLOCK_LIST.includes(eventType);
};

/**
 * Filters and limits event information based on the provided search criteria and limit.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.searchEventInfo - The search criteria for filtering event information.
 * @param {undefined | number} [params.limit] - The maximum number of results to return. If not specified, all matching results are returned.
 * @returns {Array} The filtered and limited array of event information.
 *
 * @example
 * // Example usage:
 * const searchCriteria = { timeEventGroup: 'AV_BLOCK' };
 * const limitedResults = getEventInfo({ searchEventInfo: searchCriteria, limit: 1 });
 * console.log(limitedResults);
 */
class EventInfoArray extends Array {
  limit(count) {
    return this.slice(0, count);
  }
  findOne() {
    return this.length > 0 ? this[0] : null;
  }
}

export const getEventInfo = (searchEventInfo) => {
  const filteredEvents = CONST_EVENT_INFO.filter((event) => {
    return Object.entries(searchEventInfo).every(([key, value]) => {
      if (value === undefined) return event[key] === null;
      return event[key] === value;
    });
  });

  return new EventInfoArray(...filteredEvents);
};

// const avBlockList = getEventInfo({
//   timeEventGroup: TIME_EVENT_TYPE.AV_BLOCK,
// });

// debugger;

/**
 * @deprecated 사용하지 마세요. 대신 getEventInfo을  사용하세요.
 *
 *
 * 이벤트 구분값으로 해당 이벤트에 대해 API 조회에 필요한 정보를 응답
 * @param {string} type 이벤트 구분값
 * @returns
 */
export const getEventInfoByType = (type) => {
  return CONST_EVENT_INFO.find((value) => value.type === type);
};

/**
 * @deprecated 사용하지 마세요. 대신 getEventInfo을  사용하세요.
 *
 * 이벤트 정보의 구분값을 통해 이벤트 구분값을 응답
 * @param {{timeEventType?: string, beatType?: number, ectopicType?: string}} queryParameters API 조회를 위한 조건
 * @returns
 */
export const getEventInfoByQuery = ({
  timeEventType = null,
  beatType = -1, // -1 인 값이 없기 때문에 -1로 초기화
  ectopicType = null,
  geminyType,
}) =>
  CONST_EVENT_INFO.find((value) => {
    return (
      value.timeEventType === timeEventType &&
      value.beatType === beatType &&
      value.ectopicType === ectopicType &&
      value.geminyType === geminyType
    );
  });

/**
 * SIDE_PANEL_EVENT_GROUP의 특정 키의 밸류를 바탕으로 해당 데이터를 리턴
 * key: SIDE_PANEL_EVENT_GROUP의 EVENT_GROUP_TYPE 객체 안에 키 값
 * value: 검색할 값
 * @param {{groupType: string, key:string, value:typeof EventConstTypes}}
 * @returns
 */
export const getSidePanelEventData = ({ groupType = 'EVENTS', key, value }) => {
  const group = SIDE_PANEL_EVENT_GROUP[groupType] || [];

  for (const item of group) {
    if (item[key] === value) {
      return item;
    }

    if (Array.isArray(item.subTypeList)) {
      for (const subItem of item.subTypeList) {
        if (subItem.type === value) {
          return subItem;
        }
      }
    }
  }

  return {};
};

export const convertEventTypeToReportDetailSectionType = (eventType) => {
  switch (eventType) {
    case EVENT_CONST_TYPES.OTHERS:
      return null;

    case EVENT_CONST_TYPES.ISO_APC:
    case EVENT_CONST_TYPES.COUPLET_APC:
    case EVENT_CONST_TYPES.BIGEMINY_APC:
    case EVENT_CONST_TYPES.TRIGEMINY_APC:
    case EVENT_CONST_TYPES.QUADRIGEMINY_APC:
      return EVENT_CONST_TYPES.SVE;

    case EVENT_CONST_TYPES.ISO_VPC:
    case EVENT_CONST_TYPES.COUPLET_VPC:
    case EVENT_CONST_TYPES.BIGEMINY_VPC:
    case EVENT_CONST_TYPES.TRIGEMINY_VPC:
    case EVENT_CONST_TYPES.QUADRIGEMINY_VPC:
      return EVENT_CONST_TYPES.VE;

    case EVENT_CONST_TYPES.AVB_2:
    case EVENT_CONST_TYPES.AVB_3:
      return EVENT_CONST_TYPES.AV_BLOCK;

    default:
      return eventType;
  }
};
export const getSidePanelStateKey = (tabValue) => {
  if (tabValue === EVENT_GROUP_TYPE.EVENTS) return 'eventInfo';
  if (tabValue === EVENT_GROUP_TYPE.REPORT) return 'reportInfo';
  if (tabValue === EVENT_GROUP_TYPE.BEATS) return 'beatInfo';
};

export const isDetailReportSection = (reportSection) => {
  return (
    reportSection &&
    ![
      REPORT_SECTION.SUMMARY,
      REPORT_SECTION.ADDITIONAL,
      REPORT_SECTION.PTE,
    ].includes(reportSection)
  );
};

export const getReportPage = (reportSection) => {
  if (reportSection === null) return null;
  if (isDetailReportSection(reportSection)) {
    return REPORT_TYPE.DETAIL;
  } else return reportSection;
};

export const getContextMenuBeatTypeList = () =>
  Object.values(CONST_CONTEXT_MENU.BEAT_TYPE)
    .filter((value) => typeof value === 'object')
    .map((obj) => ({
      ...obj,
      label: `${obj.label} beat`,
    }))
    .sort((a, b) => a.index - b.index);

export const getContextMenuEpisodeTypeList = () => {
  return Object.values(CONST_CONTEXT_MENU.EPISODE_TYPE)
    .filter((value) => typeof value === 'object')
    .sort((a, b) => a.index - b.index);
};
export const getContextMenuEpisodeTypeListWithSubMenu = () => {
  const result = [];

  Object.values(CONST_CONTEXT_MENU.EPISODE_TYPE)
    .filter((value) => typeof value === 'object')
    .forEach((episode) => {
      const target = episode.subMenu ?? [episode];
      target.forEach((item) => {
        result.push(item);
      });
    });

  return result;
};

const getContextMenuEpisodeTypeMap = (key = 'value') => {
  const result = {};

  Object.values(CONST_CONTEXT_MENU.EPISODE_TYPE)
    .filter((eventObj) => typeof eventObj === 'object')
    .forEach((episode) => {
      result[episode[key]] = episode;
    });

  return result;
};

export const getMarkAsNotList = (eventList = []) => {
  if (!Array.isArray(eventList)) {
    throw new Error('check getMarkAsNotList arguments type');
  }

  const episodeMap = getContextMenuEpisodeTypeMap('value');

  const result = eventList.map((event) => episodeMap[event]).filter(Boolean);

  const uniqueResult = [
    ...new Map(result.map((item) => [item.value, item])).values(),
  ];

  return uniqueResult
    .map((v) => ({
      ...v,
      markAsNotLabel: `Mark as not ${v.label}`,
    }))
    .sort((a, b) => a.index - b.index);
};

export const isBeatType = (type) => {
  return Object.values(BEAT_TYPE).includes(type);
};

export const isTypeEventType = (type) => {
  return Object.values(TIME_EVENT_TYPE).includes(type);
};

/**
 * 리포트에 담긴 이벤트가 요구 조건에 충족 했는지 결과 반환
 *
 * @param {*} ecgStatisticsData
 * @param {*} reportStatisticsData
 * @param {*} afInfoList

 * @returns {"ADDITIONAL": Boolean,"AF": Boolean,"AV_BLOCK": Boolean,"HR": Boolean,"PAUSE": Boolean,"RR": false,"SUMMARY": Boolean,"SVE": Boolean,"SVT": Boolean,"VE": Boolean,"VT": Boolean}
 */
export const getReportSectionValidationMap = (
  ecgStatisticsData,
  reportStatisticsData,
  afInfoList
) => {
  const RR_REPORT_MAX = 2;
  const HR_REPORT_MAX = 3;

  const ecgStatisticsMap = ecgStatisticsData ?? {};
  const reportStatisticsMap = reportStatisticsData ?? {};
  const validatedAfNumber = (afInfoList ?? []).filter(
    (value) => value.durationMs >= CONST_VALIDATED_AF_MIN_DURATION
  ).length;

  let reportSectionValidationResult = {
    /* HR */
    [CONST_REPORT_SECTION.HR]:
      reportStatisticsMap[CONST_REPORT_SECTION.HR] === HR_REPORT_MAX,
    /* RR */
    [CONST_REPORT_SECTION.RR]:
      reportStatisticsMap[CONST_REPORT_SECTION.RR] === RR_REPORT_MAX,
    /* SUMMARY */
    [CONST_REPORT_SECTION.SUMMARY]:
      !!reportStatisticsMap[CONST_REPORT_SECTION.SUMMARY],
    /* ADDITIONAL */
    [CONST_REPORT_SECTION.ADDITIONAL]: true,
  };

  /* PTE */
  if (ecgStatisticsMap[CONST_EVENT_SECTION.PATIENT]) {
    // 히스토리는 MC-5093티켓 참고
    reportSectionValidationResult[CONST_REPORT_SECTION.PTE] = true;
  }

  /* AF */
  if (validatedAfNumber) {
    reportSectionValidationResult[CONST_REPORT_SECTION.AF] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.AF];
  }

  /* PAUSE */
  if (ecgStatisticsMap[CONST_EVENT_SECTION.PAUSE]) {
    reportSectionValidationResult[CONST_REPORT_SECTION.PAUSE] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.PAUSE];
  }

  /* AV block */
  if (
    ecgStatisticsMap[CONST_EVENT_SECTION.AVB_2] ||
    ecgStatisticsMap[CONST_EVENT_SECTION.AVB_3]
  ) {
    reportSectionValidationResult[CONST_REPORT_SECTION.AV_BLOCK] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.AV_BLOCK];
  }

  /* VT */
  if (ecgStatisticsMap[CONST_EVENT_SECTION.VT]) {
    reportSectionValidationResult[CONST_REPORT_SECTION.VT] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.VT];
  }

  /* VE */
  if (
    ecgStatisticsMap[CONST_EVENT_SECTION.ISO_VPC] ||
    ecgStatisticsMap[CONST_EVENT_SECTION.COUPLET_VPC]
  ) {
    reportSectionValidationResult[CONST_REPORT_SECTION.VE] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.VE];
  }

  /* SVT */
  if (ecgStatisticsMap[CONST_EVENT_SECTION.SVT]) {
    reportSectionValidationResult[CONST_REPORT_SECTION.SVT] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.SVT];
  }

  /* SVE */
  if (
    ecgStatisticsMap[CONST_EVENT_SECTION.ISO_APC] ||
    ecgStatisticsMap[CONST_EVENT_SECTION.COUPLET_APC]
  ) {
    reportSectionValidationResult[CONST_REPORT_SECTION.SVE] =
      !!reportStatisticsMap[CONST_REPORT_SECTION.SVE];
  }

  return reportSectionValidationResult;
};

export const getDefaultReportEventAnnotation = (reportSection, eventType) =>
  (CONST_REPORT_EVENT_ANNOTATION_DEFAULT[reportSection] ?? {})[eventType] ?? '';

const UNIT_WAVEFORMINDEX_TO_MS = 4;
export const convertWaveformIndexToMs = (waveformIndex) => {
  return waveformIndex * UNIT_WAVEFORMINDEX_TO_MS;
};
