import { EventSection } from '@type/ecgEventType/baseEventType';
import AppColors from 'theme/AppColors';

/**
 * @type {BeatType}
 */
export const BEAT_TYPE = {
  NORMAL: 0,
  APC: 1,
  VPC: 2,
  NOISE: 3,
};

export const TIME_EVENT_TYPE = {
  AF: 'AF',
  PAUSE: 'PAUSE',
  OTHERS: 'OTHERS',
  LEAD_OFF: 'LEAD_OFF', // (편집 제한 등을 위해) lead off 구간을 어떻게 관리할지에 대해 추가 논의가 필요하지만 우선은 이곳에 넣어둠
  AV_BLOCK: 'AV_BLOCK', // event review에서 AVB_2, AVB_3을 제거 할때 mark as not에서 사용하기 위함
  AVB_2: 'AVB-GROUP-2', // 2nd AVB
  AVB_3: 'AVB-3', // 3rd AVB

  // todo: jyoon(240521) - [refactor] 아래 구조로 변경 필요
  // AF: {
  //   TYPE: 'AF',
  //   SUB_TYPES: {},
  // },
  // PAUSE: {
  //   TYPE: 'PAUSE',
  //   SUB_TYPES: {},
  // },
  // OTHERS: {
  //   TYPE: 'OTHERS',
  //   SUB_TYPES: {},
  // },
  // LEAD_OFF: {
  //   TYPE: 'LEAD_OFF',
  //   SUB_TYPES: {},
  // },
  // AV_BLOCK: {
  //   TYPE: 'AV_BLOCK', // event review에서 AVB_2, AVB_3을 제거 할때 mark as not에서 사용하기 위함
  //   SUB_TYPES: {
  //     AVB_2: 'AVB-GROUP-2', // 2nd AVB
  //     AVB_3: 'AVB-3', // 3rd AVB
  //   },
  // },
};

export const AV_BLOCK_LIST = [
  TIME_EVENT_TYPE.AVB_2, // 2nd AVB
  TIME_EVENT_TYPE.AVB_3, // 3rd AVB
];
// todo: jyoon(240522) - [refactor] TIME_EVENT_TYPE refactoring 이후 아래와 같이 변경 필요
// export const AV_BLOCK_LIST = Object.values(TIME_EVENT_TYPE.AV_BLOCK1.SUB_TYPES);

/**
 * @type {EctopicType}
 */
export const ECTOPIC_TYPE = {
  ISOLATE: 'ISOLATE',
  COUPLET: 'COUPLET',
  RUN: 'RUN', // 3개 이상의 연속된 apc or vpc. couplet과 run 사이에 추가 클래스가 생길 수도 있어 10으로.
};

/**
 * @type {GeminyType}
 */
export const GEMINY_TYPE = {
  BIGEMINY: 2,
  TRIGEMINY: 3,
  QUADRIGEMINY: 4,
};

export const REPORT_TYPE = {
  SUMMARY: 'SUMMARY',
  DETAIL: 'DETAIL',
  ADDITIONAL: 'ADDITIONAL',
};

// @deprecate baseEventType.ts > EventSectionEnum으로 대체
// /**
//  * @type {EventSection}
//  * @description 사이드패널의 이벤트 리스트를 정의하거나, qty등을 불러올떄 사용
//  */
// export const EVENT_SECTION = {
//   AF: TIME_EVENT_TYPE.AF,
//   PAUSE: TIME_EVENT_TYPE.PAUSE,

//   AVB_2: TIME_EVENT_TYPE.AVB_2, // 2nd AVB
//   AVB_3: TIME_EVENT_TYPE.AVB_3, // 3rd AVB

//   VT: 'VT',
//   ISO_VPC: 'IsoVPC',
//   COUPLET_VPC: 'CoupletVPC',
//   BIGEMINY_VPC: 'BigeminyVPC',
//   TRIGEMINY_VPC: 'TrigeminyVPC',
//   QUADRIGEMINY_VPC: 'QuadrigeminyVPC',

//   SVT: 'SVT',
//   ISO_APC: 'IsoAPC',
//   COUPLET_APC: 'CoupletAPC',
//   BIGEMINY_APC: 'BigeminyAPC',
//   TRIGEMINY_APC: 'TrigeminyAPC',
//   QUADRIGEMINY_APC: 'QuadrigeminyAPC',

//   OTHERS: TIME_EVENT_TYPE.OTHERS,
//   PATIENT: 'PTE',
//   PATIENT_FOUND: 'PTENotFound',
//   PATIENT_NOT_FOUND: 'PTEFound',
// };

export const REPORT_SECTION = {
  SUMMARY: 'SUMMARY',
  AF: 'AF',
  PAUSE: 'PAUSE',
  AV_BLOCK: 'AV_BLOCK',
  VT: 'VT',
  VE: 'VE',
  SVT: 'SVT',
  SVE: 'SVE',
  PTE: 'PTE',
  ADDITIONAL: 'ADDITIONAL',
  HR: 'HR',
  RR: 'RR',
};

export const REPORT_SECTION_TITLE = {
  [REPORT_SECTION.SUMMARY]: 'Notable Rythm Strips',
  [REPORT_SECTION.AF]: 'A.fib Detail Page',
  [REPORT_SECTION.PAUSE]: 'Pause Detail Page',
  [REPORT_SECTION.AV_BLOCK]: 'AV block Detail Page',
  [REPORT_SECTION.VT]: 'VT Detail Page',
  [REPORT_SECTION.VE]: 'VPC Detail Page',
  [REPORT_SECTION.SVT]: 'SVT Detail Page',
  [REPORT_SECTION.SVE]: 'APC Detail Page',
  [REPORT_SECTION.PTE]: 'Patient Events',
  [REPORT_SECTION.ADDITIONAL]: 'Additional Strips',
  [REPORT_SECTION.HR]: 'Heart Rate Page',
  [REPORT_SECTION.RR]: 'R-R Interval Page',
};

export const REPORT_SECTION_TITLE_SHORT = {
  [REPORT_SECTION.SUMMARY]: 'Notable Rythm Strips',
  [REPORT_SECTION.AF]: 'A.fib',
  [REPORT_SECTION.PAUSE]: 'Pause',
  [REPORT_SECTION.AV_BLOCK]: 'AV block',
  [REPORT_SECTION.VT]: 'VT',
  [REPORT_SECTION.VE]: 'VPC',
  [REPORT_SECTION.SVT]: 'SVT',
  [REPORT_SECTION.SVE]: 'APC',
  [REPORT_SECTION.PTE]: 'Patient Events',
  [REPORT_SECTION.ADDITIONAL]: 'Additional Strips',
  [REPORT_SECTION.HR]: 'HR',
  [REPORT_SECTION.RR]: 'R-R',
};

/**
 * @type {EventConstTypes}
 */
// todo: jyoon(240522) - [refactor] EVENT_CONST_TYPES 객체 형식으로 묶어서 파악할 필요 있음.
// todo: jyoon(240522) - [refactor] time-event, beat-event, report-event, patient-event, etc. 구문이 안됨
export const EVENT_CONST_TYPES = {
  // time-event
  AF: `EVENT-TYPE-${TIME_EVENT_TYPE.AF}`,
  PAUSE: `EVENT-TYPE-${TIME_EVENT_TYPE.PAUSE}`,
  AV_BLOCK: `EVENT-TYPE-${TIME_EVENT_TYPE.AV_BLOCK}`, // AVB
  AVB_2: `EVENT-TYPE-${TIME_EVENT_TYPE.AVB_2}`, // 2nd AVB
  AVB_3: `EVENT-TYPE-${TIME_EVENT_TYPE.AVB_3}`, // 3rd AVB
  OTHERS: `EVENT-TYPE-${TIME_EVENT_TYPE.OTHERS}`,
  //
  ISO_VPC: `EVENT-TYPE-${ECTOPIC_TYPE.ISOLATE}-${BEAT_TYPE.VPC}`,
  COUPLET_VPC: `EVENT-TYPE-${ECTOPIC_TYPE.COUPLET}-${BEAT_TYPE.VPC}`,
  BIGEMINY_VPC: `EVENT-TYPE-${GEMINY_TYPE.BIGEMINY}-${BEAT_TYPE.VPC}`,
  TRIGEMINY_VPC: `EVENT-TYPE-${GEMINY_TYPE.TRIGEMINY}-${BEAT_TYPE.VPC}`,
  QUADRIGEMINY_VPC: `EVENT-TYPE-${GEMINY_TYPE.QUADRIGEMINY}-${BEAT_TYPE.VPC}`,
  VE: `EVENT-TYPE-X-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.VPC}`,
  VT: `EVENT-TYPE-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.VPC}`,
  ISO_APC: `EVENT-TYPE-${ECTOPIC_TYPE.ISOLATE}-${BEAT_TYPE.APC}`,
  COUPLET_APC: `EVENT-TYPE-${ECTOPIC_TYPE.COUPLET}-${BEAT_TYPE.APC}`,
  BIGEMINY_APC: `EVENT-TYPE-${GEMINY_TYPE.BIGEMINY}-${BEAT_TYPE.APC}`,
  TRIGEMINY_APC: `EVENT-TYPE-${GEMINY_TYPE.TRIGEMINY}-${BEAT_TYPE.APC}`,
  QUADRIGEMINY_APC: `EVENT-TYPE-${GEMINY_TYPE.QUADRIGEMINY}-${BEAT_TYPE.APC}`,
  SVE: `EVENT-TYPE-X-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.APC}`, // RUN이 아닌 경우
  SVT: `EVENT-TYPE-${ECTOPIC_TYPE.RUN}-${BEAT_TYPE.APC}`,
  //
  PATIENT: 'EVENT-TYPE-PATIENT',
  PATIENT_FOUND: 'EVENT-TYPE-PATIENT-FOUND',
  PATIENT_NOT_FOUND: 'EVENT-TYPE-PATIENT-NOT-FOUND',
  //
  NOTABLE: `EVENT-TYPE-${REPORT_TYPE.SUMMARY}`,
  ADDITIONAL: `EVENT-TYPE-${REPORT_TYPE.ADDITIONAL}`,
  //
  NORMAL: `EVENT-TYPE-${BEAT_TYPE.NORMAL}`,
  NOISE: `EVENT-TYPE-${BEAT_TYPE.NOISE}`,
  LEAD_OFF: `EVENT-TYPE-${TIME_EVENT_TYPE.LEAD_OFF}`,
};

export const PATIENT_EVENT_TYPE_LIST = [
  EVENT_CONST_TYPES.PATIENT,
  EVENT_CONST_TYPES.PATIENT_FOUND,
  EVENT_CONST_TYPES.PATIENT_NOT_FOUND,
];
export const EVENT_CONST_TYPES_VALUE_LIST = Object.values(EVENT_CONST_TYPES);

/**
 * @constant {Object} EVENT_GROUP_TYPE
 * @description 이벤트 그룹 타입을 정의하는 상수 객체
 * @property {string} EVENTS - 이벤트 그룹의 이벤트 타입
 * @property {string} REPORT - 이벤트 그룹의 리포트 타입
 * @property {string} BEATS - 이벤트 그룹의 비트 타입
 */
export const EVENT_GROUP_TYPE = {
  EVENTS: 'EVENTS',
  REPORT: 'REPORT',
  BEATS: 'BEATS',
  SHAPE: 'SHAPE',
};

export const EVENT_GROUP_STRIP_MAX_COUNT = {
  NOTABLE: 10,
  PATIENT: 200,
  AF: 20,
  PAUSE: 20,
  AV_BLOCK: 20,
  VT: 20,
  VE: 20,
  SVT: 20,
  SVE: 20,
  ADDITIONAL: 50,
};

/**
 * @description 사이드 패널에서 사용하는 상수 객체
 * @description EVENT_GROUP_TYPE 객체로 타입이 나눠지며 23.04.26기준 event review, beat review tab에서 사용되며 "event review tab"에서 EVENT_GROUP_TYPE.EVENTS, EVENT_GROUP_TYPE.REPORT를 "beat review tab"에서는 EVENT_GROUP_TYPE.BEATS를 사용하고 있습니다.
 */
export const SIDE_PANEL_EVENT_GROUP = {
  [EVENT_GROUP_TYPE.EVENTS]: [
    {
      type: EVENT_CONST_TYPES.AF,
      label: 'A.fib',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AF,
      eventSection: EventSection.AF,
    },
    {
      type: EVENT_CONST_TYPES.PAUSE,
      label: 'Pause',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.PAUSE,
      eventSection: EventSection.PAUSE,
    },
    {
      type: EVENT_CONST_TYPES.AVB_2,
      label: '2nd AV block',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AVB_2,
      eventSection: EventSection.AVB_2,
    },
    {
      type: EVENT_CONST_TYPES.AVB_3,
      label: '3rd AV block',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.AVB_3,
      eventSection: EventSection.AVB_3,
    },
    {
      type: EVENT_CONST_TYPES.VT,
      label: 'VT',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.RUN,
      timeEventType: null,
      eventSection: EventSection.VT,
    },
    {
      type: EVENT_CONST_TYPES.ISO_VPC,
      label: 'Iso VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EventSection.ISO_VPC,
      subTypeList: [
        {
          type: EVENT_CONST_TYPES.BIGEMINY_VPC,
          label: 'VPC Bigeminy',
          beatType: BEAT_TYPE.VPC,
          ectopicType: null,
          timeEventType: null,
          geminyType: GEMINY_TYPE.BIGEMINY,
          eventSection: EventSection.BIGEMINY_VPC,
        },
        {
          type: EVENT_CONST_TYPES.TRIGEMINY_VPC,
          label: 'VPC Trigeminy',
          beatType: BEAT_TYPE.VPC,
          ectopicType: null,
          timeEventType: null,
          geminyType: GEMINY_TYPE.TRIGEMINY,
          eventSection: EventSection.TRIGEMINY_VPC,
        },
        {
          type: EVENT_CONST_TYPES.QUADRIGEMINY_VPC,
          label: 'VPC Quadrigeminy',
          beatType: BEAT_TYPE.VPC,
          ectopicType: null,
          timeEventType: null,
          geminyType: GEMINY_TYPE.QUADRIGEMINY,
          eventSection: EventSection.QUADRIGEMINY_VPC,
        },
      ],
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_VPC,
      label: 'Couplet VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EventSection.COUPLET_VPC,
    },
    {
      type: EVENT_CONST_TYPES.SVT,
      label: 'SVT',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.RUN,
      timeEventType: null,
      eventSection: EventSection.SVT,
    },
    {
      type: EVENT_CONST_TYPES.ISO_APC,
      label: 'Iso APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EventSection.ISO_APC,
      subTypeList: [
        {
          type: EVENT_CONST_TYPES.BIGEMINY_APC,
          label: 'APC Bigeminy',
          beatType: BEAT_TYPE.APC,
          ectopicType: null,
          timeEventType: null,
          geminyType: GEMINY_TYPE.BIGEMINY,
          eventSection: EventSection.BIGEMINY_APC,
        },
        {
          type: EVENT_CONST_TYPES.TRIGEMINY_APC,
          label: 'APC Trigeminy',
          beatType: BEAT_TYPE.APC,
          ectopicType: null,
          timeEventType: null,
          geminyType: GEMINY_TYPE.TRIGEMINY,
          eventSection: EventSection.TRIGEMINY_APC,
        },
        {
          type: EVENT_CONST_TYPES.QUADRIGEMINY_APC,
          label: 'APC Quadrigeminy',
          beatType: BEAT_TYPE.APC,
          ectopicType: null,
          timeEventType: null,
          geminyType: GEMINY_TYPE.QUADRIGEMINY,
          eventSection: EventSection.QUADRIGEMINY_APC,
        },
      ],
    },

    {
      type: EVENT_CONST_TYPES.COUPLET_APC,
      label: 'Couplet APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EventSection.COUPLET_APC,
    },
    {
      type: EVENT_CONST_TYPES.OTHERS,
      label: 'Others',
      beatType: null,
      ectopicType: null,
      timeEventType: TIME_EVENT_TYPE.OTHERS,
      eventSection: EventSection.OTHERS,
    },
    {
      // testResultReducer.patientEvents 에서 조회
      type: EVENT_CONST_TYPES.PATIENT,
      label: 'Patient Events',
      beatType: null,
      ectopicType: null,
      timeEventType: null,
      eventSection: EventSection.PATIENT,
      subTypeList: [
        {
          type: EVENT_CONST_TYPES.PATIENT_FOUND,
          label: 'Found',
          beatType: null,
          ectopicType: null,
          timeEventType: null,
          eventSection: EventSection.PATIENT_FOUND,
        },
        {
          type: EVENT_CONST_TYPES.PATIENT_NOT_FOUND,
          label: 'Not Found',
          beatType: null,
          ectopicType: null,
          timeEventType: null,
          eventSection: EventSection.PATIENT_NOT_FOUND,
        },
      ],
    },
  ],
  [EVENT_GROUP_TYPE.REPORT]: [
    {
      type: EVENT_CONST_TYPES.NOTABLE,
      label: 'Notable Rhythm Strips',
      max: EVENT_GROUP_STRIP_MAX_COUNT.NOTABLE,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.SUMMARY,
      eventType: null,
      ectopicType: null,
    },
    {
      // testResultReducer.patientEvents 에서 조회
      type: EVENT_CONST_TYPES.PATIENT,
      label: 'Patient Events',
      max: EVENT_GROUP_STRIP_MAX_COUNT.PATIENT,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.PTE,
      eventType: null,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.AF,
      label: 'A.fib',
      max: EVENT_GROUP_STRIP_MAX_COUNT.AF,
      sortBy: '(Sort by Duration)',
      reportSection: REPORT_SECTION.AF,
      eventType: TIME_EVENT_TYPE.AF,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.PAUSE,
      label: 'Pause',
      max: EVENT_GROUP_STRIP_MAX_COUNT.PAUSE,
      sortBy: '(Sort by Duration)',
      reportSection: REPORT_SECTION.PAUSE,
      eventType: TIME_EVENT_TYPE.PAUSE,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.AV_BLOCK,
      label: 'AV block',
      max: EVENT_GROUP_STRIP_MAX_COUNT.PAUSE,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.AV_BLOCK,
      eventType: TIME_EVENT_TYPE.AV_BLOCK,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.VT,
      label: 'VT',
      max: EVENT_GROUP_STRIP_MAX_COUNT.VT,
      sortBy: '(Sort by Beats)',
      reportSection: REPORT_SECTION.VT,
      eventType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.RUN,
    },
    {
      type: EVENT_CONST_TYPES.VE,
      label: 'VPC',
      max: EVENT_GROUP_STRIP_MAX_COUNT.VE,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.VE,
      eventType: BEAT_TYPE.VPC,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.SVT,
      label: 'SVT',
      max: EVENT_GROUP_STRIP_MAX_COUNT.SVT,
      sortBy: '(Sort by Beats)',
      reportSection: REPORT_SECTION.SVT,
      eventType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.RUN,
    },
    {
      type: EVENT_CONST_TYPES.SVE,
      label: 'APC',
      max: EVENT_GROUP_STRIP_MAX_COUNT.SVE,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.SVE,
      eventType: BEAT_TYPE.APC,
      ectopicType: null,
    },
    {
      type: EVENT_CONST_TYPES.ADDITIONAL,
      label: 'Additional Strips',
      max: EVENT_GROUP_STRIP_MAX_COUNT.ADDITIONAL,
      sortBy: '(Sort by Time)',
      reportSection: REPORT_SECTION.ADDITIONAL,
      eventType: null,
      ectopicType: null,
    },
  ],
  [EVENT_GROUP_TYPE.BEATS]: [
    {
      type: EVENT_CONST_TYPES.ISO_VPC,
      label: 'Iso VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EventSection.ISO_VPC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_VPC,
      label: 'Couplet VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EventSection.COUPLET_VPC,
    },
    {
      type: EVENT_CONST_TYPES.ISO_APC,
      label: 'Iso APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      timeEventType: null,
      eventSection: EventSection.ISO_APC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_APC,
      label: 'Couplet APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      timeEventType: null,
      eventSection: EventSection.COUPLET_APC,
    },
  ],
  [EVENT_GROUP_TYPE.SHAPE]: [
    {
      type: EVENT_CONST_TYPES.ISO_VPC,
      label: 'Iso VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      eventSection: EventSection.ISO_VPC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_VPC,
      label: 'Couplet VPC',
      beatType: BEAT_TYPE.VPC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      eventSection: EventSection.COUPLET_VPC,
    },
    {
      type: EVENT_CONST_TYPES.ISO_APC,
      label: 'Iso APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.ISOLATE,
      eventSection: EventSection.ISO_APC,
    },
    {
      type: EVENT_CONST_TYPES.COUPLET_APC,
      label: 'Couplet APC',
      beatType: BEAT_TYPE.APC,
      ectopicType: ECTOPIC_TYPE.COUPLET,
      eventSection: EventSection.COUPLET_APC,
    },
  ],
};

export const REPORT_EVENT_ANNOTATION_DEFAULT = {
  [REPORT_SECTION.SUMMARY]: {
    [EVENT_CONST_TYPES.AF]: 'A.fib',
    [EVENT_CONST_TYPES.PAUSE]: 'Pause',
    [EVENT_CONST_TYPES.VT]: 'VT',
    [EVENT_CONST_TYPES.ISO_VPC]: 'VPC (isolated)',
    [EVENT_CONST_TYPES.COUPLET_VPC]: 'VPC (couplet)',
    [EVENT_CONST_TYPES.BIGEMINY_VPC]: 'VPC (bigeminy)',
    [EVENT_CONST_TYPES.TRIGEMINY_VPC]: 'VPC (trigeminy)',
    [EVENT_CONST_TYPES.QUADRIGEMINY_VPC]: 'VPC (quadrigeminy)',
    [EVENT_CONST_TYPES.SVT]: 'SVT',
    [EVENT_CONST_TYPES.ISO_APC]: 'APC (isolated)',
    [EVENT_CONST_TYPES.COUPLET_APC]: 'APC (couplet)',
    [EVENT_CONST_TYPES.BIGEMINY_APC]: 'APC (bigeminy)',
    [EVENT_CONST_TYPES.TRIGEMINY_APC]: 'APC (trigeminy)',
    [EVENT_CONST_TYPES.QUADRIGEMINY_APC]: 'APC (quadrigeminy)',
    [EVENT_CONST_TYPES.OTHERS]: 'Others',
    [EVENT_CONST_TYPES.AVB_2]: 'AV block',
    [EVENT_CONST_TYPES.AVB_3]: 'AV block',
  },
  [REPORT_SECTION.AF]: { [EVENT_CONST_TYPES.AF]: 'Atrial fibrillation' },
  [REPORT_SECTION.PAUSE]: { [EVENT_CONST_TYPES.PAUSE]: 'Pause' },
  [REPORT_SECTION.AV_BLOCK]: {
    [EVENT_CONST_TYPES.AVB_2]: '2nd AV block',
    [EVENT_CONST_TYPES.AVB_3]: '3rd AV block',
  },
  [REPORT_SECTION.VT]: { [EVENT_CONST_TYPES.VT]: 'Nonsustained VT' },
  [REPORT_SECTION.VE]: {
    [EVENT_CONST_TYPES.ISO_VPC]: 'VPC (isolated)',
    [EVENT_CONST_TYPES.COUPLET_VPC]: 'VPC (couplet)',
    [EVENT_CONST_TYPES.BIGEMINY_VPC]: 'VPC (bigeminy)',
    [EVENT_CONST_TYPES.TRIGEMINY_VPC]: 'VPC (trigeminy)',
    [EVENT_CONST_TYPES.QUADRIGEMINY_VPC]: 'VPC (quadrigeminy)',
  },
  [REPORT_SECTION.SVT]: { [EVENT_CONST_TYPES.SVT]: 'Nonsustained SVT' },
  [REPORT_SECTION.SVE]: {
    [EVENT_CONST_TYPES.ISO_APC]: 'APC (isolated)',
    [EVENT_CONST_TYPES.COUPLET_APC]: 'APC (couplet)',
    [EVENT_CONST_TYPES.BIGEMINY_APC]: 'APC (bigeminy)',
    [EVENT_CONST_TYPES.TRIGEMINY_APC]: 'APC (trigeminy)',
    [EVENT_CONST_TYPES.QUADRIGEMINY_APC]: 'APC (quadrigeminy)',
  },
  [REPORT_SECTION.ADDITIONAL]: {
    [EVENT_CONST_TYPES.AF]: '',
    [EVENT_CONST_TYPES.PAUSE]: '',
    [EVENT_CONST_TYPES.VT]: '',
    [EVENT_CONST_TYPES.ISO_VPC]: '',
    [EVENT_CONST_TYPES.COUPLET_VPC]: '',
    [EVENT_CONST_TYPES.SVT]: '',
    [EVENT_CONST_TYPES.ISO_APC]: '',
    [EVENT_CONST_TYPES.COUPLET_APC]: '',
    [EVENT_CONST_TYPES.OTHERS]: '',
  },
};

/*
 *  차트에 그려지는 이벤트타입의 디자인을 결정하는 Const
 *  (render 시 필요한 색상 정보와 언더라인 적용 시 사용할 offset 값 포함)
 *  검색어 : Offset, chart, chartColor, chartConst
 */
export const EVENT_INFO = [
  // type: EVENT_CONST_TYPES.ISO_APC
  {
    type: EVENT_CONST_TYPES.ISO_APC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.APC,
    ectopicType: ECTOPIC_TYPE.ISOLATE,
    geminyType: null,
    eventSection: EventSection.ISO_APC,
    renderAttrs: {
      color: AppColors.SVE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_APC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.COUPLET_APC
  {
    type: EVENT_CONST_TYPES.COUPLET_APC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.APC,
    ectopicType: ECTOPIC_TYPE.COUPLET,
    geminyType: null,
    eventSection: EventSection.COUPLET_APC,
    renderAttrs: {
      color: AppColors.SVE_70,
      className: `huinno-${EVENT_CONST_TYPES.COUPLET_APC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.BIGEMINY_APC
  {
    type: EVENT_CONST_TYPES.BIGEMINY_APC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.APC,
    ectopicType: null,
    geminyType: GEMINY_TYPE.BIGEMINY,
    eventSection: EventSection.BIGEMINY_APC,
    renderAttrs: {
      color: AppColors.SVE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_APC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.TRIGEMINY_APC
  {
    type: EVENT_CONST_TYPES.TRIGEMINY_APC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.APC,
    ectopicType: null,
    geminyType: GEMINY_TYPE.TRIGEMINY,
    eventSection: EventSection.TRIGEMINY_APC,
    renderAttrs: {
      color: AppColors.SVE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_APC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.QUADRIGEMINY_APC
  {
    type: EVENT_CONST_TYPES.QUADRIGEMINY_APC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.APC,
    ectopicType: null,
    geminyType: GEMINY_TYPE.QUADRIGEMINY,
    eventSection: EventSection.QUADRIGEMINY_APC,
    renderAttrs: {
      color: AppColors.SVE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_APC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.SVT,
  {
    type: EVENT_CONST_TYPES.SVT,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.APC,
    ectopicType: ECTOPIC_TYPE.RUN,
    geminyType: null,
    eventSection: EventSection.SVT,
    renderAttrs: {
      color: AppColors.SVE_70,
      offset: -0.5,
      offsetAlter: 1.5,
      className: `huinno-${EVENT_CONST_TYPES.SVT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.ISO_VPC
  {
    type: EVENT_CONST_TYPES.ISO_VPC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.VPC,
    ectopicType: ECTOPIC_TYPE.ISOLATE,
    geminyType: null,
    eventSection: EventSection.ISO_VPC,
    renderAttrs: {
      color: AppColors.VE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_VPC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.COUPLET_VPC
  {
    type: EVENT_CONST_TYPES.COUPLET_VPC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.VPC,
    ectopicType: ECTOPIC_TYPE.COUPLET,
    geminyType: null,
    eventSection: EventSection.COUPLET_VPC,
    renderAttrs: {
      color: AppColors.VE_70,
      className: `huinno-${EVENT_CONST_TYPES.COUPLET_VPC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.BIGEMINY_VPC
  {
    type: EVENT_CONST_TYPES.BIGEMINY_VPC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.VPC,
    ectopicType: null,
    geminyType: GEMINY_TYPE.BIGEMINY,
    eventSection: EventSection.BIGEMINY_VPC,
    renderAttrs: {
      color: AppColors.VE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_VPC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.TRIGEMINY_VPC
  {
    type: EVENT_CONST_TYPES.TRIGEMINY_VPC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.VPC,
    ectopicType: null,
    geminyType: GEMINY_TYPE.TRIGEMINY,
    eventSection: EventSection.TRIGEMINY_VPC,
    renderAttrs: {
      color: AppColors.VE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_VPC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.QUADRIGEMINY_VPC
  {
    type: EVENT_CONST_TYPES.QUADRIGEMINY_VPC,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.VPC,
    ectopicType: null,
    geminyType: GEMINY_TYPE.QUADRIGEMINY,
    eventSection: EventSection.QUADRIGEMINY_VPC,
    renderAttrs: {
      color: AppColors.VE_70,
      className: `huinno-${EVENT_CONST_TYPES.ISO_VPC}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.VT
  {
    type: EVENT_CONST_TYPES.VT,
    timeEventType: null,
    timeEventGroup: null,
    beatType: BEAT_TYPE.VPC,
    ectopicType: ECTOPIC_TYPE.RUN,
    geminyType: null,
    eventSection: EventSection.VT,
    renderAttrs: {
      color: AppColors.VE_70,
      offset: -0.5,
      offsetAlter: 1.5,
      className: `huinno-${EVENT_CONST_TYPES.VT}`,
      zIndex: 4,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.AF
  {
    type: EVENT_CONST_TYPES.AF,
    timeEventType: TIME_EVENT_TYPE.AF,
    timeEventGroup: null,
    beatType: null,
    ectopicType: null,
    geminyType: null,
    eventSection: EventSection.AF,
    renderAttrs: {
      color: AppColors.AF_70,
      offset: 3,
      offsetAlter: 4.5,
      className: `huinno-${EVENT_CONST_TYPES.AF}`,
      zIndex: 3,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.OTHERS
  {
    type: EVENT_CONST_TYPES.OTHERS,
    timeEventType: TIME_EVENT_TYPE.OTHERS,
    timeEventGroup: null,
    beatType: null,
    ectopicType: null,
    geminyType: null,
    eventSection: EventSection.OTHERS,
    renderAttrs: {
      color: AppColors.OTHERS_70,
      offset: -6.5,
      offsetAlter: -9,
      className: `huinno-${EVENT_CONST_TYPES.OTHERS}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.AVB_2
  {
    type: EVENT_CONST_TYPES.AVB_2,
    timeEventType: TIME_EVENT_TYPE.AVB_2,
    timeEventGroup: TIME_EVENT_TYPE.AV_BLOCK,
    beatType: null,
    ectopicType: null,
    geminyType: null,
    eventSection: EventSection.AVB_2,
    renderAttrs: {
      color: AppColors.AVB_70,
      offset: -3.5,
      offsetAlter: -6,
      className: `huinno-${EVENT_CONST_TYPES.AVB_2}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.AVB_3
  {
    type: EVENT_CONST_TYPES.AVB_3,
    timeEventType: TIME_EVENT_TYPE.AVB_3,
    timeEventGroup: TIME_EVENT_TYPE.AV_BLOCK,
    beatType: null,
    ectopicType: null,
    geminyType: null,
    eventSection: EventSection.AVB_3,
    renderAttrs: {
      color: AppColors.AVB_70,
      offset: -3.5,
      offsetAlter: -6,
      className: `huinno-${EVENT_CONST_TYPES.AVB_3}`,
      zIndex: 2,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.PAUSE
  {
    type: EVENT_CONST_TYPES.PAUSE,
    timeEventType: TIME_EVENT_TYPE.PAUSE,
    timeEventGroup: null,
    beatType: null,
    ectopicType: null,
    geminyType: null,
    eventSection: EventSection.PAUSE,
    renderAttrs: {
      color: AppColors.PAUSE_70,
      offset: 6,
      offsetAlter: 7.5,
      className: `huinno-${EVENT_CONST_TYPES.PAUSE}`,
      zIndex: 5,
      priorityZIndex: -1,
    },
  },
  // type: EVENT_CONST_TYPES.PATIENT
  {
    type: EVENT_CONST_TYPES.PATIENT,
    timeEventType: 'patient', // todo: jyoon(230521) - [refactor] 이벤트 타입을 정의해야함
    timeEventGroup: null,
    beatType: null,
    ectopicType: null,
    geminyType: null,
    eventSection: EventSection.PATIENT,
    renderAttrs: {},
  },
  // type: EVENT_CONST_TYPES.PATIENT_FOUND
  {
    type: EVENT_CONST_TYPES.PATIENT_FOUND,
    timeEventType: 'patient', // todo: jyoon(230521) - [refactor] 이벤트 타입을 정의해야함
    timeEventGroup: null,
    beatType: null,
    ectopicType: null,
    geminyType: null,
    eventSection: EventSection.PATIENT_FOUND,
    renderAttrs: {},
  },
  // type: EVENT_CONST_TYPES.PATIENT_NOT_FOUND
  {
    type: EVENT_CONST_TYPES.PATIENT_NOT_FOUND,
    timeEventType: 'patient', // todo: jyoon(230521) - [refactor] 이벤트 타입을 정의해야함
    timeEventGroup: null,
    beatType: null,
    ectopicType: null,
    geminyType: null,
    eventSection: EventSection.PATIENT_NOT_FOUND,
    renderAttrs: {},
  },
  // type: EVENT_CONST_TYPES.NORMAL
  {
    type: EVENT_CONST_TYPES.NORMAL,
    timeEventType: 'normal', // todo: jyoon(230521) - [refactor] 이벤트 타입을 정의해야함
    timeEventGroup: null,
    beatType: null,
    ectopicType: null,
    geminyType: null,
    eventSection: null,
    renderAttrs: {},
  },
  // type: EVENT_CONST_TYPES.NOISE
  {
    type: EVENT_CONST_TYPES.NOISE,
    timeEventType: 'noise', // 의도된 garbage 값 // todo: jyoon(230521) - [refactor] 이벤트 타입을 정의해야함
    timeEventGroup: null,
    beatType: BEAT_TYPE.NOISE,
    ectopicType: null,
    geminyType: null,
    eventSection: null,
    renderAttrs: {
      color: AppColors.MEDIUM_DARK,
      className: `huinno-${EVENT_CONST_TYPES.NOISE}`,
      zIndex: 0,
      priorityZIndex: 0,
    },
  },
  // type: EVENT_CONST_TYPES.LEAD_OFF
  {
    type: EVENT_CONST_TYPES.LEAD_OFF,
    timeEventType: TIME_EVENT_TYPE.LEAD_OFF,
    timeEventGroup: null,
    beatType: null, // 의도된 garbage 값
    ectopicType: null,
    geminyType: null,
    eventSection: null,
    renderAttrs: {
      selectedAreaColor: AppColors.LEAD_OFF,
      unselectedAreaColor: AppColors.LEAD_OFF,
      selectedFillOpacity: 1,
      unselectedFillOpacity: 1,
      color: AppColors.MEDIUM_DARK,
      className: `huinno-${EVENT_CONST_TYPES.LEAD_OFF}`,
      zIndex: 1,
      priorityZIndex: 10,
    },
  },
];

export const EVENT_INFO_ITEM_TYPES = {
  EVENT_TYPE: 'Event type',
  EPISODE_HR: 'Episode HR Avg (Range)',
  EVENT_HR: 'Event HR Avg (Range)',
  FASTEST: 'Fastest',
  DURATION: 'Duration',
  BEATS: 'Beats in run',
  LONGEST: 'Longest',
  R_R_INTERVAL: 'R-R Interval',
  ONSET: 'Onset',
  TERMINATION: 'Termination',
  PREV_BEAT_HR: 'Previous beat HR',
  BEAT_HR: 'Beat HR',
  FIRST_BEAT_HR: '1st beat HR',
  SECONDE_BEAT_HR: '2nd beat HR',
  TIME: 'Time',
  FINDINGS: 'Findings',
  TRIGGER_METHOD: 'Trigger method',
  NINETY_SEC_HR: '90 sec strip HR Avg (Range)',
  TRIGGER: 'Patient triggered time',
  DIARY_ENTRY: 'Diary Entry',
  CYCLES: 'Cycles',
  GEMINY: 'Geminy',
};

export const REPORT_EVENT_INFO_ITEM_TYPES = {
  STRIP_TITLE: 'Strip title',
  STRIP: 'Strip',
  TEN_STRIP: '10 sec strip',
  NINETY_STRIP: '90 sec strip',
};

export const PLACE_HOLDER_TYPE = {
  EMPTY_SELECTED_EVENT: 'empty-selected-event',
  EMPTY_SELECTED_REPORT_PAGE: 'empty-selected-report-page',
  EMPTY_EVENT_IN_REPORT: 'empty-event-in-report',
};

export const CONTEXT_MENU = {
  BEAT_TYPE: {
    NORMAL: {
      index: 0,
      label: 'Normal',
      shortLabel: 'N',
      FullName: 'Normal',
      shortcut: 'Q',
      value: BEAT_TYPE.NORMAL, // value: api전송시 request param에서 사용될 값
    },
    APC: {
      index: 10,
      label: 'S',
      shortLabel: 'S',
      FullName: 'APC',
      shortcut: 'W',
      value: BEAT_TYPE.APC,
    },
    VPC: {
      index: 20,
      label: 'V',
      shortLabel: 'V',
      FullName: 'VPC',
      shortcut: 'E',
      value: BEAT_TYPE.VPC,
    },
    QUESTIONABLE: {
      index: 30,
      label: 'Questionable',
      shortLabel: 'Q',
      FullName: 'Questionable',
      shortcut: 'R',
      value: BEAT_TYPE.NOISE,
    },
  },
  EPISODE_TYPE: {
    AF: {
      index: 0,
      label: 'A.fib',
      value: TIME_EVENT_TYPE.AF,
      shortcut: 'A',
      subMenu: null,
    },
    AVB_2: {
      index: 21,
      label: '2nd AV block',
      value: TIME_EVENT_TYPE.AVB_2,
      shortcut: 'F',
      subMenu: null,
    },
    AVB_3: {
      index: 22,
      label: '3rd AV block',
      value: TIME_EVENT_TYPE.AVB_3,
      shortcut: 'G',
      subMenu: null,
    },
    OTHERS: {
      index: 30,
      label: 'Others',
      value: TIME_EVENT_TYPE.OTHERS,
      shortcut: 'D',
      subMenu: null,
    },
  },
  REMOVE: {
    index: 4,
    value: 'REMOVE',
    label: 'Remove',
    shortcut: 'Z',
  },
};

/* TODO jh : 리포트 밸리데이션 콘스트로 옮기기 */
export const REPORT_ANNOTATION_LENGTH_LIMITATION = {
  [REPORT_SECTION.SUMMARY]: 17,
  [REPORT_SECTION.AF]: 60,
  [REPORT_SECTION.PAUSE]: 60,
  [REPORT_SECTION.AV_BLOCK]: 60,
  [REPORT_SECTION.VT]: 60,
  [REPORT_SECTION.VE]: 60,
  [REPORT_SECTION.SVT]: 60,
  [REPORT_SECTION.SVE]: 60,
  [REPORT_SECTION.PTE]: 60,
  [REPORT_SECTION.ADDITIONAL]: 60,
};

/** 30초 */
/* TODO jh : 벨리데이션 콘스트로 옮기기  */
export const VALIDATED_AF_MIN_DURATION = 30000;

export const SUFFIX_LIST = {
  EDIT_BEAT_SUFFIX: {
    ADD_BEATS: 'add-beats',
    REMOVE_BEATS: 'remove-beats',
    UPDATE_BEATS_BY_INDEXES: 'update-beats/waveform-indexes',
    UPDATE_BEATS_BY_BETWEEN_INDEX: 'update-beats/between-waveform-index',
  },
  FILTER_BPM: 'filter/bpm',
};

export const CLASS_NAME_HUINNO_CONTEXT_MENU_AREA = 'huinno-context-menu-area';
export const CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED =
  'huinno-event-marker-priority-selected';

/**
 * @typedef {Object} BeatType
 * @property {0} NORMAL
 * @property {1} APC
 * @property {2} VPC
 * @property {3} NOISE
 */

/**
 * @typedef {Object} EctopicType
 * @property {'ISOLATE'} ISOLATE
 * @property {'COUPLET'} COUPLET
 * @property {'RUN'} RUN
 */

/**
 *@typedef {Object} GeminyType
 * @property {'BIGEMINY'} 2
 * @property {'TRIGEMINY'} 3
 * @property {'QUADRIGEMINY'} 4
 */

/**
 * @typedef {Object} BeatReviewEvent
 * @property {'EVENT-TYPE-ISOLATE-2'} ISO_VPC
 * @property {'EVENT-TYPE-COUPLET-2'} COUPLET_VPC
 * @property {'EVENT-TYPE-ISOLATE-1'} ISO_APC
 * @property {'EVENT-TYPE-COUPLET-1'} COUPLET_APC
 */

/**
 * @typedef {Object} EventSection
 * // TimeEvents
 * @property {"AF"} AF
 * @property {"PAUSE"} PAUSE
 * @property {"AVB-GROUP-2"} AVB_2
 * @property {"AVB-GROUP-3"} AVB_3
 *  // V
 * @property {"VT"} VT
 * @property {"IsoVPC"} ISO_VPC
 * @property {"CoupletVPC"} COUPLET_VPC
 * @property {"BigeminyVPC"} BIGEMINY_VPC
 * @property {"TrigeminyVPC"} TRIGEMINY_VPC
 * @property {"QuadrigeminyVPC"} QUADRIGEMINY_VPC
 * // S
 * @property {"SVT"} SVT
 * @property {"IsoAPC"} ISO_APC
 * @property {"CoupletAPC"} COUPLET_APC
 * @property {"BigeminyAPC"} BIGEMINY_APC
 * @property {"TrigeminyAPC"} TRIGEMINY_APC
 * @property {"QuadrigeminyAPC"} QUADRIGEMINY_APC
 * // ETC
 * @property {"OTHERS"} OTHERS
 * @property {"PTE"} PATIENT
 * @property {"PTEFound"} PATIENT_FOUND
 * @property {"PTENotFound"} PATIENT_NOT_FOUND
 */

/**
 * @typedef {Object} EventConstTypes
 * // TimeEvents
 * @property {"EVENT-TYPE-AF"} AF
 * @property {"EVENT-TYPE-PAUSE"} PAUSE
 * @property {"EVENT-TYPE-AV-BLOCK"} AV_BLOCK
 * @property {"EVENT-TYPE-AVB-2"} AVB_2 2nd AV block
 * @property {"EVENT-TYPE-AVB-3"} AVB_3 3rd AV block
 * @property {"EVENT-TYPE-OTHERS"} OTHERS
 * // V
 * @property {"EVENT-TYPE-ISOLATE-2"} ISO_VPC
 * @property {"EVENT-TYPE-COUPLET-2"} COUPLET_VPC
 * @property {"EVENT-TYPE-BIGEMINY-2"} BIGEMINY_VPC
 * @property {"EVENT-TYPE-TRIGEMINY-2"} TRIGEMINY_VPC
 * @property {"EVENT-TYPE-QUADRIGEMINY-2"} QUADRIGEMINY_VPC
 * @property {"EVENT-TYPE-X-RUN-2"} VE
 * @property {"EVENT-TYPE-RUN-2"} VT
 * // S
 * @property {"EVENT-TYPE-ISOLATE-1"} ISO_APC
 * @property {"EVENT-TYPE-COUPLET-1"} COUPLET_APC
 * @property {"EVENT-TYPE-BIGEMINY-1"} BIGEMINY_APC
 * @property {"EVENT-TYPE-TRIGEMINY-1"} TRIGEMINY_APC
 * @property {"EVENT-TYPE-QUADRIGEMINY-1"} QUADRIGEMINY_APC
 * @property {"EVENT-TYPE-X-RUN-1"} SVE
 * @property {"EVENT-TYPE-RUN-1"} SVT
 * //
 * @property {"EVENT-TYPE-PATIENT"} PATIENT
 * @property {"EVENT-TYPE-PATIENT-FOUND"} PATIENT_FOUND
 * @property {"EVENT-TYPE-PATIENT-NOT-FOUND"} PATIENT_NOT_FOUND
 * //
 * @property {"EVENT-TYPE-SUMMARY"} NOTABLE
 * @property {"EVENT-TYPE-ADDITIONAL"} ADDITIONAL
 * //
 * @property {"EVENT-TYPE-0"} NORMAL
 * @property {"EVENT-TYPE-3"} NOISE
 * @property {"EVENT-TYPE-LEAD_OFF"} LEAD_OFF
 */
