import { useDispatch } from 'react-redux';

import { SELECTION_MARKER_TYPE } from 'constant/ChartEditConst';

import ChartUtil from 'util/ChartUtil';

import {
  setSelectionStripRequest,
  resetRepresentativeStripInfo,
} from 'redux/duck/testResultDuck';

function useGetResetMarkersFunc() {
  const dispatch = useDispatch();
  const chartEditInst = ChartUtil.chartEdit();

  const removeSelectionMarker = () => {
    chartEditInst.removeSelectionMarkerAll();
  };
  const removeRepresentativeStripMarker = () => {
    chartEditInst.removeRepresentativeReportStrip();
  };
  const removeSelectionHighlightAll = () => {
    chartEditInst.removeSelectionHighlightAll();
  };

  const resetSelectionStrip = () => {
    removeSelectionMarker();
    removeSelectionHighlightAll();

    dispatch(
      setSelectionStripRequest({
        selectionMarkerType: SELECTION_MARKER_TYPE.RESET,
      })
    );
  };
  const resetSidePanelRepresentative = () => {
    removeRepresentativeStripMarker();

    dispatch(resetRepresentativeStripInfo());
  };

  return {
    removeSelectionMarker,
    removeRepresentativeStripMarker,
    removeSelectionHighlightAll,
    resetSelectionStrip,
    resetSidePanelRepresentative,
  };
}

export default useGetResetMarkersFunc;
