import { EVENT_CONST_TYPES } from 'constant/EventConst';

import useGetArrhythmiaEvents from './useGetArrhythmiaEvents/useGetArrhythmiaEvents';

/**
 * 특정 WI의 Type이 [N,S,V,Q, Couplet VPC, Couplet APC, RUN] 중에 무엇인지 Return 하는 hook입니다.
 * @param {Number} selectWaveformIndex
 * @param {Number} onsetWaveformIndex selectWaveformIndex가 속해있는 구간의 시작 지점
 * @param {Number} terminationWaveformIndex selectWaveformIndex가 속해있는 구간의 끝 지점
 * @param {Array} beats selectWaveformIndex가 속해있는 구간의 beats 배열
 * @returns {String} selectWaveformIndex의 Type (ex, 'EVENT-TYPE-ISOLATE-2', 'EVENT-TYPE-RUN-2', ...)
 */
function useGetEventTypeOfWI({
  selectWaveformIndex,
  onsetWaveformIndex,
  terminationWaveformIndex,
  beats,
}) {
  const markList = useGetArrhythmiaEvents(
    onsetWaveformIndex,
    terminationWaveformIndex,
    beats
  );

  let type;

  markList['ectopicList'].map((v) =>
    v.waveformIndex.map((idx) => {
      if (idx === selectWaveformIndex) type = v.type;
      return idx;
    })
  );
  markList['noiseList'].map((v) =>
    v.waveformIndex.map((idx) => {
      if (idx === selectWaveformIndex) type = v.type;
      return idx;
    })
  );

  return type || EVENT_CONST_TYPES.NORMAL;
}

export default useGetEventTypeOfWI;
