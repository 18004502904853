import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  BADGE_TYPE_TEXT_LOOKUP,
  INTL_MAP,
  LABEL_TEXT_LOOKUP,
  MIN_AVG_MAX_BADGE_TYPE,
  ONCHANGE_OPTIONS,
  SELECTABLE_VALUE_TYPE,
} from 'constant/HrReviewConst';

import { convertPresentText, getRefinedBinKey } from 'util/HrReviewUtil';

import Tooltip from 'component/ui/tooltip/Tooltip';

import {
  moveToReportExistPosition,
  verifyDataAndExecuteCallback,
} from 'redux/duck/hrReviewDuck';

function OverallInfo({
  unit,
  histType,
  unitType,
  unitValue,
  sortOptionList,
  currentUnitReportData,
  handleOnChange,
}) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleMoveToReportExistPosition = ({ newBinKey, waveformIndex }) => {
    dispatch(moveToReportExistPosition({ newBinKey, waveformIndex }));
  };

  const handleVerifyDataAndExecuteCallback = (
    unitValue,
    unitType,
    successCallback
  ) => {
    dispatch(
      verifyDataAndExecuteCallback(unitValue, unitType, successCallback)
    );
  };

  const labelText = LABEL_TEXT_LOOKUP[histType];
  const unitValueText = `${convertPresentText(unitValue, histType)} ${unit}`;
  const { waveformIndex } = currentUnitReportData?.timeEvent.extra || {};

  const onClickUnitButton = () => {
    handleVerifyDataAndExecuteCallback(unitValue, unitType, successCallback);

    function successCallback() {
      const newBinKey = getRefinedBinKey(unitValue, histType);
      const isChangeBin = handleOnChange(
        SELECTABLE_VALUE_TYPE.BIN_KEY,
        newBinKey
      );

      if (unitType === BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.AVG]) {
        handleOnChange(SELECTABLE_VALUE_TYPE.POSITION, 1);
        return;
      }

      const newSortOrder =
        sortOptionList[
          unitType === BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MAX]
            ? 0
            : 1
        ];
      const isChangeSortOrder = handleOnChange(
        SELECTABLE_VALUE_TYPE.SORT_ORDER,
        newSortOrder,
        {
          [ONCHANGE_OPTIONS.NOTI_CHANGE_SORT_ORDER]: true,
          [ONCHANGE_OPTIONS.CAUSE_TYPE_CHANGE_SORT_ORDER]: unitType,
        }
      );
      if (!isChangeBin && !isChangeSortOrder) {
        handleOnChange(SELECTABLE_VALUE_TYPE.POSITION, 1);
      }
    }
  };
  const onClickViewButton = () => {
    handleVerifyDataAndExecuteCallback(unitValue, unitType, successCallback);

    function successCallback() {
      const newBinKey = getRefinedBinKey(unitValue, histType);
      handleMoveToReportExistPosition({ newBinKey, waveformIndex });
      handleOnChange(SELECTABLE_VALUE_TYPE.BIN_KEY, newBinKey);

      if (unitType === BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.AVG]) {
        return;
      }

      const newSortOrder =
        sortOptionList[
          unitType === BADGE_TYPE_TEXT_LOOKUP[MIN_AVG_MAX_BADGE_TYPE.MAX]
            ? 0
            : 1
        ];
      handleOnChange(SELECTABLE_VALUE_TYPE.SORT_ORDER, newSortOrder, {
        [ONCHANGE_OPTIONS.NOTI_CHANGE_SORT_ORDER]: true,
        [ONCHANGE_OPTIONS.CAUSE_TYPE_CHANGE_SORT_ORDER]: unitType,
      });
    }
  };

  return (
    <OverallUnitWrapper>
      <Tooltip
        title={formatMessage(INTL_MAP.VIEW_UNIT_TYPE, {
          unitType,
          labelText,
        })}
        placement="top">
        <UnitButton onClick={onClickUnitButton}>
          <UnitText>{`${unitType} ${labelText}`}</UnitText>
          <UnitValueText>{unitValueText}</UnitValueText>
        </UnitButton>
      </Tooltip>
      {currentUnitReportData && (
        <Tooltip
          title={formatMessage(INTL_MAP.VIEW_ADDED_STRIP)}
          placement="top">
          <ViewButton onClick={onClickViewButton} />
        </Tooltip>
      )}
      {!currentUnitReportData && (
        <Tooltip title={formatMessage(INTL_MAP.NO_STRIP_ADDED)} placement="top">
          <ReportAmountTextWrapper>
            <ReportAmountText>0</ReportAmountText>
            <ReportAmountText fontSize="9px">/1</ReportAmountText>
          </ReportAmountTextWrapper>
        </Tooltip>
      )}
    </OverallUnitWrapper>
  );
}

const ViewButton = styled.div`
  padding: 8px 4px;
  font-size: 12px;
  font-weight: 500;
  text-decoration-line: underline;
  border-radius: 6px;
  cursor: pointer;
  ::after {
    content: 'View';
  }
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
  :hover {
    background: ${({ theme }) => theme.color.COOL_GRAY_30};
    color: ${({ theme }) => theme.color.COOL_GRAY_90};
  }
`;

const ReportAmountTextWrapper = styled.div`
  display: flex;
  gap: 2px;
  padding: 8px 9px;
`;
const ReportAmountText = styled.div`
  font-weight: 500;
  cursor: default;
  font-size: ${({ fontSize }) => fontSize || '12px'};
  color: ${({ theme }) => theme.color.RED_70};
`;

const OverallUnitWrapper = styled.div`
  padding: 4px 0px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UnitButton = styled.div`
  display: flex;
  height: 16px;
  padding: 8px;
  border-radius: 6px;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
  }
  border: 1px solid ${({ theme }) => theme.color.COOL_GRAY_60};
`;

const UnitText = styled.div`
  width: 54px;
  font-size: 12px;
  line-height: 130%;
  color: ${({ theme }) => theme.color.COOL_GRAY_80};
`;

const UnitValueText = styled.div`
  width: 78px;
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

export default OverallInfo;
