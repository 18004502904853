import styled from 'styled-components';

import {
  MIN_AVG_MAX_BADGE_TYPE,
  HR_REVIEW_HISTOGRAM_TYPE,
  LABEL_TEXT_LOOKUP,
} from 'constant/HrReviewConst';

// HrReviewBadge Component
function HrReviewBadge({ minAvgMaxBadgeType, histType }: HrReviewBadgeProps) {
  const histTypeText = LABEL_TEXT_LOOKUP[histType];

  switch (minAvgMaxBadgeType) {
    case MIN_AVG_MAX_BADGE_TYPE.MIN: {
      return <Badge>{`Min ${histTypeText}`}</Badge>;
    }
    case MIN_AVG_MAX_BADGE_TYPE.AVG: {
      return <Badge>{`Avg ${histTypeText}`}</Badge>;
    }
    case MIN_AVG_MAX_BADGE_TYPE.MAX: {
      return <Badge>{`Max ${histTypeText}`}</Badge>;
    }
    case MIN_AVG_MAX_BADGE_TYPE.NONE:
    default: {
      return null;
    }
  }
}

// HrReviewBadge's Styled Component
const Badge = styled.div`
  padding: 0 3px;
  border-radius: 2px;

  background-color: ${(props) => props.theme.color.BLUE_70};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.color.WHITE};
  font-size: 11px;
  font-weight: 500;
  line-height: 130%;
`;

// HrReviewBadge's Types
type HrReviewBadgeProps = {
  /** 최소값, 최대값 분류 */
  minAvgMaxBadgeType: MIN_AVG_MAX_BADGE_TYPE;
  /** Histogram 분류 */
  histType: HR_REVIEW_HISTOGRAM_TYPE;
};

export default HrReviewBadge;
