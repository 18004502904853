import { memo } from 'react';

import Tooltip from 'component/ui/tooltip/Tooltip';

import {
  ChartIdLabel,
  ChartInfoWrapper,
  ChartLeftInfoWrapper,
  ChartRightInfoWrapper,
  EditedLabel,
  IconWrapper,
  RrIntervalRatio,
  StyledBeatIcon,
} from './ChartCommonUI';

import { InfoIconWrapper } from '../../FormsPanel/FormsPanelFooterFragment';

function ChartEventsInfoFragment({
  isEdited,
  isSelected,
  totalIndex,
  rrIntervalRatio,
  isCoupletEctopicTypeClicked,
  hasOriginWaveformIndex,
  hasTerminationWaveformIndex,
}: any) {
  return (
    <ChartInfoWrapper className="ChartInfoWrapper">
      <ChartLeftInfoWrapper
        className="ChartLeftInfoWrapper"
        isSelected={
          isSelected && (hasOriginWaveformIndex || hasTerminationWaveformIndex)
        }
        isEdited={isEdited}>
        <IconWrapper className="beatIcon">
          <StyledBeatIcon disabled={false} />
        </IconWrapper>
        <ChartIdLabel className="chartIdLabel">{totalIndex + 1}</ChartIdLabel>
        <EditedLabel className="editedLabel">Edited</EditedLabel>
      </ChartLeftInfoWrapper>
      <ChartRightInfoWrapper
        className="ChartRightInfoWrapper"
        isSelected={isSelected}>
        <RrIntervalRatio>{rrIntervalRatio}</RrIntervalRatio>
        <Tooltip
          title={
            isCoupletEctopicTypeClicked
              ? '이전 Beat R-R : 첫번째 Beat R-R : 두번째 Beat R-R'
              : '이전 Beat R-R : Beat R-R'
          }
          placement="bottom"
          offset={[0, 0]}>
          <InfoIconWrapper />
        </Tooltip>
      </ChartRightInfoWrapper>
    </ChartInfoWrapper>
  );
}

export default memo(ChartEventsInfoFragment, (prevProps, nextProps) => {
  return (
    prevProps.chartInfo.originWaveformIndex ===
      nextProps.chartInfo.originWaveformIndex &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.isEdited === nextProps.isEdited &&
    prevProps.rrIntervalRatio === nextProps.rrIntervalRatio
  );
});
