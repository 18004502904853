import styled, { keyframes } from 'styled-components';

const SPINNER_SIZE = 16;
export const SPINNER_CLASS = 'huinno-button-spinner';

/**
 * @param {*} props
 * @returns
 */
function ButtonSpinner(props) {
  return (
    <Wrapper className={SPINNER_CLASS}>
      <Spinner>
        <Circle reverse={props.reverse} />
      </Spinner>
    </Wrapper>
  );
}

export default ButtonSpinner;

const Wrapper = styled.div`
  width: ${SPINNER_SIZE}px;
  height: ${SPINNER_SIZE}px;
`;

const SpinningKeyframe = (subType) => keyframes`
    0% {
        ${subType}transform: rotate(0deg);
        stroke-dashoffset: ${0.66 * SPINNER_SIZE};
    } 50% {
        ${subType}transform: rotate(720deg);
        stroke-dashoffset: ${3.14 * SPINNER_SIZE};
    } 100% {
        ${subType}transform: rotate(1080deg);
        stroke-dashoffset: ${0.66 * SPINNER_SIZE};
    }
`;

const Spinner = styled.svg.attrs(({ spinnerSize }) => ({
  width: spinnerSize,
  height: spinnerSize,
  x: 0.5,
  y: 0.5,
  viewBox: `0 0 ${SPINNER_SIZE} ${SPINNER_SIZE}`,
}))``;

const Circle = styled.circle.attrs(() => ({
  cx: 8,
  cy: 8,
  r: 6,
}))`
  fill: transparent;
  stroke: ${(props) =>
    props.reverse ? props.theme.color.BLUE_70 : props.theme.color.WHITE};
  stroke-width: 3;
  stroke-linecap: round;
  stroke-dasharray: ${3.14 * SPINNER_SIZE}px;
  -webkit-transform-origin: ${SPINNER_SIZE / 2}px ${SPINNER_SIZE / 2}px 0;
  -moz-transform-origin: ${SPINNER_SIZE / 2}px ${SPINNER_SIZE / 2}px 0;
  -ms-transform-origin: ${SPINNER_SIZE / 2}px ${SPINNER_SIZE / 2}px 0;
  -o-transform-origin: ${SPINNER_SIZE / 2}px ${SPINNER_SIZE / 2}px 0;
  transform-origin: ${SPINNER_SIZE / 2}px ${SPINNER_SIZE / 2}px 0;
  -webkit-animation: ${SpinningKeyframe('-webkit-')} 2s linear infinite;
  -moz-animation: ${SpinningKeyframe('-moz-')} 2s linear infinite;
  -ms-animation: ${SpinningKeyframe('-ms-')} 2s linear infinite;
  -o-animation: ${SpinningKeyframe('-o-')} 2s linear infinite;
  animation: ${SpinningKeyframe('')} 2s linear infinite;
`;
