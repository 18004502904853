import { ECG_CHART_UNIT } from 'constant/ChartEditConst';

import { WaveformIndex } from '@type/ecgEventType/eventUnit';

import { getKeyWaveformIndex } from './BeatUtil';

/**
 * onsetWaveformIndex와 terminationWaveformIndex를 포함하는 정규 30초 구간을 반환
 *
 * 정규 30초: 0, 30, 60, 90, ...
 *
 * @param {*} onsetWaveformIndex
 * @param {*} terminationWaveformIndex
 * @returns
 */
export function getRegular30sWaveformIndexRange(
  onsetWaveformIndex: WaveformIndex,
  terminationWaveformIndex: WaveformIndex
) {
  const THIRTY_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX;

  let result = {
    onsetWaveformIndex: getKeyWaveformIndex(onsetWaveformIndex),
    terminationWaveformIndex: getKeyWaveformIndex(terminationWaveformIndex),
  };

  if (result.terminationWaveformIndex < terminationWaveformIndex) {
    result.terminationWaveformIndex += THIRTY_SEC_WAVEFORM_LENGTH;
  }

  return result;
}
