import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';

import { SortOption } from 'constant/SortConst';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import OrderingContextmenu from 'component/fragment/test-result/share/orderingContextMenu';

import {
  getOrderedWaveformIndexListOfFormRequested,
  selectFirstSelectedInfoOfFormPanel,
  selectFormDataOfSelectedEvent,
  setOrderSettingType,
} from 'redux/duck/shapeReviewDuck';
import {
  ChartListItem,
  SelectedItemInfo,
} from 'redux/duck/shapeReview/shapeReviewDuckType';

function OrderingContextMenuContainer(props) {
  const { formRankNumber, orderingTypeSetting } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const formDataOfSelectedEvent = useShallowEqualSelector(
    selectFormDataOfSelectedEvent
  ) as ChartListItem[];
  const selectFirstSelectedInfoOfForm = useShallowEqualSelector(
    selectFirstSelectedInfoOfFormPanel
  ) as SelectedItemInfo;

  const handleSetOrderSettingType = (orderingTypeSetting: SortOption) => {
    dispatch(setOrderSettingType(orderingTypeSetting));
  };

  // api 요청
  // ordering : api params ['','hr','rr-interval','similarity ]
  const handleGetWaveformIndexListOfFormRequested = ({
    ordering,
  }: {
    ordering: string;
  }) => {
    const formId =
      formDataOfSelectedEvent[selectFirstSelectedInfoOfForm?.index]?.formInfo
        ?.id;

    return dispatch(
      getOrderedWaveformIndexListOfFormRequested({ formId, ordering })
    );
  };

  return (
    <OrderingContextmenu
      orderingTypeSetting={orderingTypeSetting}
      formRankNumber={formRankNumber}
      handleSetOrderSettingType={handleSetOrderSettingType}
      handleGetWaveformIndexListOfFormRequested={
        handleGetWaveformIndexListOfFormRequested
      }
      theme={theme}
    />
  );
}
export default OrderingContextMenuContainer;
