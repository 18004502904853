import { defineMessages } from 'react-intl';

export const INTL_MAP = defineMessages({
  ADD_BEAT_DEFAULT_TOOLTIP: {
    id: 'TenSecStripDetailHeader-addBeatButton-default-Tooltip-title',
    description: 'Beat 추가 / Add Beat',
    defaultMessage: 'Beat 추가',
  },
  ADD_BEAT_CLICKED_TOOLTIP: {
    id: 'TenSecStripDetailHeader-addBeatButton-clicked-Tooltip-title',
    description: 'Beat 추가 취소 / Cancel Adding Beat',
    defaultMessage: 'Beat 추가 취소',
  },
  CALIPER_DEFAULT_TOOLTIP: {
    id: 'TenSecStripDetailHeader-caliperButton-default-Tooltip-title',
    description: '캘리퍼 켜기 / Caliper On',
    defaultMessage: '캘리퍼 켜기',
  },
  CALIPER_CLICKED_TOOLTIP: {
    id: 'TenSecStripDetailHeader-caliperButton-clicked-Tooltip-title',
    description: '캘리퍼 끄기 / Caliper Off',
    defaultMessage: '캘리퍼 끄기',
  },
  TICK_MARKS_DEFAULT_TOOLTIP: {
    id: 'TenSecStripDetailHeader-tickmarks-Button-default-Tooltip-title',
    description: '틱 마커 켜기 / Tick Marks On',
    defaultMessage: '틱 마커 켜기',
  },
  TICK_MARKS_CLICKED_TOOLTIP: {
    id: 'TenSecStripDetailHeader-tickmarks-Button-clicked-Tooltip-title',
    description: '틱 마커 끄기 / Tick Marks Off',
    defaultMessage: '틱 마커 끄기',
  },
  REMOVE_TOOLTIP: {
    id: 'TenSecStripDetailHeader-removeButton-Tooltip-title',
    description: 'Beat 삭제 / Remove Beat',
    defaultMessage: 'Beat 삭제',
  },
  OPEN_IN_NEW_TOOLTIP: {
    id: 'TenSecStripDetailHeader-openInNewButton-Tooltip-title',
    description: 'Event Review에서 보기/ View in Event Review',
    defaultMessage: 'Event Review에서 보기',
  },
});
