import styled from 'styled-components';

import {
  HR_REVIEW_HISTOGRAM_TYPE,
  LABEL_TEXT_LOOKUP,
} from 'constant/HrReviewConst';

import OverallInfo from 'component/fragment/test-result/hr-review/side-panel/OverallInfo';

function Overall({
  histType,
  maxData,
  minData,
  avgData,
  reportData,
  handleOnChange,
  unit,
  sortOptionList,
}) {
  const overallList =
    histType === HR_REVIEW_HISTOGRAM_TYPE.HR
      ? [{ Max: maxData }, { Avg: avgData }, { Min: minData }]
      : [{ Max: maxData }, { Min: minData }];

  return (
    <OverallWrapper>
      <OverallHeader>
        <OverallHeaderHRText>
          {`Overall ${LABEL_TEXT_LOOKUP[histType]}`}
        </OverallHeaderHRText>
        <OverallHeaderReportText>Report</OverallHeaderReportText>
      </OverallHeader>
      <OverallInfoWrapper>
        {overallList.map((obj) => {
          const [[unitType, unitValue]] = Object.entries(obj);
          const [currentUnitReportData] = reportData[histType].filter(
            (report) => report.stripType === unitType.toUpperCase()
          );

          return (
            <OverallInfo
              key={unitType}
              unit={unit}
              histType={histType}
              unitType={unitType}
              unitValue={unitValue}
              sortOptionList={sortOptionList}
              currentUnitReportData={currentUnitReportData}
              handleOnChange={handleOnChange}
            />
          );
        })}
      </OverallInfoWrapper>
    </OverallWrapper>
  );
}

const OverallWrapper = styled.div`
  padding: 12px 18px 16px;
  display: flex;
  flex-direction: column;
`;

const OverallHeader = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const OverallHeaderHRText = styled.div`
  width: 154px;
  font-size: 11px;
  font-weight: 700;
  line-height: 130%;
  color: ${({ theme }) => theme.color.COOL_GRAY_70};
`;

const OverallHeaderReportText = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 130%;
  color: ${({ theme }) => theme.color.COOL_GRAY_60};
`;

const OverallInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default Overall;
