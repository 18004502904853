import React, { useRef } from 'react';
import styled from 'styled-components';

import { ShapeReviewSectionArea } from 'constant/ShapeReviewConst';

import {
  formatRRIntervalRatio,
  getRRIRatio,
} from 'util/shapeReview/shapeReviewUtil';
import { getParentComponent } from 'util/Utility';

import {
  ActivePanelType,
  PanelListFragmentProps,
} from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';

import { onClickDivMouseEvent } from '@type/reactTypes';
import {
  ChartListItem,
  Checkbox,
  ClickedInfo,
} from 'redux/duck/shapeReview/shapeReviewDuckType';

import ChartFragment from '.';

const hiddenCheckBox = `& .CheckBoxCoupletWrapper {
  visibility: hidden;
}`;
const showCheckBox = `& .CheckBoxCoupletWrapper {
  visibility: visible;
}`;

const GridItemWrapper = styled.div<{
  gridType: ActivePanelType;
  originBeatsAllEmpty?: boolean;
  terminationBeatsAllEmpty?: boolean;
  activePanel: ActivePanelType;
  isSelected: boolean;
  isFocused: boolean;
}>`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  border-radius: 2px;

  ${(props) => {
    let eventResult = '';
    /**
     * # chartBox case 정리
     *
     * case1. focus 여부
     *  case1.1 선택된 패널 여부
     * case2. selected 여부
     *  case2.1 선택된 패널 여부
     */

    // case1. focus 여부
    if (props.isFocused) {
      // case1.1 선택된 패널 여부
      if (props.activePanel === props.gridType) {
        eventResult += showCheckBox;
        eventResult += `border: 1.5px solid ${props.theme.color.BLUE_70} !important;`;
      } else {
        eventResult += hiddenCheckBox;
        eventResult += `background-color: ${props.theme.color.BLUE_30};`;
        eventResult += `border: none`;
      }
    }

    // case2. selected 여부
    if (props.isSelected) {
      // case2.1 선택된 패널 여부
      if (props.activePanel === props.gridType) {
        eventResult += showCheckBox;
        eventResult += `border: none;`;
        eventResult += `background-color: ${props.theme.color.BLUE_40};`;
      } else {
        eventResult += hiddenCheckBox;
        eventResult += `border: none;
          background-color: ${props.theme.color.BLUE_30};
        `;
      }
    } else {
      eventResult += `background-color: ${props.theme.color.WHITE};`;
    }

    return eventResult;
  }};

  :hover {
    background-color: ${(props) =>
      props.isSelected ? 'none' : props.theme.color.COOL_GRAY_30};

    & .CheckBoxCoupletWrapper {
      visibility: visible;
    }
  }
`;

interface IEventsPanelRef {
  check: () => void;
}

interface IGridItem
  extends Pick<
    PanelListFragmentProps,
    | 'activePanel'
    | 'onClickGridItem'
    | 'setSelectedItemList'
    | 'setLastSelectedSectionInfo'
  > {
  gridType: ActivePanelType;
  selectedClass: string;
  clickedInfo: ClickedInfo | undefined;
  chartInfo: ChartListItem;
  //
  hasOriginWaveformIndex?: boolean;
  hasTerminationWaveformIndex?: boolean;
  isEdited: boolean;
  isFocused: boolean;
  isSelected: boolean;
  isCheckedFirst: boolean;
  isCheckedSecond: boolean;
  isCoupletEctopicTypeClicked: boolean;
  handleSelectedCheckboxStatus?: (checkboxStatus: Checkbox) => void;
  InfoFragment: JSX.Element;
}

function GridItem({
  gridType,
  selectedClass,
  activePanel,
  clickedInfo,
  chartInfo,
  //
  isEdited,
  isFocused,
  isSelected,
  isCheckedFirst,
  isCheckedSecond,
  isCoupletEctopicTypeClicked,
  //
  onClickGridItem,
  setSelectedItemList,
  setLastSelectedSectionInfo,
  //
  handleSelectedCheckboxStatus,
  InfoFragment,
}: IGridItem) {
  const eventsPanelRef = useRef<IEventsPanelRef | null>(
    null
  ) as React.MutableRefObject<IEventsPanelRef>;

  const hrList = chartInfo.beats.hr;
  const indexOfCenterWI = chartInfo.beats.waveformIndex.findIndex(
    (v: number) => v === chartInfo.originWaveformIndex
  );
  const indexOfTerminationWI = chartInfo.beats.waveformIndex.findIndex(
    (v: number) => v === chartInfo.terminationWaveformIndex
  );
  const comparisonArray = isCoupletEctopicTypeClicked
    ? [hrList[indexOfCenterWI], hrList[indexOfTerminationWI]]
    : [hrList[indexOfCenterWI]];

  const rrIntervalRatio = getRRIRatio({
    comparisonArray,
    referencePoint: hrList[indexOfCenterWI - 1],
  });

  const hasOriginWaveformIndex = chartInfo.beats.waveformIndex.includes(
    chartInfo.originWaveformIndex
  );
  const hasTerminationWaveformIndex = chartInfo.beats.waveformIndex.includes(
    chartInfo.terminationWaveformIndex
  );

  const isHasEctopicBeatsOrFormSelected =
    isSelected &&
    (hasOriginWaveformIndex ||
      hasTerminationWaveformIndex ||
      gridType === ShapeReviewSectionArea.FORMS);

  return (
    <GridItemWrapper
      className={'GridItem item-' + chartInfo.index + ' ' + selectedClass}
      gridType={gridType}
      activePanel={activePanel}
      isSelected={isHasEctopicBeatsOrFormSelected}
      isFocused={isFocused}
      onClick={(e: onClickDivMouseEvent) => {
        if (getParentComponent(e.target, 'CheckBox')) {
          return;
        }

        onClickGridItem(e, chartInfo);
      }}>
      <ChartFragment
        // local state
        hasOriginWaveformIndex={hasOriginWaveformIndex}
        hasTerminationWaveformIndex={hasTerminationWaveformIndex}
        ref={eventsPanelRef}
        rrIntervalRatio={
          indexOfCenterWI > 0 &&
          indexOfTerminationWI > 0 &&
          `1 : ${formatRRIntervalRatio(rrIntervalRatio)}`
        }
        // props state
        gridType={gridType}
        activePanel={activePanel}
        chartInfo={chartInfo}
        clickedInfo={clickedInfo}
        // props state
        isEdited={isEdited}
        isFocused={isFocused}
        isSelected={isSelected}
        isCheckedFirst={isCheckedFirst}
        isCheckedSecond={isCheckedSecond}
        isCoupletEctopicTypeClicked={isCoupletEctopicTypeClicked}
        // props state
        setLastSelectedSectionInfo={setLastSelectedSectionInfo}
        setSelectedItemList={setSelectedItemList}
        // props state
        InfoFragment={InfoFragment}
        //redux func
        handleSelectedCheckboxStatus={handleSelectedCheckboxStatus}
      />
    </GridItemWrapper>
  );
}

export default GridItem;
// export default React.memo(GridItem, (prev, next) => {
//   return (
//     // prev.chartInfo === next.chartInfo &&
//     // prev.selectedClass === next.selectedClass &&
//     // prev.isFocused === next.isFocused &&
//     // prev.isSelected === next.isSelected &&
//     // prev.isCheckedFirst === next.isCheckedFirst &&
//     // prev.isCheckedSecond === next.isCheckedSecond
//     prev.gridType === next.gridType &&
//     prev.chartInfo === next.chartInfo &&
//     prev.clickedInfo === next.clickedInfo &&
//     prev.selectedClass === next.selectedClass &&
//     prev.activePanel === next.activePanel &&
//     prev.isFocused === next.isFocused &&
//     prev.isSelected === next.isSelected &&
//     prev.isEdited === next.isEdited &&
//     prev.isCheckedFirst === next.isCheckedFirst &&
//     prev.isCheckedSecond === next.isCheckedSecond &&
//     prev.isCoupletEctopicTypeClicked === next.isCoupletEctopicTypeClicked
//   );
// });
