import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  ChartBoxNumber,
  SHAPE_REVIEW_CHART_INFO,
  ShapeReviewSectionArea,
} from 'constant/ShapeReviewConst';
import {
  EVENT_GROUP_TYPE,
  EVENT_INFO,
  SIDE_PANEL_EVENT_GROUP,
} from 'constant/EventConst';

import { EventTypeContext } from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';

import CheckBox from 'component/ui/checkbox/CheckBox';

import { SelectedItemInfo } from 'redux/duck/shapeReview/shapeReviewDuckType';
import { EventSection } from '@type/ecgEventType/baseEventType';

import FourSecChart from './FourSecChart';

import { IShapeReviewSidePanelEventGroup } from '../../FormsPanel/FormsPanelListFragment';
import {
  ShortCutKeyGuide,
  TooltipCustomTitleWrapper,
} from '../FormsEventsPanelNavigationFragment';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6.5px;
  justify-content: center;
  align-items: center;
  width: ${SHAPE_REVIEW_CHART_INFO.CONTAINER_WIDTH}px;
  height: 100%;
`;
const CheckBoxCoupletWrapper = styled.div<{
  hasOriginWaveformIndex?: boolean;
  isGridTypeForms?: boolean;
}>`
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  display: flex;
  justify-content: ${({ hasOriginWaveformIndex, isGridTypeForms }) => {
    return isGridTypeForms
      ? 'space-between'
      : hasOriginWaveformIndex
      ? 'space-between'
      : 'flex-end';
  }};
  visibility: hidden;
`;

const EditedFormWrapper = styled.div`
  width: 176px;
  height: 54px;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};
  color: ${(props) => props.color};
  background-color: white;
`;

const shapeReviewEventGroupType = SIDE_PANEL_EVENT_GROUP[
  EVENT_GROUP_TYPE.SHAPE
] as Array<IShapeReviewSidePanelEventGroup>;

function ChartFragment({ InfoFragment, ...props }: any, ref: any) {
  const {
    hasOriginWaveformIndex,
    hasTerminationWaveformIndex,
    gridType,
    chartInfo,
    clickedInfo,
    //
    isCheckedFirst,
    isCheckedSecond,
    isFocused,
    isSelected,
    isEdited,
    isCoupletEctopicTypeClicked,
    //
    setLastSelectedSectionInfo,
    setSelectedItemList,
    handleSelectedCheckboxStatus,
  } = props;
  const [coupletFirst, setCoupletFirst] = useState(false);
  const [coupletSecond, setCoupletSecond] = useState(false);

  useImperativeHandle(ref, () => ({
    check: () => {
      setCoupletFirst(true);
      setCoupletSecond(true);
    },
  }));

  useEffect(() => {
    setCoupletFirst(isCheckedFirst);
    setCoupletSecond(isCheckedSecond);
  }, [isCheckedFirst, isCheckedSecond]);

  const { eventType } = useContext<any>(EventTypeContext);

  const isEditedForm = chartInfo.formInfo?.formRankNumber === 0;
  const isEtcForm = chartInfo.formInfo?.formRankNumber === -1;

  let $checkBox = makeCheckBox(
    hasOriginWaveformIndex,
    hasTerminationWaveformIndex,
    gridType
  );

  const filterShapeReviewEventGroupType = shapeReviewEventGroupType.filter(
    (v) => v.type === clickedInfo?.eventType
  )[0];
  const filterShapeReviewEventGroupTypeColor = EVENT_INFO.filter(
    (v) => v.type === filterShapeReviewEventGroupType?.type
  )[0]?.renderAttrs.color;

  return (
    <ItemWrapper className="ItemWrapper">
      {isEditedForm || isEtcForm ? (
        <EditedFormWrapper color={filterShapeReviewEventGroupTypeColor}>
          {isEditedForm
            ? `Edited ${filterShapeReviewEventGroupType.label}`
            : 'Etc.'}
        </EditedFormWrapper>
      ) : (
        <FourSecChart
          className="FourSecChart"
          chartInfo={chartInfo}
          gridType={gridType}
          isEdited={isEdited}
          isFocused={isFocused}
          isSelected={isSelected}
        />
      )}
      {$checkBox}
      {React.cloneElement(InfoFragment, props)}
    </ItemWrapper>
  );

  function makeCheckBox(
    hasOriginWaveformIndex: boolean,
    hasTerminationWaveformIndex: boolean,
    gridType: string
  ) {
    const isIsolate =
      eventType === EventSection.ISO_APC || eventType === EventSection.ISO_VPC;

    // 삭제 된 경우 (show & hide) & (styled-components props)
    const isGridTypeForms = gridType === ShapeReviewSectionArea.FORMS;

    // isGridTypeForms인 경우는 체크박스를 무조건 보여준다.
    const isOnsetCheckBoxShow = hasOriginWaveformIndex || isGridTypeForms;
    const isTerminationCheckBoxShow =
      hasTerminationWaveformIndex || isGridTypeForms;

    if (isIsolate) {
      return (
        isOnsetCheckBoxShow && (
          <CheckBoxCoupletWrapper
            className="CheckBoxCoupletWrapper"
            hasOriginWaveformIndex={hasOriginWaveformIndex}
            isGridTypeForms={isGridTypeForms}>
            <CheckBox
              hover
              size={'20px'}
              hoverStyle={{ padding: '6px' }}
              labelStyle={{ fontSize: 12 }}
              backgroundLabel={'1'}
              checked={coupletFirst}
              tooltipTitle={
                <TooltipCustomTitleWrapper>
                  <div>{coupletFirst ? '선택 해제' : '선택'}</div>
                  <ShortCutKeyGuide style={{ width: 15 }}>1</ShortCutKeyGuide>
                </TooltipCustomTitleWrapper>
              }
              onChange={() => {
                onClickCheckBox({
                  number: ChartBoxNumber.KeyOne,
                  handleSelectedCheckboxStatus,
                });
              }}
            />
          </CheckBoxCoupletWrapper>
        )
      );
    }

    return (
      <CheckBoxCoupletWrapper
        className="CheckBoxCoupletWrapper"
        hasOriginWaveformIndex={hasOriginWaveformIndex}
        isGridTypeForms={isGridTypeForms}>
        {isOnsetCheckBoxShow && (
          <CheckBox
            tooltipTitle={
              <TooltipCustomTitleWrapper>
                <div>{coupletFirst ? '선택 해제' : '첫 번째 Beat 선택'}</div>
                <ShortCutKeyGuide style={{ width: 15 }}>1</ShortCutKeyGuide>
              </TooltipCustomTitleWrapper>
            }
            checked={coupletFirst}
            onChange={() => {
              onClickCheckBox({
                number: ChartBoxNumber.KeyOne,
                handleSelectedCheckboxStatus,
              });
            }}
            backgroundLabel={'1'}
            hover
            hoverStyle={{ padding: '6px' }}
            labelStyle={{ fontSize: 12 }}
            size={'20px'}
          />
        )}

        {isTerminationCheckBoxShow && (
          <CheckBox
            tooltipTitle={
              <TooltipCustomTitleWrapper>
                <div>{coupletSecond ? '선택 해제' : '두 번째 Beat 선택'}</div>
                <ShortCutKeyGuide style={{ width: 15 }}>2</ShortCutKeyGuide>
              </TooltipCustomTitleWrapper>
            }
            checked={coupletSecond}
            onChange={() => {
              onClickCheckBox({
                number: ChartBoxNumber.KeyTwo,
                handleSelectedCheckboxStatus,
              });
            }}
            backgroundLabel={ChartBoxNumber.KeyTwo}
            hover
            hoverStyle={{ padding: '6px' }}
            labelStyle={{ fontSize: 12 }}
            size={'20px'}
          />
        )}
      </CheckBoxCoupletWrapper>
    );
  }
  function onClickCheckBox({
    number,
    handleSelectedCheckboxStatus,
  }: {
    number: string;
    handleSelectedCheckboxStatus: (status: boolean[]) => void;
  }) {
    setLastSelectedSectionInfo([chartInfo, undefined]);

    new Promise<any>((resolve) => {
      setSelectedItemList((prev: Map<number, SelectedItemInfo>) => {
        let currentChartInfo = prev.get(chartInfo.index);
        let newChartInfo: any = {
          index: currentChartInfo?.index,
          matrix: currentChartInfo?.matrix,
          checkbox: currentChartInfo?.checkbox,
        };

        if (!!currentChartInfo) {
          if (number === ChartBoxNumber.KeyOne) {
            newChartInfo.checkbox = [
              !newChartInfo.checkbox[0],
              newChartInfo.checkbox[1],
            ];
          } else if (number === ChartBoxNumber.KeyTwo) {
            newChartInfo.checkbox = [
              newChartInfo.checkbox[0],
              !newChartInfo.checkbox[1],
            ];
          }
        } else {
          if (number === ChartBoxNumber.KeyOne) {
            chartInfo.checkbox = [true, false];
            newChartInfo = chartInfo;
          } else if (number === ChartBoxNumber.KeyTwo) {
            chartInfo.checkbox = [false, true];
            newChartInfo = chartInfo;
          }
        }

        if (!isCoupletEctopicTypeClicked) {
          newChartInfo.checkbox = [newChartInfo.checkbox[0]];
        } else {
          newChartInfo.checkbox = [
            newChartInfo.checkbox[0],
            newChartInfo.checkbox[1],
          ];
        }

        const hasSomeTrue = newChartInfo.checkbox.some(
          (v: boolean) => v === true
        );

        if (hasSomeTrue) {
          resolve(newChartInfo);
          return new Map([...prev, [chartInfo.index, newChartInfo]]);
        } else {
          const newState = new Map(prev);
          newState.delete(chartInfo.index);
          resolve(newChartInfo);
          return newState;
        }
      });
    }).then((newChartInfo) => {
      // 체크박스 클릭 시 shapeReviewState.selectedCheckboxStatus redux 상태값 변경 redux func
      if (typeof handleSelectedCheckboxStatus === 'function') {
        handleSelectedCheckboxStatus(newChartInfo.checkbox);
      }
    });
  }
}

export default React.forwardRef(ChartFragment);

// export default React.memo(React.forwardRef(ChartFragment), (prev, next) => {
//   return (
//     prev.isSelected === next.isSelected &&
//     prev.isCheckedFirst === next.isCheckedFirst &&
//     prev.isCheckedSecond === next.isCheckedSecond
//   );
// });
