import { useLocation } from 'react-router';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import {
  REPORT_SECTION,
  REPORT_SECTION_TITLE,
  REPORT_EVENT_INFO_ITEM_TYPES,
} from 'constant/EventConst';
import { TAB_VALUE_OBJECT } from 'constant/Const';
import { getAmplitudeLabelByRate } from 'constant/ChartEditConst';

import DateUtil from 'util/DateUtil';

import useGetReportEventEditorFunc from 'component/hook/useGetReportEventEditorFunc';

import TypeContent from 'component/fragment/test-result/side-panel/TypeContent';
import { ColumnItemListWrapper } from 'component/fragment/test-result/side-panel/CommonUi';
import OverallTogo from 'component/fragment/test-result/side-panel/OverallTogo';

import { ReactComponent as ReportIcon } from 'static/icon/icon-report.svg';

/**
 * @typedef ReportInfoViewerProps
 * @prop {string} eventType
 * @prop {ReportEventInfo} data 리포트 이벤트 정보
 * @prop {boolean} isEditable
 * @prop {() => *} openNewTabHandler
 *
 */
/**
 *
 * @param {ReportInfoViewerProps} props
 * @returns
 */
function ReportInfoViewer(props) {
  const {
    //
    eventType,
    data,
    isEditable,
    openNewTabHandler,
  } = props;

  const {
    amplitudeRate,
    reportSection,
    annotation,
    mainRepresentativeInfo,
    subRepresentativeInfo,
    stripMs,
  } = data || {};

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const tabValue = urlSearchParams.get('tab');

  return (
    <ReportContentWrapper isEditable={isEditable}>
      <ReportSectionTitleWrapper>
        <ReportIcon />
        {REPORT_SECTION_TITLE[reportSection]}
      </ReportSectionTitleWrapper>
      {reportSection !== REPORT_SECTION.PTE && (
        <GeneralInfo
          amplitudeRate={mainRepresentativeInfo?.amplitudeRate ?? amplitudeRate}
          annotation={annotation}
          stripMs={mainRepresentativeInfo?.stripMs || stripMs}
          tabValue={tabValue}
        />
      )}
      {reportSection === REPORT_SECTION.PTE && (
        <PTEInfo
          ninetySecStripMs={mainRepresentativeInfo?.stripMs}
          tenSecStripMs={
            subRepresentativeInfo.isRemoved
              ? null
              : subRepresentativeInfo.stripMs
          }
        />
      )}
      {/* 반드시 마지막 자식으로 있어야 함 */}
      <ReportInfoViewerMenu
        eventType={eventType}
        data={data}
        openNewTabHandler={openNewTabHandler}
        tabValue={tabValue}
      />
    </ReportContentWrapper>
  );
}

// ReportInfoViewer's Styled Components
const ReportContentWrapper = styled(ColumnItemListWrapper)`
  position: relative;
  margin: 8px 0;
  padding: 8px;

  border-radius: 6px;
  background-color: ${(props) => props.theme.color.COOL_GRAY_20};
  & > *:last-child {
    visibility: hidden;
  }
  ${(props) =>
    props.isEditable &&
    `
    :hover {
      background-color: ${props.theme.color.COOL_GRAY_30};
      & > *:last-child {
        visibility: visible};
      }
    }
    `}
`;

const ReportSectionTitleWrapper = styled.div`
  margin-bottom: 8px;
  gap: 4px;

  display: flex;
  align-items: center;

  & svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${(props) => props.theme.color.COOL_GRAY_80};
    }
  }

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: ${(props) => props.theme.color.COOL_GRAY_90};
`;

// ReportInfoViewer's Functions

// ReportInfoViewer's Sub Components
/**
 * @typedef ReportInfoViewerMenuProps
 * @prop {string} eventType
 * @prop {ReportEventInfo} data 리포트 이벤트 정보
 * @prop {boolean} isEditable
 * @prop {() => *} openNewTabHandler
 * @prop {string} tabValue
 */
/**
 *
 * @param {ReportInfoViewerProps} props
 * @returns
 */
function ReportInfoViewerMenu(props) {
  const {
    //
    eventType,
    data,
    openNewTabHandler,
    tabValue,
  } = props;

  const { reportEventId, reportSection } = data;

  const intl = useIntl();
  const { startReportEventEditor, deleteReportEvent } =
    useGetReportEventEditorFunc();

  return (
    <MenuWrapper>
      {tabValue !== TAB_VALUE_OBJECT.HR_REVIEW && (
        <MenuItem
          onClick={() => {
            if (tabValue === TAB_VALUE_OBJECT.EVENT_REVIEW) {
              startReportEventEditor(eventType, data);
            } else {
              openNewTabHandler();
            }
          }}>
          {intl.formatMessage({
            id: '11-ReportInfoViewer-MenuItem-title-edit',
            description: '수정',
            defaultMessage: '수정',
          })}
        </MenuItem>
      )}
      <MenuItem
        isRed
        onClick={() => deleteReportEvent(reportEventId, reportSection)}>
        {intl.formatMessage({
          id: '11-ReportInfoViewer-MenuItem-title-delete',
          description: '삭제',
          defaultMessage: '삭제',
        })}
      </MenuItem>
    </MenuWrapper>
  );
}

// ReportInfoViewerMenu's Styled Components
const MenuWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 4px;
  gap: 2px;

  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.COOL_GRAY_50};
  background: ${(props) => props.theme.color.WHITE};
  /* Dialogue Shadow */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14);

  display: flex;
`;
const MenuItem = styled.div`
  padding: 5px 8px;

  border-radius: 4px;
  background: ${(props) => props.theme.color.WHITE};
  :hover {
    background: ${(props) => props.theme.color.COOL_GRAY_30};
  }

  cursor: pointer;

  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 14.3px */
  color: ${(props) =>
    props.isRed ? props.theme.color.RED_70 : props.theme.color.COOL_GRAY_90};
`;

/**
 * Notable Rhythm Strips, Detail Sections
 * @param {{annotation: string, stripMs: number, tabValue: string}} props
 * @returns
 */
function GeneralInfo(props) {
  const { amplitudeRate, annotation, stripMs, tabValue } = props;
  const stripString = DateUtil.formatFullDateTime(new Date(stripMs));

  return (
    <>
      <TypeContent
        type={REPORT_EVENT_INFO_ITEM_TYPES.STRIP_TITLE}
        content={annotation}
        isFreeHeightContent={true}
      />

      <TypeContent type={'Strip'} content={stripString}>
        {tabValue === TAB_VALUE_OBJECT.EVENT_REVIEW && (
          <OverallTogo selectedTimeMs={stripMs} />
        )}
      </TypeContent>

      <TypeContent
        type={'Amplitude'}
        content={`${getAmplitudeLabelByRate(amplitudeRate)}mm/mV`}
      />
    </>
  );
}

/**
 * Patient Events
 * @param {{ninetySecStripMs: number, tenSecStripMs: number | null}} props
 * @returns
 */
function PTEInfo(props) {
  const {
    //
    ninetySecStripMs,
    tenSecStripMs,
  } = props;
  const tenSecStripString = tenSecStripMs
    ? DateUtil.formatFullDateTime(new Date(tenSecStripMs))
    : 'Not Added';
  const ninetySecStripString = DateUtil.formatFullDateTime(
    new Date(ninetySecStripMs)
  );

  return (
    <>
      <TypeContent
        type={REPORT_EVENT_INFO_ITEM_TYPES.TEN_STRIP}
        content={tenSecStripString}>
        {tenSecStripMs && <OverallTogo selectedTimeMs={tenSecStripMs} />}
      </TypeContent>
      <TypeContent
        type={REPORT_EVENT_INFO_ITEM_TYPES.NINETY_STRIP}
        content={ninetySecStripString}>
        <OverallTogo selectedTimeMs={ninetySecStripMs} />
      </TypeContent>
    </>
  );
}

// ReportInfoViewer's Types
/**
 * @typedef ReportEventInfo 리포트 이벤트 타입
 * @prop {string} reportEventId
 * @prop {string} reportSection
 * @prop {string} [annotation]
 * @prop {object} mainRepresentativeInfo Report Event 의 대표 Strip 정보
 * @prop {number} mainRepresentativeInfo.stripMs
 * @prop {number} mainRepresentativeInfo.representativeOnsetIndex
 * @prop {number} mainRepresentativeInfo.representativeTerminationIndex
 * @prop {number} mainRepresentativeInfo.amplitudeRate
 * @prop {boolean} [mainRepresentativeInfo.isRemoved]
 * @prop {object} [subRepresentativeInfo] Report 중 PTE Section 의 (10초 길이)보조 Strip 정보
 * @prop {number} subRepresentativeInfo.stripMs
 * @prop {number} subRepresentativeInfo.representativeOnsetIndex
 * @prop {number} subRepresentativeInfo.representativeTerminationIndex
 * @prop {number} subRepresentativeInfo.amplitudeRate
 * @prop {boolean} subRepresentativeInfo.isRemoved
 * @prop {string} [subRepresentativeInfo.reportEventId]
 */

export default ReportInfoViewer;
