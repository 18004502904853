import { optimisticEventDataUpdateForTimeEventParam } from '@type/optimisticUpdate/type';

import { Command } from '../../eventUpdateCmdPattern';
import { OptimisticEventDataUpdateForNormal } from '../optimisticEventDataUpdateForNormal';

export class PostOthers extends OptimisticEventDataUpdateForNormal {
  result: any;
  reqParam: any;

  constructor(reqParam: any) {
    super();
    this.reqParam = reqParam;
  }

  *optimisticEventDataUpdate({
    updateReqOption,
  }: optimisticEventDataUpdateForTimeEventParam) {
    yield this._validation({ updateReqOption });
    yield this.updateLogic({ updateReqOption });
  }

  _validation({ updateReqOption }: optimisticEventDataUpdateForTimeEventParam) {
    this.validation();
  }

  *updateLogic({
    updateReqOption,
  }: optimisticEventDataUpdateForTimeEventParam) {
    yield* this.optimisticEventData({
      updateReqOption,
    });
  }

  getResult() {
    return this.result;
  }
}

// ### COMMAND 역할
export class PostOthersCommand {
  command: any;
  executeInst: any;

  constructor(value: any) {
    this.command = new Command(PostOthers, null, value);
  }

  *execute() {
    this.executeInst = new this.command.executeClass(this.command.value);
    yield this.executeInst.optimisticEventDataUpdate(this.command.value);
  }

  getResult() {
    return this.executeInst.getResult();
  }
}
