import { useEffect, useState } from 'react';

function useManagedClickEvent(clickCallback, doubleClickCallback, delay = 250) {
  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (clicks === 1) clickCallback();
      setClicks(0);
    }, delay);

    if (clicks === 2) doubleClickCallback();

    return () => clearTimeout(timer);
  }, [clicks]);

  return () => setClicks((prev) => prev + 1);
}

export default useManagedClickEvent;
