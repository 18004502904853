import styled, { useTheme } from 'styled-components';

import { ComponentId } from 'constant/ComponentId';
import { TEN_SEC_STRIP_DETAIL } from 'constant/ChartEditConst';

import TenSecStripDetailAddReportHeaderContainer from 'redux/container/fragment/test-result/share/tenSecStripDetail/TenSecStripDetailAddReportHeaderContainer';
import TenSecStripDetailHeaderContainer from 'redux/container/fragment/test-result/share/tenSecStripDetail/TenSecStripDetailHeaderContainer';
import TenSecStripDetailChartContainer from 'redux/container/fragment/test-result/share/tenSecStripDetail/TenSecStripDetailChartContainer';

import ProgressFragment from 'component/fragment/ProgressFragment';

const TenSecStripDetailWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  border-bottom: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  & > * {
    :not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
    }
  }
  background-color: ${(props) => props.theme.color.WHITE};
`;

function TenSecStripDetail(props) {
  const {
    tenSecStripDetailChartInstRef,
    chartOption,
    setChartOption,
    selectedBeatBtnInfoList,
    setSelectedBeatBtnInfoList,
    selectedBeatOption,
    setSelectedBeatOption,
    initTenSecStripDetail,
    tenSecStripTabValue,
    tenSecStripDetail,
  } = props;

  const theme = useTheme();

  const { pending, ecgRaw } = tenSecStripDetail || {};
  const isReportRepresentativeStripChangeMode =
    chartOption.isReportRepresentativeStripChangeMode;

  return (
    <TenSecStripDetailWrapper data-cid={ComponentId.TEN_SEC_STRIP_DETAIL}>
      {isReportRepresentativeStripChangeMode ? (
        <TenSecStripDetailAddReportHeaderContainer />
      ) : (
        <TenSecStripDetailHeaderContainer
          chartOption={chartOption}
          setChartOption={setChartOption}
          selectedBeatBtnInfoList={selectedBeatBtnInfoList}
          selectedBeatOption={selectedBeatOption}
          initTenSecStripDetail={initTenSecStripDetail}
        />
      )}
      {/* todo: jyoon - 확인 필요 */}
      {/* (pending || ecgRaw?.length === 0) &&
      tenSecStripTabValue === TEN_SEC_STRIP_DETAIL.TAB.HR_REVIEW && */}
      {pending && tenSecStripTabValue === TEN_SEC_STRIP_DETAIL.TAB.HR_REVIEW && (
        <ProgressFragment
          style={{
            position: 'absolute',
            backgroundColor: theme.color.WHITE_DIM,
            zIndex: 10,
          }}
        />
      )}
      <TenSecStripDetailChartContainer
        ref={tenSecStripDetailChartInstRef}
        tenSecStripTabValue={tenSecStripTabValue}
        chartOption={chartOption}
        setChartOption={setChartOption}
        selectedBeatBtnInfoList={selectedBeatBtnInfoList}
        setSelectedBeatBtnInfoList={setSelectedBeatBtnInfoList}
        selectedBeatOption={selectedBeatOption}
        setSelectedBeatOption={setSelectedBeatOption}
        initTenSecStripDetail={initTenSecStripDetail}
      />
    </TenSecStripDetailWrapper>
  );
}

export default TenSecStripDetail;
