import styled from 'styled-components';

const defaultStyle = {
  position: {
    top: '-3px',
    right: '-3px',
  },
};

const NotificationBadgeIcon = styled.div`
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  background-color: ${(props) => props.theme.color.RED_70};
  top: ${(props) => props.position?.top ?? defaultStyle.position.top};
  right: ${(props) => props.position?.right ?? defaultStyle.position.right};
`;

export default function NotificationBadge(props) {
  const { style, position /* hoverColor */ } = props;

  return (
    <NotificationBadgeIcon
      style={style}
      position={position} /* hoverColor={hoverColor} */
    />
  );
}
