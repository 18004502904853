import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { CONTEXT_MENU } from 'constant/EventConst';
import { ComponentId } from 'constant/ComponentId';

import { getContextMenuBeatTypeList } from 'util/EventConstUtil';

import MenuItem from 'component/ui/menu/MenuItem';
import Divider from 'component/ui/divider/Divider';
import ListItemText from 'component/ui/menu/ListItemText';
import { MenuBase } from 'component/ui/menu/Menu';

const ItemContainer = styled.div`
  min-width: 200px;
  padding: 4px;
  position: absolute;

  ${({ position: { positionX, positionY } }) => {
    return `
    top: ${positionY}px;
    left: ${positionX}px;
  `;
  }}

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  border: solid 1px #c7ced2;
  background-color: #ffffff;
  animation: 0.2s ease-in-out;
  transform-origin: top;
  -webkit-transition: -webkit-visibility 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1002;

  ${({ clickFlagRef, rePositionInfo: { top, right, bottom, left } }) => {
    let result = 'transform:';
    if (top) result += ` translateY(100%)`;
    if (right) result += ` translateX(-100%)`;
    if (bottom) result += ` translateY(-100%)`;
    if (left) result += ` translateX(100%)`;
    return result;
  }}
`;

function BeatEditContextmenu(props) {
  const intl = useIntl();
  const {
    // props
    beatContextmenuPositionSetting: {
      beatContextmenuPosition: { positionX, positionY },
    },
    isOpenBeatContextmenuSetting: { isOpenBeatContextmenu },
    // state
    theme,
    wrapperRef,
    clickFlagRef,
    dragFlag,
    // useState
    repositionInfoState: { rePositionInfo, setRePositionInfo },
    // event handler
    onClick,
  } = props;

  if (!isOpenBeatContextmenu) return null;

  if (dragFlag) {
    return (
      <ItemContainer
        className="BeatEditContextmenu"
        data-cid={ComponentId.BEAT_EDIT_CONTEXTMENU}
        ref={wrapperRef}
        position={{ positionX, positionY }}
        rePositionInfo={rePositionInfo}
        clickFlagRef={clickFlagRef.current}>
        <MenuBase>
          <MenuItem
            style={{ padding: '3px 12px 5px' }}
            key={'header-1'}
            type={'header'}
            disabled={true}
            title={intl.formatMessage({
              id: '11-ArrhythmiaEditContextmenu-MenuItem-title-header',
              description: '선택 구간을 다음으로 편집',
              defaultMessage: '선택 구간을 다음으로 편집',
            })}
          />
          <Divider />
          {getContextMenuBeatTypeList().map(
            ({ index, value, label, shortcut }) => {
              return (
                <ContextMenuItem
                  key={index}
                  label={label}
                  shortcut={shortcut}
                  style={{ padding: '6px 16px' }}
                  color={theme.color.MEDIUM_DARK}
                  onClick={() => onClick(value)}
                />
              );
            }
          )}
          <Divider />
          <MenuItem
            key={'header-2'}
            style={{ padding: '5px 12px 5px' }}
            type={'header'}
            disabled={true}
            title={intl.formatMessage({
              id: '11-ArrhythmiaEditContextmenu-MenuItem-title-header-2',
              description: '선택 구간의 Beat를 제거',
              defaultMessage: '선택 구간의 Beat를 제거',
            })}
          />
          <Divider />

          <MenuItem
            style={{ padding: '5px 16px' }}
            onClick={() => onClick(CONTEXT_MENU.REMOVE.value)}>
            <ListItemText>{CONTEXT_MENU.REMOVE.label}</ListItemText>
            <ListItemText
              textColor={theme.color.MEDIUM_DARK}
              style={{
                textDecoration: 'underline',
                textUnderlinePosition: 'under',
              }}>
              {CONTEXT_MENU.REMOVE.shortcut}
            </ListItemText>
          </MenuItem>
        </MenuBase>
      </ItemContainer>
    );
  }

  return (
    <ItemContainer
      className="BeatEditContextmenu"
      data-cid={ComponentId.BEAT_EDIT_CONTEXTMENU}
      ref={wrapperRef}
      position={{ positionX, positionY }}
      rePositionInfo={rePositionInfo}
      clickFlagRef={clickFlagRef.current}>
      {getContextMenuBeatTypeList().map(({ index, value, label, shortcut }) => {
        return (
          <ContextMenuItem
            key={index}
            label={label}
            shortcut={shortcut}
            style={{ padding: '10px 16px' }}
            color={theme.color.MEDIUM_DARK}
            onClick={() => onClick(value)}
          />
        );
      })}
    </ItemContainer>
  );
}

function ContextMenuItem({ style, onClick, label, color, shortcut }) {
  return (
    <MenuItem style={style} onClick={onClick}>
      <ListItemText>{`${label}`}</ListItemText>
      <ListItemText
        textColor={color}
        style={{
          textDecoration: 'underline',
          textUnderlinePosition: 'under',
        }}>
        {shortcut}
      </ListItemText>
    </MenuItem>
  );
}

export default BeatEditContextmenu;
