import { useIntl } from 'react-intl';
import { TEN_SEC_STRIP_DETAIL, ECG_CHART_UNIT } from 'constant/ChartEditConst';
import { getStringFloat } from 'util/NumberUtil';
import { selectTenSecStripDuckMap } from 'util/selectTenSecDuckMap';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import TenSecStripDetailAddReportHeader from 'component/fragment/test-result/share/tenSecStripDetail/TenSecStripDetailAddReportHeader';

const TEN_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX;

function TenSecStripDetailAddReportHeaderContainer(props) {
  const intl = useIntl();

  const { representativeTerminationIndex, representativeOnsetIndex } =
    useShallowEqualSelector(
      (state) => state.testResultReducer.eventReview.representativeStripInfo
    );

  const { hrAvg } = useShallowEqualSelector(
    (state) =>
      selectTenSecStripDuckMap(state)[TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW]
  );

  const representativeDurationSecHalf =
    (((representativeTerminationIndex - representativeOnsetIndex) /
      TEN_SEC_WAVEFORM_LENGTH) *
      10) /
    2;

  const avgHrValue = `${getStringFloat(hrAvg)} bpm`;

  const selectStripHelpText = intl.formatMessage(
    {
      id: '11-ReportAnnotationInput-StripHelpText-child-02',
      description:
        '선택한 시간을 기준으로 전, 후 5초의 Strip이 리포트에 담깁니다. / 5 seconds before and after the clicked point of this event will be included in the report.',
      defaultMessage:
        '선택한 시간을 기준으로 전, 후 {radius}초의 Strip이 리포트에 담깁니다.',
    },
    { radius: representativeDurationSecHalf }
  );

  return (
    <TenSecStripDetailAddReportHeader
      selectStripHelpText={selectStripHelpText}
      avgHrValue={avgHrValue}
    />
  );
}

export default TenSecStripDetailAddReportHeaderContainer;
