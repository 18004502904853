import {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  createElement,
} from 'react';
import styled from 'styled-components';

import Const from 'constant/Const';

import NumberUtil from 'util/NumberUtil';

const PARENT_WIDTH = 190; // 225 - 17 - 18
const GAP = 8;
const CLASS_NAME_SIBLING = 'position-input-sibling';

function PositionInput(props, ref) {
  const {
    children,
    //
    style,
    value,
    maxValue,
    helpText,
    onChange,
  } = props;

  const stringValue = NumberUtil.threeDigitComma(value);
  const stringMaxValue = NumberUtil.threeDigitComma(maxValue);

  const currentValueRef = useRef(value);
  const positionRef = useRef(null);
  const positionInputRef = useRef(null);

  const [isEdit, setIsEdit] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [inEditValue, setInEditValue] = useState(value);
  /** Position 요소의 형제 요소(Sort Dropdown 요소)의 너비 */
  const [siblingWidth, setSiblingWidth] = useState(0);

  const onChangeInputValueHandler = (event) => {
    const {
      target: { value },
    } = event;

    let inputValue = value;
    if (event.nativeEvent.data === '/') {
      inputValue = value.replace(/\//g, '');
    }

    const newPosition = Number(inputValue);
    if (inputValue === '') {
      setInEditValue('');
      return;
    }
    if (isNaN(newPosition)) {
      setInEditValue(currentValueRef.current);
      return;
    }
    if (newPosition < maxValue) {
      setInEditValue(newPosition);
    } else {
      setInEditValue(maxValue);
    }
  };

  const savePosition = () => {
    setIsEdit(false);
    setIsHover(false);
    if (inEditValue === '' || inEditValue === 0) {
      setInEditValue(value);
    } else {
      onChange(inEditValue);
    }
  };

  const onKeyDown = (e) => {
    // 값 편집 중 비의도적인 다른 키보드 액션 실행 방지
    e.stopPropagation();
    if (e.key === Const.KEY_MAP.ENTER) {
      savePosition();
    }
  };

  useImperativeHandle(ref, () => ({
    getInputRef: () => {
      setIsEdit(true);
      return positionInputRef.current;
    },
  }));

  useEffect(() => {
    setInEditValue(value);
  }, [value]);

  /*
  Position Input 의 형제 요소(Sort Order Dropdown UI) 너비 값 확인
   */
  useEffect(() => {
    const targetElement = document.querySelector(`.${CLASS_NAME_SIBLING}`);
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        setSiblingWidth(width);
      }
    });

    resizeObserver.observe(targetElement);

    // cleanup function
    return () => {
      resizeObserver.unobserve(targetElement);
      resizeObserver.disconnect();
    };
  }, []);

  const $childComponent =
    children &&
    createElement(children.type, {
      ...children.props,
      abbreviated: isHover || isEdit,
    });

  return (
    <Wrapper style={style}>
      <PositionWrapper
        ref={positionRef}
        siblingWidth={siblingWidth}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        <EditablePosition isInputMode={isHover || isEdit}>
          {stringValue}
        </EditablePosition>
        <InputPosition
          ref={positionInputRef}
          type="text"
          pattern="[0-9]*"
          min={1}
          max={maxValue}
          maxLength={maxValue ? maxValue.toString().length : 1}
          value={inEditValue}
          isHover={isHover}
          isInputMode={isHover || isEdit}
          onChange={onChangeInputValueHandler}
          onClick={() => {
            positionInputRef.current?.select();
            setIsEdit(true);
          }}
          onBlur={() => {
            setIsEdit(false);
            setInEditValue(value);
          }}
          onKeyDown={onKeyDown}
        />
        <EllipsisWrapper>
          <Text>{`of ${stringMaxValue}`}</Text>
        </EllipsisWrapper>
      </PositionWrapper>
      <PositionTag className={CLASS_NAME_SIBLING}>
        {$childComponent ?? helpText}
      </PositionTag>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  gap: ${GAP}px;
  justify-content: space-between;
`;

const PositionWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.color.COOL_GRAY_90};
  display: flex;

  // Ellipsis 스타일링에 필요
  align-items: center;
  ${(props) => {
    if (!props.siblingWidth) {
      return ``;
    }
    const maxWidth = PARENT_WIDTH - props.siblingWidth - GAP;
    return `max-width: ${maxWidth}px;`;
  }}
`;

const EllipsisWrapper = styled.div`
  margin-left: 4px;

  // Ellipsis 스타일링에 필요
  align-self: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Text = styled.span`
  // Ellipsis 스타일링에 필요
  vertical-align: super;
`;

const EditablePosition = styled.span`
  display: ${({ isInputMode }) => (!isInputMode ? 'block' : 'none')};
  ${({ isHover, theme }) =>
    isHover && `cursor:'pointer; border:1px solid ${theme.color.MEDIUM_LIGHT}`};
`;

const PositionTag = styled.div`
  display: flex;

  height: 24px;

  font-weight: 500;
  font-size: 10px;
  align-items: center;
  color: ${({ theme }) => theme.color.MEDIUM_DARK};
`;

const InputPosition = styled.input`
  display: ${({ isInputMode }) => (isInputMode ? 'block' : 'none')};
  width: ${({ maxLength }) => `${maxLength * 10}px`};
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  height: 16px;
  border: ${({ theme, isHover }) =>
    `1px solid ${isHover ? theme.color.MEDIUM_LIGHT : theme.color.BLUE}`};
  border-radius: 5px;
  transition: box-shadow 0.2s ease-in-out;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

export default forwardRef(PositionInput);
