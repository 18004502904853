import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import FormsPanelFooterFragment, {
  IFormsPanelFooterFragmentProps,
} from 'component/fragment/test-result/shape-review/FormsPanel/FormsPanelFooterFragment';

import { selectFormTotalCount } from 'redux/duck/shapeReviewDuck';

function FormsPanelFooterFragmentContainer() {
  const { totalEventsCount, totalFormsCount } = useShallowEqualSelector(
    selectFormTotalCount
  ) as IFormsPanelFooterFragmentProps;

  return (
    <FormsPanelFooterFragment
      totalEventsCount={totalEventsCount}
      totalFormsCount={totalFormsCount}
    />
  );
}

export default FormsPanelFooterFragmentContainer;
