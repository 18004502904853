import React, { useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import AppColors from 'theme/AppColors';

function ScaleProgress(props) {
  return (
    <ScaleLoader
      color={props.color || AppColors.PRIMARY}
      loading={true}
      width={props.width || 8}
      height={props.height || 40}
      radius={props.radius || 4}
      margin={props.margin || 2}
    />
  );
}

export default ScaleProgress;
