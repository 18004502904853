import { useEffect, useState } from 'react';

import { ShapeReviewSectionArea } from 'constant/ShapeReviewConst';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import {
  selectFormPanelDetailOfFirstIndexOfLastSelectedSection,
  selectPaginationInfo,
  selectSelectedEventType,
} from 'redux/duck/shapeReviewDuck';

import { Pagination } from '../ShapeReviewFragmentContainer';
import FormsEventsPanelNavigationFragmentContainer from '../share/FormsEventsPanelNavigationFragmentContainer';

function EventsPanelNavigationFragmentContainer({
  checkBoxStatus,
  setCheckBoxStatus,
}: any) {
  const selectedEventType: any = useShallowEqualSelector(
    selectSelectedEventType
  );
  const firstSelectedInfoOfFormPanel: any = useShallowEqualSelector(
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection
  );
  const paginationInfo: any = useShallowEqualSelector(selectPaginationInfo);

  const panelPaginationInfoOfSelectedEvent =
    paginationInfo[ShapeReviewSectionArea.EVENTS][selectedEventType]?.[
      firstSelectedInfoOfFormPanel?.formInfo.id
    ];

  const navigationOption = { type: ShapeReviewSectionArea.EVENTS };
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 0,
  });

  // setting currentPage
  useEffect(() => {
    const isSelectedEventType = !selectedEventType;
    if (isSelectedEventType || !panelPaginationInfoOfSelectedEvent) return;

    setPagination({
      currentPage: panelPaginationInfoOfSelectedEvent.currentPage,
    });
  }, [selectedEventType, panelPaginationInfoOfSelectedEvent]);

  useEffect(() => {
    if (!panelPaginationInfoOfSelectedEvent) {
      setPagination({ currentPage: 0 });
    }
  }, [panelPaginationInfoOfSelectedEvent]);

  return (
    <FormsEventsPanelNavigationFragmentContainer
      // props state
      checkBoxStatus={checkBoxStatus}
      setCheckBoxStatus={setCheckBoxStatus}
      // local state
      navigationOption={navigationOption}
      pagination={pagination}
      setPagination={setPagination}
      // redux state
      panelPaginationInfoOfSelectedEvent={panelPaginationInfoOfSelectedEvent}
    />
  );
}

export default EventsPanelNavigationFragmentContainer;
