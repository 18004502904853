import { call, put, select } from 'redux-saga/effects';
import rfdc from 'rfdc';

import ApiManager from 'network/ApiManager';

import {
  patchBeatsByWaveformIndexSucceed,
  setIsArrangeRequired,
} from 'redux/duck/shapeReviewDuck';
import { selectEcgTestId } from 'redux/duck/beatReviewDuck';

import { UpdateEventDataParam } from '../beatReview/beatReview_EventUpdate';

export class ShapeReview {
  tabType = 'ShapeReview';
  rfdcClone;
  callStack: UpdateEventDataParam[];
  constructor(param: any) {
    this.rfdcClone = rfdc();
    this.callStack = [];
  }
  *patch({
    dataForOptimisticEventDataUpdate,
    isOptimisticEventDataUpdate,
    reqBody,
    filterSelectedItemListOfEventPanel,
  }: {
    dataForOptimisticEventDataUpdate: any;
    isOptimisticEventDataUpdate: any;
    reqBody: any;
    filterSelectedItemListOfEventPanel: any;
  }) {
    const { waveformIndex: succeedWaveformIndexes, beatType: succeedBeatType } =
      dataForOptimisticEventDataUpdate;

    // API에 수정 요청했지만 실패한 WI List (AF => S 변경했거나, 삭제된 WI를 편집 요청한 경우) 실패처리
    const failedWaveformIndexes = reqBody.waveformIndexes.filter(
      (v: number) => !succeedWaveformIndexes.includes(v)
    );

    let updatedWaveformIndexMap: { [key: number]: any } = {};

    for (let onset of filterSelectedItemListOfEventPanel.onset) {
      const waveformIndexes = onset.beats.waveformIndex;
      const originIndex = onset.originWaveformIndex;
      const onsetWaveformIndex =
        waveformIndexes[waveformIndexes.indexOf(originIndex)];
      const terminationWaveformIndex =
        waveformIndexes[waveformIndexes.indexOf(originIndex) + 1];

      if (
        succeedWaveformIndexes.includes(onsetWaveformIndex) &&
        !failedWaveformIndexes.includes(terminationWaveformIndex)
      ) {
        updatedWaveformIndexMap[originIndex] = [onsetWaveformIndex];
      } else {
        delete updatedWaveformIndexMap[originIndex];
      }
    }

    for (let termination of filterSelectedItemListOfEventPanel.termination) {
      const waveformIndexes = termination.beats.waveformIndex;
      const originIndex = termination.originWaveformIndex;
      const onsetWaveformIndex =
        waveformIndexes[waveformIndexes.indexOf(originIndex)];
      const terminationWaveformIndex =
        waveformIndexes[waveformIndexes.indexOf(originIndex) + 1];

      if (
        succeedWaveformIndexes.includes(terminationWaveformIndex) &&
        !failedWaveformIndexes.includes(onsetWaveformIndex)
      ) {
        updatedWaveformIndexMap[originIndex] = [
          ...(updatedWaveformIndexMap[originIndex] ?? []),
          terminationWaveformIndex,
        ];
      } else {
        delete updatedWaveformIndexMap[originIndex];
      }
    }

    yield put(
      patchBeatsByWaveformIndexSucceed({
        isOptimisticEventDataUpdate,
        updatedWaveformIndexMap,
        resBeatType: reqBody.beatType,
        responseValidationResult: {
          succeedWaveformIndexes,
          failedWaveformIndexes,
        },
      })
    );
    yield put(setIsArrangeRequired({ isArrangeRequired: true }));
  }
  *postPatch({
    beatUpdateResponseData,
    isOptimisticEventDataUpdate,
    reqBody,
    filterSelectedItemListOfEventPanel,
  }: {
    beatUpdateResponseData: any;
    isOptimisticEventDataUpdate: any;
    reqBody: any;
    filterSelectedItemListOfEventPanel: any;
  }) {
    const {
      result: {
        waveformIndex: succeedWaveformIndexes,
        beatType: succeedBeatType,
      },
    } = beatUpdateResponseData;

    // API에 수정 요청했지만 실패한 WI List (AF => S 변경했거나, 삭제된 WI를 편집 요청한 경우) 실패처리
    const failedWaveformIndexes = reqBody.waveformIndexes.filter(
      (v: number) => !succeedWaveformIndexes.includes(v)
    );

    let updatedWaveformIndexMap: { [key: number]: any } = {};
    if (!isOptimisticEventDataUpdate && failedWaveformIndexes.length === 0)
      return;

    for (let onset of filterSelectedItemListOfEventPanel.onset) {
      const waveformIndexes = onset.beats.waveformIndex;
      const originIndex = onset.originWaveformIndex;
      const onsetWaveformIndex =
        waveformIndexes[waveformIndexes.indexOf(originIndex)];

      if (failedWaveformIndexes.includes(onsetWaveformIndex)) {
        updatedWaveformIndexMap[originIndex] = [onsetWaveformIndex];
      }
    }

    for (let termination of filterSelectedItemListOfEventPanel.termination) {
      const waveformIndexes = termination.beats.waveformIndex;
      const originIndex = termination.originWaveformIndex;
      const terminationWaveformIndex =
        waveformIndexes[waveformIndexes.indexOf(originIndex) + 1];

      if (failedWaveformIndexes.includes(terminationWaveformIndex)) {
        updatedWaveformIndexMap[originIndex] = [
          ...(updatedWaveformIndexMap[originIndex] ?? []),
          terminationWaveformIndex,
        ];
      }
    }

    const ecgTestId: string = yield select(selectEcgTestId);
    const chartSampleSize = 2500; // 10초차트 에서 사용하기 위해 2500개 호출
    const result: {
      data: {
        results: any[];
      };
    } = yield call(ApiManager.getBeatsFilterWaveformIndexWithSampleSize, {
      ecgTestId,
      waveformIndexes: failedWaveformIndexes,
      sampleSize: chartSampleSize,
      withRegisteredStrip: false,
      withRaw: true,
      axiosSource: null,
      signal: null,
    });

    let resBeatType: { [key: number]: any } = {};
    for (let item of result.data.results) {
      resBeatType[item.originWaveformIndex] = item.beatType;
    }
    yield put(
      patchBeatsByWaveformIndexSucceed({
        isOptimisticEventDataUpdate,
        updatedWaveformIndexMap,
        resBeatType: resBeatType,
        responseValidationResult: {
          succeedWaveformIndexes,
          failedWaveformIndexes,
        },
      })
    );
  }
  // post() {}
  // delete() {}
  error() {}
}
