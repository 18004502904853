import { useIntl } from 'react-intl';

import ReportInfoViewer from 'component/fragment/test-result/side-panel/ReportsInfoWrapper/ReportInfoViewer';

import {
  ColumnItemListWrapper,
  InfoTitle,
  InfoViewerWrapper,
} from '../CommonUi';

/**
 * @typedef ReportsInfoWrapperProps
 * @prop {string} eventType
 * @prop {import('./ReportInfoViewer').ReportEvent[]} reportInfoList
 * @prop {boolean} isReadOnly
 * @prop {() => void} openNewTabHandler
 * @prop {React.ReactNode} children
 */
/**
 *
 * @param {ReportsInfoWrapperProps} props
 * @returns
 */
function ReportsInfoWrapper(props) {
  const {
    eventType,
    reportInfoList = [],
    isReadOnly,
    openNewTabHandler,
    children,
    wrapperStyle,
  } = props;

  const intl = useIntl();

  return (
    <InfoViewerWrapper id="report-info-wrapper" style={wrapperStyle}>
      <InfoTitle>
        {intl.formatMessage({
          id: '11-ReportsInfoWrapper-InfoTitle-child',
          description: '리포트',
          defaultMessage: '리포트',
        })}
      </InfoTitle>
      {children}
      <ColumnItemListWrapper>
        {reportInfoList
          .filter((reportInfo) => !reportInfo.mainRepresentativeInfo?.isRemoved)
          .map((reportInfo) => {
            return (
              <ReportInfoViewer
                key={`${reportInfo.reportEventId}-${reportInfo.mainRepresentativeInfo?.stripMs}`}
                eventType={eventType}
                data={reportInfo}
                isEditable={!isReadOnly}
                openNewTabHandler={openNewTabHandler}
              />
            );
          })}
      </ColumnItemListWrapper>
    </InfoViewerWrapper>
  );
}

export default ReportsInfoWrapper;
