import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SUFFIX_LIST } from 'constant/EventConst';

import {
  getUpdateBeatActionCreatorDuckMap,
  selectTenSecStripDuckMap,
} from 'util/selectTenSecDuckMap';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { BeatType } from '@type/ecgEventType/baseEventType';

interface TenSecStripDetail {
  onsetWaveformIdx: number;
  terminationWaveformIdx: number;
  beatLabelButtonDataList: any[]; // 배열의 요소 타입에 따라 'any' 대신 적절한 타입을 사용하세요.
}

interface BeatInfo {
  // BeatInfo의 속성들을 정의하세요.
  waveformIndex: number;
  beatType: BeatType;
}

function useEditBeatEvent({
  tabType,
  initTenSecStripDetail,
}: {
  tabType: string; // TEN_SEC_STRIP_DETAIL.TAB
  initTenSecStripDetail: () => void;
}) {
  const dispatch = useDispatch();

  // redux selectors
  const tenSecStripDetail = useShallowEqualSelector(
    (state: any) =>
      selectTenSecStripDuckMap(state)[
        tabType as keyof ReturnType<typeof selectTenSecStripDuckMap>
      ]
  );

  // redux action Creators
  const handleAddBeats = useCallback(
    (
      reqBody: object,
      onsetWaveformIndex: number,
      terminationWaveformIndex: number,
      suffix: string,
      tabType: string
    ) => {
      initTenSecStripDetail();
      return dispatch(
        getUpdateBeatActionCreatorDuckMap(
          'post',
          reqBody,
          onsetWaveformIndex,
          terminationWaveformIndex,
          suffix,
          tabType
        )[
          tabType as keyof ReturnType<typeof getUpdateBeatActionCreatorDuckMap>
        ]() as any
      );
    },
    []
  );
  const handleEditBeats = useCallback(
    (
      reqBody: object,
      onsetWaveformIndex: number,
      terminationWaveformIndex: number,
      suffix: string,
      tabType: string
    ) => {
      initTenSecStripDetail();
      return dispatch(
        getUpdateBeatActionCreatorDuckMap(
          'patch',
          reqBody,
          onsetWaveformIndex,
          terminationWaveformIndex,
          suffix,
          tabType
        )[
          tabType as keyof ReturnType<typeof getUpdateBeatActionCreatorDuckMap>
        ]() as any
      );
    },
    []
  );
  const handleDeleteBeats = useCallback(
    (
      reqBody: object,
      onsetWaveformIndex: number,
      terminationWaveformIndex: number,
      suffix: string,
      tabType: string
    ) => {
      initTenSecStripDetail();
      return dispatch(
        getUpdateBeatActionCreatorDuckMap(
          'delete',
          reqBody,
          onsetWaveformIndex,
          terminationWaveformIndex,
          suffix,
          tabType
        )[
          tabType as keyof ReturnType<typeof getUpdateBeatActionCreatorDuckMap>
        ]() as any
      );
    },
    []
  );

  // functions
  function addBeat({
    beatType,
    selectedBeatBtnInfoList,
  }: {
    beatType: string;
    selectedBeatBtnInfoList: BeatInfo[];
  }) {
    const { onsetWaveformIdx, terminationWaveformIdx } =
      tenSecStripDetail as TenSecStripDetail;
    let _selectedBeatBtnWaveformIndexList: number[],
      _onsetWaveformIdxOfTenSecStrip: number,
      _terminationWaveformIdxOfTenSecStrip: number,
      _reqWaveformIndexList: number[],
      reqBody: any,
      suffix: string,
      _tabType: string;

    _selectedBeatBtnWaveformIndexList = selectedBeatBtnInfoList.map(
      (selectedBeatBtnInfoObj) => selectedBeatBtnInfoObj.waveformIndex
    );
    _onsetWaveformIdxOfTenSecStrip = onsetWaveformIdx;
    _terminationWaveformIdxOfTenSecStrip = terminationWaveformIdx;

    _reqWaveformIndexList = [..._selectedBeatBtnWaveformIndexList]
      .filter(Boolean)
      .map(
        (beatWaveformIndex) =>
          beatWaveformIndex + _onsetWaveformIdxOfTenSecStrip
      );

    reqBody = {
      waveformIndexes: _reqWaveformIndexList,
      beatType,
    };
    suffix = SUFFIX_LIST.EDIT_BEAT_SUFFIX.ADD_BEATS;
    _tabType = tabType;

    handleAddBeats(
      reqBody,
      _onsetWaveformIdxOfTenSecStrip,
      _terminationWaveformIdxOfTenSecStrip,
      suffix,
      _tabType
    );
  }
  function editBeat({
    beatType,
    selectedBeatBtnInfoList,
  }: {
    beatType: string;
    selectedBeatBtnInfoList: BeatInfo[];
  }) {
    if (beatType == null) {
      console.error('Invalid beatType:', beatType);
      return;
    }

    const {
      onsetWaveformIdx,
      terminationWaveformIdx,
      beatLabelButtonDataList,
    }: TenSecStripDetail = tenSecStripDetail as TenSecStripDetail;

    let targetBeatTypeValue: number,
      _selectedBeatBtnWaveformIndexList: number[],
      _onsetWaveformIdxOfTenSecStrip: number,
      _terminationWaveformIdxOfTenSecStrip: number,
      _reqWaveformIndexList: number[],
      reqBody: any,
      suffix: string,
      _tabType: string;

    targetBeatTypeValue = parseInt(beatType);
    _selectedBeatBtnWaveformIndexList = selectedBeatBtnInfoList.map(
      (selectedBeatBtnInfoObj) => selectedBeatBtnInfoObj.waveformIndex
    );
    _onsetWaveformIdxOfTenSecStrip = onsetWaveformIdx;
    _terminationWaveformIdxOfTenSecStrip = terminationWaveformIdx;

    _reqWaveformIndexList = [..._selectedBeatBtnWaveformIndexList]
      .filter(Boolean)
      .filter(
        // 이전 비트와 같은 비트로 수정하려고 하는 경우 API 요청에서 해당 WI 제외
        (selectedWI) =>
          beatLabelButtonDataList.find(
            (buttonInfo) => buttonInfo.xAxisPoint === selectedWI
          ).beatType !== targetBeatTypeValue
      )
      .map(
        (beatWaveformIndex) =>
          beatWaveformIndex + _onsetWaveformIdxOfTenSecStrip
      );

    // API요청할 waveformIndexes가 빈배열이라면 early return
    if (_reqWaveformIndexList.length === 0) return;

    reqBody = {
      waveformIndexes: _reqWaveformIndexList,
      beatType: targetBeatTypeValue,
    };
    suffix = SUFFIX_LIST.EDIT_BEAT_SUFFIX.UPDATE_BEATS_BY_INDEXES;
    _tabType = tabType;

    handleEditBeats(
      reqBody,
      _onsetWaveformIdxOfTenSecStrip,
      _terminationWaveformIdxOfTenSecStrip,
      suffix,
      _tabType
    );
  }
  function deleteBeat({
    selectedBeatBtnInfoList,
  }: {
    selectedBeatBtnInfoList: BeatInfo[];
  }) {
    const { onsetWaveformIdx, terminationWaveformIdx } =
      tenSecStripDetail as TenSecStripDetail;
    let _selectedBeatBtnWaveformIndexList: number[],
      _onsetWaveformIdxOfTenSecStrip: number,
      _terminationWaveformIdxOfTenSecStrip: number,
      _reqWaveformIndexList: number[],
      reqBody: any,
      suffix: string,
      _tabType: string;

    _selectedBeatBtnWaveformIndexList = selectedBeatBtnInfoList.map(
      (selectedBeatBtnInfoObj) => selectedBeatBtnInfoObj.waveformIndex
    );
    _onsetWaveformIdxOfTenSecStrip = onsetWaveformIdx;
    _terminationWaveformIdxOfTenSecStrip = terminationWaveformIdx;

    _reqWaveformIndexList = [..._selectedBeatBtnWaveformIndexList]
      .filter(Boolean)
      .map(
        (beatWaveformIndex) =>
          beatWaveformIndex + _onsetWaveformIdxOfTenSecStrip
      );

    reqBody = {
      waveformIndexes: _reqWaveformIndexList,
    };
    suffix = SUFFIX_LIST.EDIT_BEAT_SUFFIX.REMOVE_BEATS;
    _tabType = tabType;

    handleDeleteBeats(
      reqBody,
      _onsetWaveformIdxOfTenSecStrip,
      _terminationWaveformIdxOfTenSecStrip,
      suffix,
      _tabType
    );
  }

  return {
    addBeat,
    editBeat,
    deleteBeat,
  };
}

export default useEditBeatEvent;
