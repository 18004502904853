import styled from 'styled-components';

import ScaleProgress from 'component/ui/progress/ScaleProgress';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

function ProgressFragment(props) {
  const { style, displayProgress = true } = props;
  return (
    <Wrapper style={style}>{displayProgress && <ScaleProgress />}</Wrapper>
  );
}

export default ProgressFragment;
