import { useState, useLayoutEffect } from 'react';
import { debounce } from 'util/Utility';

const DEBOUNCE_DELAY_TIME = 500;

/**
 * 파라미터로 전달된 Element Ref 의 사이즈를 반환합니다.
 * 만약 없다면, 브라우저의 너비와 높이 값으로 반환합니다.
 *
 * https://stackoverflow.com/a/57272554
 * @param {Array<number>} initSize 초기 사이즈
 * @param {Element | null} elementRef 사이즈를 확인할 대상 Element Ref
 * @returns [너비, 높이]
 */
function useElementSize(
  initSize: [number, number],
  correctionValue: number = 0,
  elementRef: any = null
) {
  const [size, setSize] = useState(
    initSize ?? [window.innerWidth, window.innerHeight]
  );

  useLayoutEffect(() => {
    const resizeEventHandler = debounce((event: any) => {
      if (elementRef?.current && elementRef.current.offsetWidth) {
        setSize([
          elementRef.current.offsetWidth,
          elementRef.current.offsetHeight,
        ]);
      } else {
        setSize([window.innerWidth + correctionValue, window.innerHeight]);
      }
    }, DEBOUNCE_DELAY_TIME);
    window.addEventListener('resize', resizeEventHandler);
    return () => {
      window.removeEventListener('resize', resizeEventHandler);
    };
  }, []);

  return size;
}

export default useElementSize;
