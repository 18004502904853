import { put, select } from 'redux-saga/effects';

import { optimisticEventDataUpdate } from 'util/optimisticEventDataUpdate/optimisticEventDataUpdate';

import { TimeEvent } from '@type/ecgEventType/eventType';
import {
  EventUpdateParam,
  optimisticEventDataUpdateForTimeEventConstTypes,
  TimeEventForOptimisticUpdate,
} from '@type/optimisticUpdate/type';
import {
  getTimeEventsListSucceed,
  selectBeatsNEctopicList,
  selectIsWholeUnMark,
  selectSelectedEventList,
} from 'redux/duck/testResultDuck';
import { Ms, WaveformIndex } from '@type/ecgEventType/eventUnit';
import { BeatType, EventConstTypes } from '@type/ecgEventType/baseEventType';
import {
  FindOverLappingEvent,
  InclusionTypeDecisionParam,
} from '@type/optimisticUpdate/process';

export class EventReviewOptimisticEventDataUpdateForTimeEvent extends optimisticEventDataUpdate {
  validation() {}
  // set TimeEventList: redux setting으로 optimistic update진행
  *setTimeEventsList({
    freshLeadOffList,
    freshTimeEventList,
  }: {
    freshLeadOffList: TimeEvent[];
    freshTimeEventList: TimeEvent[];
  }) {
    yield put(getTimeEventsListSucceed(freshLeadOffList, freshTimeEventList));
  }

  validatePauseInQ({
    selectedBeatsInfo,
    staleTimeEvent,
    recordingStartMs,
  }: {
    staleTimeEvent: TimeEvent;
    recordingStartMs: Ms;
    selectedBeatsInfo: {
      waveformIndexList: WaveformIndex[];
      beatTypeList: BeatType[];
    };
  }) {
    if (!isPauseEvent(staleTimeEvent)) return false;

    const { waveformIndexList, beatTypeList } = selectedBeatsInfo;

    const onsetWaveformIndex = getFormattedWaveformIndex(
      staleTimeEvent.onsetMs,
      recordingStartMs
    );
    const terminationWaveformIndex = getFormattedWaveformIndex(
      staleTimeEvent.terminationMs,
      recordingStartMs
    );

    const onsetIndex = getIndexOfBeat(waveformIndexList, onsetWaveformIndex);
    const terminationIndex = getIndexOfBeat(
      waveformIndexList,
      terminationWaveformIndex
    );

    const isOnsetPauseInQ = isNoiseBeat(beatTypeList, onsetIndex);
    const isTerminationInQ = isNoiseBeat(beatTypeList, terminationIndex);

    function isPauseEvent(staleTimeEvent: TimeEvent) {
      return staleTimeEvent.type === EventConstTypes.PAUSE;
    }

    function getFormattedWaveformIndex(time: Ms, recordingStartMs: Ms) {
      return (time - recordingStartMs) / 4;
    }

    function getIndexOfBeat(
      waveformIndexList: WaveformIndex[],
      formattedTime: number
    ) {
      return waveformIndexList.findIndex((wfi) => wfi >= formattedTime);
    }

    function isNoiseBeat(beatTypeList: BeatType[], index: number) {
      const checkBeat = beatTypeList[index];
      const nextCheckBeat = beatTypeList[index + 1];
      return checkBeat === BeatType.NOISE || nextCheckBeat === BeatType.NOISE;
    }

    return isOnsetPauseInQ || isTerminationInQ;
  }

  // get redux state
  *getBeatsNEctopicList(): Generator<any, any, any> {
    return yield select(selectBeatsNEctopicList);
  }

  *getTimeEventList(): Generator<any, TimeEvent[], TimeEvent[]> {
    const timeEventList = yield select(
      (state) => state.testResultReducer.timeEventsList
    );

    return timeEventList;
  }

  *getSelectedEventList(): Generator<any, any, any> {
    return yield select(selectSelectedEventList);
  }

  *getIsWholeUnMark(): Generator<any, any, any> {
    return yield select(selectIsWholeUnMark);
  }

  // 구간 filter function
  // todo: jyoon - [refactor] -> "Fully Exclusive Range"에서 border 포함 여부 관련해서
  /***********************************************************************************/
  /* # 구간 filter function                                                          */
  /*    * [A] Fully Exclusive Range (isFullyExclusiveRangeOfSelectionStrip)          */
  /*    * [B] Fully Inclusive Range (isFullyInclusiveRangeOfSelectionStrip)          */
  /*    * [C] Exclusive Range (isExclusiveRangeOfSelectionStrip)                     */
  /*      = Fully Exclusive Range + "include border"                                 */
  /*    * [D] Inclusive Range (isInclusiveRangeOfSelectionStrip)                     */
  /*      = Fully inclusive Range + "include border"                                 */
  /*    * [E] Partially inclusive Range (isPartiallyInclusiveRangeOfSelectionStrip)  */
  /*      = selection strip Range onset, termination에 걸쳐 있는 event               */
  /*      = 최소 0, 최대 2개                                                         */
  /*                                                                                 */
  /***********************************************************************************/

  /***********************************************************************************/
  /* # 용어 설명                                                                     */
  /*    * Fully - 경계 포함하지 않음                                                 */
  /***********************************************************************************/

  /***********************************************************************************/
  /*                                                                                 */
  /*                        [ C ]               [ C ]                                */
  /*                            |               |      <--- border                   */
  /*                    [ A ]   (     [ B ]     )   [ A ]                            */
  /*                            |               |      <--- border                   */
  /*                            [ D ]       [ D ]                                    */
  /*                            |               |      <--- border                   */
  /*                         [  E  ]         [  E  ]                                 */
  /*                                                                                 */
  /*                            (              )       <--- ✅ selectionStrip 구간   */
  /*                                                                                 */
  /***********************************************************************************/

  // [A] selectionStrip 구간이 event 구간을 포함하지 않는 경우(경계선 미포함)
  isFullyExclusiveRangeOfSelectionStrip({
    onsetSelectionStrip,
    terminationSelectionStrip,
    onsetRange,
    terminationRange,
  }: InclusionTypeDecisionParam): boolean {
    return (
      onsetSelectionStrip > terminationRange ||
      terminationSelectionStrip < onsetRange
    );
  }

  // [B] selectionStrip 구간이 event 구간을 완전히 포함하는 경우
  isFullyInclusiveRangeOfSelectionStrip({
    onsetSelectionStrip,
    terminationSelectionStrip,
    onsetRange,
    terminationRange,
  }: InclusionTypeDecisionParam): boolean {
    return (
      onsetSelectionStrip < onsetRange &&
      terminationSelectionStrip > terminationRange
    );
  }

  // [C] selectionStrip 구간이 event 구간을 포함하지 않는 경우(경계선 포함)
  isExclusiveRangeOfSelectionStrip({
    onsetSelectionStrip,
    terminationSelectionStrip,
    onsetRange,
    terminationRange,
  }: InclusionTypeDecisionParam): boolean {
    return (
      onsetSelectionStrip >= terminationRange ||
      terminationSelectionStrip <= onsetRange
    );
  }

  // [D] selectionStrip 구간이 event 구간을 포함하는 경우(경계선 포함)
  isInclusiveRangeOfSelectionStrip({
    onsetSelectionStrip,
    terminationSelectionStrip,
    onsetRange,
    terminationRange,
  }: InclusionTypeDecisionParam): boolean {
    return (
      onsetSelectionStrip <= onsetRange &&
      terminationSelectionStrip >= terminationRange
    );
  }

  // [E] selectionStrip 구간이 event 구간에 걸쳐 있는 경우
  isPartiallyInclusiveRangeOfSelectionStrip(
    {
      onsetSelectionStrip,
      terminationSelectionStrip,
      onsetRange,
      terminationRange,
    }: InclusionTypeDecisionParam,
    { isIncludeBorder = true }: { isIncludeBorder: boolean }
  ): boolean {
    const offset = isIncludeBorder ? 1 : 0;
    const onset =
      onsetRange < onsetSelectionStrip &&
      terminationRange > onsetSelectionStrip - offset;
    const termination =
      onsetRange < terminationSelectionStrip + offset &&
      terminationRange > terminationSelectionStrip;

    return onset || termination;
  }

  /**
   *  # 특정 구간내 excludeList에 포함되지 않는 구간을 반환
   *
   *  eg)
   *    [---------------|-----|----------------|-----|---------------]
   *    0              20     30              60    70             100
   *
   *     # 0 부터 100까지의 구간에서 20~30, 60~70 구간을 제외한 나머지 구간을 반환
   *     # parameter: start: 0, end: 100, excludeList: [[20, 30], [60, 70]]
   *     # return: [[0, 20], [30, 60], [70, 100]]
   */
  getRemainingRange({ start, end, excludeList }) {
    let intervals: [number, number][] = [];
    let currentStart: number = start;

    for (let i = 0; i < excludeList.length; i++) {
      let { onsetMs: exStart, terminationMs: exEnd } = excludeList[i];
      if (currentStart < exStart) {
        intervals.push([currentStart, exStart]);
      }
      currentStart = exEnd;
    }

    if (currentStart < end) {
      intervals.push([currentStart, end]);
    }

    return intervals;
  }

  // etc function
  findOverLappingEvent({
    baseSelectionStripTimeStamp,
    partiallyInclusiveRange,
  }: FindOverLappingEvent) {
    return partiallyInclusiveRange.find(
      (event) =>
        event.onsetMs <= baseSelectionStripTimeStamp &&
        event.terminationMs > baseSelectionStripTimeStamp
    );
  }

  sortTimeEventList(freshTimeEventList: TimeEvent[]) {
    // ORDER BY type, onsetMs asc;
    // 필요이유:
    //    - 세팅하는 데이터가 사용되는 곳에서 정렬이 중요해서 아래 로직 추가됨
    //    - useGetArrhythmiaEvents.jsx > 4번째 useEffect(Event Review Side Panel 에서 조회 중인 Event 의 Local Array Index 검색)
    const result = freshTimeEventList.sort((a, b) => {
      return a.onsetMs - b.onsetMs;
    });

    return result;
  }

  // process function: while edit event

  // 추가할 이벤트 구간이 기존 이벤트 구간과 겹치는 경우 겹치지 않는 구간만 반환
  getAvailableEventRange({
    excludedEventList, // 기존 이벤트 리스트
    responseInfo, // 추가할 이벤트 구간
    eventType, // 추가할 이벤트 타입
  }: {
    excludedEventList: any;
    responseInfo: TimeEventForOptimisticUpdate[];
    eventType: optimisticEventDataUpdateForTimeEventConstTypes;
  }) {
    const fullyInclusiveRange = excludedEventList.filter((staleTimeEvent) => {
      const conditionOfExclusiveRangeOfSelectionStrip =
        this.isFullyInclusiveRangeOfSelectionStrip({
          onsetSelectionStrip: responseInfo[0].onsetMs,
          terminationSelectionStrip: responseInfo[0].terminationMs,
          onsetRange: staleTimeEvent.onsetMs,
          terminationRange: staleTimeEvent.terminationMs,
        });

      return conditionOfExclusiveRangeOfSelectionStrip;
    });

    if (fullyInclusiveRange.length > 0) {
      const remainingIntervals = this.getRemainingRange({
        start: responseInfo[0].onsetMs,
        end: responseInfo[0].terminationMs,
        excludeList: fullyInclusiveRange,
      });

      responseInfo = remainingIntervals.map((interval) => {
        return {
          createAt: new Date().getTime(),
          durationMs: interval[1] - interval[0],
          onsetMs: interval[0],
          terminationMs: interval[1],
          type: eventType,
          timeEventId: null,
          onsetRPeakIndex: null,
          position: null,
          isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
        };
      });
    }

    return responseInfo;
  }
}

// Types for refactoring
export type EventHandlingParams = {
  updateReqOption: EventUpdateParam;
  //
  staleLeadOffList: any[];
  staleTimeEventList: TimeEvent[];
  //
  onsetSelectionStrip: any;
  terminationSelectionStrip: any;
};
