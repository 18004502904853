// optimistic update verification function
export const optimisticVerification = optimisticVerificationModule();

function optimisticVerificationModule() {
  let optimisticUpdateTracking = false;
  let eventReviewOptimisticUpdateList = [];
  return {
    // 편집 api request를 리스트에 추가
    setVerificationDataList(verificationData) {
      if (!optimisticUpdateTracking) return;
      return eventReviewOptimisticUpdateList.push(verificationData);
    },
    // 편집 api response를 리스트에 추가
    setEditEventResponse({ reqOption, responseData }) {
      if (!optimisticUpdateTracking) return;
      eventReviewOptimisticUpdateList = eventReviewOptimisticUpdateList.map(
        (verificationData) => {
          if (
            verificationData.currentTimeStamp ===
              reqOption.optimisticEventDataUpdateOption.reqTime ||
            reqOption.currentTimeStamp
          ) {
            verificationData.response = responseData.result;
          }
          return verificationData;
        }
      );
    },
    getEditEventList() {
      return eventReviewOptimisticUpdateList;
    },
    startOptimisticUpdateTracking() {
      optimisticUpdateTracking = true;
      console.info('💨 optimisticUpdateTracking이 시작되었습니다.');
      console.info(
        '💨 "getEditEventList()"으로 optimisticUpdate 편집한 내역을 확인할 수 있습니다.'
      );
    },
    endOptimisticUpdateTracking() {
      optimisticUpdateTracking = false;
      console.info('💨 optimisticUpdateTracking이 종료되었습니다.');
    },
  };
}

window.startOptimisticUpdateTracking =
  optimisticVerification.startOptimisticUpdateTracking;
window.endOptimisticUpdateTracking =
  optimisticVerification.endOptimisticUpdateTracking;

/**
 *
 * @param {Object} obj - The parameter object.
 * @param {TAB_VALUE_OBJECT.BEAT_REVIEW || TAB_VALUE_OBJECT.EVENT_REVIEW} obj.tabType - The tab type.
 */
window.getEditEventList = function ({ tabType } = { tabType: null }) {
  const eventReviewOptimisticUpdateList =
    optimisticVerification.getEditEventList();

  const filterFnArr = [];
  if (!!tabType) {
    filterFnArr.push((eventReviewOptimistic, args) => {
      if (eventReviewOptimistic.tabType === args.tabType) {
        return true;
      }
      return false;
    });
  }

  const filterEventReviewOptimisticUpdateList = eventReviewOptimisticUpdateList
    .map((v) => {
      delete v.currentTimeStamp;
      return v;
    })
    .filter((eventReviewOptimistic) => {
      if (filterFnArr.length === 0) return eventReviewOptimistic;

      let result = false;
      for (let fn of filterFnArr) {
        result = fn(eventReviewOptimistic, arguments[0]);
      }
      if (result) return eventReviewOptimistic;
    });

  return filterEventReviewOptimisticUpdateList;
};
