import { useEffect, useState } from 'react';
import { ShapeReviewSectionArea } from 'constant/ShapeReviewConst';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import {
  selectPaginationInfo,
  selectSelectedEventType,
} from 'redux/duck/shapeReviewDuck';
import { CheckBoxStatus, Pagination } from '../ShapeReviewFragmentContainer';
import FormsEventsPanelNavigationFragmentContainer from '../share/FormsEventsPanelNavigationFragmentContainer';
import { ShapeReviewEventType } from '@type/ecgEventType/baseEventType';
import { PanelSizeInfo } from 'redux/duck/shapeReview/shapeReviewDuckType';

export interface IFormsPanelNavigationFragmentContainer {
  checkBoxStatus: CheckBoxStatus;
  setCheckBoxStatus?: React.Dispatch<React.SetStateAction<CheckBoxStatus>>;
}

function FormsPanelNavigationFragmentContainer({
  checkBoxStatus,
  setCheckBoxStatus,
}: IFormsPanelNavigationFragmentContainer) {
  const navigationOption = { type: ShapeReviewSectionArea.FORMS };

  // redux selectors
  const selectedEventType = useShallowEqualSelector(
    selectSelectedEventType
  ) as ShapeReviewEventType;
  const paginationInfo = useShallowEqualSelector(
    selectPaginationInfo
  ) as PanelSizeInfo;
  const panelPaginationInfoOfSelectedEvent =
    paginationInfo[navigationOption.type][selectedEventType];

  const [pagination, setPagination] = useState<Pagination>({ currentPage: 0 });

  // useEffects
  // setting currentPage
  useEffect(() => {
    const isSelectedEventType = !selectedEventType;
    if (isSelectedEventType) return;

    setPagination({
      currentPage: panelPaginationInfoOfSelectedEvent.currentPage,
    });
  }, [selectedEventType, panelPaginationInfoOfSelectedEvent]);

  return (
    <FormsEventsPanelNavigationFragmentContainer
      // props state
      checkBoxStatus={checkBoxStatus}
      setCheckBoxStatus={setCheckBoxStatus}
      // local state
      navigationOption={navigationOption}
      panelPaginationInfoOfSelectedEvent={panelPaginationInfoOfSelectedEvent}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
}

export default FormsPanelNavigationFragmentContainer;
