import { useEffect, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';

const ProgressBar = styled.progress`
  height: 6px;

  -webkit-appearance: none;
  appearance: none;

  &::-webkit-progress-bar {
    background-color: ${(props) => props.theme.color.BLUE_40};
    border-radius: 10px;
  }
  &::-webkit-progress-value {
    background-color: ${(props) => props.theme.color.BLUE_70};
    border-radius: 10px;
  }
`;

interface ProgressIndicatorProps {
  style?: CSSProperties;
  progressValue: number;
  startValue?: number;
  maxValue?: number;
}

function ProgressTimer({
  style,
  startValue = 0,
  progressValue,
  maxValue = 100,
}: ProgressIndicatorProps) {
  // const [progress, setProgress] = useState(startValue);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === maxValue) {
  //         clearInterval(timer);
  //         return maxValue;
  //       }
  //       return Math.min(oldProgress + 1, maxValue);
  //     });
  //   }, 1000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [startValue, maxValue]);

  return <ProgressBar style={style} value={progressValue} max={maxValue} />;
}

export default ProgressTimer;
