/**
 * 두 Beat Event 배열의 일치함 테스트
 *
 * 적어도 하나의 항목이 다를 경우 다름
 * @param {*[]} leftList
 * @param {*[]} rightList
 * @param {*} onsetWaveformIndex
 * @returns
 */

export function isEqualBeatEventInfoMap(left, right) {
  const { onsetWaveformIndex } = right;
  const leftValues = Object.values(left).filter(
    (values) => typeof values === 'object'
  );
  const rightValues = Object.values(right).filter(
    (values) => typeof values === 'object'
  );
  // isEqualBeatEventInfoMapLog1(onsetWaveformIndex, leftValues, rightValues);
  const testResult =
    leftValues.length === rightValues.length &&
    !leftValues.some(
      (leftInfo) =>
        !rightValues.some((rightInfo) =>
          isEqualBeatEventInfo(leftInfo, rightInfo, onsetWaveformIndex)
        )
    );
  // isEqualBeatEventInfoMapLog2(onsetWaveformIndex, testResult);
  return testResult;
}

export function isEqualBeatEventInfo(left, right, onsetWaveformIndex) {
  // isEqualBeatEventInfoLog(onsetWaveformIndex, testResult);

  if (!left || !right) return false;
  return (
    isEqualNumberArray(left.beats.waveformIndex, right.beats.waveformIndex) &&
    isEqualNumberArray(left.beats.beatType, right.beats.beatType) &&
    isEqualNumberArray(left.beats.hr, right.beats.hr) &&
    isEqualBeatEventList(left.noises, right.noises, onsetWaveformIndex) &&
    isEqualBeatEventList(left.ectopics, right.ectopics, onsetWaveformIndex)
  );
}

function isEqualNumberArray(left, right) {
  return (
    left.length === right.length &&
    left.reduce((acc, cur) => acc + cur ?? 0, 0) ===
      right.reduce((acc, cur) => acc + cur ?? 0, 0)
  );
}
function isEqualBeatEventList(leftList, rightList, onsetWaveformIndex) {
  const leftMap = getObjectFromArray(leftList, 'onsetRPeakIndex');
  const rightMap = getObjectFromArray(rightList, 'onsetRPeakIndex');
  const testResult =
    leftList.length + rightList.length === 0 ||
    (leftList.length === rightList.length &&
      !rightList.some(
        (rightEvent) =>
          !_isEqualBeatEvent(leftMap[rightEvent.onsetRPeakIndex], rightEvent)
      ) &&
      !leftList.some(
        (leftEvent) =>
          !_isEqualBeatEvent(leftEvent, rightMap[leftEvent.onsetRPeakIndex])
      ));

  // isEqualBeatEventListLog(onsetWaveformIndex, testResult, leftList, rightList);
  return testResult;

  function _isEqualBeatEvent(leftEvent, rightEvent) {
    const result =
      Boolean(leftEvent?.type) &&
      Boolean(rightEvent?.type) &&
      rightEvent.onsetRPeakIndex === leftEvent.onsetRPeakIndex &&
      rightEvent.ectopicType === leftEvent.ectopicType &&
      rightEvent.beatType === leftEvent.beatType &&
      rightEvent.waveformIndex.at(0) === leftEvent.waveformIndex.at(0) &&
      rightEvent.waveformIndex.at(-1) === leftEvent.waveformIndex.at(-1) &&
      rightEvent.onsetWaveformIndex === leftEvent.onsetWaveformIndex &&
      rightEvent.terminationWaveformIndex ===
        leftEvent.terminationWaveformIndex;

    return result;
  }
}

/**
 * 두 Time Event 배열의 일치함 테스트
 *
 * 적어도 하나의 항목이 다를 경우 다름
 * @param {*[]} leftList
 * @param {*[]} rightList
 * @returns
 */
export function isEqualTimeEventList(leftList, rightList) {
  const leftMap = getObjectFromArray(leftList, 'timeEventId');
  const rightMap = getObjectFromArray(rightList, 'timeEventId');

  return (
    leftList.length + rightList.length === 0 ||
    (leftList.length === rightList.length &&
      !rightList.some((rightEvent) => {
        return !isEqualTimeEvent(
          leftMap[
            !!rightEvent.isOptimisticEventDataUpdate
              ? rightEvent.onsetMs
              : rightEvent.timeEventId
          ],
          rightEvent
        );
      }) &&
      !leftList.some((leftEvent) => {
        return !isEqualTimeEvent(
          leftEvent,
          rightMap[
            !!leftEvent.isOptimisticEventDataUpdate
              ? leftEvent.onsetMs
              : leftEvent.timeEventId
          ]
        );
      }))
  );
}
function isEqualTimeEvent(leftEvent, rightEvent) {
  return (
    leftEvent !== undefined &&
    rightEvent !== undefined &&
    Boolean(leftEvent?.type) &&
    Boolean(rightEvent?.type) &&
    rightEvent.timeEventId === leftEvent.timeEventId &&
    rightEvent.type === leftEvent.type &&
    rightEvent.onsetMs === leftEvent.onsetMs &&
    rightEvent.terminationMs === leftEvent.terminationMs &&
    rightEvent.onsetLocalWaveformIndex === leftEvent.onsetLocalWaveformIndex &&
    rightEvent.terminationLocalWaveformIndex ===
      leftEvent.terminationLocalWaveformIndex
  );
}

/**
 * Array 객체를 Object 객체로 변환
 * @param {*[]} targetArray
 * @param {String} targetKeyField
 * @returns
 */
function getObjectFromArray(targetArray, targetKeyField) {
  let targetObject = {};
  for (const item of targetArray) {
    if (!item.isOptimisticEventDataUpdate) {
      targetObject[item[targetKeyField]] = item;
    } else {
      targetObject[item['onsetMs']] = item;
    }
  }
  return targetObject;
}

// optimistic update시 "event marker" update equal function 분석 로그
function isEqualBeatEventInfoMapLog1(
  onsetWaveformIndex,
  leftValues,
  rightValues
) {
  console.log({
    onsetWaveformIndex,
    left: JSON.stringify(
      leftValues.map((value) => value.ectopics.map((v) => v.onsetRPeakIndex))
    ),
    right: JSON.stringify(
      rightValues.map((value) => value.ectopics.map((v) => v.onsetRPeakIndex))
    ),
  });
}
function isEqualBeatEventInfoMapLog2(onsetWaveformIndex, testResult) {
  const testArray = [];
  if (testArray.includes(onsetWaveformIndex)) {
    console.log(
      onsetWaveformIndex,
      'selected beat event equality test',
      testResult
    );
  }
}
function isEqualBeatEventInfoLog(onsetWaveformIndex, testResult) {
  const testArray = [];
  if (testArray.includes(onsetWaveformIndex)) {
    console.log(
      onsetWaveformIndex,
      'selected beat event equality test',
      testResult
    );
  }
}
function isEqualBeatEventListLog(
  onsetWaveformIndex,
  testResult,
  leftList,
  rightList
) {
  const testArray = [];
  if (testArray.includes(onsetWaveformIndex) && !testResult) {
    console.log({
      onsetWaveformIndex,
      testResult,
      left: JSON.stringify(leftList.map((value) => value.onsetRPeakIndex)),
      right: JSON.stringify(rightList.map((value) => value.onsetRPeakIndex)),
    });
    console.log(
      onsetWaveformIndex,
      'selected beat event equality test',
      testResult
    );
  }
}
