import { useDispatch } from 'react-redux';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import FormsPanelListFragment from 'component/fragment/test-result/shape-review/FormsPanel/FormsPanelListFragment';

import {
  selectClickedInfo,
  selectActivePanel,
  editedFormListByClickedEventType,
  selectIsCoupletEctopicTypeClicked,
  selectEventPoolingStateOfSelectedEvent,
  selectFormPoolingStateOfSelectedEvent,
  setSelectedCheckboxStatus,
} from 'redux/duck/shapeReviewDuck';
import {
  Checkbox,
  ClickedInfo,
  PoolingData,
} from 'redux/duck/shapeReview/shapeReviewDuckType';

import {
  PanelListFragmentContainerProps,
  ActivePanelType,
} from '../ShapeReviewFragmentContainer';

function FormsPanelListFragmentContainer({
  // local state
  checkBoxStatus,
  // useChartList Hook state
  gridLayoutRef,
  chartList,
  chartItemRectangle,
  selectedItemList,
  lastSelectedSectionInfo,
  setSelectedItemList,
  setLastSelectedSectionInfo,
  //
  onClickGridItem,
}: PanelListFragmentContainerProps) {
  // redux selectors
  const dispatch = useDispatch();
  const clickedInfo = useShallowEqualSelector(selectClickedInfo) as ClickedInfo;
  const activePanel = useShallowEqualSelector(
    selectActivePanel
  ) as ActivePanelType;
  const formPanelPatchedList = useShallowEqualSelector(
    editedFormListByClickedEventType
  ) as number[];
  const { calledType, pending } = useShallowEqualSelector(
    selectFormPoolingStateOfSelectedEvent
  ) as Pick<PoolingData, 'calledType' | 'pending'>;
  const { pending: pendingOfEventPoolingStateOfSelectedEvent } =
    useShallowEqualSelector(selectEventPoolingStateOfSelectedEvent) as {
      pending: Pick<PoolingData, 'pending'>;
    };

  const isCoupletEctopicTypeClicked = useShallowEqualSelector(
    selectIsCoupletEctopicTypeClicked
  ) as boolean;

  const handleSelectedCheckboxStatus = (checkboxStatus: Checkbox) => {
    dispatch(setSelectedCheckboxStatus(checkboxStatus));
  };

  return (
    <FormsPanelListFragment
      // props state
      checkBoxStatus={checkBoxStatus}
      // useChartList Hook state
      gridLayoutRef={gridLayoutRef}
      chartList={chartList}
      chartItemRectangle={chartItemRectangle}
      selectedItemList={selectedItemList}
      lastSelectedSectionInfo={lastSelectedSectionInfo}
      setSelectedItemList={setSelectedItemList}
      setLastSelectedSectionInfo={setLastSelectedSectionInfo}
      // redux state
      activePanel={activePanel}
      clickedInfo={clickedInfo}
      panelPatchedList={formPanelPatchedList}
      poolingStateOfPanel={{ calledType, pending }}
      isCoupletEctopicTypeClicked={isCoupletEctopicTypeClicked}
      pendingOfEventPoolingStateOfSelectedEvent={
        pendingOfEventPoolingStateOfSelectedEvent
      }
      //
      onClickGridItem={onClickGridItem}
      handleSelectedCheckboxStatus={handleSelectedCheckboxStatus}
    />
  );
}

export default FormsPanelListFragmentContainer;
