import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { ComponentId } from 'constant/ComponentId';
import {
  BE_FIELD_SIMILARITY,
  SIMILARITY_VALUE_TEXT,
  SORT_ORDER_TYPE_OPTION_LIST_MAP,
  SortOption,
  SortOptionList,
  TIME_VALUE_TEXT,
} from 'constant/SortConst';

import Divider from 'component/ui/divider/Divider';
import ListItemText from 'component/ui/menu/ListItemText';
import MenuItem from 'component/ui/menu/MenuItem';

import { ReactComponent as BlackCheckIcon } from 'static/icon/icon-check-black.svg';

interface SvgBlackCheckIconProps {
  selected: boolean;
}

const ItemContainer = styled.div`
  min-width: 155px;
  padding: 4px;
  position: absolute;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  border: solid 1px #c7ced2;
  background-color: #ffffff;
  animation: 0.2s ease-in-out;
  transform-origin: top;
  -webkit-transition: -webkit-visibility 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1002;
`;

const SvgBlackCheckIcon = styled(BlackCheckIcon)<SvgBlackCheckIconProps>`
  ${({ selected, theme }) =>
    selected
      ? `
      path {
        stroke: ${theme.color.COOL_GRAY_90};
      `
      : `
        path {
          stroke: ${theme.color.TRANSPARENT};
        }
      `}
`;
function OrderingContextmenu(props) {
  const {
    orderingTypeSetting,
    formRankNumber,
    handleSetOrderSettingType,
    handleGetWaveformIndexListOfFormRequested,
  } = props;

  const intl = useIntl();

  // 선택된 정렬 옵션을 찾는 함수
  // 정렬 옵션을 선택한 적 없는 Form은 Form 마다 해당되는 default 옵션을 설정한다.
  // ETC , Edited Form -> Time
  // Clustered Form -> Similarity
  const selectedOrderValue = (
    orderingTypeSetting: SortOption,
    formRankNumber: number,
    queryOrderBy: string
  ) => {
    const initOrderOption = orderingTypeSetting.queryOrderBy === '';
    if (initOrderOption) {
      const editedEditForm = formRankNumber < 1; // edited, edit form
      const clusteredForm = formRankNumber >= 1; // ai가 clustering으로 분류된 form
      if (editedEditForm) {
        return queryOrderBy === '';
      } else if (clusteredForm) {
        return queryOrderBy === `-${BE_FIELD_SIMILARITY}`;
      }
    } else {
      return orderingTypeSetting.queryOrderBy === queryOrderBy;
    }
  };

  return (
    <ItemContainer
      className="BeatEditContextmenu"
      data-cid={ComponentId.ORDERING_CONTEXTMENU}>
      <MenuItem
        style={{ padding: '3px 12px 5px' }}
        key="header-1"
        type="header"
        disabled
        title={intl.formatMessage({
          id: '99-OrderingContextmenu-MenuItem-title',
          description: 'Sort by',
          defaultMessage: 'Sort by',
        })}
      />
      <Divider />

      {getOrderingTypeList(SORT_ORDER_TYPE_OPTION_LIST_MAP, formRankNumber).map(
        ({
          valueText,
          optionText,
          tooltipTitle,
          queryOrderBy,
          ascending,
          isDefault,
        }: SortOption) => {
          const isSelectedOrderValue = selectedOrderValue(
            orderingTypeSetting,
            formRankNumber,
            queryOrderBy as string
          );
          return (
            <ContextMenuItem
              key={tooltipTitle.id}
              label={optionText}
              style={{ padding: '10px 24px' }}
              isSelectedOrderValue={isSelectedOrderValue}
              onClick={() => {
                handleSetOrderSettingType({
                  valueText,
                  optionText,
                  tooltipTitle,
                  queryOrderBy,
                  ascending,
                  isDefault,
                });
                handleGetWaveformIndexListOfFormRequested({
                  ordering: queryOrderBy,
                });
              }}
            />
          );
        }
      )}
    </ItemContainer>
  );
}

/**
 * 선택한 정렬 옵션으로 설정
 * @param SORT_ORDER_TYPE_OPTION_LIST_MAP - Order Map Const Type
 * @param formRankNumber - 각 Form이 갖고 있는 formRankNumber 값
 * * ETC, EDITED FORM은 1 보다 작다.
 * * Clustered Form 은 1 보다 크다.
 * @returns
 */

function getOrderingTypeList(
  SORT_ORDER_TYPE_OPTION_LIST_MAP: Record<string, SortOption>,
  formRankNumber: number
): SortOptionList {
  const isClusteredForm = formRankNumber >= 1;
  return Object.values(SORT_ORDER_TYPE_OPTION_LIST_MAP).filter((value) => {
    if (isClusteredForm) {
      return value.valueText !== TIME_VALUE_TEXT;
    } else {
      return value.valueText !== SIMILARITY_VALUE_TEXT;
    }
  });
}
function ContextMenuItem({ style, onClick, label, isSelectedOrderValue }) {
  return (
    <MenuItem style={style} onClick={onClick}>
      <div style={{ display: 'flex', gap: 4 }}>
        <SvgBlackCheckIcon selected={isSelectedOrderValue} />
        <ListItemText>{`${label}`}</ListItemText>
      </div>
    </MenuItem>
  );
}

export default OrderingContextmenu;
