import { ECG_CHART_UNIT, TEN_SEC_STRIP_DETAIL } from 'constant/ChartEditConst';
import LocalStorageKey from 'constant/LocalStorageKey';

import LocalStorageManager from 'manager/LocalStorageManager';

type handleOpenEventReviewInNewTabParam = {
  tabType: string;
  dataLength: number;
  typeOfCenterWI: string;
  onsetWaveformIdx: number;
};
/** 10초 Strip 에서 조회 중인 지점을 새탭에서 Event Review 화면으로 제공 */
export const handleOpenEventReviewInNewTab = ({
  tabType,
  dataLength,
  typeOfCenterWI,
  onsetWaveformIdx,
}: handleOpenEventReviewInNewTabParam) => {
  if (tabType === TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW || dataLength === 0) {
    return;
  }
  const { origin, pathname } = window.location;
  const newBeatReviewSidePanelState = [
    {
      type: typeOfCenterWI,
      position: null,
      waveformIndex:
        onsetWaveformIdx + ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX,
      timeEventId: null,
    },
  ];
  const currentTidEventReviewUrl = origin + pathname + '?tab=event-review';

  LocalStorageManager.setItem(
    LocalStorageKey.SIDE_PANEL_STATE,
    newBeatReviewSidePanelState
  );

  window.open(currentTidEventReviewUrl, '_blank');
};

interface ReviewState {
  isHrReview: boolean;
  isEventReview: boolean;
  isBeatReview: boolean;
  isShapeReview: boolean;
}

export function generateReviewState(tenSecStripTabValue: string): ReviewState {
  const reviewTypes: { [key: string]: keyof ReviewState } = {
    [TEN_SEC_STRIP_DETAIL.TAB.HR_REVIEW]: 'isHrReview',
    [TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW]: 'isEventReview',
    [TEN_SEC_STRIP_DETAIL.TAB.BEAT_REVIEW]: 'isBeatReview',
    [TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW]: 'isShapeReview',
  };

  const reviewState: Partial<ReviewState> = {};
  for (const [key, value] of Object.entries(reviewTypes)) {
    reviewState[value] = tenSecStripTabValue === TEN_SEC_STRIP_DETAIL.TAB[key];
  }

  return reviewState as ReviewState;
}

export function compareTenSecStripData(arr1, arr2) {
  if (arr1 !== null && arr2 === null) return false;
  if (arr1 === null || arr2 === null) return true;

  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    const item1 = arr1[i];
    const item2 = arr2[i];
    if (
      item1.xAxisPoint !== item2.xAxisPoint ||
      item1.isSelected !== item2.isSelected ||
      item1.beatType !== item2.beatType
    ) {
      return false; // 하나라도 다른 요소가 있으면 False 반환
    }
  }

  return true; // 모든 요소가 같다면 True 반환
}
