import { BEAT_TYPE, ECTOPIC_TYPE } from 'constant/EventConst';

import { THIRTY_SEC_WAVEFORM_LENGTH, isGeneralSection } from './common';

const CONDITION_OF_ECTOPIC_TYPE_FOR_ONSET_MARKER = [ECTOPIC_TYPE.RUN];
const CONDITION_OF_BEAT_TYPE_FOR_ONSET_MARKER = [BEAT_TYPE.APC, BEAT_TYPE.VPC];

export const filterLocalTimeEventList = (
  totalList,
  recordingStartMs,
  localOnsetWaveformIndex,
  localTerminationWaveformIndex
) => {
  if (!localOnsetWaveformIndex && !localTerminationWaveformIndex) return [];
  const localOnsetMs = recordingStartMs + localOnsetWaveformIndex * 4;
  const localTerminationMs =
    recordingStartMs + localTerminationWaveformIndex * 4;
  const localWaveformLength =
    localTerminationWaveformIndex - localOnsetWaveformIndex;

  return totalList
    .filter(
      (value) =>
        // ECG Strip 구간과 중첩되는 시간의 Arrhythmia Event 검색
        !(
          localTerminationMs <= value.onsetMs ||
          value.terminationMs <= localOnsetMs
        )
    )
    .map((value) => ({
      ...value,
      onsetLocalWaveformIndex: Math.max(
        parseInt((value.onsetMs - localOnsetMs) / 4),
        0
      ),
      hasOnsetMarker: localOnsetMs <= value.onsetMs,
      /**
       * 구간의 최대 길이는 데이터 길이와 같음
       */
      terminationLocalWaveformIndex: Math.min(
        parseInt((value.terminationMs - localOnsetMs) / 4),
        localWaveformLength
      ),
      hasTerminationMarker: value.terminationMs <= localTerminationMs,
    }));
};

export const extractNTransformLocalBeatEventInfo = (
  totalInfoMap,
  localOnsetWaveformIndex,
  localTerminationWaveformIndex
) => {
  const filterLocalBeatEventList = (totalList) => {
    const localWaveformLength =
      localTerminationWaveformIndex - localOnsetWaveformIndex;
    return totalList
      .filter(
        (value) =>
          !(
            localTerminationWaveformIndex <= value.onsetWaveformIndex ||
            value.terminationWaveformIndex < localOnsetWaveformIndex
          )
      )
      .map((value) => ({
        ...value,
        onsetLocalWaveformIndex: Math.max(
          value.onsetWaveformIndex - localOnsetWaveformIndex,
          0
        ),
        hasOnsetMarker:
          CONDITION_OF_ECTOPIC_TYPE_FOR_ONSET_MARKER.includes(
            value.ectopicType
          ) &&
          CONDITION_OF_BEAT_TYPE_FOR_ONSET_MARKER.includes(value.beatType) &&
          (value.hasOnsetMarker
            ? localOnsetWaveformIndex <= value.onsetWaveformIndex
            : value.hasOnsetMarker),
        /**
         * 구간의 최대 길이는 데이터 길이와 같음
         */
        terminationLocalWaveformIndex: Math.min(
          value.terminationWaveformIndex - localOnsetWaveformIndex,
          localWaveformLength
        ),
        hasTerminationMarker:
          CONDITION_OF_ECTOPIC_TYPE_FOR_ONSET_MARKER.includes(
            value.ectopicType
          ) &&
          CONDITION_OF_BEAT_TYPE_FOR_ONSET_MARKER.includes(value.beatType) &&
          (value.hasTerminationMarker
            ? value.terminationWaveformIndex <= localTerminationWaveformIndex
            : value.hasTerminationMarker),
      }));
  };

  const result = {
    beats: {
      waveformIndex: [],
      beatType: [],
      hr: [],
    },
    noises: [],
    ectopics: [],
  };
  if (!localOnsetWaveformIndex && !localTerminationWaveformIndex) return result;

  const preOnsetWaveformIndex =
    localOnsetWaveformIndex -
    (localOnsetWaveformIndex % THIRTY_SEC_WAVEFORM_LENGTH);
  const postOnsetWaveformIndex = isGeneralSection(
    localOnsetWaveformIndex,
    localTerminationWaveformIndex
  )
    ? preOnsetWaveformIndex
    : localTerminationWaveformIndex -
      (localTerminationWaveformIndex % THIRTY_SEC_WAVEFORM_LENGTH);

  const isSingleSection = preOnsetWaveformIndex === postOnsetWaveformIndex;
  const preBeatEventInfo = totalInfoMap[preOnsetWaveformIndex];
  const postBeatEventInfo = totalInfoMap[postOnsetWaveformIndex];

  if (!preBeatEventInfo && !postBeatEventInfo) return result;
  // Beats
  const tempWaveformIndex = isSingleSection
    ? preBeatEventInfo.beats.waveformIndex
    : [
        ...(preBeatEventInfo?.beats?.waveformIndex ?? []),
        ...(postBeatEventInfo?.beats?.waveformIndex ?? []),
      ];
  const rangeOnsetIndex = tempWaveformIndex.findIndex(
    (value) => localOnsetWaveformIndex <= value
  );
  const tempVal = tempWaveformIndex.findIndex(
    (value) => localTerminationWaveformIndex < value
  );
  const rangeTerminationIndex =
    tempVal === -1 ? tempWaveformIndex.length : tempVal;
  result.beats = {
    waveformIndex: tempWaveformIndex.slice(
      rangeOnsetIndex,
      rangeTerminationIndex
    ),
    beatType: (isSingleSection
      ? preBeatEventInfo.beats.beatType
      : [
          ...(preBeatEventInfo?.beats?.beatType ?? []),
          ...(postBeatEventInfo?.beats?.beatType ?? []),
        ]
    ).slice(rangeOnsetIndex, rangeTerminationIndex),
    hr: (isSingleSection
      ? preBeatEventInfo.beats.hr
      : [
          ...(preBeatEventInfo?.beats?.hr ?? []),
          ...(postBeatEventInfo?.beats?.hr ?? []),
        ]
    ).slice(rangeOnsetIndex, rangeTerminationIndex),
  };

  // 노이즈 구간
  result.noises = filterLocalBeatEventList(
    isSingleSection
      ? preBeatEventInfo.noises
      : [
          ...(preBeatEventInfo?.noises ?? []),
          ...(postBeatEventInfo?.noises ?? []),
        ]
  );

  // Ectopic 구간
  result.ectopics = filterLocalBeatEventList(
    isSingleSection
      ? preBeatEventInfo.ectopics
      : [
          ...(preBeatEventInfo?.ectopics ?? []),
          ...(postBeatEventInfo?.ectopics ?? []),
        ]
  );

  return result;
};
