import styled from 'styled-components';

import {
  ShapeReviewSectionArea,
  panelTabIndex,
} from 'constant/ShapeReviewConst';

import {
  ShapeReviewFragmentProps,
  MAX_PENDING_SEC,
} from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';
import SelectedItemPanelFragmentContainer from 'redux/container/fragment/test-result/shape-review/selectedItemPanel/SelectedItemPanelFragmentContainer';
import NavigationFragmentContainer from 'redux/container/fragment/test-result/shape-review/navigation/NavigationFragmentContainer';
import FormsPanelFragmentContainer from 'redux/container/fragment/test-result/shape-review/formsPanel';
import EventsPanelFragmentContainer from 'redux/container/fragment/test-result/shape-review/eventsPanel';

import ProgressTimer from 'component/ui/progress/ProgressTimer';

import { ReactComponent as SyncIcon } from 'static/icon/ic-sync.svg';

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  * {
    box-sizing: border-box;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  column-gap: 2px;
  border-top: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  background: ${(props) => props.theme.color.COOL_GRAY_30};
`;

const FocusWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  background: transparent;
  pointer-events: none;

  ${(props) => {
    return `border: 1px solid ${props.theme.color.BLUE_70}`;
  }}
`;

/** 브라우저에 표현된 너비 = window.innerWidth - 226px(RightSide 의 고정 너비) */
const BottomLeftSideWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 428px;
  border-right: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  border-bottom: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  background: ${(props) => props.theme.color.WHITE};
  box-sizing: content-box;

  :focus {
    outline: none;
  }
`;

const BottomCenterWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  border-right: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  background: ${(props) => props.theme.color.WHITE};

  :focus {
    outline: none;
  }
`;

const BottomRightSideWrapper = styled.div`
  z-index: 1001;
  min-width: 225px;
  width: 225px;
  height: 100%;
  border-left: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  border-right: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
  z-index: 1;
  background: ${(props) => props.theme.color.WHITE};
`;

const ProcessingStatusIndicatorWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

const PendingText = styled.div<{ isError: boolean }>`
  width: 340px;
  height: 34px;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
  text-align: center;
  color: ${(props) =>
    props.isError ? props.theme.color.RED_70 : props.theme.color.BLUE_70};
`;

const RearrangeButtonWrapper = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

const RearrangeIcon = styled(SyncIcon)`
  width: 16px;
  height: 16px;
  path {
    fill: ${(props) => props.theme.color.COOL_GRAY_60};
  }
`;

const RearrangeText = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 14.3px;
  text-align: center;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;

export interface IFocusState {
  panel: ShapeReviewSectionArea | 'None';
}

const MESSAGE_CHANGE_INTERVAL = 6; // N초 마다 다른 메시지 출력

interface IProcessingStatusIndicatorProps {
  isError: boolean;
  beatPostprocessProgress: number;
  onClick: () => void;
}
function ProcessingStatusIndicator({
  isError,
  beatPostprocessProgress,
  onClick,
}: IProcessingStatusIndicatorProps) {
  if (isError) {
    return (
      <>
        <PendingText isError={isError}>
          정렬하는 중에 문제가 발생했습니다.
        </PendingText>
        <RearrangeButtonWrapper onClick={onClick}>
          <RearrangeIcon />
          <RearrangeText>다시 정렬하기</RearrangeText>
        </RearrangeButtonWrapper>
      </>
    );
  }

  const isLongPending = beatPostprocessProgress > MAX_PENDING_SEC;

  const pendingMsgList = [
    `편집 결과를 반영하여 Form과 Event를 정렬 중입니다. \n 정렬에는 최대 1분이 소요됩니다.`,
  ];
  const longPendingMsgList = [
    `이벤트 개수가 많아 시간이 더 걸리고 있어요. \n 열심히 일하는 정렬 요정을 믿어주세요.🧚‍♀️`,
    `이벤트 개수가 많아 시간이 더 걸리고 있어요. \n 좋은 음악을 들으면서 기다려보세요! 🎶`,
    `정렬 완료까지 1,482분 남았습니다. \n 농담이에요! 조금만 더 기다려주세요. 😄⏳`,
  ];

  const selectedMessageIndex = Math.floor(
    (beatPostprocessProgress % (MESSAGE_CHANGE_INTERVAL * 3)) /
      MESSAGE_CHANGE_INTERVAL
  );

  return (
    <>
      <PendingText isError={isError}>
        <div>
          {(isLongPending
            ? longPendingMsgList[selectedMessageIndex]
            : pendingMsgList[0]
          )
            .split('\n')
            .map((line, lineIndex) => (
              <div key={lineIndex}>
                {line}
                <br />
              </div>
            ))}
        </div>
      </PendingText>
      <ProgressTimer
        style={{ width: 195 }}
        progressValue={beatPostprocessProgress % (MAX_PENDING_SEC + 1)}
        maxValue={MAX_PENDING_SEC}
      />
    </>
  );
}

function ShapeReviewFragment({
  activePanel,
  handleSetActivePanel,
  //
  beatPostprocessPending,
  beatPostprocessError,
  beatPostprocessProgress,
  handlePatchBeatPostprocessRequested,
}: ShapeReviewFragmentProps) {
  return (
    <>
      {beatPostprocessPending || !!beatPostprocessError ? (
        <ProcessingStatusIndicatorWrapper>
          <ProcessingStatusIndicator
            isError={!!beatPostprocessError}
            beatPostprocessProgress={beatPostprocessProgress}
            onClick={handlePatchBeatPostprocessRequested}
          />
        </ProcessingStatusIndicatorWrapper>
      ) : (
        <Wrapper>
          <TopWrapper className="topWrapper">
            <NavigationFragmentContainer />
          </TopWrapper>
          <BottomWrapper className="bottomWrapper">
            {/* Forms panel */}
            <BottomLeftSideWrapper
              className="bottomLeftSideWrapper forms-panel"
              tabIndex={panelTabIndex[ShapeReviewSectionArea.FORMS]}
              onClick={(e) => {
                activePanel !== ShapeReviewSectionArea.FORMS &&
                  handleSetActivePanel({
                    activePanel: ShapeReviewSectionArea.FORMS,
                  });
              }}>
              {activePanel === ShapeReviewSectionArea.FORMS && (
                <FocusWrapper className="FocusWrapper" />
              )}
              <FormsPanelFragmentContainer />
            </BottomLeftSideWrapper>
            {/* Events panel */}
            <BottomCenterWrapper
              className="bottomCenterWrapper events-panel"
              tabIndex={panelTabIndex[ShapeReviewSectionArea.EVENTS]}
              onClick={(e) => {
                activePanel !== ShapeReviewSectionArea.EVENTS &&
                  handleSetActivePanel({
                    activePanel: ShapeReviewSectionArea.EVENTS,
                  });
              }}>
              <EventsPanelFragmentContainer activePanel={activePanel} />
            </BottomCenterWrapper>
            {/* Side panel */}
            <BottomRightSideWrapper className="bottomRightSideWrapper side-panel">
              <SelectedItemPanelFragmentContainer />
            </BottomRightSideWrapper>
          </BottomWrapper>
        </Wrapper>
      )}
    </>
  );
}

export default ShapeReviewFragment;
