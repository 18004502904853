import { forwardRef, useCallback, useState } from 'react';
import { Slide, Zoom, Grow } from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';

import AppColors from 'theme/AppColors';

import { ReactComponent as RawErrorIcon } from 'static/icon/ic-error.svg';
import { ReactComponent as RawCloseIcon } from 'static/icon/ic-close.svg';

const ErrorIcon = styled((props) => (
  <RawErrorIcon width={20} height={20} {...props} />
))`
  flex: 1 0 auto;
  & path {
    fill: ${(props) => props.theme.color.RED_70};
  }
`;
const CloseIcon = styled((props) => (
  <RawCloseIcon width={24} height={24} {...props} />
))`
  flex: 1 0 auto;

  & path {
    fill: ${(props) => props.theme.color.WHITE};
  }
`;

const DefaultContentWrapper = styled('div')`
  box-sizing: border-box;
  padding: 16px 12px;
  border-radius: 4px;
  max-width: 332px;
  min-width: 332px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  background-color: ${(props) => props.theme.color.COOL_GRAY_90};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);

  gap: 8px;
`;
const ContentMessageWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const MessageWrapper = styled('div')`
  padding: 0 8px 0 4px;

  white-space: pre;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  color: ${(props) => props.theme.color.WHITE};
`;
const ActionWrapper = styled('div')`
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;

  cursor: pointer;

  color: ${(props) => props.theme.color.RED_60};
  font-size: 13px;
  font-weight: 700;
  line-height: 130%;
`;

export const messageIconConst = {
  error: 'error',
};
const messageIcon = {
  [messageIconConst.error]: <ErrorIcon />,
};
const DefaultCustomContent = forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <DefaultContentWrapper ref={ref} style={props.style}>
      <ContentMessageWrapper>
        {messageIcon[props.messageIcon]}
        <MessageWrapper>{props.message}</MessageWrapper>
      </ContentMessageWrapper>
      <ActionWrapper style={props.actionWrapperStyle}>
        <CloseIcon onClick={() => handleDismiss()} />
      </ActionWrapper>
    </DefaultContentWrapper>
  );
});
export const WithoutIconContent = forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <DefaultContentWrapper
      style={{ maxWidth: 'initial', ...props.style }}
      ref={ref}>
      <ContentMessageWrapper>
        <MessageWrapper>{props.message}</MessageWrapper>
      </ContentMessageWrapper>
      {props.handleActionOnClick && (
        <ActionWrapper
          style={{
            padding: '0 8px',
            color: AppColors.BLUE_60,
            ...props.actionWrapperStyle,
          }}
          onClick={() => {
            if (typeof props.handleActionOnClick !== 'function') return;
            props.handleActionOnClick();
            handleDismiss();
          }}>
          {props.actionText}
        </ActionWrapper>
      )}
    </DefaultContentWrapper>
  );
});

/**
 * Custom hook for displaying snackbar notifications with various options.
 *
 * @param {React.ElementType} CustomContentElement - The custom content element to display in the snackbar.
 * @param {number} autoHideDuration - The duration (in milliseconds) for which the snackbar is displayed. Default is 4000 milliseconds.
 * @param {{ vertical: 'top' | 'bottom', horizontal: 'left' | 'center' | 'right' }} anchorOrigin - The position at which the snackbar will appear. Default is { vertical: 'bottom', horizontal: 'right' }.
 * @param {Object} style - Custom CSS styles to be applied to the snackbar content. Default is an empty object ({}).
 * @param {'Grow' | 'Slide' | 'Zoom'} transition - The type of transition to use when showing/hiding the snackbar. Default is 'Grow'.
 * @param {props} customContentElementProps - props for CustomContentElement
 *
 * @returns {{ enqueueMessage: (message: string, option: Object=) => void, closeMessage: () => void }} - The functions to enqueue and close snackbar messages.
 */
function useSnackbarStack(
  CustomContentElement = DefaultCustomContent,
  autoHideDuration = 4 * 1000,
  anchorOrigin = {
    vertical: 'bottom', //  'top' | 'bottom';
    horizontal: 'right', // 'left' | 'center' | 'right';
  },
  style = {},
  transition = 'Grow', // 'Grow' | 'Slide' | 'Zoom'
  customContentElementProps = {},
  persist = false
) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [key, setKey] = useState(0);

  const TransitionComponent = {
    Grow: Grow,
    Slide: Slide,
    Zoom: Zoom,
  };

  const enqueueMessage = useCallback(
    (message, option = {}) =>
      setKey(
        enqueueSnackbar(message, {
          content: (key, message) => (
            <CustomContentElement
              id={key}
              message={message}
              style={style}
              {...customContentElementProps}
              {...option}
            />
          ),
          TransitionComponent: TransitionComponent[transition],
          anchorOrigin,
          autoHideDuration,
          persist,
        })
      ),
    [autoHideDuration, enqueueSnackbar]
  );

  const closeMessage = useCallback(() => {
    closeSnackbar(key);
  }, [closeSnackbar, key]);

  return { enqueueMessage, closeMessage };
}

export default useSnackbarStack;
