import { useDispatch } from 'react-redux';

import { setChartSelectedStrip } from 'redux/duck/testResultDuck';

function useGetMoveChartListFunc() {
  const dispatch = useDispatch();

  const moveAtOnChartList = (selectedTimeMs) =>
    dispatch(setChartSelectedStrip(selectedTimeMs));

  return moveAtOnChartList;
}

export default useGetMoveChartListFunc;
