import { ECTOPIC_TYPE } from 'constant/EventConst';

import { OpenableRange, IBeatEvent, Range } from '@type/beatNEctopic/process';
import {
  WaveformIndexOrUndefined,
  WaveformIndex,
} from '@type/ecgEventType/eventUnit';

const CONST_ECTOPIC_TYPE = ECTOPIC_TYPE;
const THIRTY_SEC_WAVEFORM_LENGTH: number = 7500;

/** 구간을 표현하는 onset 과 termination 이 정규 30초 구간으로 되어 있는지 여부 반환 */
export function isRegular30sWaveformIndexRangeRange(
  onsetWaveformIndex: WaveformIndex,
  terminationWaveformIndex: WaveformIndex
): boolean {
  const result =
    onsetWaveformIndex % THIRTY_SEC_WAVEFORM_LENGTH === 0 &&
    terminationWaveformIndex % THIRTY_SEC_WAVEFORM_LENGTH === 0;
  return result;
}

/** 두 개의 Beat 목록 중 겹치는 요소가 있는지 여부 반환 */
export function isSomeBeatIncludesOtherList(
  leftBeatList: Array<WaveformIndex>,
  rightBeatList: Array<WaveformIndex>
) {
  const result = leftBeatList.some((waveformIndex) =>
    rightBeatList.includes(waveformIndex)
  );
  return result;
}

/** 30초 간격의 웨이브폼 인덱스 배열 반환, 최소 하나의 값(onset) 있음 */
export function getThirtySecIntervalList(
  onset: WaveformIndex,
  termination: WaveformIndex
): Array<WaveformIndex> {
  const gap = termination - onset;
  if (gap % THIRTY_SEC_WAVEFORM_LENGTH !== 0)
    throw new Error('getThirtySecIntervals error: 잘못된 파라미터');

  const result = Array.from(
    {
      length: Math.max(gap / THIRTY_SEC_WAVEFORM_LENGTH, 1),
    },
    (_: any, i: number) => i * THIRTY_SEC_WAVEFORM_LENGTH + onset
  );
  return result;
}

/** 두 구간정보 사이에 겹쳐진 구간이 있는지 여부 반환 */
export function hasOverlappedRange(
  leftRange: Range,
  rightRange: Range
): boolean {
  const result = !(
    leftRange.terminationWaveformIndex <= rightRange.onsetWaveformIndex ||
    rightRange.terminationWaveformIndex <= leftRange.onsetWaveformIndex
  );
  return result;
}
/** 임의의 Waveform Index 가 Event Review 에서 표현되는 Chart 의 Onset Waveform Index 를 반환 */
export function getKeyWaveformIndex(
  waveformIndex: WaveformIndex
): WaveformIndex {
  const result = waveformIndex - (waveformIndex % THIRTY_SEC_WAVEFORM_LENGTH);
  return result;
}

/** Beat 수에 따른 Ectopic Type 값을 반환 */
export function getEctopicType<T>(waveformIndexList: Array<T>): string {
  let result;
  if (waveformIndexList.length === 1) result = CONST_ECTOPIC_TYPE.ISOLATE;
  else if (waveformIndexList.length === 2) result = CONST_ECTOPIC_TYPE.COUPLET;
  else result = CONST_ECTOPIC_TYPE.RUN;
  return result;
}

/** Beat Event 의 경계 Marker 정보를 반환 */
export function getEdgeMarkerValues(typeChangedRange: OpenableRange): {
  markerWaveformIndex: WaveformIndexOrUndefined;
  hasMarker: boolean;
} {
  const hasMarker =
    Number.isInteger(typeChangedRange.onsetWaveformIndex) &&
    Number.isInteger(typeChangedRange.terminationWaveformIndex);
  const onsetWaveformIndex = typeChangedRange.onsetWaveformIndex ?? -1;
  const terminationWaveformIndex =
    typeChangedRange.terminationWaveformIndex ?? -1;
  const result = {
    markerWaveformIndex: hasMarker
      ? onsetWaveformIndex +
        Math.floor((terminationWaveformIndex - onsetWaveformIndex) / 2)
      : undefined,
    hasMarker,
  };
  return result;
}

/**
 * Beat Event List 정렬을 위한 비교 함수
 *
 * Beat Event 의 Marker Rendering 최적화를 위해 반드시 필요!
 */
export function compareBeatEvents(a: IBeatEvent, b: IBeatEvent): number {
  const aFirst = a.waveformIndex.at(0) ?? 0;
  const bFirst = b.waveformIndex.at(0) ?? 0;
  const result = aFirst - bFirst;
  return result;
}
