import { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';

import {
  CHART_EDIT_CONST,
  ECG_CHART_UNIT,
  TEN_SEC_STRIP_DETAIL,
  PLOT_LINE_COLOR_MAP,
  AMPLITUDE_OPTION,
} from 'constant/ChartEditConst';
import { CLASS_NAME_CHART_BASE, CHART_ICON_PATH } from 'constant/ChartConst';
import { CLASS_CONST } from 'constant/ClassConst';
import { ComponentId } from 'constant/ComponentId';
import LocalStorageKey from 'constant/LocalStorageKey';

import { ECGChartCommonOption } from 'util/ChartUtil';
import { handleOpenEventReviewInNewTab } from 'util/TenSecStripDetailUtil';

import useManagedClickEvent from 'component/hook/useManagedClickEvent';

import BeatEditContextmenuContainer from 'redux/container/fragment/test-result/share/beatEditContextmenu/BeatEditContextmenuContainer';

import LocalStorageManager from 'manager/LocalStorageManager';

const LINE_WIDTH = 1;
const X_AXIS_TICK_LENGTH = 15;
const CHART_MARGIN_TOP = 32;
const CHART_MARGIN_BOTTOM = 24;

const CLASS_CONST_CALIPER_MOUSE_TRACKER = CLASS_CONST.CALIPER.MOUSE_TRACKER;
const CONST_CHART_ICON_PATH = CHART_ICON_PATH;

function ShortTermChart(props) {
  const {
    // props
    chartOption,
    setChartOption,
    selectedBeatBtnInfoList,
    setSelectedBeatBtnInfoList,
    // local state
    chartRef,
    chartContainerRef,
    clickFlagRef,
    dragFlag,
    chartWrapperSize: { chartWrapperWidth, chartWrapperHeight },
    onClickChartContainer,
    theme,
    initTenSecStripDetail,
    highlightArea,
    typeOfCenterWI,
    // redux state
    ecgRaw,
    recordingStartMs,
    beatLabelButtonDataList,
    beatReviewSidePanelState,
    beatContextmenuPositionSetting, // {beatContextmenuPosition,setBeatContextmenuPosition},
    selectedBeatOptionSetting, // {selectedBeatOption,setSelectedBeatOption}
    // redux action creator
    isOpenBeatContextmenuSetting, // {isOpenBeatContextmenu, handleBeatContextmenu}
    caliperPlotLines,
    setBeatsLabelBtnStatus,
    //
    isBeatEditMode,
    onsetWaveformIdx,
    beatTypeZones,
  } = props;

  const { editModeType, isCaliperMode, tabType } = chartOption;
  const { isOpenBeatContextmenu, handleBeatContextmenu } =
    isOpenBeatContextmenuSetting;
  const { selectedBeatOption } = selectedBeatOptionSetting;

  const openNewTabHandler = () =>
    handleOpenEventReviewInNewTab({
      tabType,
      dataLength: ecgRaw?.length,
      typeOfCenterWI,
      onsetWaveformIdx,
    });

  const clickHandler = useManagedClickEvent(() => {}, openNewTabHandler);
  return (
    <Wrapper
      data-cid={ComponentId.TEN_SEC_STRIP_DETAIL_CHART}
      ref={chartContainerRef}
      editModeType={editModeType}
      isCaliperMode={isCaliperMode}
      caliperPlotLines={caliperPlotLines}
      tabType={tabType}
      chartWrapperHeight={chartWrapperHeight}
      theme={theme}>
      {/* <>{new Date().toISOString()}</> */}
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        allowChartUpdate={true}
        immutable={false}
        updateArgs={[false, false, false]}
        options={generateChartOptions({
          theme,
          chartRef,
          chartOption,
          setChartOption,
          isBeatEditMode,
          ecgRaw,
          highlightArea,
          selectedBeatOption,
          setBeatsLabelBtnStatus,
          beatTypeZones,
          onsetWaveformIdx,
          selectedBeatBtnInfoList,
          setSelectedBeatBtnInfoList,
          clickHandler,
          isOpenBeatContextmenu,
          handleBeatContextmenu,
          initTenSecStripDetail,
          beatLabelButtonDataList,
          recordingStartMs,
        })}
        containerProps={{
          className: ComponentId.TEN_SEC_STRIP_CHART_CONTAINER,
          'data-cid': ComponentId.TEN_SEC_STRIP_CHART_CONTAINER,
          style: {
            // 화면에서 제공할 너비, 높이
            width: chartWrapperWidth - 40 - 40,
            // 차트 옵션에서 Border 를 그리기 위한 여백을 좌우에 부여한것을 고려한 Plot Height 계산
            height:
              (chartWrapperWidth - 40 - 40 - LINE_WIDTH * 3) * 0.12 +
              CHART_MARGIN_TOP +
              CHART_MARGIN_BOTTOM,
            margin: 'auto',
          },
        }}
        callback={(chart) => {}}
      />
      {isOpenBeatContextmenu && (
        <BeatEditContextmenuContainer
          chartRef={chartRef}
          clickFlagRef={clickFlagRef}
          dragFlag={dragFlag}
          chartOption={chartOption}
          selectedBeatBtnInfoList={selectedBeatBtnInfoList}
          beatContextmenuPositionSetting={beatContextmenuPositionSetting}
          isOpenBeatContextmenuSetting={isOpenBeatContextmenuSetting}
          selectedBeatOptionSetting={selectedBeatOptionSetting}
          initTenSecStripDetail={initTenSecStripDetail}
        />
      )}
    </Wrapper>
  );
}

// ShortTermChart's Styled Components
const Wrapper = styled.div`
  display: flex;
  height: ${(props) =>
    props.chartWrapperHeight + CHART_MARGIN_TOP + CHART_MARGIN_BOTTOM}px;

  & .${ComponentId.TEN_SEC_STRIP_CHART_CONTAINER} {
    .huinno-0-line {
      stroke: ${(props) => props.theme.color.ECG_BLACK};
      stroke-width: ${LINE_WIDTH}px;
    }
    .huinno-1-line {
      stroke: ${(props) => props.theme.color.SVE_600};
      stroke-width: ${LINE_WIDTH}px;
    }
    .huinno-2-line {
      stroke: ${(props) => props.theme.color.VE_600};
      stroke-width: ${LINE_WIDTH}px;
    }
    .huinno-3-line {
      stroke: ${(props) => props.theme.color.MEDIUM_DARK};
      stroke-width: ${LINE_WIDTH}px;
    }

    & .highcharts-plot-border {
      fill: ${(props) => props.theme.color.VERY_ULTRA_LIGHT_PRIMARY_BLUE};
    }
    .${CHART_EDIT_CONST.MOUSE_TRACKER} {
      ${(props) => {
        return props.editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT
          ? 'visibility: visible; transform: translate(-500px);'
          : 'visibility: hidden';
      }}
    }
    .${CLASS_CONST_CALIPER_MOUSE_TRACKER} {
      ${(props) => {
        return props.isCaliperMode && props.caliperPlotLines.length < 2
          ? 'visibility: visible; transform: translate(-500px);'
          : 'visibility: hidden';
      }}
    }
  }
  .highcharts-plot-bands-5 {
    // xAxis,yAxis plot band는 height를 수정할 수 없으므로, transform을 사용
    transform: scale(1, 0.99) translate(0, 1px);
  }

  .showLegHoverCursor {
    cursor: col-resize;
  }
  .showIntervalHoverCursor {
    cursor: pointer;
  }
`;

/********************************/
/*    ShortTermChart options    */
/********************************/
const generateChartOptions = ({
  theme,
  chartRef,
  chartOption,
  setChartOption,
  isBeatEditMode,
  ecgRaw,
  highlightArea,
  selectedBeatOption,
  setBeatsLabelBtnStatus,
  beatTypeZones,
  onsetWaveformIdx,
  selectedBeatBtnInfoList,
  setSelectedBeatBtnInfoList,
  clickHandler,
  isOpenBeatContextmenu,
  handleBeatContextmenu,
  initTenSecStripDetail,
  beatLabelButtonDataList,
  recordingStartMs,
}) => {
  const {
    tabType,
    chart: { plotBorderColor = '', plotBorderWidth = 0, amplitude },
    editModeType,
    isCaliperMode,
    isReportRepresentativeStripChangeMode,
  } = chartOption;

  const withoutPlotBorderTabList = [
    TEN_SEC_STRIP_DETAIL.TAB.HR_REVIEW,
    TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW,
    TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW,
  ];

  const xAxisPlotLinesOption = _getXAxisPlotLinesOption(
    tabType,
    selectedBeatBtnInfoList,
    chartOption,
    isCaliperMode,
    isReportRepresentativeStripChangeMode,
    theme,
    selectedBeatOption
  );

  return {
    chart: {
      spacing: [
        CHART_MARGIN_TOP,
        LINE_WIDTH,
        CHART_MARGIN_BOTTOM,
        LINE_WIDTH * 2,
      ],
      plotBorderColor: plotBorderColor,
      plotBorderWidth:
        !withoutPlotBorderTabList.includes(tabType) && plotBorderWidth,
      style: {
        fontFamily: 'Spoqa Han Sans Neo',
      },
      events: {
        click: function (event) {
          const isAddBeatMode =
            editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
          if (!isAddBeatMode) {
            initTenSecStripDetail();
          }

          clickHandler();
          /*  Dev Mode */
          const xAxisPoint = parseInt(event.xAxis[0].value);
          clickedWIInfo({
            beatLabelButtonDataList,
            xAxisPoint,
            onsetWaveformIdx,
            recordingStartMs,
          });
        },
        load: function (chart) {
          // const chart = this;
        },
        render: function () {
          const chartInst = this;

          /* 
            #1 render grid 
            #2 render mouse tracker
            #2-1 render mouse tracker - DottedHorizontalLine
            #2-2 render mouse tracker - plus button
          */

          _init(chartInst);

          // #1 render grid
          // ChartUtil.renderBorderGrid(
          //   chartInst,
          //   X_AXIS_TICK_LENGTH + CHART_MARGIN_TOP,
          //   1,
          //   50,
          //   theme,
          //   CHART_MARGIN_TOP
          // );

          // #2 mouseTracker
          _renderMouseTracker(chartInst, theme);
        },
        redraw: function () {
          // render보다 정확하게 redraw해야하는 경우에만 동작하도록
          const chart = this;
        },
      },
      animation: false,
    },
    xAxis: {
      minPadding: 0,
      min: 0, // ecg data raw: # down sampling
      max: ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX,
      startOnTick: false,
      endOnTick: false,
      // crosshair: {
      //   className: 'beat-edit-crosshair',
      //   color: theme.color.PRIMARY_BLUE,
      //   dashStyle: 'shortdot',
      // },
      lineWidth: 0,
      // Major Tick & Grid
      tickInterval: 250,
      tickLength: X_AXIS_TICK_LENGTH,
      tickWidth: 1,
      gridLineColor: theme.color.COOL_GRAY_50,
      gridLineWidth: 1,
      // Minor Grid
      minorTickInterval: 50,
      minorGridLineColor: theme.color.COOL_GRAY_40,
      minorGridLineWidth: 1,
      gridZIndex: 0,
      // minorTicks: true,
      // minorGridLineWidth: 0,
      labels: {
        enabled: true,
        padding: 0,
        x: 9,
        y: 14,
        style: {
          fontSize: '9px',
          'line-height': '130%',
          fill: theme.color.MEDIUM_DARK,
          color: theme.color.MEDIUM_DARK,
        },
        // 마지막 10초의 라벨은 생략
        formatter: function () {
          return this.value !== ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX
            ? `${this.value / 250}s`
            : '';
        },
      },
      plotLines: xAxisPlotLinesOption,
      plotBands: [
        {
          className: 'huinno-ten-sec-strip-highlight-area',
          borderColor: theme.color.BLUE_70,
          borderWidth: 1.5,
          color: theme.color.BLUE_10,
          from: highlightArea?.from, // Start of the plot band
          to: highlightArea?.to, // End ot the plot band
          zIndex: 5,
        },
      ],
    },
    yAxis: [
      {
        // amplitude 가 1이 아닌 홀수일 경우 무한소수가 되어 하이차트가 제대로 표현할 수 없으므로 임의의 값으로 설정
        min:
          amplitude === AMPLITUDE_OPTION.THIRTY_MV.RATE
            ? -0.33
            : -1 / amplitude,
        max:
          amplitude === AMPLITUDE_OPTION.THIRTY_MV.RATE ? 0.66 : 2 / amplitude,
        lineWidth: 0,
        startOnTick: false,
        endOnTick: false,
        tickInterval:
          amplitude === AMPLITUDE_OPTION.THIRTY_MV.RATE
            ? 0.165
            : 0.5 / amplitude,
        gridLineColor: theme.color.COOL_GRAY_40,
        gridLineWidth: 1,
        gridZIndex: 0,
        title: {
          enabled: false,
        },
        labels: {
          enabled: false,
        },
      },
    ],
    series: [
      {
        id: '10sec-beats-strip-series',
        data: (ecgRaw ?? []).length
          ? ecgRaw
          : Array.from({ length: 2500 }, () => -100),
        onsetWaveformIdx: onsetWaveformIdx,
        lineWidth: LINE_WIDTH,
        color: theme.color.ECG_BLACK,
        animation: false,
        pointPlacement: 'on',
        zoneAxis: 'x',
        zones: beatTypeZones,
        className: `huinno-10sec-data ${CLASS_NAME_CHART_BASE}`,
      },
    ],
    plotOptions: {
      series: {
        events: {
          click: function (event) {
            const isAddBeatMode =
              editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
            if (!isAddBeatMode) {
              initTenSecStripDetail();
            }

            clickHandler();

            /* Dev Mode */
            const xAxisPoint = parseInt((event.x - 25) * 2);
            clickedWIInfo({
              beatLabelButtonDataList,
              xAxisPoint,
              onsetWaveformIdx,
              recordingStartMs,
            });
          },
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false, // hover 시 마우스 포인터와 가까운 포인트 강조 효과 제거
            },
          },
        },
        states: {
          inactive: {
            enabled: false,
          },
          hover: {
            enabled: true,
            halo: null, // hover 시 마우스 포인터와 가까운 포인트 주변 후광(?) 효과 제거
            lineWidthPlus: 0,
          },
        },
        animation: false, // animation 제거(렌더 시간 단축!!!)
      },
    },
    ...ECGChartCommonOption,
  };
};

/****************************************************************************************************************/
/*    generateChartOptions: beatReview에서 init 시점에 가운데 비트 선택 된 상태에서 xAxisPlot line render 옵션  */
/****************************************************************************************************************/
function _getXAxisPlotLinesOption(
  tabType,
  selectedBeatBtnInfoList,
  chartOption,
  isCaliperMode,
  isReportRepresentativeStripChangeMode,
  theme,
  selectedBeatOption
) {
  const showCenterXaxisPlotLineReviewTab = [
    TEN_SEC_STRIP_DETAIL.TAB.BEAT_REVIEW,
  ];
  const isShowCenterXaxisPlotLineReviewTab =
    showCenterXaxisPlotLineReviewTab.includes(tabType);
  const hasCenterSelectedBeat =
    !selectedBeatBtnInfoList[0]?.waveformIndex ||
    selectedBeatBtnInfoList[0]?.waveformIndex ===
      ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX;
  const isAddBeatMode =
    chartOption.editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
  let xAxisPlotLinesOption = undefined;
  if (
    isShowCenterXaxisPlotLineReviewTab &&
    hasCenterSelectedBeat &&
    !isCaliperMode &&
    !isReportRepresentativeStripChangeMode &&
    !isAddBeatMode
  ) {
    xAxisPlotLinesOption = [
      {
        className: 'huinno-ten-sec-strip-xAxis-center-plot-line',
        color:
          theme.color[PLOT_LINE_COLOR_MAP[selectedBeatOption]] ||
          theme.color.MEDIUM_PRIMARY_BLUE,
        width: 2,
        value: ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX / 2,
        zIndex: 6,
      },
    ];
  }
  return xAxisPlotLinesOption;
}

/*********************************************/
/*    generateChartOptions: init             */
/*********************************************/
function _init(chartInst) {
  chartInst.beatRenderEvents = [];
  chartInst.arrhythmiaEvents = [];
}

/*********************************************/
/*    generateChartOptions: mouse tracker    */
/*********************************************/
function _renderMouseTracker(chartInst, theme) {
  if (chartInst.huinnoMouseTracker) return;
  if (chartInst.huinnoCaliperMouseTracker) return;

  chartInst.huinnoMouseTracker = chartInst.renderer
    .g()
    .attr({
      class: `${CHART_EDIT_CONST.MOUSE_TRACKER}`,
      zIndex: 6,
    })
    .add();

  chartInst.huinnoCaliperMouseTracker = chartInst.renderer
    .g()
    .attr({
      class: `${CLASS_CONST_CALIPER_MOUSE_TRACKER}`,
      zIndex: 6,
    })
    .add();

  // #2-1 render mouse tracker - DottedHorizontalLine
  _renderMouseTrackerDottedHorizontalLine(chartInst, theme);
  // #2-2 render mouse tracker - plus button
  _renderMouseTrackerPlusButton(chartInst, theme);
  _renderMouseTrackerCaliperButton(chartInst, theme);
}
function _renderMouseTrackerDottedHorizontalLine(chartInst, theme) {
  // add Mode
  const dottedHorizontalLine = chartInst.renderer
    .path()
    .attr({
      class: `${CHART_EDIT_CONST.VERTICAL_DOTTED_LINE}`,
      fill: 'none',
      d: `M0 ${CHART_MARGIN_TOP + 1} V${
        chartInst.yAxis[0].height + CHART_MARGIN_TOP + 1
      }`,
      stroke: theme.color.PRIMARY_BLUE,
      'stroke-width': 2,
      'stroke-dasharray': '2,2',
    })
    .css({
      'z-index': 5,
    })
    .add(chartInst.huinnoMouseTracker);

  // Caliper
  const dottedHorizontalLineForCaliper = chartInst.renderer
    .path()
    .attr({
      class: `${CLASS_CONST.CALIPER.VERTICAL_DOTTED_LINE}`,
      fill: 'none',
      d: `M0 ${CHART_MARGIN_TOP + 1} V${
        chartInst.yAxis[0].height + CHART_MARGIN_TOP + 1
      }`,
      stroke: theme.color.DARK,
      'stroke-width': 2,
      'stroke-dasharray': '2,2',
    })
    .css({
      'z-index': 5,
    })
    .add(chartInst.huinnoCaliperMouseTracker);
}
function _renderMouseTrackerPlusButton(chartInst, theme) {
  let defaultAttrs, hoverAttrs, SelectAttrs, disabledAttrs;
  defaultAttrs = {
    r: 2,
    width: 8,
    height: 8,
    zIndex: 7,
    'stroke-width': 0.5,
    stroke: theme.color.PRIMARY_BLUE,
    fill: theme.color.PRIMARY_BLUE,
    style: {
      fontFamily: 'Spoqa Han Sans Neo',
      color: theme.color.WHITE,
      fontSize: '12px',
      lineHeight: '130%',
      fontWeight: 500,
    },
  };
  hoverAttrs = {
    ...defaultAttrs,
  };
  SelectAttrs = {
    ...defaultAttrs,
    fill: theme.color.PRIMARY_BLUE,
    zIndex: 7,
    style: {
      ...defaultAttrs.style,
      color: theme.color.WHITE,
    },
  };
  disabledAttrs = {
    ...defaultAttrs,
  };

  // button add
  const plusButton = chartInst.renderer
    .button(
      '+',
      0,
      0,
      () => {},
      defaultAttrs,
      hoverAttrs,
      SelectAttrs,
      disabledAttrs
    )
    .css({})
    .attr({
      class: `${CHART_EDIT_CONST.BEAT_ADD_PLUS_BUTTON}`,
      zIndex: 6,
    })
    .add(chartInst.huinnoMouseTracker);

  const offsetX = plusButton.element.getBoundingClientRect().width / 2;
  plusButton.element.style.transform = `translate(-${offsetX}px, 0)`;
}
function _renderMouseTrackerCaliperButton(chartInst, theme) {
  let defaultAttrs, hoverAttrs, SelectAttrs, disabledAttrs;

  defaultAttrs = {
    r: 2,
    width: 8,
    height: 8,
    zIndex: 7,
    'stroke-width': 0.5,
    stroke: theme.color.DARK,
    fill: theme.color.DARK,
  };
  hoverAttrs = {
    ...defaultAttrs,
  };
  SelectAttrs = {
    ...defaultAttrs,
    fill: theme.color.DARK,
    zIndex: 7,
    style: {
      ...defaultAttrs.style,
      color: theme.color.WHITE,
    },
  };
  disabledAttrs = {
    ...defaultAttrs,
  };

  // button add
  const caliperButton = chartInst.renderer
    .button(
      '',
      0,
      0,
      () => {},
      defaultAttrs,
      hoverAttrs,
      SelectAttrs,
      disabledAttrs
    )
    .css({})
    .add(chartInst.huinnoCaliperMouseTracker);

  // icon Add
  const caliperIcon = chartInst.renderer
    .path()
    .attr({
      d: CONST_CHART_ICON_PATH.caliperIconPath,
      fill: theme.color.WHITE,
      zIndex: 8,
    })
    .add(caliperButton);

  const offsetX = caliperButton.element.getBoundingClientRect().width / 2;
  caliperButton.element.style.transform = `translate(-${offsetX}px, 0)`;
}

/********************************************************/
/*    generateChartOptions: 10초 차트에 devMode 추가    */
/********************************************************/
function clickedWIInfo({
  beatLabelButtonDataList,
  xAxisPoint,
  onsetWaveformIdx,
  recordingStartMs,
}) {
  const devMode = LocalStorageManager.getItem(LocalStorageKey.DEV_MODE);

  if (onsetWaveformIdx === null) return;
  if (!devMode) return;

  const { nearestRPI } = beatLabelButtonDataList.reduce(
    (acc, cur) => {
      const diff = Math.abs(cur.xAxisPoint - xAxisPoint);
      if (diff < acc.minDiff) {
        return { minDiff: diff, nearestRPI: cur };
      }
      return acc;
    },
    { minDiff: Infinity, nearestRPI: null }
  );

  const table = {};
  table['10s Detail Chart'] = {
    nearestRPI: onsetWaveformIdx + nearestRPI.xAxisPoint,
    typeOfNearestRPI: nearestRPI.title,
    clickedWI: onsetWaveformIdx + xAxisPoint,
    clickedMs: recordingStartMs + (onsetWaveformIdx + xAxisPoint) * 4,
    repWI: onsetWaveformIdx - (onsetWaveformIdx % 7500),
    repMs:
      recordingStartMs + (onsetWaveformIdx - (onsetWaveformIdx % 7500)) * 4,
  };
  console.table(table);
}

/********************/
/*    deprecated    */
/********************/
function _rePositionChartPlotBorder(chartInst) {
  const huinnoChartBorderRect_y =
    chartInst.plotBorder.element.getAttribute('y');
  chartInst.plotBorder.element.setAttribute(
    'y',
    parseInt(huinnoChartBorderRect_y) - 0.3
  );
  const huinnoChartBorderRect_height =
    chartInst.plotBorder.element.getAttribute('height');
  chartInst.plotBorder.element.setAttribute(
    'height',
    parseInt(huinnoChartBorderRect_height) + 1.3
  );
}
// Add Beat Mode Click Event Function
function onSelectBeat(event, setShow, setOffSetX, setOffSetY) {
  setShow(true);
  setOffSetX(event.offsetX + 50);
  setOffSetY(event.offsetY);
}

export default memo(ShortTermChart, (prevProps, nextProps) => {
  return (
    prevProps.ecgRaw === nextProps.ecgRaw &&
    prevProps.chartOption.editModeType === nextProps.chartOption.editModeType &&
    prevProps.chartOption.chart.amplitude ===
      nextProps.chartOption.chart.amplitude &&
    prevProps.isOpenBeatContextmenuSetting.isOpenBeatContextmenu ===
      nextProps.isOpenBeatContextmenuSetting.isOpenBeatContextmenu &&
    prevProps.beatContextmenuPositionSetting.beatContextmenuPosition ===
      nextProps.beatContextmenuPositionSetting.beatContextmenuPosition &&
    prevProps.selectedBeatOptionSetting.selectedBeatOption ===
      nextProps.selectedBeatOptionSetting.selectedBeatOption &&
    prevProps.selectedBeatBtnInfoList === nextProps.selectedBeatBtnInfoList &&
    prevProps.beatLabelButtonDataList === nextProps.beatLabelButtonDataList &&
    prevProps.highlightArea === nextProps.highlightArea &&
    prevProps.caliperPlotLines === nextProps.caliperPlotLines &&
    prevProps.chartOption.isCaliperMode === nextProps.chartOption.isCaliperMode
  );
});
