import { ECG_CHART_UNIT } from 'constant/ChartEditConst';

import { BeatType } from '@type/ecgEventType/baseEventType';
import { hrValue } from '@type/optimisticUpdate/type';

const HALF_TEN_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX;
const TEN_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.TEN_SEC_WAVEFORM_IDX;

/**
 * 중심위치로부터 반경 5초인 총 10초 구간의 R-R HR 평균을 계산
 *
 * 10s Avg HR = (HR 합계)/(비트 수 - 1) ← 단, 첫번째 비트의 HR 및 R-R 제외
 *
 * reference MC-643
 * @param withExtraBeats 중심위치로부터 반경 6초(5초 + 1초) + a초 안의 Beats 데이터
 * @param centerWaveformIndex 중심위치 Waveform Index
 * @returns HR 계산이 불가능할 경우 null
 */
export const getTenSecAvgHrByCenter = (
  withExtraBeats: Beats,
  centerWaveformIndex: WaveformIndex
): number | null | hrValue => {
  const fromWaveformIndex = centerWaveformIndex - HALF_TEN_SEC_WAVEFORM_LENGTH;
  const toWaveformIndex = centerWaveformIndex + HALF_TEN_SEC_WAVEFORM_LENGTH;

  return getTenSecAvgHrByFromTo(
    withExtraBeats,
    fromWaveformIndex,
    toWaveformIndex
  );
};

export const getTenSecAvgHrByFromTo = (
  withExtraBeats: Beats,
  fromWaveformIndex: WaveformIndex,
  toWaveformIndex: WaveformIndex
): number | null | hrValue => {
  if (toWaveformIndex - fromWaveformIndex !== TEN_SEC_WAVEFORM_LENGTH) {
    return null;
  }

  const { waveformIndex, beatType, hr } = withExtraBeats;
  const beatCount = beatType.length;
  let hrList = Array<number | hrValue>();

  for (let i = 0; i < beatCount; i++) {
    const cursorWaveformIndex = waveformIndex[i];
    if (
      cursorWaveformIndex >= fromWaveformIndex &&
      cursorWaveformIndex <= toWaveformIndex
    ) {
      hrList.push(hr[i]);
    }
  }

  // 10s strip에 hr값이 2개 보다적은 경우 avg hr 값은 null
  if (hrList.length < 2) return null;

  let result: number | null | hrValue = null;
  const isOptimisticEventDataUpdated = hrList.some(
    (hr) => hr === hrValue.optimisticEventDataUpdated
  );

  // hr에 "optimisticEventDataUpdated" 값이 있는 경우 sync icon on 표시 해주기 위한 과정
  if (isOptimisticEventDataUpdated) {
    result = hrValue.optimisticEventDataUpdated;
  } else {
    // hr값이 null인 케이스 두 가지
    // 1. Q비트
    // 2. Q비트 뒤에 있는 비트인 경우
    const filterHrListOnlyNumber = hrList
      .splice(1) //  validation1. 첫 번째 Beat 의 R-R HR 은 계산에서 제외
      .filter((value) => typeof value === 'number') as number[]; //  validation2. hr값이 숫자가 아닌 케이스(hr이 null경우 === 비트가 Q(noise)인 경우)

    // 10s strip에 hr값이 2개 보다적은 경우 avg hr 값은 null
    if (filterHrListOnlyNumber.length === 0) return null;
    const avg =
      filterHrListOnlyNumber.reduce((acc, cur) => acc + cur, 0) /
      filterHrListOnlyNumber.length;
    // 소수점 자리 버림
    result = Math.floor(avg);
  }

  return result;
};

type Beats = {
  waveformIndex: Array<WaveformIndex>;
  beatType: Array<BeatType | null>;
  hr: Array<number>;
};
// type BeatType = null | 0 | 1 | 2 | 3;
type WaveformIndex = number;
