import { useState } from 'react';
import styled from 'styled-components';

import { TEN_SEC_STRIP_DETAIL } from 'constant/ChartEditConst';
import { ShapeReviewSectionArea } from 'constant/ShapeReviewConst';

import useChartList from 'component/hook/useChartList';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { CheckBoxStatus } from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';
import TenSecStripDetailContainer from 'redux/container/fragment/test-result/share/tenSecStripDetail/TenSecStripDetailContainer';
import EventsPanelListFragmentContainer from 'redux/container/fragment/test-result/shape-review/eventsPanel/EventsPanelListFragmentContainer';
import EventsPanelNavigationFragmentContainer from 'redux/container/fragment/test-result/shape-review/eventsPanel/EventsPanelNavigationFragmentContainer';

import {
  selectEventDataOfSelectedForm,
  selectSelectedInfoOfEventsPanel,
} from 'redux/duck/shapeReviewDuck';
import { EventsPanelSelectedInfo } from 'redux/duck/shapeReview/shapeReviewDuckType';

const TenSecStripDetailContainerWrapper = styled.div<{
  activePanel?: string;
}>`
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  ${({ activePanel, theme }) =>
    activePanel === ShapeReviewSectionArea.EVENTS &&
    `
    border-left: 1px solid ${theme.color.BLUE_70};
    border-right: 1px solid ${theme.color.BLUE_70};
    border-bottom: 1px solid ${theme.color.BLUE_70};
  `}
`;

function EventsPanelFragmentContainer({ activePanel }) {
  const [checkBoxStatus, setCheckBoxStatus] = useState<CheckBoxStatus>({
    isIndeterminate: false,
    isChecked: false,
  });

  const panelList: any = useShallowEqualSelector(selectEventDataOfSelectedForm);
  const selectedInfoOfEventsPanel = useShallowEqualSelector(
    selectSelectedInfoOfEventsPanel
  ) as EventsPanelSelectedInfo;

  const {
    gridLayoutRef,
    chartItemRectangle,
    //
    chartList,
    //
    selectedItemList,
    lastSelectedSectionInfo,
    setSelectedItemList,
    setLastSelectedSectionInfo,
    //
    onClickGridItem,
  } = useChartList({
    panelType: ShapeReviewSectionArea.EVENTS,
    panelList,
    selectedInfo: selectedInfoOfEventsPanel,
    setCheckBoxStatus,
  });

  return (
    <>
      <EventsPanelNavigationFragmentContainer
        checkBoxStatus={checkBoxStatus}
        setCheckBoxStatus={setCheckBoxStatus}
      />
      {
        <>
          <EventsPanelListFragmentContainer
            // local state
            checkBoxStatus={checkBoxStatus}
            // useChartList Hook state
            gridLayoutRef={gridLayoutRef}
            chartItemRectangle={chartItemRectangle}
            chartList={chartList}
            selectedItemList={selectedItemList}
            lastSelectedSectionInfo={lastSelectedSectionInfo}
            setSelectedItemList={setSelectedItemList}
            setLastSelectedSectionInfo={setLastSelectedSectionInfo}
            //
            onClickGridItem={onClickGridItem}
          />
          <TenSecStripDetailContainerWrapper activePanel={activePanel}>
            <TenSecStripDetailContainer
              tenSecStripTabValue={TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW}
            />
          </TenSecStripDetailContainerWrapper>
        </>
      }
    </>
  );
}

export default EventsPanelFragmentContainer;
