import { CHART_CONST } from 'constant/ChartConst';

export const THIRTY_SEC_WAVEFORM_LENGTH = CHART_CONST.XAXIS_MAX;

/**
 * onset 과 termination 의 waveformIndex 가 일반적인 00초 ~ 30초 또는 30초 ~ 60초 이면서 길이가 30초인 구간인지 여부를 반환
 * @param {WaveformIndex} owi Onset Waveform Index
 * @param {WaveformIndex} twi Termination Waveform Index
 * @returns
 */
export const isGeneralSection = (owi, twi) =>
  twi - owi === THIRTY_SEC_WAVEFORM_LENGTH &&
  owi % THIRTY_SEC_WAVEFORM_LENGTH === 0;
