import {
  postBeatsRequested as postHrReviewBeatsRequested,
  patchBeatsRequested as patchHrReviewBeatsRequested,
  deleteBeatsRequested as deleteHrReviewBeatsRequested,
  setCaliperPlotLines as setHrReviewCaliperPlotLines,
  setIsCaliperMode as setHrReviewIsCaliperMode,
  setIsTickMarksMode as setHrReviewIsTickMarksMode,
} from 'redux/duck/hrReviewDuck';
import {
  postBeatsRequested as postEventReviewBeatsRequested,
  patchBeatsRequested as patchEventReviewBeatsRequested,
  deleteBeatsRequested as deleteEventReviewBeatsRequested,
  setCaliperPlotLines as setEventReviewCaliperPlotLines,
  setIsCaliperMode as setEventReviewIsCaliperMode,
  setIsTickMarksMode as setEventReviewIsTickMarksMode,
} from 'redux/duck/testResultDuck';
import {
  postBeatsRequested as postBeatReviewBeatsRequested,
  patchBeatsRequested as patchBeatReviewBeatsRequested,
  deleteBeatsRequested as deleteBeatReviewBeatsRequested,
  setCaliperPlotLines as setBeatReviewCaliperPlotLines,
  setIsCaliperMode as setBeatReviewIsCaliperMode,
  setIsTickMarksMode as setBeatReviewIsTickMarksMode,
} from 'redux/duck/beatReviewDuck';
import {
  postBeatsRequested as postShapeReviewBeatsRequested,
  patchBeatsRequested as patchShapeReviewBeatsRequested,
  patchBeatsByWaveformIndexRequested as patchShapeReviewEventsRequested,
  deleteBeatsRequested as deleteShapeReviewBeatsRequested,
  setCaliperPlotLines as setShapeReviewCaliperPlotLines,
  setIsCaliperMode as setShapeReviewIsCaliperMode,
  setIsTickMarksMode as setShapeReviewIsTickMarksMode,
  deleteBeatsByWaveformIndexRequested,
  deleteBeatsByFormIdRequested,
} from 'redux/duck/shapeReviewDuck';

/**
 *
 * @param {*} state
 * @returns {{HR_REVIEW: {onsetWaveformIdx: number | undefined, terminationWaveformIdx: number | undefined},EVENT_REVIEW: {onsetWaveformIdx: number | undefined, terminationWaveformIdx: number | undefined},BEAT_REVIEW: {onsetWaveformIdx: number | undefined, terminationWaveformIdx: number | undefined}}}
 */
export const selectTenSecStripDuckMap = (state) => {
  const duckMap = {
    HR_REVIEW: state.hrReviewReducer.tenSecStripDetail,
    EVENT_REVIEW: state.testResultReducer.eventReview.tenSecStripDetail,
    BEAT_REVIEW: state.beatReviewReducer.tenSecStripDetail,
    SHAPE_REVIEW: state.shapeReviewReducer.tenSecStripDetail,
  };
  return duckMap;
};

export const selectCaliperDuckMap = (state) => {
  const duckMap = {
    HR_REVIEW: state.hrReviewReducer.caliper,
    EVENT_REVIEW: state.testResultReducer.caliper,
    BEAT_REVIEW: state.beatReviewReducer.caliper,
    SHAPE_REVIEW: state.shapeReviewReducer.caliper,
  };
  return duckMap;
};

export const selectBeatEventDetailUpdateDuckMap = (state) => {
  const duckMap = {
    HR_REVIEW: null, // optimistic update 미지원으로 avg hr update 기능 없음
    EVENT_REVIEW: state.testResultReducer.beatsNEctopicList,
    BEAT_REVIEW: state.beatReviewReducer.tenSecStripDetail,
    SHAPE_REVIEW: null, // optimistic update 미지원으로 avg hr update 기능 없음
  };
  return duckMap;
};

export const getUpdateBeatActionCreatorDuckMap = (mode, ...params) => {
  const duckMap = {
    HR_REVIEW() {
      switch (mode) {
        case 'post':
          return postHrReviewBeatsRequested(...params);
        case 'patch':
          return patchHrReviewBeatsRequested(...params);
        case 'delete':
          return deleteHrReviewBeatsRequested(...params);
        default:
          return;
      }
    },
    EVENT_REVIEW() {
      switch (mode) {
        case 'post':
          return postEventReviewBeatsRequested(...params);
        case 'patch':
          return patchEventReviewBeatsRequested(...params);
        case 'delete':
          return deleteEventReviewBeatsRequested(...params);
        default:
          return;
      }
    },
    BEAT_REVIEW() {
      switch (mode) {
        case 'post':
          return postBeatReviewBeatsRequested(...params);
        case 'patch':
          return patchBeatReviewBeatsRequested(...params);
        case 'delete':
          return deleteBeatReviewBeatsRequested(...params);
        default:
          return;
      }
    },
    SHAPE_REVIEW() {
      switch (mode) {
        case 'post':
          return postShapeReviewBeatsRequested(...params);
        case 'patchEvents':
          return patchShapeReviewEventsRequested(...params);
        case 'patch':
          return patchShapeReviewBeatsRequested(...params);
        case 'deleteEvents':
          return deleteBeatsByWaveformIndexRequested(...params);
        case 'deleteForms':
          return deleteBeatsByFormIdRequested(...params);
        case 'delete':
          return deleteShapeReviewBeatsRequested(...params);
        default:
          return;
      }
    },
  };
  return duckMap;
};

export const getCaliperActionCreatorDuckMap = (mode, ...params) => {
  const duckMap = {
    HR_REVIEW() {
      switch (mode) {
        case 'setCaliperPlotLines':
          return setHrReviewCaliperPlotLines(...params);
        case 'setIsCaliperMode':
          return setHrReviewIsCaliperMode(...params);
        case 'setIsTickMarksMode':
          return setHrReviewIsTickMarksMode(...params);
        default:
          return;
      }
    },
    EVENT_REVIEW() {
      switch (mode) {
        case 'setCaliperPlotLines':
          return setEventReviewCaliperPlotLines(...params);
        case 'setIsCaliperMode':
          return setEventReviewIsCaliperMode(...params);
        case 'setIsTickMarksMode':
          return setEventReviewIsTickMarksMode(...params);
        default:
          return;
      }
    },
    BEAT_REVIEW() {
      switch (mode) {
        case 'setCaliperPlotLines':
          return setBeatReviewCaliperPlotLines(...params);
        case 'setIsCaliperMode':
          return setBeatReviewIsCaliperMode(...params);
        case 'setIsTickMarksMode':
          return setBeatReviewIsTickMarksMode(...params);
        default:
          return;
      }
    },
    SHAPE_REVIEW() {
      switch (mode) {
        case 'setCaliperPlotLines':
          return setShapeReviewCaliperPlotLines(...params);
        case 'setIsCaliperMode':
          return setShapeReviewIsCaliperMode(...params);
        case 'setIsTickMarksMode':
          return setShapeReviewIsTickMarksMode(...params);
        default:
          return;
      }
    },
  };
  return duckMap;
};
