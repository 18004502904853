export enum EventHandlerTarget {
  tenSecStrip = 'tenSecStrip',
  beatEditContextMenu = 'beatEditContextMenu',
}

interface PriorityInfoItem {
  priority: number;
  class?: any;
  subPriority?: any;
}

type PriorityInfo = Record<EventHandlerTarget, PriorityInfoItem>;

const priorityInfo: PriorityInfo = {
  [EventHandlerTarget.tenSecStrip]: { priority: 2 },
  [EventHandlerTarget.beatEditContextMenu]: {
    priority: 1,
    // subPriority, class의미: 같은 priority 였을때 사용하기 위함
    // class: ''
    // subPriority: ''
  },
};

interface AddKeyupHandler {
  eventHandler: KeyupHandler;
  target: EventHandlerTarget;
}

type KeyupHandler = (event: CustomKeyboardEvent) => void;

interface CustomKeyboardEvent extends KeyboardEvent {
  // preventImmediatePropagation: () => void;
}

let eventHandlerInfoList: AddKeyupHandler[] = [];
let immediatePropagationStopped = false;

function addKeyupHandler({ eventHandler, target }: AddKeyupHandler): void {
  const args = arguments[0];
  eventHandlerInfoList.push(args);
}

function removeKeyupHandler(eventHandler: KeyupHandler): void {
  const index = eventHandlerInfoList.findIndex((eventHandlerInfo) => {
    return eventHandlerInfo.eventHandler === eventHandler;
  });
  if (index !== -1) {
    eventHandlerInfoList.splice(index, 1);
  }
}

window.addEventListener('keyup', (event: KeyboardEvent) => {
  const ordered = eventHandlerInfoList.sort((a, b) => {
    return priorityInfo[a.target].priority - priorityInfo[b.target].priority;
  });
  if (ordered.length > 0) {
    for (let i = 0; i <= ordered.length; i++) {
      if (immediatePropagationStopped) {
        immediatePropagationStopped = false;
        break;
      }
      const eventHandlerInfo = ordered[i];
      eventHandlerInfo?.eventHandler(event);

      // priority 조건에 따라서 event 실행을 break 할 상황이에 맞춰 바뀔 코드
      // # 예상 케이스
      //   - 같은 priority가 있을 수 있음.
      //   - 다른 priority이지만 같이 수행해야 하는 케이스 잇을 수 있음.
      ordered.length > 1 && (immediatePropagationStopped = true);
    }
  }
});
export { addKeyupHandler, removeKeyupHandler };
