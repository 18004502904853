import { TEN_SEC_STRIP_DETAIL } from 'constant/ChartEditConst';

import {
  OptimisticEventDataUpdateForBeats,
  optimisticEventDataUpdateCase,
} from '@type/optimisticUpdate/type';

import {
  ReqBody,
  UpdateReqOption,
  eventReviewOptimisticEventDataUpdateForBeatEvent,
} from '../optimisticEventDataUpdateForBeatEvent';

export const optimisticEventDataUpdateOptionMap = {
  [TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU]:
    optimisticEventDataUpdateCase.patchBeatsByRangeList,
  [TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW]:
    optimisticEventDataUpdateCase.patchBeatsByWaveformIndexList,
};

export interface Validation {
  reqBody: ReqBody;
  updateReqOption: UpdateReqOption;
  mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
  copyMergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
}
export class PatchBeat extends eventReviewOptimisticEventDataUpdateForBeatEvent {
  result: any;
  reqParam: any;

  validation({
    reqBody,
    updateReqOption,
    mergedBeatsNEctopicList,
    copyMergedBeatsNEctopicList,
  }: Validation): OptimisticEventDataUpdateForBeats {
    return mergedBeatsNEctopicList;
  }
}
