import { memo } from 'react';

import { shapeReviewLabel } from 'constant/ShapeReviewConst';

import Tooltip from 'component/ui/tooltip/Tooltip';

import {
  ChartIdLabel,
  ChartInfoWrapper,
  ChartLeftInfoWrapper,
  ChartRightInfoWrapper,
  EditedLabel,
  EventsNumberLabel,
  IconWrapper,
  StyledFolderIcon,
} from './ChartCommonUI';

import { InfoIconWrapper } from '../../FormsPanel/FormsPanelFooterFragment';

const EXCEPTION_LIST = [shapeReviewLabel.Edited, shapeReviewLabel.Etc];

function ChartFormsInfoFragment({
  isEdited,
  isSelected,
  formLabel,
  eventCount,
}: any) {
  return (
    <ChartInfoWrapper className="ChartInfoWrapper">
      <ChartLeftInfoWrapper
        className="ChartLeftInfoWrapper"
        isSelected={isSelected}
        isEdited={isEdited}>
        <IconWrapper className="beatIcon">
          <StyledFolderIcon disabled={false} />
        </IconWrapper>
        <ChartIdLabel className="chartIdLabel">{formLabel}</ChartIdLabel>
        {EXCEPTION_LIST.some((label) => formLabel.includes(label)) &&
          !isEdited && (
            <Tooltip
              title={
                formLabel.includes(shapeReviewLabel.Edited)
                  ? '편집된 이벤트가 Edited Form에 추가됩니다.'
                  : '다른 Form과 유사하지 않은 형태의 이벤트가 분류됩니다.'
              }
              placement="bottom"
              offset={[0, 0]}>
              <InfoIconWrapper />
            </Tooltip>
          )}
        {isEdited && <EditedLabel className="editedLabel">Edited</EditedLabel>}
      </ChartLeftInfoWrapper>
      <ChartRightInfoWrapper
        className="ChartRightInfoWrapper"
        isSelected={isSelected}>
        <EventsNumberLabel>{eventCount.toLocaleString()}</EventsNumberLabel>
        {!EXCEPTION_LIST.some((label) => formLabel.includes(label)) && (
          <Tooltip title="포함된 Event 개수" placement="bottom" offset={[0, 0]}>
            <InfoIconWrapper />
          </Tooltip>
        )}
      </ChartRightInfoWrapper>
    </ChartInfoWrapper>
  );
}

export default memo(ChartFormsInfoFragment, (prevProps, nextProps) => {
  return (
    prevProps.isEdited === nextProps.isEdited &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.chartInfo.formInfo.id === nextProps.chartInfo.formInfo.id
  );
});
