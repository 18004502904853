import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Const from 'constant/Const';
import {
  CheckBoxAll,
  POSITION_MOVE_TYPE,
  ShapeReviewSectionArea,
} from 'constant/ShapeReviewConst';
import { SortOption } from 'constant/SortConst';

import NumberUtil from 'util/NumberUtil';
import { onSelectAll } from 'util/inputUtil';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import FormsEventsPanelNavigationFragment from 'component/fragment/test-result/shape-review/share/FormsEventsPanelNavigationFragment';

import {
  selectActivePanel,
  selectClickedInfo,
  selectEventPoolingStateOfSelectedEvent,
  selectFormPanelDetailOfFirstIndexOfLastSelectedSection,
  selectNumberOfClickedForms,
  selectSelectAllInfo,
  setOrderingContextmenuRequest,
  setPage,
  setSelectAll,
} from 'redux/duck/shapeReviewDuck';
import {
  ClickedInfo,
  PanelSizeItemInfo,
  SelectAll,
  SetPage,
} from 'redux/duck/shapeReview/shapeReviewDuckType';
import { inputRef } from '@type/reactTypes';

import { Pagination, ActivePanelType } from '../ShapeReviewFragmentContainer';
import { IFormsPanelNavigationFragmentContainer } from '../formsPanel/FormsPanelNavigationFragmentContainer';

export interface NavigationOption {
  type: Extract<ShapeReviewSectionArea, 'FORMS' | 'EVENTS'>;
}

export interface IFormsEventsPanelNavigationFragmentContainer
  extends IFormsPanelNavigationFragmentContainer {
  navigationOption: NavigationOption;
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  //
  panelPaginationInfoOfSelectedEvent: PanelSizeItemInfo;
}

function FormsEventsPanelNavigationFragmentContainer({
  // props state
  checkBoxStatus,
  setCheckBoxStatus,
  navigationOption,
  // local state
  pagination,
  setPagination,
  // redux state
  panelPaginationInfoOfSelectedEvent,
}: IFormsEventsPanelNavigationFragmentContainer) {
  const navigationType = navigationOption.type;

  const dispatch = useDispatch();
  const inputRef = useRef() as inputRef;

  // redux selectors
  const { eventType } = useShallowEqualSelector(
    selectClickedInfo
  ) as ClickedInfo;
  const activePanel = useShallowEqualSelector(
    selectActivePanel
  ) as ActivePanelType;
  const selectAllState = useShallowEqualSelector(
    (state: any) => selectSelectAllInfo(state)[navigationType]
  ) as CheckBoxAll;
  const numberOfClickedForms = useShallowEqualSelector(
    selectNumberOfClickedForms
  ) as number;
  const { calledType, pending, pauseState } = useShallowEqualSelector(
    selectEventPoolingStateOfSelectedEvent
  ) as any;

  const isOpenOrderingContextmenu = useShallowEqualSelector(
    (state) =>
      state.shapeReviewReducer.shapeReviewState.isOrderingBeatContextmenu
  ) as boolean;

  const orderingTypeSetting = useShallowEqualSelector(
    (state) => state.shapeReviewReducer.shapeReviewState.orderingTypeSetting
  ) as SortOption;

  const {
    formInfo: { formRankNumber },
  } = useShallowEqualSelector(
    selectFormPanelDetailOfFirstIndexOfLastSelectedSection
  ) as { formInfo: { formRankNumber: number } };

  // redux dispatches
  const handleSetSelectAll = ({ panelType, selectAllState }: SelectAll) => {
    return dispatch(setSelectAll({ panelType, selectAllState }));
  };
  const handleSetPage = ({ panelType, setPageType, value }: SetPage) => {
    return dispatch(setPage({ panelType, setPageType, value }));
  };
  const handleOrderingContextmenu = (isOpenOrderingContextmenu: boolean) =>
    dispatch(setOrderingContextmenuRequest(isOpenOrderingContextmenu));

  let disablePrevIcon = true;
  let disableNextIcon = true;
  if (!!panelPaginationInfoOfSelectedEvent) {
    disablePrevIcon = panelPaginationInfoOfSelectedEvent.currentPage === 1;
    disableNextIcon =
      panelPaginationInfoOfSelectedEvent.currentPage ===
      panelPaginationInfoOfSelectedEvent.totalPageSize;
  }

  // setting currentPage
  useEffect(() => {
    if (!inputRef.current) return;

    const currentPage = String(pagination.currentPage);
    inputRef.current.style.width =
      (currentPage.length === 0 ? 3 : currentPage.length + 2) + 'ch';
  }, [pagination]);

  useEffect(() => {
    if (setCheckBoxStatus) {
      if (selectAllState === CheckBoxAll.Indeterminate) {
        setCheckBoxStatus({
          isIndeterminate: true,
          isChecked: true,
        });
      }
      if (selectAllState === CheckBoxAll.CheckedAll) {
        setCheckBoxStatus({
          isIndeterminate: false,
          isChecked: true,
        });
      }
      if (selectAllState === CheckBoxAll.None) {
        setCheckBoxStatus({
          isIndeterminate: false,
          isChecked: false,
        });
      }
    }
  }, [selectAllState]);

  const onChangeInputPosition: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const {
      target: { value },
    } = event;
    let inputValue = NumberUtil.getNumbers(value);

    if (inputValue > panelPaginationInfoOfSelectedEvent?.totalPageSize) {
      inputValue = panelPaginationInfoOfSelectedEvent?.totalPageSize;
    }

    if (inputValue === '0') {
      inputValue = 1;
    }

    setPagination((prev) => {
      return {
        ...prev,
        currentPage: inputValue,
      };
    });
  };

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    if (e.key === Const.KEY_MAP.ENTER && !!pagination.currentPage) {
      (e.target as HTMLInputElement).blur();
      handleSetPage({
        panelType: navigationType,
        setPageType: POSITION_MOVE_TYPE.JUMP,
        value: +pagination.currentPage,
      });
    }
  };

  return (
    <FormsEventsPanelNavigationFragment
      // props state
      navigationOption={navigationOption}
      panelPaginationInfoOfSelectedEvent={panelPaginationInfoOfSelectedEvent}
      pagination={pagination}
      setPagination={setPagination}
      checkBoxStatus={checkBoxStatus}
      setCheckBoxStatus={setCheckBoxStatus}
      // redux state
      activePanel={activePanel}
      numberOfClickedForms={numberOfClickedForms}
      poolingStateOfPanel={{ calledType, pending, pauseState }}
      eventType={eventType}
      formRankNumber={formRankNumber}
      isOpenOrderingContextmenu={isOpenOrderingContextmenu}
      orderingTypeSetting={orderingTypeSetting}
      // redux dispatches
      handleSetSelectAll={handleSetSelectAll}
      handleSetPage={handleSetPage}
      handleOrderingContextmenu={handleOrderingContextmenu}
      // local state
      inputRef={inputRef}
      paginationIconStatus={{ disablePrevIcon, disableNextIcon }}
      // local state - event listener callback function
      onKeyUp={onKeyUp}
      onSelectAll={onSelectAll}
      onChangeInputPosition={onChangeInputPosition}
    />
  );
}

export default FormsEventsPanelNavigationFragmentContainer;
