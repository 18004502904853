import styled from 'styled-components';

export const ButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 24px;
  position: relative;

  > {
    z-index: 0;
    :first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    :last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-of-type, :last-of-type) {
      border-radius: 0;
    }
    &:not(:first-of-type) {
      margin-left: -1px;
    }
    &:not(:last-of-type) {
      border-right-color: transparent;
    }
    div[data-clicked='true'] {
      border-right-color: initial;
      z-index: 1;
    }
  }
`;

function ButtonGroup(props) {
  const { children, style } = props;
  return <ButtonGroupWrapper style={style}>{children}</ButtonGroupWrapper>;
}

export default ButtonGroup;
