import { optimisticEventDataUpdate } from 'util/optimisticEventDataUpdate/optimisticEventDataUpdate';

export type EventEditErrorClass =
  typeof EventEditErrorClass[keyof typeof EventEditErrorClass];
export type EventEditValidation =
  typeof EventEditValidation[keyof typeof EventEditValidation];

export const EventEditErrorClass = {
  OPTIMISTIC_EVENT_UPDATE: 'OPTIMISTIC_EVENT_UPDATE',
} as const;
export const EventEditValidation = {
  S_BEAT_IN_AF: {
    type: 'S_BEAT_IN_AF',
    msg: 'CAN NOT CREATE S BEAT IN AF',
  },
  OVER_HR_LIMIT: {
    type: `OVER_HR_${optimisticEventDataUpdate.basisBpm}`,
    msg: `CAN NOT CREATE BEAT OVER HR ${optimisticEventDataUpdate.basisBpm}`,
  },
} as const;
