import React from 'react';
import styled, { useTheme } from 'styled-components';

/**
 * @typedef TypeContentProps
 * @prop {string} type 항목 명
 * @prop {*} content 항목 값
 * @prop {React.ReactNode} [children] 항목 값 우측에 제공할 요소, 기본적으로 값 영역에 Hover 시 보여짐
 * @prop {boolean} [showChildren] children 요소가 항상 보여지도록 상태고정 여부
 * @prop {boolean} [isFreeHeightContent] content 또는 children 으로 전달되는 요소의 높이가 16px을 넘어서는 경우 true 설정 필요, 기본은 false
 */
/**
 * Side Panel 에서 항목의 이름과 값을 표현
 *
 *
 * **Ref. (23" 08. 23.)**
 *
 * https://www.figma.com/file/z17YYGRtWzt0PQ4f5tbICm/05-08-%5BEvent-Review%5D-리포트-담기?node-id=1897%3A609519&mode=dev
 *
 * @param {TypeContentProps} props
 * @returns
 */
function TypeContent(props) {
  const {
    type,
    content,
    children,
    showChildren,
    isFreeHeightContent = false,
  } = props;

  const newChildren = React.Children.map(children, (child) =>
    React.isValidElement(child) ? React.cloneElement(child, { type }) : child
  );
  return (
    <Wrapper>
      <Type>{type}</Type>
      <ContentWrapper
        showChildren={!!showChildren}
        isPointer={!!children && !showChildren}
        isFreeHeightContent={isFreeHeightContent}>
        {content && `${content}`}
        <ChildrenWrapper>{newChildren}</ChildrenWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}

// TypeContent's Styled Component's
const Wrapper = styled.div`
  // min-width: 100%;
  // max-width: 100%;
  padding: 4px 0;
  gap: 2px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Type = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 130%;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;

const ChildrenWrapper = styled.div`
  visibility: hidden;

  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  min-height: 16px;
  ${(props) =>
    !props.isFreeHeightContent &&
    `
  max-height: 16px;
  `}

  padding: 2px 0;
  gap: 4px;

  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
  color: ${(props) => props.theme.color.COOL_GRAY_90};

  cursor: default;
  ${({ showChildren }) =>
    showChildren && `& ${ChildrenWrapper} { visibility: visible; }`}
  :hover {
    & ${ChildrenWrapper} {
      visibility: visible;
    }
  }
`;

/**
 * @typedef SelectStripContentProps
 * @prop {string} label
 * @prop {React.ReactNode} [labelButton]
 * @prop {string} [content]
 * @prop {React.ReactNode} help
 * @prop {boolean} selected
 * @prop {(event: MouseEvent) => void} [onClick]
 * @prop {(event: MouseEvent) => void} [onClickLabelButton]
 * @prop {React.ReactNode} children
 */
/**
 *
 * @param {SelectStripContentProps} props
 * @returns
 */
export function SelectStripContent(props) {
  const {
    label,
    labelButton,
    content,
    help,
    selected,
    onClick,
    onClickLabelButton,
    children,
  } = props;
  const theme = useTheme();

  return (
    <SelectStripWrapper
      selected={selected}
      isRemoved={!content}
      onClick={onClick}>
      <SelectStripLabelWrapper>
        {label}
        <SelectStripLabelButtonWrapper onClick={onClickLabelButton}>
          {labelButton}
        </SelectStripLabelButtonWrapper>
      </SelectStripLabelWrapper>
      {content && (
        <SelectStripContentWrapper selected={selected}>
          <Type
            style={{
              padding: '0 3px',
              color: theme.color.WHITE,
              backgroundColor: selected
                ? theme.color.BLUE_70
                : theme.color.COOL_GRAY_70,
              borderRadius: 2,
              width: 'fit-content',
            }}>
            Selected strip
          </Type>
          <ContentWrapper showChildren={false} isPointer={!!children}>
            {`${content}`}
            <ChildrenWrapper>{children}</ChildrenWrapper>
          </ContentWrapper>
          {selected && help}
        </SelectStripContentWrapper>
      )}
      {!content && help}
    </SelectStripWrapper>
  );
}

// SelectStripContent's Styled Component's
const SelectStripWrapper = styled.div`
  margin-bottom: 14px;
  padding: 10px;
  gap: 8px;

  cursor: pointer;

  border-radius: 6px;
  border: 1px solid
    ${(props) =>
      props.selected
        ? props.theme.color.BLUE_70
        : props.theme.color.COOL_GRAY_50};
  background: ${(props) => props.theme.color.WHITE};

  display: flex;
  flex-direction: column;

  &:hover {
    border: 1px solid ${(props) => props.theme.color.BLUE_70};

    ${(props) =>
      props.isRemoved &&
      `
      ${SelectStripLabelButtonWrapper} {
          color: ${props.theme.color.COOL_GRAY_90};

          & svg path {
            fill: ${props.theme.color.COOL_GRAY_90};
          }
      }
      `}
  }
`;

const SelectStripLabelWrapper = styled.div`
  height: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 11px;
  font-weight: 700;
  line-height: 130%; /* 14.3px */
  color: ${(props) => props.theme.color.COOL_GRAY_90};
`;

const SelectStripLabelButtonWrapper = styled.div`
  padding: 2px 4px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.color.WHITE};

  display: flex;
  align-items: center;

  cursor: pointer;

  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: ${(props) => props.theme.color.COOL_GRAY_70};

  & svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${(props) => props.theme.color.COOL_GRAY_70};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.color.COOL_GRAY_30};
    color: ${(props) => props.theme.color.COOL_GRAY_90};

    & svg path {
      fill: ${(props) => props.theme.color.COOL_GRAY_90};
    }
  }
`;

const SelectStripContentWrapper = styled(Wrapper)`
  padding: 8px;
  gap: 4px;

  border-radius: 6px;

  background: ${(props) =>
    props.selected
      ? props.theme.color.BLUE_30
      : props.theme.color.COOL_GRAY_30};
`;

export default TypeContent;
