import { Dispatch, createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ShapeReviewSectionArea,
  ShapeReviewState,
} from 'constant/ShapeReviewConst';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import ShapeReviewFragment from 'component/fragment/test-result/shape-review';

import {
  BeatPostprocess,
  ChartListItem,
  Checkbox,
  ClickedInfo,
  LastSelectedSectionInfo,
  PoolingData,
  SelectedItemInfo,
} from 'redux/duck/shapeReview/shapeReviewDuckType';
import {
  getArrangeRequiredStatusRequested,
  getClusteringStatisticsRequested,
  patchBeatPostprocessRequested,
  selectBeatPostprocessState,
  setActivePanel,
} from 'redux/duck/shapeReviewDuck';
import { NetworkResponseError } from '@type/types';
import { divRef } from '@type/reactTypes';
import { EventSection } from '@type/ecgEventType/baseEventType';

export interface Pagination {
  currentPage: string | number;
}
export interface CheckBoxStatus {
  isIndeterminate: boolean;
  isChecked: boolean;
}

export interface ChartItemRectangle {
  width: number;
  height: number;
}
export interface ShapeReviewFragmentProps {
  activePanel: ActivePanelType;
  handleSetActivePanel: Dispatch<ActivePanel>;
  //
  beatPostprocessPending: boolean;
  beatPostprocessError: NetworkResponseError;
  beatPostprocessProgress: number;
  handlePatchBeatPostprocessRequested: () => void;
}

export interface PanelListFragmentContainerProps {
  //
  checkBoxStatus: CheckBoxStatus;
  //
  gridLayoutRef: divRef;
  chartList: ChartListItem[];
  chartItemRectangle: ChartItemRectangle;
  selectedItemList: Map<number, SelectedItemInfo>;
  setSelectedItemList: React.Dispatch<
    React.SetStateAction<Map<number, SelectedItemInfo>>
  >;
  lastSelectedSectionInfo: LastSelectedSectionInfo;
  setLastSelectedSectionInfo: React.Dispatch<
    React.SetStateAction<LastSelectedSectionInfo>
  >;
  //
  onClickGridItem: (
    e: React.MouseEvent<HTMLDivElement>,
    clickedData: ChartListItem
  ) => void;
}

export interface PanelListFragmentProps
  extends PanelListFragmentContainerProps {
  activePanel: ActivePanelType;
  poolingStateOfPanel: {
    calledType: string;
    pending: boolean;
    pauseState?: boolean;
  };
  panelPatchedList: number[];
  clickedInfo?: ClickedInfo;
  isCoupletEctopicTypeClicked: boolean;
  eventPoolingProgressPercentage?: number;
  formPanelSelectedItemListLength?: number;
  pendingOfEventPoolingStateOfSelectedEvent?: Pick<PoolingData, 'pending'>;
  formPanelDetailOfFirstIndexOfLastSelectedSection?: ChartListItem;
  onClickPause?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  onClickFetchingAgain?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  handleSelectedCheckboxStatus?: (CheckBoxStatus: Checkbox) => void;
}

export interface StatisticData {
  AF: number;
  PAUSE: number;
  OTHERS: number;
  PTE: number;
  //
  IsoAPC: number;
  CoupletAPC: number;
  SVT: number;
  IsoVPC: number;
  CoupletVPC: number;
  VT: number;
}

export type ActivePanelType = Extract<
  ShapeReviewSectionArea,
  'FORMS' | 'EVENTS'
>;
export interface ActivePanel {
  activePanel: ActivePanelType;
}

export const EventTypeContext = createContext<{
  eventType: EventSection | '';
  setEventType: (eventType: EventSection) => void;
}>({ eventType: '', setEventType: (type) => {} });

export const MAX_PENDING_SEC = 60; // 1분

function ShapeReviewFragmentContainer() {
  const dispatch = useDispatch();

  // redux selectors
  const activePanel = useShallowEqualSelector(
    (state: any) =>
      state.shapeReviewReducer.shapeReviewState[ShapeReviewState.ACTIVE_PANEL]
  ) as ActivePanelType;
  const { pending: beatPostprocessPending, error: beatPostprocessError } =
    useShallowEqualSelector(selectBeatPostprocessState) as Omit<
      BeatPostprocess,
      'data'
    >;

  // redux dispatches
  const handleGetArrangeRequiredStatusRequested = () =>
    dispatch(getArrangeRequiredStatusRequested());
  const handlePatchBeatPostprocessRequested = () =>
    dispatch(patchBeatPostprocessRequested());
  const handleGetClusteringStatisticsRequested = () =>
    dispatch(getClusteringStatisticsRequested());
  const handleSetActivePanel = ({ activePanel }: ActivePanel) =>
    dispatch(setActivePanel({ activePanel }));

  const [eventType, setEventType] = useState<EventSection | ''>('');
  const [beatPostprocessProgress, setBeatPostprocessProgress] =
    useState<number>(0);

  const setEventTypeFn = (eventType: EventSection): void => {
    setEventType(eventType);
  };

  // useEffect
  // Clustering Statistics 호출
  useEffect(() => {
    handleGetClusteringStatisticsRequested();
  }, []);
  // Arrange(재정렬) 필요 여부 파악
  useEffect(() => {
    handleGetArrangeRequiredStatusRequested();
  }, []);
  // Arrange(재정렬) process progress bar count
  useEffect(() => {
    if (beatPostprocessPending === false || !!beatPostprocessError) return;

    const timer = setInterval(() => {
      setBeatPostprocessProgress((oldProgress) => oldProgress + 1);
    }, 1000);

    return () => {
      clearInterval(timer);
      setBeatPostprocessProgress(0);
    };
  }, [beatPostprocessPending, beatPostprocessError]);

  return (
    <EventTypeContext.Provider
      value={{ eventType, setEventType: setEventTypeFn }}>
      <ShapeReviewFragment
        // local state
        beatPostprocessProgress={beatPostprocessProgress}
        // redux state
        activePanel={activePanel}
        beatPostprocessPending={beatPostprocessPending}
        beatPostprocessError={beatPostprocessError}
        // redux action
        handleSetActivePanel={handleSetActivePanel}
        handlePatchBeatPostprocessRequested={
          handlePatchBeatPostprocessRequested
        }
      />
    </EventTypeContext.Provider>
  );
}

export default ShapeReviewFragmentContainer;
