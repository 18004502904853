import styled from 'styled-components';
import { useIntl } from 'react-intl';

import Const from 'constant/Const';
import { EVENT_INFO_ITEM_TYPES } from 'constant/EventConst';

import useGetMoveChartListFunc from 'component/hook/useGetMoveChartListFunc';
import useKeyEventListener, {
  keyboardEventInterfaceMap,
} from 'component/hook/useKeyEventListener';

import Tooltip from 'component/ui/tooltip/Tooltip';
import { LocationIcon } from 'component/ui/icons';
import ShortcutText from 'component/ui/highlight/ShortcutText';

import { TooltipTitleWrapper } from '../hr-review/side-panel';

function OverallTogo(props) {
  const { type, selectedTimeMs, onClick } = props;
  const isOnset = type === EVENT_INFO_ITEM_TYPES.ONSET;
  const isTermination = type === EVENT_INFO_ITEM_TYPES.TERMINATION;

  const intl = useIntl();
  const moveAtOnChartList = useGetMoveChartListFunc(selectedTimeMs);

  useKeyEventListener(
    Const.EVENT_TYPE.KEYDOWN,
    [Const.KEY_MAP.COMMA, Const.KEY_MAP.PERIOD],
    onKeyDown,
    keyboardEventInterfaceMap.key
  );

  function onKeyDown(event) {
    if (selectedTimeMs === undefined) return;

    const isClickedOnset = isOnset && event.key === Const.KEY_MAP.COMMA;
    const isClickedTermination =
      isTermination && event.key === Const.KEY_MAP.PERIOD;

    if (isClickedOnset) {
      moveAtOnChartList(selectedTimeMs);
    } else if (isClickedTermination) {
      moveAtOnChartList(selectedTimeMs);
    }
  }

  return (
    <Wrapper
      onClick={() => {
        onClick?.() || moveAtOnChartList(selectedTimeMs);
      }}>
      <Tooltip
        title={
          <TooltipTitleWrapper>
            {intl.formatMessage({
              id: '99-OverallTogo-iconButton-tooltip-title',
              description: '이 시점으로 이동 / Jump to this point',
              defaultMessage: '이 시점으로 이동',
            })}
            {isOnset && <ShortcutText text="," />}
            {isTermination && <ShortcutText text="." />}
          </TooltipTitleWrapper>
        }
        placement="top">
        <LocationIcon />
      </Tooltip>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 20px;
  height: 20px;

  cursor: pointer;
  order: 1;

  & svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${(props) => props.theme.color.COOL_GRAY_60};
    }
  }
  &:hover svg {
    path {
      fill: ${(props) => props.theme.color.COOL_GRAY_90};
    }
  }
`;

export default OverallTogo;
